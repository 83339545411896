import { useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Types from '~/types/api'
import { RequestToWorkWithQuery, SetUserTimezone } from './queries'
import { useHistory } from 'react-router-dom'
import TooltipContainer from '~/components/tooltip/tooltip-container'
import jstz from 'jstimezonedetect'

import storage from '~/utils/session-storage'
import Loader from '../loader'

import { BeforeAuthView, DEFAULT_SUBBUBLE_TEXT } from './before-auth-view'
import { ChatContainerPure } from '../chat/pure-container'
import { EmbeddableChatHeader } from './header'
import { validateCredentials } from './utils'
import { CHAT_WIDGET_CATEGORY } from '../mixpanel'
import { TurtleCredentials } from '~/utils/google-auth-flow'
import mixpanel from 'mixpanel-browser'
import { getStoredUTMCodes, useTrackUTMCodes } from './utm'

export const EmbeddableChat = () => {
  const { location } = useHistory()
  const [feedId, setFeedId] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null)
  const [sendRequestToWork] = useMutation<
    Types.RequestToWorkWithQuery,
    Types.RequestToWorkWithQueryVariables
  >(RequestToWorkWithQuery)
  const [setTimezone] = useMutation<
    Types.SetUserTimezone,
    Types.SetUserTimezoneVariables
  >(SetUserTimezone)
  const trackUtmCodes = useTrackUTMCodes()

  const searchParams = useMemo(() => {
    const defaultValue: Record<string, string | undefined> = {}
    const valuesArr = location.search.substring(1).split('&')
    return valuesArr.reduce((result, item) => {
      const [key, value] = item.split('=')
      result[key] = value.includes('null')
        ? undefined
        : decodeURIComponent(value)
      return result
    }, defaultValue)
  }, [location.search])

  const identifyUser = useCallback((userId: string) => {
    trackUtmCodes(getStoredUTMCodes())

    const analyticsId = searchParams['analyticsId']
    if (analyticsId) {
      const mixpanelId = searchParams['mpId']
      if (mixpanelId) {
        mixpanel.alias(userId, mixpanelId)
      } else {
        mixpanel.identify(userId)
      }
      mixpanel.track('authSuccess', {
        app: CHAT_WIDGET_CATEGORY,
        userId,
      })
    }
  }, [])

  useEffect(() => {
    const analyticsId = searchParams['analyticsId']
    const mixpanelId = searchParams['mpId']

    if (analyticsId) {
      mixpanel.init(analyticsId)

      if (mixpanelId) {
        mixpanel.identify(mixpanelId)
      }
    }
  }, [])

  const handleAuthSuccess = (credentials: TurtleCredentials | null) => {
    setIsAuthorized(true)
    setIsWarningMessageVisible(true)
    if (credentials?.user_id) {
      identifyUser(credentials.user_id)
    }
  }

  const hideWarningMessage = () => setIsWarningMessageVisible(false)

  useEffect(() => {
    const credentials = storage.getItem('credentials')
    if (!credentials) {
      setIsAuthorized(false)
    } else {
      const areCredentialsValid = validateCredentials(credentials)

      if (areCredentialsValid) {
        setIsAuthorized(true)
        const credentialsObject = JSON.parse(credentials)
        identifyUser(credentialsObject['user_id'])
      } else {
        setIsAuthorized(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isAuthorized === true && !feedId) {
      const contractorId = searchParams['contractorId']
      const chatId = searchParams['chatId'] || searchParams['id']
      setTimezone({
        variables: {
          input: {
            source: 'browser_detection',
            timezone: jstz.determine().name(),
          },
        },
      })
      if (contractorId) {
        sendRequestToWork({
          variables: { input: { contractorId } },
        })
          .then(res => {
            setTimeout(
              () => setFeedId(res.data?.requestToWorkWith?.feedId ?? null),
              2500
            )
          })
          .catch(error => {
            setError(error.message)
          })
      } else if (chatId) {
        setFeedId(chatId)
      } else {
        setError('No ID provided either for chat or contractor')
      }
    }
  }, [isAuthorized, feedId])

  const theme = searchParams['theme']

  if (isAuthorized === false) {
    return (
      <BeforeAuthView
        avatarSrc={searchParams['avatarSrc']}
        bubbleText={searchParams['bubbleText']}
        subBubbleText={searchParams['bubbleBottomText']}
        chatTitle={searchParams['chatTitle']}
        headerColor={searchParams['headerColor']}
        headerTextColor={searchParams['headerTextColor']}
        calendarLink={searchParams['calendarLink']}
        calendarText={searchParams['calendarText']}
        theme={theme}
        onAuthSuccess={handleAuthSuccess}
      />
    )
  }

  if (!feedId && !error) {
    if (theme === 'dark') {
      return (
        <div className="dark-theme-loader">
          <Loader colorScheme="light" expandOnFullHeight={false} />
        </div>
      )
    }
    return <Loader colorScheme="dark" expandOnFullHeight={true} />
  } else if (!feedId) {
    return (
      <div className="emb-chat--error">
        <span>Error: {error}</span>
      </div>
    )
  }

  return (
    <>
      <ChatContainerPure
        chatId={feedId}
        theme={theme}
        header={
          <>
            <EmbeddableChatHeader
              chatTitle={searchParams['chatTitle']}
              headerColor={searchParams['headerColor']}
              headerTextColor={searchParams['headerTextColor']}
              theme={theme}
            />
            {isWarningMessageVisible && (
              <div className="before-chat-view--warning">
                <div>
                  Share anything you like below using chat.{' '}
                  {searchParams['warningText'] || DEFAULT_SUBBUBLE_TEXT}
                </div>
                <div
                  className="before-chat-view--warning-button"
                  onClick={hideWarningMessage}
                >
                  Got it
                </div>
              </div>
            )}
          </>
        }
      />
      <TooltipContainer />
    </>
  )
}
