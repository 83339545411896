import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import cnDedupe from 'classnames/dedupe'

import {CARD_LIST_CLASS, CARD_NODE_CONTAINER_CLASS} from './card-list-constants'
import {EXPAND_COLLAPSE_ANIMATION_TIMEOUT_MS} from './card-list-constants'

const CHILDREN_CONTAINER_CLASS = 'children-wrapper'


export default class CardListItemLayout extends React.Component {

  static propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    renderCard: PropTypes.func.isRequired,
    renderChildren: PropTypes.func.isRequired,

    filteringInfo: PropTypes.shape({
      hasPassingDescendants: PropTypes.bool.isRequired,
      numFailingActiveChildren: PropTypes.number.isRequired,
    }).isRequired,

    expandCollapseAnimationManager: PropTypes.object.isRequired,

    containerClasses: PropTypes.string,
  }

  static defaultProps = {
    containerClasses: undefined,
  }

  constructor() {
    super()
    this.state = {
      collapsingChildren: null,
      expandCollapseInitialHeight: 0,
    }
    this.containerNode = null
    this.handleContainerRef = (ref) => this.containerNode = ref
    this.lastChildren = null
    this.willAnimateExpandCollapse = false
    this.animationFns = null
    this.animationTid = null
  }

  render() {
    const {props} = this
    const containerClassName = cn(CARD_NODE_CONTAINER_CLASS, props.containerClasses)
    return (
      <div className={containerClassName} ref={this.handleContainerRef}>
        {props.renderCard()}
        {this.renderChildrenContent()}
      </div>
    )
  }

  renderChildrenContent() {
    const {state, props} = this
    const children = state.collapsingChildren || (props.isExpanded
      ? this.lastChildren = this.renderChildren()
      : null
    )
    if (!children) {
      return null
    }
    const cardListClass = cn(CARD_LIST_CLASS, this.willAnimateExpandCollapse && 'animating')
    const cardListStyle = (this.willAnimateExpandCollapse || null) && {
      height: props.isExpanded ? 0 : state.expandCollapseInitialHeight
    }
    return (
      <div className={cardListClass} style={cardListStyle}>
        <div className={CHILDREN_CONTAINER_CLASS}>
          {children}
        </div>
      </div>
    )
  }

  renderChildren() {
    const {filteringInfo} = this.props
    return (mayExpand(filteringInfo)
      ? this.props.renderChildren()
      : null
    )
  }

  get childCardListNode() {
    return this.containerNode.querySelector('.' + CARD_LIST_CLASS)
  }

  get childrenContainerNode() {
    return this.containerNode.querySelector('.' + CHILDREN_CONTAINER_CLASS)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevProps = this.props

    const prevIsExpanded = prevProps.isExpanded
    const nextIsExpanded = nextProps.isExpanded

    if (mayExpand(prevProps.filteringInfo) && mayExpand(nextProps.filteringInfo) &&
        prevIsExpanded !== null && nextIsExpanded !== prevIsExpanded) {
      this.willAnimateExpandCollapse = true
      if (!nextIsExpanded) {
        this.setState({
          expandCollapseInitialHeight: this.getChildrenHeight(),
          collapsingChildren: this.lastChildren
        })
      }
    }

    this.finishAnimation()
  }

  componentDidUpdate() {
    if (this.willAnimateExpandCollapse) {
      this.willAnimateExpandCollapse = false
      // This is needed due to a bug in React which leads to "animating" class
      // not being added for some reason when animating collapse.
      this.childCardListNode.className += ' animating'
      this.animationFns = this.props.isExpanded
        ? {start: this.startExpandAnimation, finish: this.finishExpandAnimation}
        : {start: this.startCollapseAnimation, finish: this.finishCollapseAnimation}
      const startAnimation = () => this.startAnimation()
      this.animationTid = setTimeout(startAnimation, 30)
    }
  }

  componentWillUnmount() {
    this.clearAnimationTimeout()
  }

  startAnimation() {
    if (!this.childCardListNode) {
      this.animationTid = null
      return
    }
    this.props.expandCollapseAnimationManager.setAnimating(true)
    this.animationFns.start.call(this)
    const finishAnimation = () => this.finishAnimation()
    this.animationTid = setTimeout(finishAnimation, EXPAND_COLLAPSE_ANIMATION_TIMEOUT_MS)
  }

  finishAnimation() {
    this.clearAnimationTimeout()
    if (this.animationFns) {
      const {finish} = this.animationFns
      this.animationFns = null
      this.childCardListNode && finish.call(this)
      this.props.expandCollapseAnimationManager.setAnimating(false)
    }
  }

  clearAnimationTimeout() {
    if (this.animationTid) {
      clearTimeout(this.animationTid)
      this.animationTid = null
    }
  }

  startExpandAnimation() {
    this.childCardListNode.style.height = this.getChildrenHeight() + 'px'
  }

  finishExpandAnimation() {
    const {childCardListNode} = this
    childCardListNode.style.height = null
    childCardListNode.className = cnDedupe(childCardListNode.className, {'animating': false})
  }

  startCollapseAnimation() {
    this.childCardListNode.style.height = '0'
  }

  finishCollapseAnimation() {
    this.setState({collapsingChildren: null})
  }

  getWidth() {
    return this.containerNode.getBoundingClientRect().width
  }

  getChildrenHeight() {
    return this.childrenContainerNode.getBoundingClientRect().height
  }
}


function mayExpand(filteringInfo) {
  return filteringInfo.hasPassingDescendants
}
