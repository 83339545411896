import React, { useRef } from 'react'
import Scrollable from '.'

type ScrollableContainerProps = {
  className?: string
}

export const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  children,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <Scrollable divRef={containerRef} className={className}>
      {children}
    </Scrollable>
  )
}
