import Immutable from 'immutable'
import { buffers } from 'redux-saga'
import {
  all,
  take,
  select,
  call,
  fork,
  actionChannel,
} from 'redux-saga/effects'
import { $dispatch } from '../utils/effects'

import * as Actions from '~/actions'
import * as ActionTypes from '~/actions/types'

import {
  isRouteToCard,
  doesRouteAllowCardSelection,
  isProjectAgnosticRoute,
  ROUTE_CONTRACTS,
  ROUTE_PLAN,
  ROUTE_MOBILE_CHAT,
  ROUTE_PROFILE,
  ROUTE_RECORD,
  ROUTE_BO,
  ROUTE_SETTINGS,
  ROUTE_AGENCY_SIGN_UP,
} from '~/utils/routing'

import * as PersistentStore from '~/utils/persistent-store'

import sel from '~/selectors'
import { isCardDeleted } from '~/utils/cards'
import { isAccessError, makeAccessError } from '~/utils/access-error'

import { $navigateToRouteIfNeeded, getIsNavigating } from './utils'
import $handleCardNavigation from './handle-card-navigation'
import { fetchCard } from '~/api/cards'

const ctx = {
  isRouteChange: null,
  routingState: null,
  projectIds: null,
  userIds: null,
  prevState: {
    projectId: null,
    userId: null,
    recordId: null,
    contractId: null,
  },
  nextState: {
    projectId: null,
    userId: null,
    recordId: null,
    contractId: null,
  },
  isInitialProjectSelect: true,
  isHandlingInitialCardSelection: true,
}

export default function* $handleLocation() {
  yield take(ActionTypes.LOGIN_SUCCESS)

  const actionChan = yield actionChannel(
    [
      ActionTypes.ROUTE_CHANGED,
      ActionTypes.SELECT_PROJECT_SUCCESS,
      ActionTypes.SET_PROJECTS_LIST,
    ],
    buffers.sliding(1)
  )

  while (true) {
    const action = yield take(actionChan)
    if (getIsNavigating() || (yield select(sel.isRevealingCard))) {
      continue
    }
    try {
      yield* $handleRoute(action.type === ActionTypes.ROUTE_CHANGED)
    } catch (e) {
      yield* $dispatch(Actions.fatalError(e))
      return
    }
  }
}

function* $handleRoute(isRouteChange) {
  const ctx = prepareContext()

  const [projectId, selectedProjectId] = yield all([
    select(sel.selectingOrSelectedProjectId),
    select(sel.selectedProjectId),
  ])

  const userId = yield* select(sel.selectingOrSelectedUserId)
  const recordId = yield* select(sel.selectingOrSelectedRecordId)

  if (projectId && !selectedProjectId) {
    // The project is currently being selected, don't do anything until it loads.
    return
  }

  const [routingState, projectIds, userIds] = yield all([
    select(sel.routingState),
    call($getProjectIds),
    select(sel.users),
  ])

  ctx.isRouteChange = isRouteChange
  ctx.routingState = routingState.toJS()
  ctx.projectIds = projectIds
  if (userIds) {
    ctx.userIds = userIds.toJS()
  }
  ctx.prevState.projectId = projectId
  ctx.prevState.userId = userId
  ctx.prevState.recordId = recordId

  if (!ctx.routingState.route.type) {
    ctx.routingState.route.type = ROUTE_PLAN
  }

  let newRoute

  if (isRouteToCard(ctx.routingState.route.type)) {
    newRoute = yield* $handleRouteToCard(ctx)
  } else if (ctx.routingState.route.type === ROUTE_PROFILE) {
    newRoute = yield* $handleRouteToProfile(ctx)
  } else if (ctx.routingState.route.type === ROUTE_RECORD) {
    newRoute = yield* $handleRouteToRecord(ctx)
  } else if (ctx.routingState.route.type === ROUTE_CONTRACTS) {
    newRoute = yield* $handleRouteToContract(ctx)
  } else if (
    ctx.routingState.route.type === ROUTE_BO ||
    ctx.routingState.route.type === ROUTE_SETTINGS ||
    ctx.routingState.route.type === ROUTE_AGENCY_SIGN_UP
  ) {
    newRoute = yield* $handleRouteToBackOffice(ctx)
  } else {
    newRoute = yield* $handleNonCardRoute(ctx)
  }

  if (newRoute) {
    yield* $navigateToRouteIfNeeded(newRoute, ctx.routingState.uri)
  }
}

function prepareContext() {
  ctx.nextState.projectId = null
  return ctx
}

function* $getProjectIds() {
  const [projectIds, hasProjects] = yield all([
    select(sel.projectIds),
    select(sel.hasProjects),
  ])
  if (projectIds.size > 0 || !hasProjects) {
    return projectIds
  }
  yield take([ActionTypes.SET_PROJECTS_LIST, ActionTypes.NO_PROJECTS])
  return yield select(sel.projectIds)
}

function* $handleNonCardRoute(ctx) {
  const { routingState } = ctx
  const shouldContainProjectInUrl = !isProjectAgnosticRoute(routingState)

  if (
    yield* $selectNewProjectIfNeeded(routingState.route.projectId, null, ctx)
  ) {
    // Started selecting project; this function will be called again after the project is loaded.
    return {
      type: routingState.route.type,
      projectId: shouldContainProjectInUrl ? ctx.nextState.projectId : null,
      selectedCardId: routingState.route.selectedCardId,
      search: routingState.location.search,
    }
  }

  yield* $handleInitialCardSelectionIfNeeded(ctx)

  const route = {
    type: routingState.route.type,
    projectId: shouldContainProjectInUrl ? ctx.nextState.projectId : null,
    search: routingState.location.search,
  }
  const routeSelectedCardId = routingState.route.selectedCardId

  return yield* $handleSelectedCard(
    ctx.isRouteChange,
    routeSelectedCardId,
    route,
    ctx.nextState.projectId
  )
}

function* $handleRouteToCard(ctx) {
  const { routingState } = ctx
  let didSelectNewProjectId

  let { cardId } = routingState.route
  if (!cardId) {
    didSelectNewProjectId = yield* $selectNewProjectIfNeeded(null, null, ctx)
    cardId = ctx.nextState.projectId
  } else {
    const cardProjectId = yield* $getProjectIdFromCardId(cardId, ctx.projectIds)
    didSelectNewProjectId = yield* $selectNewProjectIfNeeded(
      cardProjectId,
      cardId,
      ctx
    )
    if (ctx.nextState.projectId === cardProjectId) {
      if (!didSelectNewProjectId) {
        // If the project is loaded, check if the card can be displayed in the current view.
        // Otherwise, this check would be made by this same saga after the project is loaded.
        cardId = yield* $fixDeletedCard(cardId, ctx.nextState.projectId)
      }
    } else {
      // The card is not accessible or doesn't exist, show project card instead.
      cardId = ctx.nextState.projectId
    }
  }

  if (didSelectNewProjectId) {
    // This function will be called again after the project is loaded.
    return {
      type: routingState.route.type,
      cardId: cardId,
      selectedCardId: routingState.route.selectedCardId,
    }
  }

  const planTopLevelCardId = yield select(sel.planTopLevelCardId)

  if (cardId !== planTopLevelCardId) {
    yield* $dispatch(Actions.showCard(cardId))
  }

  if (yield* $handleInitialCardSelectionIfNeeded(ctx)) {
    // Revealing a card can potentially change top-level card id.
    cardId = yield select(sel.planTopLevelCardId)
  }

  const route = { type: routingState.route.type, cardId }
  const routeSelectedCardId = routingState.route.selectedCardId

  return yield* $handleSelectedCard(
    ctx.isRouteChange,
    routeSelectedCardId,
    route,
    ctx.nextState.projectId
  )
}

function* $handleRouteToProfile(ctx) {
  const { routingState, userIds } = ctx

  let { userId, projectId } = routingState.route
  if (!userId || !projectId) {
    throw makeAccessError(`Profile not found`)
  }

  if (ctx.projectIds.indexOf(projectId) < 0) {
    throw makeAccessError(
      `Project ${projectId} is not within the user's projects list`
    )
  }

  yield* $selectNewProjectIfNeeded(routingState.route.projectId, null, ctx)
  if (yield* $selectNewProfileIfNeeded(routingState.route.userId, ctx)) {
    return {
      type: routingState.route.type,
      projectId: routingState.route.projectId,
      userId: routingState.route.userId,
    }
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!userIds.hasOwnProperty(userId)) {
    throw makeAccessError(`User ${userId} is not within the project`)
  }
}

function* $handleRouteToRecord(ctx) {
  const { routingState } = ctx

  let { recordId, projectId } = routingState.route
  if (!recordId || !projectId) {
    throw makeAccessError(`Record not found`)
  }

  if (ctx.projectIds.indexOf(projectId) < 0) {
    throw makeAccessError(
      `Project ${projectId} is not within the user's projects list`
    )
  }

  yield* $selectNewProjectIfNeeded(routingState.route.projectId, null, ctx)
  if (yield* $selectNewRecordIfNeeded(routingState.route.recordId, ctx)) {
    return {
      type: routingState.route.type,
      projectId: routingState.route.projectId,
      recordId: routingState.route.recordId,
    }
  }
}

function $handleRouteToContract(ctx) {
  const { routingState } = ctx

  const { projectId, type } = routingState.route
  if (!projectId) {
    throw makeAccessError(`Contract not found`)
  }

  return {
    type,
    projectId,
  }
}

function $handleRouteToBackOffice(ctx) {
  const { routingState } = ctx

  const { type } = routingState.route

  return {
    type,
  }
}

function* $handleInitialCardSelectionIfNeeded(ctx) {
  if (ctx.isHandlingInitialCardSelection) {
    ctx.isHandlingInitialCardSelection = false
    yield* $revealIntiallySelectedCardIfNeeded(
      ctx.routingState,
      ctx.nextState.projectId
    )
    yield fork($handleCardNavigation)
    return true
  }
  return false
}

function* $revealIntiallySelectedCardIfNeeded(routingState, projectId) {
  const selectedCardId = routingState.route.selectedCardId
  if (selectedCardId) {
    yield* $revealCard(selectedCardId, projectId)
  }
}

function* $revealCard(cardId, projectId) {
  const actionChan = yield actionChannel(
    ActionTypes.REVEAL_CARD_FINISHED,
    buffers.sliding(1)
  )
  yield* $dispatch(Actions.revealCard(cardId, projectId))
  yield take(actionChan)
  actionChan.close()
}

function* $fixDeletedCard(cardId, selectedProjectId) {
  const cardsById = yield select(sel.cards)
  if (isCardDeleted(cardId, cardsById)) {
    window.alert(`The card you're trying to view is deleted`)
    return selectedProjectId
  } else {
    return cardId
  }
}

function* $selectNewProjectIfNeeded(newProjectId, cardId, ctx) {
  if (!newProjectId || ctx.projectIds.indexOf(newProjectId) === -1) {
    newProjectId =
      ctx.prevState.projectId || getPersistedProjectId(ctx.projectIds)
  }
  const projectIdChanged = newProjectId !== ctx.prevState.projectId
  if (projectIdChanged) {
    const shouldSelectCard =
      cardId !== newProjectId ||
      ctx.routingState.route.type === ROUTE_MOBILE_CHAT
    const actionCardId = shouldSelectCard ? cardId : null
    yield* $dispatch(
      Actions.selectProject(
        newProjectId,
        actionCardId,
        ctx.isInitialProjectSelect
      )
    )
    ctx.isInitialProjectSelect = false
  }
  ctx.nextState.projectId = newProjectId
  return projectIdChanged
}

function* $selectNewProfileIfNeeded(newProfileId, ctx) {
  if (ctx.userIds) {
    if (!newProfileId || !(newProfileId in ctx.userIds)) {
      const persistedProfileId = PersistentStore.getUserId()
      newProfileId = ctx.prevState.userId || persistedProfileId
    }
  }
  const userIdChanged = newProfileId !== ctx.prevState.userId
  if (userIdChanged) {
    yield* $dispatch(Actions.selectUser(newProfileId))
  }
  ctx.nextState.userId = newProfileId
  return userIdChanged
}

function* $selectNewRecordIfNeeded(newRecordId, ctx) {
  const recordIdChanged = newRecordId !== ctx.prevState.recordId
  if (recordIdChanged) {
    yield* $dispatch(Actions.selectRecord(newRecordId))
  }
  ctx.nextState.recordId = newRecordId
  return recordIdChanged
}

function getPersistedProjectId(projectIds) {
  const persistedProjectId = PersistentStore.getProjectId()
  return persistedProjectId && projectIds.indexOf(persistedProjectId) >= 0
    ? persistedProjectId
    : projectIds.get(0)
}

function* $getProjectIdFromCardId(cardId, projectIds) {
  if (projectIds.indexOf(cardId) >= 0) {
    return cardId
  }
  let card = yield select(sel.cardWithId, cardId)
  if (!card) {
    card = yield* $fetchCard(cardId)
  }
  const cardProjectId = sel.card.projectId(card)
  if (projectIds.indexOf(cardProjectId) >= 0) {
    return cardProjectId
  } else {
    throw makeAccessError(
      `Project ${cardProjectId} is not within the user's projects list`
    )
  }
}

function* $fetchCard(id) {
  try {
    let response = yield call(fetchCard, id)
    return Immutable.fromJS(response.entities.card[id])
  } catch (e) {
    throw isAccessError(e)
      ? makeAccessError(`Access to card ${id} denied`, e)
      : new Error(`Failed to fetch card ${id}: ${e.message}`)
  }
}

function* $handleSelectedCard(
  isRouteChange,
  routeSelectedCardId,
  route,
  projectId
) {
  if (!doesRouteAllowCardSelection(route.type)) {
    return route
  }

  if (!isRouteChange) {
    const selectedCardId = yield select(sel.selectedCardId)
    return { ...route, selectedCardId }
  }

  const selectedCardId = routeSelectedCardId
  if (selectedCardId !== (yield select(sel.selectedCardId))) {
    if (selectedCardId) {
      yield* $revealCard(selectedCardId, projectId)
    } else {
      yield* $dispatch(Actions.clearCardSelection())
    }
  }

  return { ...route, selectedCardId }
}
