import React from 'react'
import Layout from '~/components/layout/layout-with-chat'

export default function CardListWrapper({ children }) {
  return (
    <Layout>
      <div className='main'>
        <div className='card-list-wrapper-shadow'>
          {children}
        </div>
      </div>
    </Layout>
  )
}
