
export const CARD_LIST_PADDING_LEFT = 17
export const CARD_LIST_PADDING_RIGHT = 14

export const CARD_INDENT = 25

export const CARD_LIST_CLASS = 'card-list'
export const CARD_CLASS = 'card'
export const CARD_NODE_CONTAINER_CLASS = 'card-node'

export const BEFORE_TEXT_ACTIONS_CLASS = 'before-text-actions'
export const INLINE_ACTIONS_CLASS = 'inline-actions'
export const CARD_TEXT_SECTION_CLASS = 'card-text-section'
export const CHECKBOX_CLASS = 'checkbox'

export const EXPAND_COLLAPSE_ANIMATION_TIMEOUT_MS = 200


export function getLevelIndent(cardLevel) {
  return CARD_LIST_PADDING_LEFT + cardLevel * CARD_INDENT
}
