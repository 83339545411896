
export const EMOJI_REGEXP = /((?:\uD83C[\uDF00-\uDFFF])|(?:\uD83D[\uDC00-\uDE4F\uDE80])|(?:\uD83E[\uDD10-\uDD18])|(?:\u261D|\u263A|\u270C)\uFE0F|\u2639|\u2705|\u270A|\u270B)/

export const FREQUENTLY_USED_EMOJI = ['+1', 'wink', 'joy','relaxed','sunglasses','confused', 'neutral_face', 'sweat_smile','100', 'sign_of_horns','ok_hand','ghost','rocket','white_check_mark','turtle']

export const EMOJI_LIST = {
    "grinning": "😀",
    "grimacing": "😬",
    "grin": "😁",
    "joy": "😂",
    "smiley": "😃",
    "smile": "😄",
    "sweat_smile": "😅",
    "laughing": "😆",
    "innocent": "😇",
    "wink": "😉",
    "blush": "😊",
    "slightly_smiling": "🙂",
    "upside_down": "🙃",
    "relaxed": "☺️",
    "yum": "😋",
    "relieved": "😌",
    "heart_eyes": "😍",
    "kissing_heart": "😘",
    "kissing": "😗",
    "kissing_smiling_eyes": "😙",
    "kissing_closed_eyes": "😚",
    "stuck_out_tongue_winking_eye": "😜",
    "stuck_out_tongue_closed_eyes": "😝",
    "stuck_out_tongue": "😛",
    "money_mouth": "🤑",
    "nerd": "🤓",
    "sunglasses": "😎",
    "hugging": "🤗",
    "smirk": "😏",
    "no_mouth": "😶",
    "neutral_face": "😐",
    "expressionless": "😑",
    "unamused": "😒",
    "rolling_eyes": "🙄",
    "thinking": "🤔",
    "flushed": "😳",
    "disappointed": "😞",
    "worried": "😟",
    "angry": "😠",
    "rage": "😡",
    "pensive": "😔",
    "confused": "😕",
    "slightly_sad": "🙁",
    "white_frowning": "☹",
    "persevere": "😣",
    "confounded": "😖",
    "tired_face": "😫",
    "weary": "😩",
    "triumph": "😤",
    "open_mouth": "😮",
    "scream": "😱",
    "fearful": "😨",
    "cold_sweat": "😰",
    "hushed": "😯",
    "frowning": "😦",
    "anguished": "😧",
    "cry": "😢",
    "disappointed_relieved": "😥",
    "sleepy": "😪",
    "sweat": "😓",
    "sob": "😭",
    "dizzy_face": "😵",
    "astonished": "😲",
    "zipper_mouth": "🤐",
    "mask": "😷",
    "thermometer_face": "🤒",
    "bandage_face": "🤕",
    "sleeping": "😴",
    "zzz": "💤",
    "hankey": "💩",
    "smiling_imp": "😈",
    "imp": "👿",
    "japanese_ogre": "👹",
    "japanese_goblin": "👺",
    "skull": "💀",
    "ghost": "👻",
    "alien": "👽",
    "robot": "🤖",
    "smiley_cat": "😺",
    "smile_cat": "😸",
    "joy_cat": "😹",
    "heart_eyes_cat": "😻",
    "smirk_cat": "😼",
    "kissing_cat": "😽",
    "scream_cat": "🙀",
    "crying_cat_face": "😿",
    "pouting_cat": "😾",
    "raised_hands": "🙌",
    "clap": "👏",
    "wave": "👋",
    "+1": "👍",
    "-1": "👎",
    "facepunch": "👊",
    "fist": "✊",
    "v": "✌️",
    "ok_hand": "👌",
    "hand": "✋",
    "open_hands": "👐",
    "muscle": "💪",
    "pray": "🙏",
    "point_up": "☝️",
    "point_up_2": "👆",
    "point_down": "👇",
    "point_left": "👈",
    "point_right": "👉",
    "middle_finger": "🖕",
    "splayed_fingers": "🖐",
    "sign_of_horns": "🤘",
    "vulcan_salute": "🖖",
    "white_check_mark": "✅",
    "100": "💯",
    //"1234": "🔢",
    "turtle": "🐢",
    "rocket": "🚀",
}
