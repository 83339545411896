/* eslint-disable */
import React from 'react'

export default class Scrollable extends React.Component {
  node = null
  state = { noScrollContentClass: '' }

  componentDidMount() {
    this.setState({ noScrollContentClass: this.isOverflown() })
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({ noScrollContentClass: this.isOverflown() })
    }
  }

  onNode = node => {
    this.node = node
    this.props.onNode && this.props.onNode(node)
  }

  isOverflown() {
    const { divRef } = this.props
    const ref = divRef && divRef.current ? divRef.current : this.node
    const hasVisibleScroll = ref ? ref.scrollHeight > ref.clientHeight : null
    return hasVisibleScroll === false ? 'no-scroll-content' : ''
  }

  render() {
    const { children, className, onNode, divRef, ...props } = this.props
    return (
      <div
        className={`scrollable-container ${className || ''} ${
          this.state.noScrollContentClass
        }`}
        ref={divRef ? divRef : this.onNode}
        {...props}
      >
        {children}
      </div>
    )
  }
}
export { ScrollableContainer } from './scrollable-container'
