import PropTypes from 'prop-types'
import React from 'react'

import {setClipboardText} from '~/utils/clipboard'


export default class PageError extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    errorDetails: PropTypes.string,
  }

  constructor() {
    super()
    this.state = {
      isShowingDetails: false
    }
  }

  render() {
    const {title, children} = this.props
    return (
      <div className='page-error'>
        <div className='spacer-top' />
        <div className='header'>{title}</div>
        <div className='message'>
          {children}
        </div>
        {this.props.errorDetails && this.renderDetails()}
        <div className='spacer-bottom' />
      </div>
    )
  }

  renderDetails() {
    const detailsStyle = {
      visibility: this.state.isShowingDetails ? 'visible' : 'hidden'
    }
    return (
      <div className='details'>
        {this.renderDetailsBtn()}
        <div className='details-text' style={detailsStyle}>
          {this.props.errorDetails}
        </div>
      </div>
    )
  }

  renderDetailsBtn() {
    if (this.state.isShowingDetails) {
      const copyDetails = () => {
        setClipboardText(this.props.errorDetails)
        alert('Copied to clipboard')
      }
      return <div className='details-btn' onClick={copyDetails}>Copy details</div>
    } else {
      const showDetails = () => this.setState({isShowingDetails: true})
      return <div className='details-btn' onClick={showDetails}>Show details</div>
    }
  }

}
