import React from 'react'
import { ChatContainer, ChatContainerProps } from '.'

export const ChatContainerPure = (props: ChatContainerProps) =>
  React.createElement(ChatContainer, {
    ...props,
    onMentionClick: () => () => {},
    onCardClick: () => () => {},
    onChatClose: () => () => {},
    allowAttachments: false,
    withServiceMessages: false,
  })
