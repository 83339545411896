/* eslint-disable */
import React from 'react'

import { CSSTransition } from 'react-transition-group'
import connect from '../utils/connect'
import cn from 'classnames'

import sel from '~/selectors'

import PopupWrapper from '../popup-wrapper'
import SelectItems from '../select-items'


class HeaderSearch extends React.Component {

  static mapStateToProps(state) {
    const search = sel.search(state)
    return {
      searchQuery: search.get('query') || '',
      searchResults: search.get('results'),
      searchDone: search.get('done'),
      projectId: sel.selectedProjectId(state)
    }
  }

  constructor() {
    super()
    this.state = {searchActive: false}
    this.handleSearchInput = (node) => this.searchInputNode = node
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this)
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.activateSearch = this.activateSearch.bind(this)
    this.deactivateSearch = this.deactivateSearch.bind(this)
    this.handleSearchItemSelected = this.handleSearchItemSelected.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  render() {
    let queryLength = this.props.searchQuery.length
    let searchResultsPopup

    if (this.state.searchActive && queryLength > 0) {
      const {searchResults} = this.props

      const searchResultEls = searchResults.size == 0 ? null : searchResults.map(result => {
        const item = result.get('item')
        const id = item.get('id')
        const title = item.get('title')
        const className = cn({
          'header-search-result-item': true,
          'completed': item.get('completed')
        })
        return <div className={className} key={id}>
          {title}
        </div>
      })

      let popupContent

      if (searchResultEls) {
        popupContent = (
          <SelectItems containerClassName='header-search-results-list'
              onItemSelected={this.handleSearchItemSelected}>
            {searchResultEls}
          </SelectItems>
        )
      } else {
        let message
        if (queryLength < 3) {
          message = 'Type at least 3 characters'
        } else if (this.props.searchDone) {
          message = 'Nothing is found'
        } else {
          message = 'Searching...'
        }
        popupContent = (
          <div className='header-search-results-placeholder'>
            {message}
          </div>
        )
      }

      searchResultsPopup = this.state.showingPopup && <PopupWrapper onDismiss={this.deactivateSearch}>
        <CSSTransition
            classNames='header-search-results'
            timeout={{
             enter: 100,
             exit: 0,
            }}
            exit={false}>
          <div className='header-search-results'>
            {popupContent}
            <div className='header-search-results-tip' />
          </div>
        </CSSTransition>
      </PopupWrapper>
    }

    const className = cn('header-search',
      this.state.searchActive && 'active',
      searchResultsPopup && 'showing-popup')

    return (
      <div className={className} onClick={this.handleClick}>
        <input className='header-search-input'
          placeholder='Search'
          type='text'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          ref={this.handleSearchInput}
          onFocus={this.handleFocus}
          onChange={this.handleSearchInputChange}
          value={this.props.searchQuery}>
        </input>
        {searchResultsPopup}
      </div>
    )
  }

  handleClick(evt) {
    const {searchInputNode} = this
    if (searchInputNode && hasClass('header-search', evt.target)) {
      searchInputNode.focus()
      evt.preventDefault()
    }
  }

  handleFocus() {
    this.activateSearch()
  }

  activateSearch() {
    this.searchInputNode && this.searchInputNode.select()
    this.setState({searchActive: true})
    document.addEventListener('mousedown', this.handleDocumentMouseDown)
    document.addEventListener('keydown', this.handleDocumentKeyDown)
    this.showPopupTimeout = setTimeout( () => {
      this.setState({showingPopup: true})
      this.showPopupTimeout = null
    }, 200)
  }

  deactivateSearch() {
    if (!this.state.searchActive) {
      return
    }
    this.searchInputNode.blur()
    this.setState({searchActive: false, showingPopup: false})
    this.showPopupTimeout && clearTimeout(this.showPopupTimeout)
    this.showPopupTimeout = null
    this.props.actions.doSearch('')
    document.removeEventListener('mousedown', this.handleDocumentMouseDown)
    document.removeEventListener('keydown', this.handleDocumentKeyDown)
  }

  handleSearchItemSelected(index) {
    const cardId = this.props.searchResults.getIn([index, 'item', 'id'])
    this.props.actions.revealCard(cardId, this.props.projectId)
    this.deactivateSearch()
  }

  handleDocumentMouseDown(evt) {
    if (!this.isSearchRelatedNode(evt.target)) {
      this.deactivateSearch()
    }
  }

  handleDocumentKeyDown(evt) {
    switch (evt.key) {
      case 'Escape': this.deactivateSearch(); break
      default: return
    }
    evt.stopPropagation()
    evt.preventDefault()
  }

  handleSearchInputChange(evt) {
    const query = evt.target.value
    this.props.actions.doSearch(query)
  }

  isSearchRelatedNode(node) {
    if (node.className.indexOf('header-search') >= 0) {
      return true
    }
    const parentClassName = node.parentNode && node.parentNode.className || ''
    if (parentClassName.indexOf('header-search') >= 0) {
      return true
    }
    return false
  }

}


function hasClass(cls, el) {
  return el.className && (el.className.split(' ').indexOf(cls) >= 0)
}


export default connect(HeaderSearch)
