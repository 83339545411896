export function isAccessError(e) {
  if (e.status) {
    return e.status == 401 || e.status == 403
  } else if (e.networkError) {
    return e.networkError.statusCode == 401 || e.networkError.statusCode == 403
  }
}

export function makeAccessError(message, originalError) {
  const err = new Error(message)
  if (originalError) {
    err.status = isAccessError(originalError) ? originalError.status : 403
    err.originalError = originalError
  } else {
    err.status = 403
  }
  return err
}
