import React from 'react'
import connect from '../utils/connect'

import sel from '~/selectors'

import CardListDraggingIndicator from './card-list-dragging-indicator'
import CardListDraggingItem from './card-list-dragging-item'

import CardListWrapper from './card-list-wrapper'
import CardListHeader from '../card-list-header'
import CardListItemContainer from './card-list-item-container'
import NewCardInput from './new-card-input'

import Scrollable from '../scrollable'

import CardMoveManager from './card-move-manager'
import AutoscrollManager from './autoscroll-manager'
import CardRelation from '~/utils/card-relation'

import ExpandCollapseAnimationManager from './expand-collapse-animation-manager'

import { buildPath, ROUTE_PLAN, ROUTE_MY_TASKS } from '~/utils/routing'

import throttleRAF from '~/utils/throttle-raf'
import scrollToPercentPosition from '~/utils/scroll-to-percent-position'
import scrollIntoView from '~/utils/scroll-into-view'
import addClassWithTimeout from '~/utils/add-class-with-timeout'

import { CARD_LIST_PADDING_LEFT, CARD_INDENT } from './card-list-constants'

// Must be equal to the value of $highlight-animation-duration variable
// in styles/components/card-list.styl.
//
const HIGHLIGHT_ANIMATION_DURATION_MS = 1700

class CardListContainer extends React.Component {
  static mapStateToProps(state) {
    const routeType = sel.route(state).get('type')
    const limitToThisWeek = routeType === ROUTE_MY_TASKS

    const cards = sel.cards(state)
    const card = cards.get(
      limitToThisWeek
        ? sel.selectedProjectId(state)
        : sel.planTopLevelCardId(state)
    )
    const prevCard = cards.get(
      limitToThisWeek ? null : sel.previousPlanTopLevelCardId(state)
    )

    const selectedCardId = sel.selectedCardId(state)

    const editingCardId = sel.editingCardId(state)
    const editingCard = editingCardId && cards.get(editingCardId)
    const isAddingNewCard = !!editingCard && editingCard.get('isEphemeral')

    return { limitToThisWeek, card, prevCard, selectedCardId, isAddingNewCard }
  }

  constructor() {
    super()

    this.onContainerNodeRef = this.onContainerNodeRef.bind(this)
    this.onScrollableRef = this.onScrollableRef.bind(this)
    this.onTopLevelItemContainerRef = this.onTopLevelItemContainerRef.bind(this)
    this.onDraggingIndicatorRef = this.onDraggingIndicatorRef.bind(this)
    this.onDraggingPlaceholderRef = this.onDraggingPlaceholderRef.bind(this)

    this.moveManager = new CardMoveManager(
      CARD_LIST_PADDING_LEFT,
      CARD_INDENT,
      this.updateDraggingState.bind(this)
    )

    this.autoscrollManager = new AutoscrollManager()
    this.expandCollapseAnimationManager = new ExpandCollapseAnimationManager()

    this.createChildCard = this.createChildCard.bind(this)
    this.selectCard = this.selectCard.bind(this)
    this.registerItem = this.moveManager.registerItem.bind(this.moveManager)
    this.startDragging = this.startDragging.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleListScroll = this.handleListScroll.bind(this)
    this.updateContainerBounds = throttleRAF(this.updateContainerBounds, this)
    this.makeCardHref = this.makeCardHref.bind(this)

    this.cardChanged = false
    this.selectedCardChanged = false

    this.draggingItemDx = 0
    this.draggingItemDy = 0
    this.draggingItemLevelIndent = 0
    this.draggingCardId = null
    this.draggingTargetId = null
    this.draggingTargetRelation = CardRelation.NOP

    this.isAnimatingExpandCollapse = false
  }

  onContainerNodeRef(node) {
    this.containerNode = node
  }

  onScrollableRef(scrollable) {
    this.cardListWrapper = scrollable && scrollable.node
  }

  onTopLevelItemContainerRef(topLevelItemContainer) {
    this.topLevelItemContainer = topLevelItemContainer
  }

  onDraggingIndicatorRef(indicator) {
    this.draggingIndicator = indicator
  }

  onDraggingPlaceholderRef(placeholder) {
    this.draggingPlaceholder = placeholder
  }

  makeCardHref(cardId) {
    return buildPath({ type: ROUTE_PLAN, cardId })
  }

  createChildCard() {
    const { props } = this
    const parentId = props.card.get('id')
    props.actions.createEphemeralCard(parentId)
  }

  componentDidMount() {
    this.onCardChanged()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { props } = this
    const { card } = newProps

    const prevCard = props.card
    const prevCardId = prevCard && prevCard.get('id')
    const newCardId = card && card.get('id')

    this.cardChanged = newCardId !== prevCardId
    this.selectedCardChanged = newProps.selectedCardId !== props.selectedCardId

    if (this.cardChanged) {
      this.lastManuallySelectedCardId = null
    }
  }

  componentDidUpdate() {
    if (this.cardChanged) {
      this.onCardChanged()
    } else if (this.selectedCardChanged) {
      this.onSelectedCardChanged()
    }
  }

  onCardChanged() {
    if (!this.topLevelItemContainer.isEmpty) {
      const { selectedCardId, card } = this.props
      if (selectedCardId && selectedCardId !== card.get('id')) {
        this.revealSelectedCard()
      } else if (this.props.prevCard) {
        this.highlightPreviousCard()
      }
    }
    this.cardChanged = false
    this.selectedCardChanged = false
  }

  onSelectedCardChanged() {
    if (
      this.props.selectedCardId &&
      this.lastManuallySelectedCardId !== this.props.selectedCardId
    ) {
      this.revealSelectedCard()
    }
    this.selectedCardChanged = false
  }

  highlightPreviousCard() {
    let prevCardNode

    const listContainerNode = this.cardListWrapper
    const path = this.props.prevCard.get('path')

    for (let i = path.size - 1; i > 0 && !prevCardNode; --i) {
      const cardId = path.get(i)
      prevCardNode = listContainerNode.querySelector(
        `[data-card-id="${cardId}"]`
      )
    }

    if (prevCardNode) {
      this.highlightCardNode(prevCardNode, listContainerNode)
    } else {
      listContainerNode.scrollTop = 0
    }
  }

  revealSelectedCard() {
    const { selectedCardId } = this.props
    const listContainerNode = this.cardListWrapper
    const cardNode = this.getNodeForCardWithId(selectedCardId)
    cardNode && this.revealCardNode(cardNode, listContainerNode)
  }

  revealCardWithId = id => {
    const cardNode = this.getNodeForCardWithId(id)
    cardNode &&
      scrollIntoView(cardNode, this.cardListWrapper, 10, { timeoutMs: 200 })
  }

  getNodeForCardWithId(id) {
    return this.cardListWrapper.querySelector(`[data-card-id="${id}"]`)
  }

  highlightCardNode(cardNode, listContainerNode) {
    this.revealCardNode(cardNode, listContainerNode)
    addClassWithTimeout(
      HIGHLIGHT_ANIMATION_DURATION_MS,
      'highlighted',
      cardNode
    )
  }

  revealCardNode(cardNode, listContainerNode) {
    scrollToPercentPosition(1 / 4, listContainerNode, cardNode)
  }

  getContainerNodeBounds() {
    return this.containerNode.getBoundingClientRect()
  }

  startDragging(desc) {
    const {
      mouseX,
      mouseY,
      itemDx,
      itemDy,
      levelIndent,
      width,
      stopDragging,
    } = desc
    const { cardId, cardProps } = desc

    const containerBounds = this.getContainerNodeBounds()

    this.draggingCardId = cardId
    this.draggingItemDx = itemDx
    this.draggingItemDy = itemDy

    this.draggingItemLevelIndent = levelIndent
    this.itemStopDragging = stopDragging

    const itemX = mouseX - itemDx
    const itemY = mouseY - itemDy

    this.moveManager.startDragging(
      cardId,
      containerBounds,
      itemX + levelIndent,
      mouseY
    )
    this.draggingPlaceholder.startDragging(cardProps, itemX, itemY, width)
    this.autoscrollManager.startDragging(this.cardListWrapper, mouseY)

    this.props.actions.startModifyingCard(cardId)

    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('mouseup', this.handleMouseUp)
    window.addEventListener('keydown', this.handleKeyDown)
  }

  stopListeningMouseEvents() {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleMouseMove(e) {
    const mouseX = e.clientX
    const mouseY = e.clientY

    const itemX = mouseX - this.draggingItemDx
    const itemY = mouseY - this.draggingItemDy

    this.moveManager.updateMousePosition(
      itemX + this.draggingItemLevelIndent,
      mouseY
    )
    this.draggingPlaceholder.updateItemPosition(itemX, itemY)
    this.autoscrollManager.updateMousePosition(mouseY)
  }

  handleListScroll() {
    if (this.draggingCardId) {
      this.updateContainerBounds()
    }
  }

  updateContainerBounds() {
    const containerBounds = this.getContainerNodeBounds()
    this.moveManager.updateContainerBounds(containerBounds)
  }

  handleMouseUp() {
    this.stopDragging(true)
  }

  handleKeyDown(e) {
    if (e.key === 'Escape') {
      this.stopDragging(false)
    }
  }

  updateDraggingState(targetId, targetRelation, draggingMarkX, draggingMarkY) {
    this.draggingTargetId = targetId
    this.draggingTargetRelation = targetRelation
    if (targetId === null) {
      this.draggingIndicator.hide()
    } else {
      this.draggingIndicator.showAt(draggingMarkX, draggingMarkY)
    }
  }

  stopDragging(doMove) {
    this.stopListeningMouseEvents()
    this.moveManager.stopDragging()
    this.autoscrollManager.stopDragging()
    this.draggingIndicator.hide()
    this.draggingPlaceholder.stopDragging()
    this.itemStopDragging()
    this.itemStopDragging = null
    if (doMove) {
      this.doMoveItem()
    } else {
      this.props.actions.cancelModifyingCard(this.draggingCardId)
    }
    this.draggingCardId = null
  }

  doMoveItem() {
    const { draggingCardId, draggingTargetId, draggingTargetRelation } = this
    if (draggingTargetRelation !== CardRelation.NOP) {
      this.props.actions.moveCard(
        draggingCardId,
        draggingTargetId,
        draggingTargetRelation
      )
    } else {
      this.props.actions.cancelModifyingCard(draggingCardId)
    }
  }

  selectCard(cardId) {
    if (cardId) {
      this.lastManuallySelectedCardId = cardId
      this.props.actions.selectCard(cardId)
    } else {
      this.props.actions.clearCardSelection()
    }
  }

  render() {
    const { limitToThisWeek, card, isAddingNewCard } = this.props

    if (card === null) {
      return null
    }

    return (
      <CardListWrapper>
        <CardListHeader
          topLevelCardId={card.get('id')}
          makeCardHref={this.makeCardHref}
          hasUnreadMessages={this.props.hasUnreadMessages}
          selectCard={this.selectCard}
        ></CardListHeader>
        <CardListDraggingItem ref={this.onDraggingPlaceholderRef} />
        <Scrollable
          onScroll={this.handleListScroll}
          className="card-list-scrollable"
          ref={this.onScrollableRef}
        >
          <div className="card-list-wrapper" ref={this.onContainerNodeRef}>
            <CardListDraggingIndicator ref={this.onDraggingIndicatorRef} />
            <CardListItemContainer
              wrappedRef={this.onTopLevelItemContainerRef}
              renderChildrenOnly={true}
              limitToThisWeek={limitToThisWeek}
              filteringInfo={sel.cardViewState.FILTERING_INFO_PASSING}
              cardId={card.get('id')}
              showAssignee={true}
              level={0}
              selectCard={this.selectCard}
              makeHref={this.makeCardHref}
              registerItem={this.registerItem}
              startDragging={this.startDragging}
              revealCardWithId={this.revealCardWithId}
              expandCollapseAnimationManager={
                this.expandCollapseAnimationManager
              }
            ></CardListItemContainer>
            {!isAddingNewCard && (
              <NewCardInput createChildCard={this.createChildCard} />
            )}
          </div>
        </Scrollable>
      </CardListWrapper>
    )
  }
}

export default connect(CardListContainer)
