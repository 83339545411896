import { take, call, select } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import sel from '~/selectors'

import { createCard } from '~/api/cards'

export default function* $cardQuickAddSaga() {
  while (true) {
    const { name } = yield take(ActionTypes.QUICK_ADD_CARD)
    try {
      const parentId = yield select(sel.selectedProjectId)

      yield* $dispatch(Actions.createEphemeralCard(parentId))

      const ephemeralId = yield select(sel.editingCardId)

      const response = yield call(createCard, name, parentId, null, false, true)

      const finalId = response.result

      yield* $dispatch(
        Actions.replaceEphemeralCard(
          ephemeralId,
          finalId,
          parentId,
          response.entities
        )
      )
    } catch (e) {
      console.error('{ERROR} failed file upload', e)
    }
  }
}
