import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

export const validateCredentials = (credentials: string) => {
  const credentialsObject = JSON.parse(credentials)

  if ('token' in credentialsObject && 'user_id' in credentialsObject) {
    const decodedToken: Record<string, unknown> = jwtDecode(
      credentialsObject.token
    )

    const isTokenExpired = dayjs
      .unix(decodedToken.exp as number)
      .isBefore(dayjs())

    if (!isTokenExpired) {
      return true
    }
  }

  localStorage.removeItem('credentials')
  return false
}
