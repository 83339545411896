import scrollTo from './scroll-to'


export default function scrollToPercentPosition(
    percentPosition, container, element, scrollIfVisible = true, animate = false) {

  const containerBounds = container.getBoundingClientRect()
  const elementBounds = element.getBoundingClientRect()

  if (!scrollIfVisible &&
      elementBounds.top >= containerBounds.top &&
      elementBounds.bottom <= containerBounds.bottom) {
    return
  }

  const targetTop = containerBounds.top + containerBounds.height * percentPosition
  const dTop = targetTop - elementBounds.top
  const scrollMax = Math.max(0, container.scrollHeight - containerBounds.height)
  const newScrollTop = Math.min(scrollMax, Math.max(0, container.scrollTop - dTop))

  scrollTo(newScrollTop, container, animate)
}
