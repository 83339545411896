import {all, take, select} from 'redux-saga/effects'

import sel from '~/selectors'
import {getCardIdsToExpandToRevealCard} from '~/utils/cards'

import * as Actions from '~/actions'
import {$dispatch, nextTick} from './utils/effects'


export default function* $resetSelectedCardIfHiddenSaga() {
  let prevCardsViewState = null
  let prevSelectedCardId = null
  let prevRoute = null
  while (true) {
    yield take()
    const selectedProjectId = yield select(sel.selectedProjectId)
    if (selectedProjectId == null) {
      continue
    }
    const [cardsViewState, selectedCardId, route] = yield all([
      select(sel.cardsViewState),
      select(sel.selectedCardId),
      select(sel.route),
    ])
    if (cardsViewState != prevCardsViewState ||
        selectedCardId != prevSelectedCardId ||
        route != prevRoute) {
      let upToDateRoute
      if (route == prevRoute) {
        // FIXME (UGLY HACK): allow navigation saga to update route in store. This is needed
        // when both selectedCardIdcardsViewState and route change simultaneously as a result
        // of some action.
        yield nextTick()
        upToDateRoute = yield select(sel.route)
      } else {
        upToDateRoute = route
      }

      prevCardsViewState = cardsViewState
      prevSelectedCardId = selectedCardId
      prevRoute = route

      yield* $resetSelectedCardIfHidden(
        selectedCardId, selectedProjectId, cardsViewState, upToDateRoute
      )
    }
  }
}


function* $resetSelectedCardIfHidden(selectedCardId, projectId, cardsViewState, route) {
  if (selectedCardId == null) {
    return
  }

  const cards = yield select(sel.cards)
  const routeType = route.get('type')
  const topLevelCardId = route.get('cardId')

  const cardIdsToExpand = getCardIdsToExpandToRevealCard(selectedCardId, cardsViewState,
    cards, routeType, topLevelCardId, projectId)

  if (cardIdsToExpand && cardIdsToExpand.length == 0) {
    return
  }

  const newSelectedCardId = cardIdsToExpand == null
    ? topLevelCardId
    : cardIdsToExpand[0]

  yield* $dispatch(newSelectedCardId
    ? Actions.selectCard(newSelectedCardId)
    : Actions.clearCardSelection()
  )
}
