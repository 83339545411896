import React from 'react'
import queryString from 'query-string'
import connect from '../utils/connect'
import sel from '~/selectors'
import { formatShortDate, formatDate, getWeekPeriod } from '~/utils/time'
import { buildPath, ROUTE_PLAN } from '~/utils/routing'
import TurtleAvatar from '~assets/images/turtle-user-avatar.png'
import dayjs from 'dayjs'

class Availability extends React.Component {
  static mapStateToProps(state) {
    const values = queryString.parse(sel.routeQuery(state))
    const { start, end } = selectAvailabilityPeriod(values)
    const me = sel.me(state)
    const projectId = sel.selectedProjectId(state)
    const selectedProject = sel.selectedProject(state)
    const projectName = selectedProject && selectedProject.get('name')
    const availabilityForm = sel.availabilityForm(state)
    return {
      start,
      end,
      myAvatar: me && me.get('photo'),
      name: me && me.get('name'),
      projectId,
      projectName,
      availabilityForm,
    }
  }

  state = {
    availability: '',
    validationError: '',
  }

  render() {
    return (
      <div className="availability-container">
        <form className="form-card" onSubmit={this.submit}>
          <div className="question dialog">
            <div className="talk-head">
              <img className="avatar" src={TurtleAvatar} />
            </div>
            <div className="dialog-text">
              <div className="name">Turtle.dev</div>
              <div>
                How much are you going to work from Sunday,{' '}
                {formatShortDate(this.props.start)} till Saturday,{' '}
                {formatShortDate(this.props.end)}?
              </div>
            </div>
          </div>
          <div className="input-row dialog">
            <div className="talk-head">
              <img className="avatar" src={this.props.myAvatar} />
            </div>
            <div className="dialog-text">
              <div className="name">{this.props.name}</div>
              <div>
                {"I'm planning to do"}
                <input
                  autoFocus={true}
                  className={`hours-input ${
                    this.state.validationError ? 'invalid' : ''
                  }`}
                  value={this.state.availability}
                  onChange={this.setValue}
                ></input>
                <span className="hours-span">hour{this.multiHours()}</span> a
                week.
              </div>
            </div>
          </div>
          <div className="submit-row">
            {this.state.validationError && (
              <div className="validation-error">
                {this.state.validationError}
              </div>
            )}
            {!this.state.validationError && this.submittable() && (
              <button className="hours-submit">Save</button>
            )}
          </div>
          {this.showStatus() && (
            <div className="popup">
              <div className="question dialog">
                <div className="talk-head">
                  <img className="avatar" src={TurtleAvatar} />
                </div>
                <div className="dialog-text">
                  <div className="name">Turtle.dev</div>
                  <div>{this.statusText()}</div>
                  <div>
                    {this.props.availabilityForm.get('error') && (
                      <a
                        href="#"
                        className="reset-link"
                        onClick={this.resetForm}
                      >
                        Try again?
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {this.props.availabilityForm.get('success') && (
                <button className="back-button" onClick={this.backLinkClick}>
                  Done
                </button>
              )}
            </div>
          )}
        </form>
      </div>
    )
  }

  setValue = e => {
    const availability = e.target.value
    const validationError = getAvailabilityError(availability)
    this.setState({ availability, validationError })
  }

  multiHours = () => (this.state.availability === '1' ? '' : 's')

  submittable = () => this.state.availability && !this.state.validationError

  showStatus = () => {
    return (
      this.props.availabilityForm.get('loading') ||
      this.props.availabilityForm.get('success') ||
      this.props.availabilityForm.get('error')
    )
  }

  showBack = () => {
    return (
      this.props.availabilityForm.get('success') ||
      this.props.availabilityForm.get('error')
    )
  }

  statusText = () => {
    if (this.props.availabilityForm.get('loading')) {
      return '🚂 Processing...'
    }
    if (this.props.availabilityForm.get('success')) {
      return 'Availability saved, thank you!'
    }
    if (this.props.availabilityForm.get('error')) {
      return `Oh, snap! Something went wrong :(`
    }
    return "If you're seeing this, ping up Turtle dev team, they owe you a beer"
  }

  resetForm = e => {
    e.preventDefault()
    this.props.actions.resetAvailabilityForm()
  }

  getBackLink = () => {
    return buildPath({ type: ROUTE_PLAN, cardId: this.props.projectId })
  }

  backLinkClick = e => {
    if (!(e.metaKey || e.ctrlKey)) {
      e.preventDefault()
      this.props.actions.revealCard(this.props.projectId, this.props.projectId)
    }
  }

  submit = e => {
    e.preventDefault()
    if (this.submittable()) {
      this.props.actions.submitAvailability(
        formatDate(this.props.start),
        formatDate(this.props.end),
        `${this.state.availability}h`
      )
    }
  }
}

export default connect(Availability)

function getAvailabilityError(availability) {
  const availabilityNumber = Number(availability)
  if (isNaN(availabilityNumber)) {
    return 'Sorry, only hours today'
  }
  return availabilityNumber > 168
    ? 'Need more hours in week'
    : availabilityNumber > 112
    ? "But you'll need some sleep"
    : availabilityNumber < 0
    ? 'Sorry, no time-travelling yet'
    : ''
}

function selectAvailabilityPeriod(values) {
  const start = values.from
  const end = values.to

  const defaultPeriod = getWeekPeriod(1)
  if (!isValidRange(start, end)) {
    return defaultPeriod
  }
  return {
    start: ensureSunday(start),
    end: ensureSaturday(end),
  }
}

function isValidRange(start, end) {
  if (!start || !end) {
    return false
  }
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  if (!startDate.isValid() || !endDate.isValid()) {
    return false
  }
  return startDate.isBefore(endDate)
}

function ensureSunday(date) {
  const workingDate = dayjs(date)
  if (workingDate.day() === 0) {
    return workingDate
  }
  return workingDate.startOf('isoWeek').subtract(1, 'days')
}

function ensureSaturday(date) {
  const workingDate = dayjs(date)
  if (workingDate.day() === 6) {
    return workingDate
  }
  return workingDate.endOf('isoWeek').subtract(1, 'days')
}
