import client from '~/api/apollo-client'
import { GetTrackingUserData as Result } from '~/types/api'
import { GetTrackingUserData } from './gql'

export const getTrackingUserData = async () => {
  const { data } = await client.query<Result>({
    query: GetTrackingUserData,
    errorPolicy: 'ignore',
  })

  if (data?.currentUser?.id) {
    return data.currentUser
  } else {
    return null
  }
}
