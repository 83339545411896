import React, { useCallback, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { MixpanelContext } from './mixpanel-context'
import { getTrackingUserData } from './get-tracking-user-data'
import { trackMixpanelEvent } from './track-mixpanel-event'

type Props = {
  children?: React.ReactNode
}

export const MixpanelContextProvider = ({ children }: Props) => {
  useEffect(() => {
    getTrackingUserData().then(user => {
      if (user) {
        mixpanel.identify(user.id)
        mixpanel.people.set_once({
          name: user.name,
          $email: user.email,
        })
      }
    })
  }, [])

  const trackEvent = useCallback(
    (eventCategory: string) => (
      eventName: string,
      payload?: Record<string, unknown>
    ) => trackMixpanelEvent(eventCategory, eventName, payload),
    []
  )

  return (
    <MixpanelContext.Provider value={{ trackEvent }}>
      {children}
    </MixpanelContext.Provider>
  )
}
