import React from 'react'
import connect from '../utils/connect'
import sel from '~/selectors'
import Layout from '~/components/layout/layout-with-single-frame'
import { requestToWorkWith } from '~/api/request-to-work-with'

class ProfileContainer extends React.Component {
  static mapStateToProps(state) {
    const users = sel.users(state)
    const selectedUserId = sel.selectedUserId(state)
    const selectingUserId = sel.selectingUserId(state)
    const me = sel.me(state)

    const user = users.get(selectedUserId ? selectedUserId : selectingUserId)

    return {
      userId: selectedUserId ? selectedUserId : selectingUserId,
      avatar: user && user.get('photo'),
      name: user && user.get('name'),
      myId: me && me.get('id'),
      user: user,
    }
  }

  requestToWorkWith = userId => {
    // this.props.actions.toggleDirectChat(generateDirectFeedId(this.props.userId, this.props.myId))
    var { toggleDirectChat } = this.props.actions
    requestToWorkWith(userId).then(groupChat => {
      console.log(groupChat, 'requestToWorkWith')
      toggleDirectChat(groupChat.feedId)
    })
  }

  render() {
    const { user, userId, avatar, name, myId } = this.props
    const avatarStyle = avatar ? { backgroundImage: `url(${avatar})` } : null

    return (
      <Layout narrow={true}>
        <div className="profile-container">
          <div className="avatar" style={avatarStyle}></div>
          <div className="name">{name}</div>
          {myId !== userId && (
            <div
              onClick={() => this.requestToWorkWith(userId)}
              className="chat-btn"
            >
              Request to work
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default connect(ProfileContainer)
