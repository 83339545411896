import {
  all,
  take,
  takeEvery,
  fork,
  cancel,
  select,
  call,
} from 'redux-saga/effects'
import { $dispatch, delay } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as actions from '~/actions'
import sel from '~/selectors'
// import { fetchProjectWithIdGQL } from '~/api/fetch-project-with-id'

const OP_ACTIONS = [
  ActionTypes.SELECT_PROJECT,
  ActionTypes.START_MODIFYING_CARD,
  ActionTypes.CANCEL_MODIFYING_CARD,
  ActionTypes.MOVE_CARD,
  ActionTypes.CREATE_EPHEMERAL_CARD,
  ActionTypes.DELETE_CARD,
  ActionTypes.COMMIT_EDITING_CARD,
  ActionTypes.MARK_CARD_WITH_ID_COMPLETED,
  ActionTypes.CLEAR_CARD_OPTIMISTIC_STATE,
]

export default function* $realtimeTreeUpdatesSaga() {
  yield all([
    fork(takeEvery, OP_ACTIONS, $updateActiveOps),
    fork($handleProjectUpdates),
  ])
}

let activeOpsCardIds = []
let pendingUpdates = []
let activeUpdate = null // {action, task}

function* $updateActiveOps(action) {
  switch (action.type) {
    case ActionTypes.SELECT_PROJECT: {
      yield* $cancelActiveUpdate()
      activeOpsCardIds.length = 0
      pendingUpdates.length = 0
      break
    }
    case ActionTypes.CLEAR_CARD_OPTIMISTIC_STATE: {
      removeActiveOp(action.originalCardId)
      removeActiveOp(action.cardId)
      yield* $maybeStartUpdate()
      break
    }
    case ActionTypes.CANCEL_MODIFYING_CARD: {
      removeActiveOp(action.cardId)
      yield* $maybeStartUpdate()
      break
    }
    case ActionTypes.CREATE_EPHEMERAL_CARD: {
      addActiveOp(action.ephemeralId)
      yield* $cancelActiveUpdate()
      break
    }
    default: {
      addActiveOp(action.cardId)
      yield* $cancelActiveUpdate()
      break
    }
  }
}

function addActiveOp(cardId) {
  if (activeOpsCardIds.indexOf(cardId) == -1) {
    activeOpsCardIds.push(cardId)
    // console.debug(`active ops:`, activeOpsCardIds)
  }
}

function removeActiveOp(cardId) {
  const index = activeOpsCardIds.indexOf(cardId)
  if (index != -1) {
    activeOpsCardIds.splice(index, 1)
    // console.debug(`active ops:`, activeOpsCardIds)
  }
}

function* $maybeStartUpdate() {
  if (pendingUpdates.length && !activeOpsCardIds.length) {
    yield* $startUpdate()
  }
}

function* $handleProjectUpdates() {
  while (true) {
    const action = yield take(ActionTypes.PROJECT_REMOTELY_UPDATED)
    const myUserId = yield select(sel.userId)

    if (action.actorId == myUserId) {
      // console.debug(`ignoring project update generated by self`)
      continue
    }

    pendingUpdates.push(action)

    if (activeOpsCardIds.length == 0) {
      yield* $startUpdate()
    }
  }
}

function* $startUpdate() {
  yield* $cancelActiveUpdate()

  const action = pendingUpdates.pop() // TODO: combine all pending updates
  pendingUpdates.length = 0

  activeUpdate = { action, task: null }
  activeUpdate.task = yield fork($doUpdate)
}

function* $cancelActiveUpdate() {
  if (activeUpdate) {
    yield cancel(activeUpdate.task)
    pendingUpdates.unshift(activeUpdate.action)
    activeUpdate = null
  }
}

function* $doUpdate() {
  yield call(delay, 300) // debounce for 300 ms
  // const projectId = yield select(sel.selectedProjectId)
  // // const response = yield call(fetchProjectWithIdGQL, projectId)
  // yield* $dispatch(actions.updateProject(response.entities))
}
