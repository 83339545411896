import { all, call, select, takeEvery, fork } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import sel from '~/selectors'
import * as ActionTypes from '~/actions/types'
import * as actions from '~/actions'
// import { getTimeEntries, getCustomTimeEntries } from '~/api/dashboard'

export default function* $reports(action) {
  yield all([
    fork(takeEvery, ActionTypes.GET_TRACKED_HOURS, $offsetTrackedHours),
    fork(takeEvery, ActionTypes.GET_CUSTOM_TRACKED_HOURS, $customTrackedHours),
  ])
}

function* $offsetTrackedHours(action) {
  const { offset, periodType } = action
  const projectId = yield select(sel.selectedProjectId)
  let report
  try {
    // report = yield call(getTimeEntries, projectId, periodType, offset)
  } catch (err) {
    report = { err }
  }
  yield* $dispatch(actions.trackedHoursReady(report))
}

function* $customTrackedHours(action) {
  const { start, end } = action
  const projectId = yield select(sel.selectedProjectId)
  let report
  try {
    // report = yield call(getCustomTimeEntries, projectId, start, end)
  } catch (err) {
    report = { err }
  }
  yield* $dispatch(actions.trackedHoursReady(report))
}
