/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'
import {findDOMNode} from 'react-dom'
import Textarea from 'react-textarea-autosize'


export default class CardListItemInput extends React.Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    delete: PropTypes.func,
    changeNestingLevel: PropTypes.func,
    autofocus: PropTypes.bool,
    autofocusDelay: PropTypes.number,
    selectionStart: PropTypes.number,
    selectionEnd: PropTypes.number,
  }

  static defaultProps = {
    autofocus: true,
    autofocusDelay: 0,
    flavor: null,
  }

  static Flavor = {
    DEFAULT: null,
    IN_HEADER: 'IN_HEADER',
  }

  render() {
    const {props} = this
    return (
      props.flavor === CardListItemInput.Flavor.IN_HEADER
      ? <Textarea className='task-input -in-header'
                  defaultValue={props.value}
                  ref={this.handleInputNodeRef}
                  onKeyDown={this.handleKeyDown}>
        </Textarea>
      : <input className='task-input'
               type='text'
               size='40'
               defaultValue={props.value}
               ref={this.handleInputNodeRef}
               onKeyDown={this.handleKeyDown}>
      </input>
    )
  }

  constructor() {
    super()
    this.inputNode = null
    this.handleInputNodeRef = this.handleInputNodeRef.bind(this)
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown)
  }

  handleDocumentMouseDown(e) {
    if (e.target != this.inputNode) {
      this.submit(false)
    }
  }

  handleInputNodeRef(node) {
    this.inputNode = node instanceof React.Component ? findDOMNode(node) : node

    if (node && this.props.autofocus) {
      const focus = () => {
        if (this.inputNode) {
          this.inputNode.focus()
          const {selectionStart, selectionEnd} = this.props
          if (selectionStart != null) {
            this.inputNode.setSelectionRange(selectionStart, selectionEnd)
          } else {
            this.inputNode.select()
          }
        }
      }
      if (this.props.autofocusDelay) {
        setTimeout(focus, this.props.autofocusDelay)
      } else {
        focus()
      }
    }
  }

  handleKeyDown(e) {
    switch (e.key) {
      case 'Enter': this.submit(true); break
      case 'Escape': this.props.cancel(); break
      case 'Tab': {
        this.changeNestingLevel(!e.shiftKey, e.target)
        break
      }
      default: return
    }
    e.stopPropagation()
    e.preventDefault()
  }

  changeNestingLevel(doIncrease, inputNode) {
    this.props.changeNestingLevel && this.props.changeNestingLevel(
      doIncrease, inputNode.value, inputNode.selectionStart, inputNode.selectionEnd
    )
  }

  submit(enterPressed) {
    const value = this.inputNode.value

    if (value == '' && this.props.delete) {
      this.props.delete()
      return
    }

    const newValue = this.props.submit(value, enterPressed)

    if (newValue != undefined && this.inputNode) {
      this.inputNode.value = ''
      const focus = () => {
        const {inputNode} = this
        if (inputNode) {
          inputNode.blur() // blur is needed to kick auto scroll to the input
          inputNode.focus()
          inputNode.select()
        }
      }
      setTimeout(focus, 30)
    }
  }
}
