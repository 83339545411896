import { gql } from '@apollo/client'

export const GetCurrentUserData = gql`
  query GetCurrentUserData {
    currentUser {
      id
      name
    }
  }
`
