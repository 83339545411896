import {buildPath as buildPathFromRoute} from '~/utils/routing'
import {buildPathFromLocation} from '~/utils/url'
import {$dispatch} from '../utils/effects'

import * as Actions from '~/actions'
import history from '~/utils/history'


let pendingPath = null
let isNavigating = false


export function* $navigateToRouteIfNeeded(route, currentPath) {
  const newPath = buildPathFromRoute(route)
  if (newPath != currentPath) {
    const location = parsePath(newPath)
    isNavigating = true
    yield* $dispatch(Actions.routeChanged(route, location, newPath))
    isNavigating = false
    navigateToPathThrottled(newPath)
  }
}


function navigateToPathThrottled(path) {
  if (!pendingPath) {
    setTimeout(doNavigate, 100)
  }
  pendingPath = path
}


function doNavigate() {
  const currentPath = buildPathFromLocation(window.location)
  const newPath = pendingPath

  pendingPath = null

  if (newPath != currentPath) {
    isNavigating = true
    history.push(newPath)
    isNavigating = false
  }
}


export function getIsNavigating() {
  return isNavigating
}


function parsePath(path) {
  const searchIndex = indexOrLength('?', path)
  const hashIndex = indexOrLength('#', path)
  return (searchIndex <= hashIndex
    ? {
      pathname: path.substring(0, searchIndex),
      search: path.substring(searchIndex, hashIndex),
      hash: path.substring(hashIndex)
    }
    : {
      pathname: path.substring(0, hashIndex),
      search: path.substring(searchIndex),
      hash: path.substring(hashIndex, searchIndex)
    }
  )
}


function indexOrLength(sym, str) {
  const index = str.indexOf(sym)
  return index >= 0 ? index : str.length
}
