import { fromJS, Map } from 'immutable'
import * as ActionTypes from '~/actions/types'
import sel from '~/selectors'

import entitiesReducer from './entities'
import cardsReducer from './cards'
import routerReducer from './router'
import filtersReducer from './filters'
import authReducer from './auth'
import feedsReducer from './feeds'
import unreadCountsReducer from './unread-counts'
import projectsReducer from './projects'
import usersReducer from './users'
import planTopLevelCardIdReducer from './plan-top-level-card-id'
import cardsViewStateReducer from './cards-view-state'
import presenceReducer from './presence'
import invitationReducer from './invitation'
import searchReducer from './search'
import selectedCardIdReducer from './selected-card-id'
import reportReducer from './reports'
import trackedHoursReducer from './tracked-hours'
import directChatsReducer from './direct-chats'
import paymentStatusReducer from './payment-status'
import videoReducer from './video-meeting'
import recordsReducer from './records'

const INITIAL_STATE = Map({
  entities: entitiesReducer.INITIAL_STATE,
  router: routerReducer.INITIAL_STATE,
  filters: filtersReducer.INITIAL_STATE,
  auth: authReducer.INITIAL_STATE,
  permissions: Map({}),
  feeds: feedsReducer.INITIAL_STATE,
  unreadCounts: unreadCountsReducer.INITIAL_STATE,
  projects: projectsReducer.INITIAL_STATE,
  users: usersReducer.INITIAL_STATE,
  planTopLevelCardId: planTopLevelCardIdReducer.INITIAL_STATE,
  cardsViewState: cardsViewStateReducer.INITIAL_STATE,
  selectedCardId: selectedCardIdReducer.INITIAL_STATE,
  editingCardId: null,
  cardMovingOpId: null,
  presence: presenceReducer.INITIAL_STATE,
  invitation: invitationReducer.INITIAL_STATE,
  search: searchReducer.INITIAL_STATE,
  uploadProgress: Map({}),
  drafts: Map({}),
  reports: reportReducer.INITIAL_STATE,
  trackedHours: trackedHoursReducer.INITIAL_STATE,
  isRevealingCard: false,
  newActionToUndo: null,
  fatalError: null,
  directChats: directChatsReducer.INITIAL_STATE,
  fullscreenChat: false,
  hasBankCard: false,
  paymentStatus: paymentStatusReducer.INITIAL_STATE,
  availabilityForm: Map({}),
  video: videoReducer.INITIAL_STATE,
  records: recordsReducer.INITIAL_STATE,
})

export default function rootReducer(prevState = INITIAL_STATE, action) {
  let wrapReducer = (reducer, ...args) => state =>
    reducer(state, action, ...args)

  return prevState.withMutations(state => {
    // A hack to tell selectors that the state is mutable and that they should recalculate
    // on each call. See memoizePath and memoizeKey functions in selectors.js.
    // TODO: use isImmutable there instead when Immutable.js 4.0 is out and remove this line,
    // see github.com/facebook/immutable-js/pull/1113.
    state.__mutable = true

    let entities = state.get('entities')

    const selectProjectId = () => sel.selectedProjectId(state)
    const selectMyUserId = () =>
      state.get('auth').get('credentials').get('user_id')
    const selectCards = () => entities.get('card')
    const selectPrevCards = () => prevState.get('entities').get('card')
    const selectMovingOpId = () => state.get('cardMovingOpId')
    const selectOpenFeedIds = () => state.get('feeds').get('openIds')
    const selectUnreadCount = cardId =>
      sel.unreadCountForCardWithId(state, cardId)
    const selectRouteType = () => sel.route(state).get('type')
    const selectPreviousRouteType = () => sel.route(prevState).get('type')
    const selectTopLevelCardId = () => sel.route(state).get('cardId')
    const selectFilters = () => sel.filters(state)
    const selectPrevFilters = () => sel.filters(prevState)
    const selectMe = () => sel.me(state)
    const feedEvents = () => sel.feedEvents(state)
    const selectedProjectActivityFeedId = () =>
      sel.selectedProjectActivityFeedId(state)

    entities = entitiesReducer(entities, action)
    entities = entities.update(
      'card',
      wrapReducer(cardsReducer, selectMovingOpId)
    )

    // beware: order matters here
    //
    state
      .set('entities', entities)
      .update('router', wrapReducer(routerReducer))
      .update(
        'filters',
        wrapReducer(filtersReducer, {
          selectRouteType,
          selectPreviousRouteType,
        })
      )
      .update('auth', wrapReducer(authReducer))
      .update('permissions', wrapReducer(permissionsReducer))
      .update(
        'feeds',
        wrapReducer(feedsReducer, {
          selectMe,
          selectCards,
          feedEvents,
          selectedProjectActivityFeedId,
        })
      )
      .update(
        'unreadCounts',
        wrapReducer(unreadCountsReducer, selectOpenFeedIds)
      )
      .update('projects', wrapReducer(projectsReducer, selectCards))
      .update('users', wrapReducer(usersReducer))
      .update(
        'planTopLevelCardId',
        wrapReducer(planTopLevelCardIdReducer, {
          selectCards,
          selectProjectId,
        })
      )
      .update(
        'cardsViewState',
        wrapReducer(cardsViewStateReducer, {
          selectFilters,
          selectCards,
          selectPrevFilters,
          selectPrevCards,
          selectProjectId,
          selectTopLevelCardId,
          selectRouteType,
          selectMyUserId,
        })
      )
      .update(
        'selectedCardId',
        wrapReducer(selectedCardIdReducer, selectUnreadCount)
      )
      .update('editingCardId', wrapReducer(editingCardIdReducer))
      .update('cardMovingOpId', wrapReducer(cardMovingOpIdReducer, selectCards))
      .update('presence', wrapReducer(presenceReducer))
      .update('invitation', wrapReducer(invitationReducer))
      .update('search', wrapReducer(searchReducer))
      .update('uploadProgress', wrapReducer(uploadProgressReducer))
      .update('drafts', wrapReducer(draftsReducer))
      .update('reports', wrapReducer(reportReducer))
      .update('trackedHours', wrapReducer(trackedHoursReducer, selectMyUserId))
      .update('isRevealingCard', wrapReducer(isRevealingCardReducer))
      .update('newActionToUndo', wrapReducer(newActionToUndoReducer))
      .update('fatalError', wrapReducer(fatalErrorReducer))
      .update('directChats', wrapReducer(directChatsReducer))
      .update('fullscreenChat', wrapReducer(fullscreenChatReducer))
      .update('hasBankCard', wrapReducer(hasBankCardReducer))
      .update('paymentStatus', wrapReducer(paymentStatusReducer))
      .update('availabilityForm', wrapReducer(availabilityForm))
      .update('video', wrapReducer(videoReducer))
      .update('records', wrapReducer(recordsReducer))
  })
}

function cardMovingOpIdReducer(currentOpId, action, selectCards) {
  switch (action.type) {
    case ActionTypes.MOVE_CARD: {
      const isWhileEditing = selectCards().getIn([
        action.cardId,
        'optimistic',
        'isEditing',
      ])
      return isWhileEditing ? currentOpId : currentOpId || action.opId
    }
    case ActionTypes.CLEAR_CARD_OPTIMISTIC_STATE:
      return null
    default:
      return currentOpId
  }
}

function editingCardIdReducer(state, action) {
  switch (action.type) {
    case ActionTypes.CREATE_EPHEMERAL_CARD:
      return action.ephemeralId
    case ActionTypes.START_EDITING_CARD:
      return action.cardId
    case ActionTypes.CANCEL_EDITING_CARD:
    case ActionTypes.COMMIT_EDITING_CARD: {
      return action.cardId === state ? null : state
    }
    default:
      return state
  }
}

function draftsReducer(state, { type, feedId, draft }) {
  switch (type) {
    case ActionTypes.UPDATE_DRAFT:
      return state.set(feedId, draft)
    default:
      return state
  }
}

function uploadProgressReducer(state, { type, feedId, progress }) {
  switch (type) {
    case ActionTypes.SET_UPLOAD_PROGRESS:
      return state.set(feedId, progress)
    default:
      return state
  }
}

function permissionsReducer(state, { type, permissions }) {
  switch (type) {
    case ActionTypes.SET_PERMISSIONS:
      return fromJS(permissions)
    default:
      return state
  }
}

function isRevealingCardReducer(state, { type }) {
  switch (type) {
    case ActionTypes.REVEAL_CARD:
      return true
    case ActionTypes.REVEAL_CARD_FINISHED:
      return false
    default:
      return state
  }
}

function newActionToUndoReducer(state, action) {
  switch (action.type) {
    case ActionTypes.NEW_UNDO_ITEM:
      return fromJS(action.actionToUndo)
    case ActionTypes.UNDO:
      return null
    default:
      return state
  }
}

function fatalErrorReducer(state, action) {
  switch (action.type) {
    case ActionTypes.FATAL_ERROR:
      return action.error || new Error('Unknown error')
    default:
      return state
  }
}

function fullscreenChatReducer(state, { type }) {
  switch (type) {
    case ActionTypes.TOGGLE_FULLSCREEN_CHAT:
      return !state
    default:
      return state
  }
}

function hasBankCardReducer(state, { type, hasCard }) {
  if (type === ActionTypes.PAYMENT_SUCCESS) {
    return true
  }
  return type === ActionTypes.SET_HAVE_BANK_CARD ? hasCard : state
}

function availabilityForm(state, { type, error }) {
  switch (type) {
    case ActionTypes.SUBMIT_AVAILABILITY:
      return Map({ loading: true })
    case ActionTypes.AVAILABILITY_SUBMITTED:
      return Map({ success: true })
    case ActionTypes.AVAILABILITY_SUBMISSION_FAILED:
      return Map({ error })
    case ActionTypes.RESET_AVAILABILITY_FORM:
      return Map({})
  }
  return state
}

// Remembering last location state is needed as a workaround to an issue in react-router-redux:
// https://github.com/reactjs/react-router-redux/issues/421
// TODO: check if this is still needed
let lastLocationState = null
let lastLocationStatePlain = null

export function selectLocationState(state) {
  let locationState = sel.routingState(state)
  if (locationState !== lastLocationState) {
    lastLocationState = locationState
    lastLocationStatePlain = locationState.toJS()
  }
  return lastLocationStatePlain
}
