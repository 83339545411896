import * as Types from '~/types/api'
import client from '~/api/apollo-client'
import * as Queries from './queries'
import {
  normalizeCardData,
  UsersObject,
  injectCardPaths,
  ProjectCardsObject,
} from './util'
import CardRelation from '~/utils/card-relation'
import * as persistentStore from '~/utils/persistent-store'

export const assignCard = async (cardId: string, assigneeId: string) => {
  const { data } = await client.mutate<
    Types.AssignCardMutation,
    Types.AssignCardMutationVariables
  >({
    mutation: Queries.AssignCardMutation,
    variables: { input: { cardId, assigneeId } },
  })
  return normalizeCardData(data?.cardAssign)
}

export const unassignCard = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UnassignCardMutation,
    Types.UnassignCardMutationVariables
  >({
    mutation: Queries.UnassignCardMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardUnassign)
}

export const completeCard = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.CompleteCardMutation,
    Types.CompleteCardMutationVariables
  >({
    mutation: Queries.CompleteCardMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardComplete)
}

export const uncompleteCard = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UncompleteCardMutation,
    Types.UncompleteCardMutationVariables
  >({
    mutation: Queries.UncompleteCardMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardUncomplete)
}

export const increaseCardBudget = async (
  cardId: string,
  budgetDuration: string
) => {
  const { data } = await client.mutate<
    Types.IncreaseBudgetMutation,
    Types.IncreaseBudgetMutationVariables
  >({
    mutation: Queries.IncreaseBudgetMutation,
    variables: { input: { cardId, budgetDuration } },
  })
  return normalizeCardData(data?.cardBudgetIncrease)
}

export const decreaseCardBudget = async (
  cardId: string,
  budgetDuration: string
) => {
  const { data } = await client.mutate<
    Types.DecreaseBudgetMutation,
    Types.DecreaseBudgetMutationVariables
  >({
    mutation: Queries.DecreaseBudgetMutation,
    variables: { input: { cardId, budgetDuration } },
  })
  return normalizeCardData(data?.cardBudgetDecrease)
}

export const unsetCardBudget = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UnsetBudgetMutation,
    Types.UnsetBudgetMutationVariables
  >({
    mutation: Queries.UnsetBudgetMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardBudgetUnset)
}

export const deleteCard = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.DeleteCardMutation,
    Types.DeleteCardMutationVariables
  >({
    mutation: Queries.DeleteCardMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardDelete)
}

export const undeleteCard = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UndeleteCardMutation,
    Types.UndeleteCardMutationVariables
  >({
    mutation: Queries.UndeleteCardMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardUndelete)
}

export const moveCard = async (
  cardId: string,
  targetId: string,
  relation: Types.MoveRelation
) => {
  if (relation === CardRelation.NOP) {
    return
  }
  const { data } = await client.mutate<
    Types.MoveCardMutation,
    Types.MoveCardMutationVariables
  >({
    mutation: Queries.MoveCardMutation,
    variables: { input: { cardId, relation, targetId } },
  })
  return normalizeCardData(data?.cardMove)
}

export const updateCardEstimate = async (cardId: string, estimate: string) => {
  const { data } = await client.mutate<
    Types.UpdateEstimateMutation,
    Types.UpdateEstimateMutationVariables
  >({
    mutation: Queries.UpdateEstimateMutation,
    variables: { input: { cardId, estimate } },
  })
  return normalizeCardData(data?.cardEstimateSet)
}

export const unsetCardEstimate = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UnsetEstimateMutation,
    Types.UnsetEstimateMutationVariables
  >({
    mutation: Queries.UnsetEstimateMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardEstimateUnset)
}

export const setCardDueDate = async (cardId: string, dueDate: string) => {
  const { data } = await client.mutate<
    Types.SetCardDueDateMutation,
    Types.SetCardDueDateMutationVariables
  >({
    mutation: Queries.SetCardDueDateMutation,
    variables: { input: { cardId, dueDate } },
  })
  return normalizeCardData(data?.cardDueDateSet)
}

export const unsetCardDueDate = async (cardId: string) => {
  const { data } = await client.mutate<
    Types.UnsetCardDueDateMutation,
    Types.UnsetCardDueDateMutationVariables
  >({
    mutation: Queries.UnsetCardDueDateMutation,
    variables: { input: { cardId } },
  })
  return normalizeCardData(data?.cardDueDateUnset)
}

export const renameCard = async (cardId: string, cardName: string) => {
  const { data } = await client.mutate<
    Types.RenameCardMutation,
    Types.RenameCardMutationVariables
  >({
    mutation: Queries.RenameCardMutation,
    variables: { input: { cardId, cardName } },
  })
  return normalizeCardData(data?.cardRename)
}

export const createCard = async (
  title: string,
  parentId: string,
  beforeId: string | null = null,
  assignTo: boolean | null = null,
  quickAdd = false
) => {
  const body: Types.CreateCardMutationVariables = {
    input: { parentId, cardName: title },
  }
  if (assignTo) {
    if (assignTo === true) {
      assignTo = persistentStore.getCredentials().user_id
    }
    body.input.assigneeId = assignTo
  }
  if (quickAdd) {
    body.input.quickAdd = true
  }
  if (beforeId) {
    body.input.beforeId = beforeId
  }
  const { data } = await client.mutate<
    Types.CreateCardMutation,
    Types.CreateCardMutationVariables
  >({
    mutation: Queries.CreateCardMutation,
    variables: { input: { ...body.input, cardId: 'new' } },
  })
  if (!data?.cardCreate) {
    return null
  }
  const { cardCreate: card } = data
  const cardChildren =
    card.descendants
      ?.filter(d => d?.parentId === card.id)
      .sort((a, b) => a?.position! - b?.position!)
      .map(d => d?.id) ?? []
  const normalizedData = {
    entities: {
      card: {
        [card.id!]: {
          ...card,
          assignee: card.assigneeId,
          path: [],
          children: cardChildren,
          type: 'card',
        },
      },
    },
    result: card.id,
  }
  injectCardPaths(normalizedData.entities.card)
  return normalizedData
}

export const fetchCard = async (cardId: string) => {
  const { data } = await client.query<
    Types.FetchCardQuery,
    Types.FetchCardQueryVariables
  >({
    query: Queries.FetchCardQuery,
    fetchPolicy: 'no-cache',
    variables: { id: cardId },
  })

  if (!data.card) {
    return null
  }
  const { card } = data
  const entitiesUsers: UsersObject = {}
  // card.members?.forEach(member => {
  //   if (member?.id) {
  //     entitiesUsers[member.id] = {
  //       ...member,
  //       type: 'user',
  //     }
  //   }
  // })
  const entitiesCards: ProjectCardsObject = {}
  if (card.id) {
    const rootCardChildren: string[] = []
    card.descendants?.forEach(descendant => {
      if (descendant?.id && descendant?.parentId === card.id) {
        rootCardChildren.push(descendant.id)
      }
    })
    entitiesCards[card.id] = {
      ...card,
      assignee: card.assigneeId,
      type: 'card',
      children: rootCardChildren,
      // memberships: card.members?.map(member => ({
      //   card_id: card.id,
      //   user_id: member?.id,
      // })),
      path: [],
    }
  }
  injectCardPaths(entitiesCards)
  const normalizedData = {
    entities: {
      card: entitiesCards,
      user: entitiesUsers,
    },
    result: card.id,
  }
  return normalizedData
}

export const inviteMember = async (cardId: string, email: string) => {
  await client.mutate<
    Types.InviteMemberMutation,
    Types.InviteMemberMutationVariables
  >({
    mutation: Queries.InviteMemberMutation,
    variables: {
      input: { cardId, email },
    },
  })
}
