import React from 'react'
import connect from '../utils/connect'
import cn from 'classnames'

import selectors from '~/selectors'

import CardFeedHeader from '../card-feed-header'
import { ChatContainerRedux } from '../chat/redux-container'

class LayoutContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      selectedFeedId: selectors.selectedFeedId(state),
    }
  }

  render() {
    const { children, selectedFeedId } = this.props
    const isChatExpanded = !!selectedFeedId

    return (
      <div className="layout-main-frame">
        <div className="layout-content">{children}</div>
        <div className={cn('chat-section', { '-expanded': isChatExpanded })}>
          <ChatContainerRedux
            chatId={selectedFeedId}
            header={<CardFeedHeader />}
          />
        </div>
      </div>
    )
  }
}

export default connect(LayoutContainer)
