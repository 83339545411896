import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import SelectItems, { SelectItem } from '../select-items'

import PopupWrapper from '../popup-wrapper'
import FilterInput from '../filter-input'
import escapeRegExp from '~/utils/escape-regexp'
import { buildPath, ROUTE_PLAN } from '~/utils/routing'

export default class HeaderProjectPicker extends React.Component {
  static propTypes = {
    selectProject: PropTypes.func,
    selectedProjectId: PropTypes.string,
    selectingProjectId: PropTypes.string,
    projects: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }

  state = {
    showPopup: false,
    filterValue: '',
  }

  render() {
    const { projects } = this.props
    const { showPopup } = this.state
    const hasMultipleProjects = projects.size > 1
    const onClick = hasMultipleProjects ? this.togglePopup : undefined
    const stateCls = showPopup ? 'open' : 'closed'

    return (
      <div className={`project-selector ${stateCls}`} onClick={onClick}>
        <div
          className={cn(
            'project-selector-caret',
            hasMultipleProjects && '-muitiple-projects'
          )}
        >
          {hasMultipleProjects && <div className="arrow" />}
          {this.getCurrentProjectName()}
        </div>
        {hasMultipleProjects && this.renderPopup()}
      </div>
    )
  }

  getCurrentProjectName() {
    const { selectedProjectId, selectingProjectId, projects } = this.props

    const projectId = selectingProjectId || selectedProjectId || ''
    const currentProject = projects.find(pr => pr.get('id') === projectId)
    const currentProjectName =
      (currentProject && currentProject.get('name')) || ''

    return currentProjectName
  }

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup })
    this.clearFilter()
  }

  onFilterChanged = e => {
    const filterValue = e.target.value
    this.updateFilter(filterValue)
  }

  onFilteringStarted = e => this.updateFilter(e.key)

  updateFilter(filterValue) {
    const { projects } = this.props

    if (filterValue === '') {
      this.clearFilter()
      return
    }

    const filterRegexp = new RegExp(
      `(?:^|\\s)${escapeRegExp(filterValue)}`,
      'i'
    )

    const showingIndexes = projects.reduce((indexes, project, index) => {
      if (filterRegexp.test(project.get('name'))) {
        indexes.push(index)
      }
      return indexes
    }, [])

    this.setState({ filterValue, showingIndexes })
  }

  clearFilter = () => this.setState({ filterValue: '', showingIndexes: null })

  renderPopup() {
    const { projects } = this.props

    const projectEls = projects.map(project => {
      const id = project.get('id')
      return (
        <SelectItem
          key={id}
          className="project-option"
          onSelected={() => {
            this.props.selectProject(id)
            this.togglePopup()
          }}
        >
          <a
            onClick={preventIfNotCtrl}
            href={buildPath({ type: ROUTE_PLAN, cardId: id })}
          >
            {project.get('name')}
          </a>
        </SelectItem>
      )
    })

    return (
      this.state.showPopup &&
      (() => (
        <PopupWrapper onDismiss={() => this.setState({ showPopup: false })}>
          {this.state.filterValue !== '' && (
            <FilterInput
              className="-in-project-picker"
              value={this.state.filterValue || ''}
              onClear={this.clearFilter}
              onChange={this.onFilterChanged}
            />
          )}
          <SelectItems
            onFilteringStarted={this.onFilteringStarted}
            showingIndexes={this.state.showingIndexes}
            containerClassName="project-picker-items"
          >
            {projectEls}
          </SelectItems>
        </PopupWrapper>
      ))()
    )
  }
}

function preventIfNotCtrl(event) {
  if (event.ctrlKey || event.metaKey) {
    event.stopPropagation()
  } else {
    event.preventDefault()
  }
}
