import React from 'react'

type Props = {
  title: string
  onClose: () => void
}

export const DefaultChatHeader = ({ title, onClose }: Props) => {
  return (
    <div className="default-header">
      <div className="title">{title}</div>
      <div className="close-icon" onClick={onClose}></div>
    </div>
  )
}
