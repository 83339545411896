/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'
import {findDOMNode} from 'react-dom'
import cn from 'classnames'

import Scrollable from '../scrollable'
import flatten from '~/utils/flatten'
import scrollListElementToView from '~/utils/scroll-list-element-into-view'

export class SelectItem extends React.Component {
  render() {
    const {children, onSelected, ...props} = this.props
    return <div {...props}>{children}</div>
  }
}

export default class SelectItems extends React.Component {

  static propTypes = {
    onItemSelected: PropTypes.func,
    onItemHighlighted: PropTypes.func,
    onFilteringStarted: PropTypes.func,
    initialHighlightedIndex: PropTypes.number,
    containerClassName: PropTypes.string,
    showingIndexes: PropTypes.arrayOf(PropTypes.number),
    containerStyle: PropTypes.object,
    children: PropTypes.node,
  }

  constructor(props) {
    super()
    this.state = {
      highlightedIndex: 0,
      flatChildren: flatten(props.children),
      showingIndexes: props.showingIndexes && props.showingIndexes.sort((a,b) => a-b),
    }
    this.handleKeyboard = this.handleKeyboard.bind(this)
    this.renderChild = this.renderChild.bind(this)
    this.scrollToElementAtIndex = this.scrollToElementAtIndex.bind(this)
    this.lastKeyboardInputTime = 0
  }

  UNSAFE_componentWillMount() {
    const {initialHighlightedIndex} = this.props
    if (initialHighlightedIndex >= 0) {
      this.setState({highlightedIndex: initialHighlightedIndex})
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newState = {}

    if (this.props.children !== nextProps.children) {
      const newFlatChildren = flatten(nextProps.children)
      if (newFlatChildren.length !== this.state.flatChildren.length) {
        newState.highlightedIndex = 0
      }
      newState.flatChildren = newFlatChildren
    }

    if (this.props.showingIndexes !== nextProps.showingIndexes){
      newState.showingIndexes = nextProps.showingIndexes && nextProps.showingIndexes.sort((a,b) => a-b)
      const indexOfPrevHighlighted = newState.showingIndexes && newState.showingIndexes.indexOf(this.state.highlightedIndex)
      if (indexOfPrevHighlighted && indexOfPrevHighlighted > -1) {
        newState.highlightedIndex = indexOfPrevHighlighted
      } else {
        newState.highlightedIndex = 0
      }
      this.scrollToElementAtIndex(newState.highlightedIndex)
    }

    this.setState(newState)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboard)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboard)
  }

  render() {
    return <Scrollable
              className={this.props.containerClassName}
              style={this.props.containerStyle}>
              {this.state.flatChildren.filter((item, index) => !this.isIndexHidden(index)).map(this.renderChild)}
            </Scrollable>
  }

  handleKeyboard(e) {
    const {flatChildren} = this.state
    if (flatChildren.length == 0) {
      return
    }
    switch (e.key) {
      case 'ArrowDown': {
        const {highlightedIndex} = this.state
        const newHighlightedIndex = Math.min(highlightedIndex + 1, this.maxIndex)
        this.onItemHighlighted(newHighlightedIndex)
        break
      }
      case 'ArrowUp': {
        const newHighlightedIndex = Math.max(this.state.highlightedIndex - 1, 0)
        this.onItemHighlighted(newHighlightedIndex)
        break
      }
      case 'Home': {
        this.onItemHighlighted(0)
        break
      }
      case 'End': {
        this.onItemHighlighted(this.maxIndex)
        break
      }
      case 'Enter': {
        this.onItemSelected(this.state.highlightedIndex)
        break
      }
      default: {
        if (typeof this.props.onFilteringStarted === 'function'
          && (e.keyCode > 64 && e.keyCode < 91) // letter key pressed
          && !this.props.showingIndexes) { // currently not filtering
            this.props.onFilteringStarted(e)
            break
        }
        return
      }
    }
    e.preventDefault()
    e.stopImmediatePropagation()
    this.lastKeyboardInputTime = Date.now()
  }

  onItemMouseEnter(index) {
    if (Date.now() - this.lastKeyboardInputTime >= 300) {
      this.setState({highlightedIndex: index})
      this.props.onItemHighlighted && this.props.onItemHighlighted(index)
    }
  }

  onItemHighlighted(index) {
    this.scrollToElementAtIndex(index)

    if (index !== this.state.highlightedIndex) {
      this.setState({highlightedIndex: index})
      this.props.onItemHighlighted && this.props.onItemHighlighted(index)
    }
  }

  onItemSelected(index) {
    const {flatChildren} = this.state
    const flatChildrenIndex = this.getFlatChilderenIndex(index)
    const item = flatChildren[flatChildrenIndex]
    const {onSelected} = item.props
    const {onItemSelected} = this.props
    onSelected && onSelected()
    onItemSelected && onItemSelected(flatChildrenIndex, item)
  }

  renderChild(child, index) {
    const className = cn('list-item', this.state.highlightedIndex === index && 'highlighted')

    return <div key={`select_${index}`}
                className={className}
                onMouseEnter={() => this.onItemMouseEnter(index)}
                onClick={() => this.onItemSelected(index)}>
              {child}
            </div>
  }

  isIndexHidden(index) {
    return this.state.showingIndexes && !this.state.showingIndexes.includes(index)
  }

  get maxIndex() {
    const {showingIndexes, flatChildren} = this.state
    return showingIndexes ? showingIndexes.length -1 : flatChildren.length - 1
  }

  getFlatChilderenIndex(index) {
    const {showingIndexes} = this.state
    return showingIndexes ? showingIndexes[index] : index
  }

  scrollToElementAtIndex(index){
    const rootNode = findDOMNode(this)
    const highlightedNode = rootNode.children[index]
    highlightedNode && scrollListElementToView(highlightedNode, rootNode)
  }

}
