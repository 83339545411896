import {all, fork} from 'redux-saga/effects'

import $handleActions from './handle-actions'
import $handleLocation from './handle-location'


export default function* $navigation() {
  yield all([
    fork($handleActions),
    fork($handleLocation),
  ])
}
