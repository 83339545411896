import React from 'react'
import connect from '../utils/connect'
import sel from '~/selectors'
import ContractorDashboard from './contractor-dashboard'

class ContractorDashboardContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      trackedHours: sel.trackedHours(state),
      projectId: sel.selectedProjectId(state),
    }
  }

  componentDidMount() {
    this.props.actions.getTrackedHours(
      this.props.trackedHours.get('offset'),
      this.props.trackedHours.get('periodType')
    )
  }

  shiftPeriod = delta => {
    this.props.actions.getTrackedHours(
      this.props.trackedHours.get('offset') + delta,
      this.props.trackedHours.get('periodType')
    )
  }

  changePeriodType = periodType => {
    this.props.actions.getTrackedHours(0, periodType)
  }

  getCustomPeriodTrackedHours = (start, end) => {
    this.props.actions.getCustomTrackedHours(start, end)
  }

  revealCard = cardId => {
    this.props.actions.revealCard(cardId, this.props.projectId)
  }

  render() {
    return (
      <ContractorDashboard
        trackedHours={this.props.trackedHours}
        shiftPeriod={this.shiftPeriod}
        getCustomPeriod={this.getCustomPeriodTrackedHours}
        changePeriodType={this.changePeriodType}
        revealCard={this.revealCard}
      />
    )
  }
}

export default connect(ContractorDashboardContainer)
