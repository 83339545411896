import { Dayjs, OpUnitType } from 'dayjs'
/* eslint-disable no-irregular-whitespace */
import dayjs from '~/utils/dayjs'

//
// The "str" parameter is expected to be in the following format:
//
//   YYYY-MM-DD[SUFFIX]
//
// It may contain any suffix (after the YYYY-MM-DD part) which is ignored.
//
// Returns Date instance.
//
export function parseDateAsLocal(str: string, isEndOfDay?: boolean) {
  if (str.length < 10) {
    throw new Error(
      `Invalid date string: expected to contain at least 10 symbols, got: "${str}"`
    )
  }
  return isEndOfDay
    ? new Date(
        +str.substr(0, 4),
        +str.substr(5, 2) - 1,
        +str.substr(8, 2),
        23,
        59,
        59,
        999
      )
    : new Date(
        +str.substr(0, 4),
        +str.substr(5, 2) - 1,
        +str.substr(8, 2),
        0,
        0,
        0,
        0
      )
}

/*
 * This time period constants should match dayjs constants
 * like week, month, etc.
 */
export const WEEK_TIME_PERIOD = 'week'
export const MONTH_TIME_PERIOD = 'month'
const TIME_PERIODS = [WEEK_TIME_PERIOD, MONTH_TIME_PERIOD]

/*
 * The offset parameter specifies the offset of the resulting time period
 * relative to the current time period, e.g.:
 *
 *  offset = 0, period = WEEK_TIME_PERIOD: this week
 *  offset = 1, period = WEEK_TIME_PERIOD: next week
 *  offset = -1, period = WEEK_TIME_PERIOD: prev week
 *  offset = 0, period = MONTH_TIME_PERIOD: this month
 *  offset = -1, period = MONTH_TIME_PERIOD: prev month
 *
 * Returns object of form {start, end}, where `start` and `end` are
 * dayjs instances.
 */
export function getTimePeriodByOffset(offset: number, periodType: OpUnitType) {
  if (TIME_PERIODS.indexOf(periodType) === -1) {
    throw Error(
      `passed ${periodType}, but should be one of ${TIME_PERIODS.join(', ')}`
    )
  }

  let shiftedDate = dayjs()
  if (offset !== 0) {
    shiftedDate = shiftedDate.add(offset, periodType)
  }
  const start = shiftedDate.clone().startOf(periodType)
  const end = shiftedDate.endOf(periodType)
  return { start, end }
}

export function getWeekPeriod(weekOffset = 0) {
  return getTimePeriodByOffset(weekOffset, WEEK_TIME_PERIOD)
}

export function formatDatePeriod({
  start,
  end,
}: {
  start: string | Dayjs
  end: string | Dayjs
}) {
  return {
    start: formatDate(start),
    end: formatDate(end),
  }
}

export function formatDate(date: string | Dayjs) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function formatDateISO(date: string) {
  return dayjs(date).format()
}

//
// Returns difference in week between the current and the passed ones
// -> 0 if current week
// -> -1 if next week
// -> 1 if prev week
// ... and so on
//
export function getWeekDelta(date: string | Dayjs | Date) {
  const startOfCurrentWeek = dayjs().startOf('week')
  const startOfTargetWeek = dayjs(date).startOf('week')
  return startOfCurrentWeek.diff(startOfTargetWeek, 'week')
}

export function formatShortDate(date: string | Dayjs) {
  return dayjs(date).format('MMM D')
}

function divrem(a: number, b: number) {
  return [Math.floor(a / b), a - b * Math.floor(a / b)]
}

function leftPad(num: number) {
  return num > 9 || num < -9 ? num.toString() : '0' + num.toString()
}

export function formatDuration(totalSeconds: number, showShortHours?: boolean) {
  const negative = totalSeconds < 0
  totalSeconds = Math.abs(totalSeconds)

  const [hours, remMinutes] = divrem(totalSeconds, 60 * 60)
  const [minutes, seconds] = divrem(remMinutes, 60)

  const str = []

  if (negative) str.push('-')
  if (hours > 0) str.push(hours.toString() + 'h')
  if (minutes > 0 || (hours > 0 && !showShortHours))
    str.push(leftPad(minutes) + 'm')
  if (totalSeconds === 0) str.push('0h')
  if (seconds && str.length === 0) str.push(seconds + 's')

  return str.join(' ')
}

export function hoursToSeconds(hours: number) {
  return hours * 60 * 60
}

export function formatHours(totalSeconds: number) {
  const negative = totalSeconds < 0
  totalSeconds = Math.abs(totalSeconds)

  const [hours] = divrem(totalSeconds, 60 * 60)
  const str = []

  if (negative) str.push('-')
  str.push(hours.toString() + 'h')

  return str.join(' ')
}

export function formatPeriodByOffset(offset: number, periodType: OpUnitType) {
  if (TIME_PERIODS.indexOf(periodType) === -1) {
    throw Error(
      `passed ${periodType}, but should be one of ${TIME_PERIODS.join(', ')}`
    )
  }

  if (offset === 0) {
    return `This ${periodType}`
  }

  const { start, end } = getTimePeriodByOffset(offset, periodType)

  if (periodType === MONTH_TIME_PERIOD) {
    return start.format('MMMM')
  } else {
    return `${formatShortDate(start)} — ${formatShortDate(end)}`
  }
}

export function formatWeeksDelta(periodStart: string) {
  const startDate = parseDateAsLocal(periodStart)
  const weekDelta = getWeekDelta(startDate)
  switch (weekDelta) {
    // Notice that the strings below use non-breaking space chars (Opt+Space on Mac).
    case 0:
      return `this week`
    case 1:
      return `previous week`
    case -1:
      return `next week`
  }
  return weekDelta > 0 ? `${+weekDelta} weeks ago` : `${-weekDelta} weeks ahead`
}
