import PropTypes from 'prop-types'
import React from 'react'

import ImmutablePropTypes from 'react-immutable-proptypes'

import HeaderSearch from './header-search'
import { MenuContainer } from '../menu'
import HeaderProjectPicker from './header-project-picker'

class Header extends React.PureComponent {
  static propTypes = {
    me: ImmutablePropTypes.map,
    selectingProjectId: PropTypes.string,
    selectedProjectId: PropTypes.string,
    projects: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    logout: PropTypes.func.isRequired,
    selectProject: PropTypes.func,
    selectUser: PropTypes.func,
    permissions: ImmutablePropTypes.map.isRequired,
    report: PropTypes.object,
  }

  constructor() {
    super()
  }

  render() {
    return (
      <div className="page-header-wrapper">
        <div className="page-header">
          <MenuContainer />
          <HeaderProjectPicker
            selectProject={this.props.selectProject}
            projects={this.props.projects}
            selectedProjectId={this.props.selectedProjectId}
            selectingProjectId={this.props.selectingProjectId}
          />
          <div className="spacer" />
          <HeaderSearch />
        </div>
      </div>
    )
  }
}

export default Header
