import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { feedMarkAsRead } from './api/mutations'
import { ChatMessages } from './chat-messages'
import { MessageDeleteModal } from './delete-modal'
import { ChatInput } from './input/chat-input'
import * as Types from '~/types/api'
import DroppableContainer from './droppable-container'
import DropOverlay from './drop-overlay'

type ChatProps = {
  chatId: string
  projectId: string | null
  events: (Types.GetChatData_feed_feedEvents | null)[]
  members: (Types.GetChatData_feed_members | null)[] | null
  currentUserId: string | null | undefined
  header?: JSX.Element
  onLoadMore: () => void
  onPostMessage: (messageBody: string) => void
  onOpenSubscription: () => () => void
  onMentionClick: (userId: string) => void
  onAddReaction: (feedEventId: string, emoji: string) => void
  onRemoveReaction: (feedEventId: string, emoji: string) => void
  onTimeEntryCreate: (date: string, duration: string, memo: string) => void
  onTimeEntryEdit: (
    messageId: string,
    timeEntry: Types.ChatEventFragment_bodyTokens_TimeEntry
  ) => void
  onEditMessage: (feedEventId: string, messageText: string) => void
  onDeleteMessage: (feedEventId: string, timeEntryId?: string | null) => void
  onCardClick: (cardId: string) => void
  uploadFile: (f: File) => void
  allowAttachments?: boolean
  withServiceMessages?: boolean
  allowTimeTracking?: boolean
  theme?: string
}

export type EditMessage = {
  text: string
  id: string
}

type MessageToDelete = {
  id: string
  timeEntryId?: string | null
}

export type TimeEntryToEdit = {
  messageId: string
  token: Types.ChatEventFragment_bodyTokens_TimeEntry
}

export const Chat = ({
  events,
  members,
  currentUserId,
  theme,
  ...props
}: ChatProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const chatElRef = useRef<HTMLDivElement>(null)
  const [
    messageToDelete,
    setMessageToDelete,
  ] = useState<MessageToDelete | null>(null)
  const [messageToEdit, setMessageToEdit] = useState<EditMessage | null>(null)
  const [
    timeEntryToEdit,
    setTimeEntryToEdit,
  ] = useState<TimeEntryToEdit | null>(null)
  const [draggedOver, setDraggedOver] = useState<boolean>(false)

  const handleDeleteMessage = useCallback(() => {
    if (messageToDelete) {
      props.onDeleteMessage(messageToDelete.id, messageToDelete.timeEntryId)
      setMessageToDelete(null)
    }
  }, [messageToDelete])

  const handleInputResize = (newHeight: number) => {
    if (chatElRef.current) {
      if (chatElRef.current.scrollTop !== chatElRef.current.scrollHeight) {
        chatElRef.current.scrollTop = chatElRef.current.scrollHeight
      } else {
        chatElRef.current.scrollTop += newHeight
      }
    }
  }

  const handleEditMessage = (
    messageId: string,
    bodyTokens: (Types.GetChatData_feed_feedEvents_bodyTokens | null)[] | null
  ) => {
    const parsedMessage = bodyTokens?.reduce((result, token) => {
      switch (token?.__typename) {
        case 'StringObject':
          result += token.string
          break
        case 'Link':
          result += token.uri
          break
        case 'User':
          result += `@${token.name}`
          break
        case 'Card':
          result += `https://oldapp.turtleos.com/plan/${token.id}`
          break
        case 'TimeEntry':
          setTimeEntryToEdit({ messageId, token })
          result += 'timeEntry'
          break
        default:
          result += ' '
          break
      }
      return result
    }, '')
    if (parsedMessage?.length && !parsedMessage.includes('timeEntry')) {
      setMessageToEdit({ id: messageId, text: parsedMessage })
    }
  }

  const handleDateTimeClick = useCallback(
    (dateTime: string) => {
      if (inputRef.current) {
        inputRef.current.value = `I'm free at ${dateTime}`
        inputRef.current.focus()
      }
    },
    [inputRef.current]
  )

  useEffect(() => {
    const unsubscribe = props.onOpenSubscription()
    feedMarkAsRead(props.chatId)

    return () => unsubscribe()
  }, [])

  return (
    <DroppableContainer
      className={cn('chat-container', { 'theme-dark': theme === 'dark' })}
      setDraggedOver={setDraggedOver}
      handleFileDrop={props.uploadFile}
      allowAttachments={props.allowAttachments}
    >
      {draggedOver && <DropOverlay />}
      {props.header}
      <ChatMessages
        messages={events}
        chatElRef={chatElRef}
        currentUserId={currentUserId!}
        chatMembers={members}
        {...props}
        onEditMessage={handleEditMessage}
        onDeleteMessage={(id, timeEntryId) => {
          setMessageToDelete({ id, timeEntryId })
          setTimeEntryToEdit(null)
        }}
        onDateTimeClick={handleDateTimeClick}
        onUserClick={() => {}}
        theme={theme}
      />
      <ChatInput
        inputRef={inputRef}
        members={members}
        onResize={handleInputResize}
        messageToEdit={messageToEdit}
        timeEntryToEdit={timeEntryToEdit?.token}
        onEditModalClose={() => setMessageToEdit(null)}
        currentUserId={currentUserId!}
        theme={theme}
        {...props}
        onTimeEntryEdit={timeEntry => {
          props.onTimeEntryEdit(timeEntryToEdit?.messageId!, timeEntry)
          setTimeEntryToEdit(null)
        }}
        onTimeEntryFormClose={() => setTimeEntryToEdit(null)}
      />
      {messageToDelete && (
        <MessageDeleteModal
          onCancelClick={() => setMessageToDelete(null)}
          onDeleteClick={handleDeleteMessage}
          theme={theme}
        />
      )}
    </DroppableContainer>
  )
}
