import client from './apollo-client'
import { requestToWorkWith as MutationType } from '~/types/api'
import { RequestToWorkWithMutation } from './queries'

export const requestToWorkWith = async (contractorId: string) => {
  const resp = await client.mutate<MutationType>({
    mutation: RequestToWorkWithMutation,
    variables: {
      input: {
        contractorId: contractorId,
      },
    },
  })
  return resp.data?.requestToWorkWith
}
