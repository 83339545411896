import {buffers} from 'redux-saga'
import {take, select, actionChannel} from 'redux-saga/effects'

import * as ActionTypes from '~/actions/types'
import sel from '~/selectors'

import {isRouteToCard, doesRouteAllowCardSelection, ROUTE_PLAN} from '~/utils/routing'

import {$navigateToRouteIfNeeded, getIsNavigating} from './utils'


export default function* $handleCardNavigationSaga() {
  const actionChan = yield actionChannel('*', buffers.none())
  let currentSelectedCardId = yield select(sel.selectedCardId)

  while (true) {
    const action = yield take(actionChan)

    if (getIsNavigating()) {
      continue
    }

    let routingState
    let newRoute

    if (action.type === ActionTypes.SHOW_CARD) {
      const topLevelCardId = yield select(sel.planTopLevelCardId)
      newRoute = {type: ROUTE_PLAN, cardId: topLevelCardId}
    }

    if (action.type === ActionTypes.SELECT_PROJECT) {
      routingState = yield* $selectRoutingState()
      newRoute = {type: routingState.route.type}
      if (isRouteToCard(routingState.route.type)) {
        newRoute.cardId = action.cardId || action.projectId
      } else {
        newRoute.projectId = action.projectId
      }
    }

    const newSelectedCardId = yield select(sel.selectedCardId)

    if (newSelectedCardId !== currentSelectedCardId) {
      currentSelectedCardId = newSelectedCardId
      if (!newRoute) {
        routingState = yield* $selectRoutingState()
        if (doesRouteAllowCardSelection(routingState.route.type)) {
          newRoute = {type: routingState.route.type}
          if (isRouteToCard(newRoute.type)) {
            newRoute.cardId = routingState.route.cardId
          } else {
            newRoute.projectId = routingState.route.projectId
          }
        }
      }
    }

    if (newRoute) {
      newRoute.selectedCardId = newSelectedCardId
      routingState = routingState || (yield* $selectRoutingState())
      yield* $navigateToRouteIfNeeded(newRoute, routingState.uri)
    }
  }
}


function* $selectRoutingState() {
  const routingState = yield select(sel.routingState)
  return routingState.toJS()
}
