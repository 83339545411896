export function formatDollars(cents: number) {
  const sign = cents < 0 ? '-' : ''
  return sign + '$' + Math.abs(cents / 100).toFixed(2)
}

export function dollarsToCents(dollars: number) {
  return dollars * 100
}

export function formatDollarsDelta(cents: number) {
  const sign = cents < 0 ? '-' : '+'
  return sign + '$' + Math.abs(cents / 100).toString()
}

export const formatMoney = (
  amount: number,
  currency: string,
  withPlus?: boolean
) => {
  const currencySymbol = currency === 'USD' ? '$' : currency
  const sign = amount < 0 ? '-' : withPlus ? '+' : ''
  const amountString = Math.abs(amount / 100).toFixed(2)

  return `${sign}${currencySymbol}${amountString}`
}
