import FastClick from 'fastclick'
FastClick.attach(document.body)

import Clipboard from 'clipboard'
new Clipboard('.btn')

import 'styles/index.styl'

import { hot } from 'react-hot-loader/root'

import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'

import history from '~/utils/history'

import store from '~/store'
import sel from '~/selectors'

import * as Actions from '~/actions'
import { buildPathFromLocation } from '~/utils/url'
import { initSentry } from '~/sentry'

import {
  routeFromLocation,
  ROUTE_MY_TASKS,
  ROUTE_PLAN,
  ROUTE_TEAM_WEEK,
  ROUTE_MOBILE_CHAT,
  ROUTE_DASHBOARD,
  ROUTE_CONTRACTOR_DASHBOARD,
  ROUTE_AVAILABILITY,
  ROUTE_PROFILE,
  ROUTE_ACTIVITY,
  ROUTE_CHAT,
  ROUTE_LOGIN_LINK,
} from '~/utils/routing'

import App from '~/components/app'
import Page404 from '~/components/pages/page-404'
import AvailabilityContainer from '~/components/availability'
import Mobile from '~/components/mobile'

import CardListContainer from '~/components/card-list'
import TeamWeek from '~/components/team-week'
import DashboardContainer from '~/components/dashboard'
import ContractorDashboardContainer from '~/components/contractor-dashboard'
import ProfileContainer from '~/components/profile'
import ActivityContainer from '~/components/activity'
import { ChatPage } from '~/components/chat/page/chat-page'

import { ApolloProvider } from '@apollo/client'
import client from '~/api/apollo-client'
import { LoginLinkContainer } from './components/login-link'
import { MixpanelContextProvider } from './components/mixpanel'
import mixpanel from 'mixpanel-browser'
import config from '~/config'

mixpanel.init(config.MIXPANEL_ID)

initSentry()

const RootElement = hot(() => (
  <Provider store={store}>
    <MixpanelContextProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={App} />
          <Route
            path={`/${ROUTE_MY_TASKS}/:projectId`}
            render={() => (
              <App>
                <CardListContainer limitToThisWeek={true} />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_PLAN}/:cardId`}
            render={() => (
              <App>
                <CardListContainer limitToThisWeek={false} />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_TEAM_WEEK}/:projectId`}
            render={() => (
              <App>
                <TeamWeek />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_DASHBOARD}/:projectId`}
            render={() => (
              <App>
                <DashboardContainer />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_CONTRACTOR_DASHBOARD}/:projectId`}
            render={() => (
              <App>
                <ContractorDashboardContainer />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_PROFILE}/:projectId/:userId`}
            render={() => (
              <App>
                <ProfileContainer />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_ACTIVITY}/:projectId/`}
            render={() => (
              <App>
                <ActivityContainer />
              </App>
            )}
          />
          <Route
            path={`/${ROUTE_AVAILABILITY}`}
            component={AvailabilityContainer}
          />
          <Route path={`/${ROUTE_CHAT}`} component={ChatPage} />
          <Route path={`/${ROUTE_MOBILE_CHAT}/:cardId`} component={Mobile} />
          <Route path={`/${ROUTE_LOGIN_LINK}`} component={LoginLinkContainer} />
          <Route component={Page404} />
        </Switch>
      </ConnectedRouter>
    </MixpanelContextProvider>
  </Provider>
))

function onLocationChange(location) {
  const uri = buildPathFromLocation(location)
  const previousURI = sel.routingState(store.getState()).get('uri')
  // eslint-disable-next-line eqeqeq
  if (uri != previousURI) {
    const storedLocation = {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    }
    const route = routeFromLocation(location)
    store.dispatch(Actions.routeChanged(route, storedLocation, uri))
  }
}

history.listen(onLocationChange)
onLocationChange(history.location)

ReactDOM.render(
  <ApolloProvider client={client}>
    <RootElement />
  </ApolloProvider>,
  document.getElementById('app')
)
