import React from 'react'
import cn from 'classnames'

type PlaceholderProps = {
  header?: JSX.Element
  theme?: string
}

export const FakeMessage = ({ theme }: { theme?: string }) => (
  <div className={cn('dummy-message', { 'theme-dark': theme === 'dark' })}>
    <div className="avatar" />
    <div className="content">
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
  </div>
)

export const ChatPlaceholder = (props: PlaceholderProps) => (
  <div
    className={cn('chat-container', { 'theme-dark': props.theme === 'dark' })}
  >
    {props.header}
    <div className="messages fake">
      {[3, 2, 1, 0].map(i => (
        <FakeMessage key={i} theme={props.theme} />
      ))}
    </div>
    <div className={cn('fake-input', { 'theme-dark': props.theme === 'dark' })}>
      <span>Write something...</span>
      <span className="fake-input__send-button" />
    </div>
  </div>
)
