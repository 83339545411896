import React from 'react'

import CardListItemContainer from '../card-list/card-list-item-container'


export default class CardListDraggingItem extends React.Component {

  constructor() {
    super()
    this.node = null
    this.onNode = this.onNode.bind(this)
    this.state = { itemProps: null }
    this.itemX = null
    this.itemY = null
  }

  onNode(node) {
    this.node = node
    if (!node) return
    this.containerBounds = node.parentNode.getBoundingClientRect()
    this.updateItemPosition(this.itemX, this.itemY)
  }

  startDragging(itemProps, itemX, itemY, width) {
    this.containerBounds = null
    this.setState({ itemProps, width })
    this.updateItemPosition(itemX, itemY)
  }

  updateItemPosition(itemX, itemY) {
    this.itemX = itemX
    this.itemY = itemY

    const {node, containerBounds} = this

    if (!containerBounds) {
      return
    }

    node.style.left = (itemX - containerBounds.left) + 'px'
    node.style.top = (itemY - containerBounds.top) + 'px'
  }

  stopDragging() {
    this.setState({ itemProps: null })
    this.containerBounds = null
    this.node = null
  }

  render() {
    const {itemProps, width} = this.state
    if (!itemProps) {
      return null
    }
    return (
      <div className='dragging-preview' style={{width}} ref={this.onNode}>
        <div className='content'>
          <CardListItemContainer {...itemProps} isDraggingPreview={true} />
        </div>
      </div>
    )
  }

}
