import { formatDuration } from './time'

const num = '(\\d+\\.\\d+|\\.\\d+|\\d+)'
const min = '(?:minutes|minute|mins|min|m)'
const hr = '(?:hours|hour|hrs|hr|h)'
const sign = '(-?)'

const patterns = [
  new RegExp(`^${sign}\\s*${num}?:(\\d+)$`),
  new RegExp(`^${sign}\\s*${num}\\s*${hr}\\s*${num}\\s*${min}$`),
  new RegExp(`^${sign}\\s*()${num}\\s*${min}$`), // dirty hack with () (blank capture group) to make minutes placed in match[2]
  new RegExp(`^${sign}\\s*${num}\\s*${hr}$`),
] // generating regexps once at start

function extractHoursAndMins(duration) {
  for (let pattern of patterns) {
    const match = pattern.exec(duration)

    if (match) {
      return { sign: match[1], h: match[2], m: match[3] }
    }
  }
  return false
}

export function isValidDurationString(duration) {
  return !!extractHoursAndMins(duration)
}

export function convertDurationFromStringToSeconds(duration) {
  const { sign, h, m } = extractHoursAndMins(duration)
  const value = (isNaN(h) ? 0 : h) * 3600 + (isNaN(m) ? 0 : m) * 60
  const signedValue = sign ? 0 - value : value
  return signedValue
}

export function formatInterval(min, max, preserveMiddleLetter) {
  if (!min) {
    return ''
  }
  if (min === max) {
    return formatDuration(min, true)
  }
  const minStr = formatDuration(min, true)
  const maxStr = formatDuration(max, true)
  if (
    !preserveMiddleLetter &&
    minStr[minStr.length - 1] === maxStr[maxStr.length - 1]
  ) {
    return `${minStr.substring(0, minStr.length - 1)}–${maxStr}`
  }
  return `${minStr}-${maxStr}`
}
