import React from 'react'
import cn from 'classnames'

export default class FilterInput extends React.Component {

  constructor(props) {
    super(props)
    this.clear = this.clear.bind(this)
  }

  componentDidMount(){
    this.filterInput.focus()
    if (this.props.value) {
      const lastTextIndex = this.props.value.length
      this.filterInput.setSelectionRange(lastTextIndex, lastTextIndex)
    }
  }

  clear(evt) {
    this.filterInput.value = ''
    this.props.onClear && this.props.onClear()

    // Prevent closing popup and opening link, since card is a link
    evt.stopPropagation()
    evt.nativeEvent.stopImmediatePropagation()
    evt.preventDefault()
  }

  render () {
    const {className, onChange, value} = this.props
    return <div className={cn('filter-input', className)} >
      <div className='clear' onClick={this.clear}></div>
      <input onChange={onChange} value={value} ref={filterInput => this.filterInput = filterInput}/>
    </div>
  }
}
