import * as T from '~/types/api'
import { nanoid } from 'nanoid'
import reactStringReplace from 'react-string-replace'

export const generateOptimisticId = () => `temp_${nanoid()}`

export const isMessageOptimistic = (messageId: string | null | undefined) => {
  return !messageId ? false : messageId.toLowerCase().includes('temp')
}

export const getBodyTokensFromText = (
  text: string,
  members: (T.GetChatData_feed_members | null)[] | null | undefined
) =>
  reactStringReplace(text, /(<user:[0-9]*>)/g, match => {
    const userId = match.substring(match.indexOf(':') + 1, match.indexOf('>'))
    const mentionedUser = members?.find(m => m?.id === userId)
    if (!mentionedUser) {
      return { __typename: 'StringObject', string: match }
    }
    return mentionedUser
  }).map(token => {
    if (typeof token === 'string') {
      return { __typename: 'StringObject', string: token }
    }
    return token
  })

export const throwBadType = (o: never) => {
  throw new Error(`Unknown object: ${o}`)
}
