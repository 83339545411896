import React from 'react'
import Scrollable from '../scrollable'
import cn from 'classnames'

type Props = {
  header: JSX.Element | null
  content: JSX.Element | null
  footer: JSX.Element | null
  className?: string
}

export default ({ header, content, footer, className }: Props) => {
  return (
    <div className={cn('table-container', className)}>
      <div className="table-header">{header}</div>
      <Scrollable>{content}</Scrollable>
      <div className="table-footer">{footer}</div>
    </div>
  )
}
