import {Map} from 'immutable'

import * as ActionTypes from '~/actions/types'
import {doesCardHaveIdInPath} from '~/utils/cards'


export const INITIAL_STATE = planTopLevelCardIdReducer.INITIAL_STATE = Map({
  current: null,
  previous: null,
})


export default function planTopLevelCardIdReducer(state = INITIAL_STATE, action, {selectCards, selectProjectId}) {
  const newState = _planTopLevelCardIdReducer(state, action, selectCards, selectProjectId)
  if (action.type == ActionTypes.SELECT_PROJECT) {
    return newState.set('previous', null)
  }
  if (newState != state) {
    const previousPlanId = state.get('current')
    if (newState.get('current') != previousPlanId) {
      return newState.set('previous', previousPlanId)
    }
  }
  return newState
}


function _planTopLevelCardIdReducer(state, action, selectCards, selectProjectId) {
  // FIXME: handle card entities changes caused by remote actions
  switch (action.type) {
    case ActionTypes.SELECT_PROJECT: {
      return state.set('current', action.projectId)
    }
    case ActionTypes.SHOW_CARD: {
      return state.set('current', action.cardId)
    }
    case ActionTypes.DELETE_CARD: {
      return state.withMutations(state => {
        // We need to check if any of the top-level cards, or any of their
        // parents, are deleted as a result of this action.
        const topLevelCard = selectCards().get(state.get('current'))
        if (doesCardHaveIdInPath(action.cardId, topLevelCard)) {
          state.set('current', selectProjectId())
        }
      })
    }
  }
  return state
}
