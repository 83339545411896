import { all, take, call, fork, select } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import CardRelation from '~/utils/card-relation'
import { formatInterval } from '../utils/duration'
import selectors from '~/selectors'

import $cardsEditingSaga from './cards-editing'
import * as api from '~/api/cards'

export default function* $cardsSaga() {
  yield all([
    fork($completionSaga),
    fork($cardsEditingSaga, $addTaskForCardWithId, changeQueueId),
    fork($deletionSaga),
    fork($movingSaga),
    fork($assignSaga),
    fork($estimateSaga),
    fork($budgetSaga),
    fork($dueDateSaga),
  ])
}

export function* $assignSaga() {
  while (true) {
    const action = yield take([
      ActionTypes.UNASSIGN_CARD,
      ActionTypes.ASSIGN_CARD,
    ])
    yield* $addTaskForCardWithId(
      action.cardId,
      action,
      $changeAssignmentStateOfCardWithId
    )
  }
}

export function* $estimateSaga() {
  while (true) {
    const action = yield take([ActionTypes.UPDATE_ESTIMATE])
    yield* $addTaskForCardWithId(
      action.cardId,
      action,
      $changeEstimateOfCardWithId
    )
  }
}

export function* $budgetSaga() {
  while (true) {
    const action = yield take([
      ActionTypes.INCREASE_BUDGET,
      ActionTypes.DECREASE_BUDGET,
    ])
    yield* $addTaskForCardWithId(
      action.cardId,
      action,
      $changeBudgetOfCardWithId
    )
  }
}

export function* $completionSaga() {
  while (true) {
    const action = yield take(ActionTypes.MARK_CARD_WITH_ID_COMPLETED)
    yield* $addTaskForCardWithId(
      action.cardId,
      action,
      $changeCompletionStateOfCardWithId
    )
  }
}

export function* $deletionSaga() {
  const actionTypes = [ActionTypes.DELETE_CARD, ActionTypes.UNDELETE_CARD]
  while (true) {
    const action = yield take(actionTypes)
    const $fn =
      action.type == ActionTypes.DELETE_CARD ? $deleteCard : $undeleteCard
    yield* $addTaskForCardWithId(action.cardId, action, $fn)
  }
}

export function* $movingSaga() {
  while (true) {
    const action = yield take(ActionTypes.MOVE_CARD)
    const card = yield select(selectors.cardWithId, action.cardId)
    if (card.getIn(['optimistic', 'isEditing'])) {
      // The card is being moved by pressing Tab/Shift+Tab, and it makes no sense to
      // send API requests until we finish editing it. The final request will be sent
      // by the saga in cards-editing.js.
      continue
    }
    const movingOpId = yield select(selectors.cardMovingOpId)
    if (movingOpId == null || movingOpId == action.opId) {
      yield* $addTaskForCardWithId(action.cardId, action, $moveCard)
    }
  }
}

export function* $dueDateSaga() {
  while (true) {
    const action = yield take([
      ActionTypes.SET_CARD_DUE_DATE,
      ActionTypes.UNSET_CARD_DUE_DATE,
    ])
    yield* $addTaskForCardWithId(action.cardId, action, $changeCardDueDate)
  }
}

function* $changeAssignmentStateOfCardWithId({ type, cardId, assigneeId }) {
  const apiFn =
    type == ActionTypes.ASSIGN_CARD ? api.assignCard : api.unassignCard
  const response = yield call(apiFn, cardId, assigneeId)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $changeEstimateOfCardWithId({ cardId, estimate }) {
  const apiFn = estimate.min ? api.updateCardEstimate : api.unsetCardEstimate
  const formattedEstimate = formatInterval(estimate.min, estimate.max, true)
  const response = yield call(apiFn, cardId, formattedEstimate)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $changeBudgetOfCardWithId({ cardId, budget, decrease }) {
  let apiFn
  if (!budget) {
    apiFn = api.unsetCardBudget
  } else if (decrease) {
    apiFn = api.decreaseCardBudget
  } else {
    apiFn = api.increaseCardBudget
  }
  const response = yield call(apiFn, cardId, budget, decrease)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $changeCompletionStateOfCardWithId(action) {
  const apiFn = action.isCompleted ? api.completeCard : api.uncompleteCard
  const response = yield call(apiFn, action.cardId)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $deleteCard({ cardId }) {
  const response = yield call(api.deleteCard, cardId)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $undeleteCard({ cardId }) {
  const response = yield call(api.undeleteCard, cardId)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function* $moveCard({ cardId, targetId, relation }) {
  if (relation == CardRelation.NOP) {
    return
  }

  const card = yield select(selectors.cardWithId, cardId)
  const newParentId = selectors.card.parentId(card)
  const newParent = yield select(selectors.cardWithId, newParentId)

  if (newParent.get('isEphemeral')) {
    // disallow moving cards under ephemeral parents as we don't have their real id yet
    return
  }

  yield* $dispatch(Actions.cardMoved(false))

  const response = yield call(api.moveCard, cardId, targetId, relation)

  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

//
// Card-specific operation queue implementation.
//

const tasksByCardId = {}

function* $addTaskForCardWithId(id, action, fn) {
  const task = { fn, action }
  const tasks = getQueueWithId(id)
  if (tasks) {
    tasks.push(task)
  } else {
    tasksByCardId[id] = [task]
    yield fork($runTasksForCardWithId, id)
  }
}

function* $runTasksForCardWithId(id) {
  const tasks = tasksByCardId[id]
  try {
    while (tasks.length) {
      const { fn, action } = tasks.pop()
      yield call(fn, action)
    }
    const currentId = getQueueId(id)
    yield* $dispatch(Actions.clearCardOptimisticState(currentId, id))
  } catch (e) {
    setTimeout(() => {
      throw e
    }, 0)
    const projectId = yield select(selectors.selectedProjectId)
    yield* $dispatch(Actions.selectProject(projectId))
  } finally {
    removeQueueWithId(id)
  }
}

function* $changeCardDueDate({ type, cardId, dueDate }) {
  const apiFn =
    type == ActionTypes.SET_CARD_DUE_DATE
      ? api.setCardDueDate
      : api.unsetCardDueDate
  const response = yield call(apiFn, cardId, dueDate)
  yield* $dispatch(Actions.updateCard(response.result, response.entities))
}

function getQueueId(initialId) {
  let tasks = tasksByCardId[initialId]
  let id = initialId
  while (typeof tasks === 'string') {
    id = tasks
    tasks = tasksByCardId[tasks]
  }
  return id
}

function getQueueWithId(id) {
  let tasks = tasksByCardId[id]
  while (typeof tasks === 'string') {
    tasks = tasksByCardId[tasks]
  }
  return tasks
}

function changeQueueId(prevId, newId) {
  if (prevId == newId) return
  const currentId = getQueueId(prevId)
  const tasks = tasksByCardId[currentId]
  if (tasks) {
    tasksByCardId[currentId] = newId
    tasksByCardId[newId] = tasks
  }
}

function removeQueueWithId(initialId) {
  let tasks = tasksByCardId[initialId]
  delete tasksByCardId[initialId]
  while (typeof tasks === 'string') {
    const chainedTasks = tasksByCardId[tasks]
    delete tasksByCardId[tasks]
    tasks = chainedTasks
  }
}
