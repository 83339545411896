import dayjs, { ConfigType, OptionType, Dayjs, OpUnitType } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

const _dayjs = (
  date?: ConfigType | null,
  format?: OptionType,
  strict?: boolean
) => {
  if (typeof date === 'string') {
    const mutatedDate = date.replace(' ', 'T')
    return dayjs(mutatedDate, format, strict)
  }
  if (date === null) {
    return dayjs(undefined, format, strict)
  }
  return dayjs(date, format, strict)
}

export { Dayjs, OpUnitType }
export default _dayjs
