import {arraysShallowEqual} from '~/utils/shallow-compare'


export function nop() {
  // nop
}


export function always() {
  return true
}


export function never() {
  return false
}


export function id(x) {
  return x
}


export function isNotNull(x) {
  return x != null
}


export function makePartialApplier(fn) {
  let prevArgs = null
  let prevBound = null
  return (...prependArgs) => {
    if (!arraysShallowEqual(prependArgs, prevArgs)) {
      prevArgs = prependArgs
      prevBound = (...args) => fn(...prependArgs, ...args)
    }
    return prevBound
  }
}


export function bind0(fn, ctx) {
  return () => fn.call(ctx)
}


export function bind1(fn, ctx) {
  return (arg) => fn.call(ctx, arg)
}


export function bind2(fn, ctx) {
  return (arg1, arg2) => fn.call(ctx, arg1, arg2)
}


export function logicalAnd(preds) {
  switch (preds.length) {
    case 0: return always
    case 1: return preds[0]
    case 2: return x => preds[0](x) && preds[1](x)
    case 3: return x => preds[0](x) && preds[1](x) && preds[2](x)
    case 4: return x => preds[0](x) && preds[1](x) && preds[2](x) && preds[3](x)
    case 5: return x => preds[0](x) && preds[1](x) && preds[2](x) && preds[3](x) && preds[4](x)
    default: return _logicalAndGeneric(preds)
  }
}


function _logicalAndGeneric(preds) {
  return x => {
    let matching = true
    for (let i = 0; matching && i < preds.length; ++i) {
      if (!preds[i](x)) {
        matching = false
      }
    }
    return matching
  }
}
