import React from 'react'
import { Query } from '@apollo/client/react/components'
import { Waypoint } from 'react-waypoint'

import connect from '../utils/connect'
import selectors from '~/selectors'
import { setIn } from '~/utils/mutate'

import Layout from '~/components/layout/layout-with-single-frame'
import Loader from '~/components/loader'
import Scrollable from '~/components/scrollable'
import TaskListsLinks from '~/components/task-lists-links'

import projectFeedEvents from './project-events-query'
import { getStringFromAction } from './utils'
import dayjs from '~/utils/dayjs'

class ActivityContainer extends React.Component {
  static mapStateToProps(state) {
    const projectId = selectors.selectedProjectId(state)
    const users = selectors.users(state)
    const cards = selectors.cards(state)
    return {
      projectId: projectId,
      users: users,
      cards: cards,
    }
  }

  revealCard = (e, cardId) => {
    e.stopPropagation()
    if (e.metaKey || e.ctrlKey) {
      return
    }
    e.preventDefault()
    this.props.actions.revealCard(cardId, this.props.projectId)
  }

  renderEvent(event) {
    const cuttedFeedId = event.feedId.replace(/[\w]+-/g, '')
    const card = this.props.cards.get(cuttedFeedId)
    let cardName
    if (card !== undefined) {
      cardName = card.get('name')
    }

    return (
      <div key={event.id} className="event">
        <div>
          {event.sender ? (
            <span className="user-name">
              {this.props.users.get(event.sender.id).get('name')}
            </span>
          ) : null}
          {getStringFromAction(event.bodyTokens.__typename)}
          <a
            className="event-card"
            href={`/plan/${cuttedFeedId}`}
            onClick={e => this.revealCard(e, cuttedFeedId)}
          >
            {cardName ? ' ' + cardName : ''}
          </a>
          <span className="event-time">
            {' • ' + dayjs(event.createdAt).format('MMM, D h:mm a')}
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { projectId } = this.props

    return (
      <Layout narrow={true}>
        <div className="activity-container">
          <div className="activity-body">
            <div className="activity-header">
              <TaskListsLinks projectId={projectId} />
              <div className="title">Activity</div>
            </div>
            <Query
              query={projectFeedEvents}
              variables={{
                projectId: projectId,
                limit: 100,
                before: 'idOfMinLoadedFeedEvent',
              }}
              errorPolicy="all"
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data, fetchMore, networkStatus }) => {
                if (loading && networkStatus !== 3) {
                  return <Loader colorScheme={'dark'} />
                }
                if (error) return <div>Error</div>

                return (
                  <Scrollable className="activity-feed-items">
                    {data.projectFeedEvents.map(event =>
                      this.renderEvent(event)
                    )}
                    <div className="activity-feed-items-waypoint">
                      <Waypoint
                        onEnter={() => {
                          const lastActivityId =
                            data.projectFeedEvents[
                              data.projectFeedEvents.length - 1
                            ].id
                          fetchMore({
                            variables: {
                              before: lastActivityId,
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                              if (!fetchMoreResult) return prev
                              const feeds = [
                                ...prev.projectFeedEvents,
                                ...fetchMoreResult.projectFeedEvents,
                              ]
                              return setIn(prev, ['projectFeedEvents'], feeds)
                            },
                          })
                        }}
                      />
                    </div>
                  </Scrollable>
                )
              }}
            </Query>
          </div>
        </div>
      </Layout>
    )
  }
}

export default connect(ActivityContainer)
