import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GetChatData, ChatMessageSubscription } from './api/queries'
import * as Types from '~/types/api'
import * as Updates from './util/update'
import * as ChatMutations from './api/mutations'
import { Chat } from './chat'
import { DefaultChatHeader } from './default-header'
import { generateOptimisticId } from './util/helpers'
import { CHAT_LOADING_LIMIT } from './util/constants'
import { ChatPlaceholder } from './chat-placeholder'

export type ChatContainerProps = {
  chatId: string
  header?: JSX.Element
  theme?: string
}

type ChatContainerAdditionalProps = {
  onMentionClick: (currentUserId: string) => (userId: string) => void
  onCardClick: (
    projectId: string | null | undefined
  ) => (cardId: string) => void
  onChatClose: (chatId: string) => () => void
  allowAttachments: boolean
  withServiceMessages: boolean
}

export const ChatContainer = ({
  chatId,
  header,
  theme,
  ...props
}: ChatContainerProps & ChatContainerAdditionalProps) => {
  if (!chatId) {
    return null
  }
  const {
    data,
    loading,
    error,
    updateQuery,
    subscribeToMore,
    fetchMore,
  } = useQuery<Types.GetChatData, Types.GetChatDataVariables>(GetChatData, {
    variables: { chatId, limit: CHAT_LOADING_LIMIT },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const projectId = useMemo(() => {
    if (data?.feed?.source?.__typename === 'Card') {
      return data.feed.source.workspace?.id
    }
    return null
  }, [data?.feed?.source])

  const cardId = useMemo(() => {
    if (data?.feed?.source?.__typename === 'Card') {
      return data.feed.source.id
    }
    return null
  }, [data?.feed?.source])

  const headerElement = useMemo(() => {
    if (header) {
      return header
    }
    let headerTitle = 'Chat'
    switch (data?.feed?.source?.__typename) {
      case 'Card':
      case 'User':
        if (data.feed.source.name) {
          headerTitle = data.feed.source.name
        }
        break
      case 'Feed':
        if (data.feed.source.feedName) {
          headerTitle = data.feed.source.feedName
        }
        break
      case 'GroupChat':
        if (data.feed.source.topic) {
          headerTitle = data.feed.source.topic
        }
        break
    }

    return (
      <DefaultChatHeader
        title={headerTitle}
        onClose={props.onChatClose(chatId)}
      />
    )
  }, [data?.feed?.source, header])

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        chatId,
        offset: data?.feed?.feedEvents?.length,
        limit: CHAT_LOADING_LIMIT,
      },
      updateQuery: Updates.updateChatOnLoadMore,
    })
  }, [data?.feed?.feedEvents?.length, chatId])

  const openSubscription = useCallback(
    () =>
      subscribeToMore<Types.ChatMessageSubscription>({
        document: ChatMessageSubscription,
        updateQuery: Updates.updateChatOnSubcriptionUpdate(chatId),
      }),
    [chatId]
  )

  const postMessage = useCallback(
    (messageBody: string) => {
      const optimisticId = generateOptimisticId()
      updateQuery(
        Updates.postMessageOptimistically(chatId, messageBody, optimisticId)
      )
      ChatMutations.feedPostMessage(chatId, messageBody, optimisticId)
    },
    [chatId]
  )

  const editMessage = useCallback(
    (feedEventId: string, messageBody: string) => {
      updateQuery(Updates.editMessageOptimistically(feedEventId, messageBody))
      ChatMutations.feedEditMessage(feedEventId, messageBody)
    },
    []
  )

  const handleFileUpload = useCallback(
    (file: File) => {
      const optimisticId = generateOptimisticId()
      updateQuery(Updates.uploadFileOptimistically(chatId, file, optimisticId))
      ChatMutations.uploadFile(file, chatId)
    },
    [chatId]
  )

  const handleCreateTimeEntry = useCallback(
    (date: string, duration: string, memo: string) => {
      if (cardId) {
        // const optimisticId = generateOptimisticId()
        // updateQuery(
        //   Updates.createTimeEntryOptimistically(
        //     chatId,
        //     date,
        //     duration,
        //     memo,
        //     optimisticId
        //   )
        // )
        console.log(cardId, date, duration, memo)
      }
    },
    [data?.feed?.source, data?.currentUser, cardId, chatId]
  )

  const handleEditTimeEntry = useCallback(
    (
      messageId: string,
      timeEntry: Types.ChatEventFragment_bodyTokens_TimeEntry
    ) => {
      updateQuery(Updates.editTimeEntryOptimistically(messageId, timeEntry))
    },
    []
  )

  const addReaction = useCallback(
    (feedEventId: string, emoji: string) => {
      updateQuery(
        Updates.addReactionOptimistically(
          feedEventId,
          emoji,
          data?.currentUser?.id!
        )
      )
      ChatMutations.feedAddReaction(feedEventId, emoji)
    },
    [data?.currentUser?.id]
  )

  const removeReaction = useCallback(
    (feedEventId: string, emoji: string) => {
      updateQuery(
        Updates.removeReactionOptimistically(
          feedEventId,
          emoji,
          data?.currentUser?.id!
        )
      )
      ChatMutations.feedRemoveReaction(feedEventId, emoji)
    },
    [data?.currentUser?.id]
  )

  if (error) {
    return <div>Error</div>
  }

  if (!data?.feed?.feedEvents || loading) {
    return <ChatPlaceholder header={headerElement} theme={theme} />
  }

  return (
    <Chat
      projectId={projectId ?? null}
      chatId={chatId}
      events={data.feed.feedEvents}
      members={data.feed.members}
      currentUserId={data?.currentUser?.id}
      header={headerElement}
      onPostMessage={postMessage}
      onEditMessage={editMessage}
      onDeleteMessage={ChatMutations.feedDeleteMessage}
      onAddReaction={addReaction}
      onRemoveReaction={removeReaction}
      onCardClick={props.onCardClick(projectId)}
      onMentionClick={props.onMentionClick(data?.currentUser?.id!)}
      onOpenSubscription={openSubscription}
      onTimeEntryCreate={handleCreateTimeEntry}
      onTimeEntryEdit={handleEditTimeEntry}
      onLoadMore={loadMore}
      uploadFile={handleFileUpload}
      allowAttachments={props.allowAttachments}
      withServiceMessages={props.withServiceMessages}
      allowTimeTracking={!!cardId}
      theme={theme}
    />
  )
}
