import { take, call, select } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'
import sel from '~/selectors'
import { SEND_PAYMENT_V2 } from '~/actions/types'
import { paymentSuccess, paymentFail } from '~/actions'
import { sendPaymentV2 } from '~/api/send-payment-v2'

export default function* $paymentsV2Saga() {
  while (true) {
    const {
      data: { amount, ...tokens },
    } = yield take(SEND_PAYMENT_V2)
    try {
      const projectId = yield select(sel.selectedProjectId)
      const { data, errors = [] } = yield call(sendPaymentV2, {
        projectId,
        amount,
        ...tokens,
      })

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      yield* $dispatch(paymentSuccess(amount, data.refillProjectBalance.id))
    } catch (e) {
      yield* $dispatch(paymentFail(e))
    }
  }
}
