/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Link } from 'react-router-dom'
import classnames from 'classnames'

import {
  buildPath,
  ROUTE_PLAN,
  ROUTE_MY_TASKS,
  ROUTE_TEAM_WEEK,
  ROUTE_DASHBOARD,
  ROUTE_CONTRACTOR_DASHBOARD,
  ROUTE_VIDEO,
  ROUTE_MEETINGS,
  ROUTE_RECORD
} from '~/utils/routing'

import config from '~/config'
import { generateDirectFeedId } from '~/utils/feeds'

import Scrollable from '../scrollable'

import MemberCard from './member-card'
import TurtleChatButton from './turtle-chat-button'

const LINKS = [
  { routeType: ROUTE_PLAN, text: 'Tasks' },
  {
    routeType: ROUTE_DASHBOARD,
    text: 'Budget',
    requiresPermission: 'view_dashboard_customer'
  },
  {
    routeType: ROUTE_CONTRACTOR_DASHBOARD,
    text: 'Tracked hours',
    requiresPermission: 'view_dashboard_contractor'
  },
  // { routeType: ROUTE_MEETINGS, text: 'Meetings' },
  { routeType: 'ADMIN', requiresPermission: 'admin_view' }
]

class Sidebar extends React.PureComponent {
  static propTypes = {
    myUserId: PropTypes.string.isRequired,
    permissions: ImmutablePropTypes.map.isRequired,
    routeType: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    planTopLevelCardId: PropTypes.string.isRequired,
    teamMembers: ImmutablePropTypes.list.isRequired,
    turtleUserId: PropTypes.string,
    unreadCounts: ImmutablePropTypes.mapOf(
      PropTypes.number, // values are numbers (unread counts)
      PropTypes.string // keys are strings (feed ids)
    ).isRequired,
    toggleInvitation: PropTypes.func.isRequired
  }

  renderAdminLink = projectId => {
    let url = `${config.ADMIN_URL}/admin/project/${projectId}/dashboard`
    return (
      <a key="ADMIN" href={url} className="sidebar-link" target="_blank">
        Admin
      </a>
    )
  }

  renderLink = link => {
    const { props } = this
    const { routeType } = link
    const isActive =
      routeType === ROUTE_PLAN
        ? [ROUTE_PLAN, ROUTE_MY_TASKS, ROUTE_TEAM_WEEK].indexOf(
            props.routeType
          ) !== -1
        : routeType === ROUTE_MEETINGS
        ? [ROUTE_MEETINGS, ROUTE_VIDEO, ROUTE_RECORD].indexOf(
            props.routeType
          ) !== -1
        : routeType === props.routeType

    let targetPath
    if (routeType == ROUTE_PLAN) {
      const cardId = isActive ? props.projectId : props.planTopLevelCardId
      targetPath = buildPath({ type: routeType, cardId: cardId })
    } else if (routeType == 'ADMIN') {
      return this.renderAdminLink(props.projectId)
    } else {
      targetPath = buildPath({ type: routeType, projectId: props.projectId })
    }

    const linkClasses = classnames('sidebar-link', {
      'is-active': isActive
    })

    return (
      <Link key={targetPath} to={targetPath} className={linkClasses}>
        {link.text}
      </Link>
    )
  }

  renderTeamMember = member => {
    const directFeedId = generateDirectFeedId(
      member.get('id'),
      this.props.myUserId
    )
    const showRemoveMember = this.props.permissions.get('project_member_manage')
    return (
      <div key={member.get('id')}>
        <MemberCard
          member={member}
          directFeedId={directFeedId}
          unreadCounts={this.props.unreadCounts}
          onNameClick={() => this.props.toggleDirectChat(directFeedId)}
          showRemoveMember={showRemoveMember ? showRemoveMember : false}
          removeMember={this.props.removeMember}
        />
      </div>
    )
  }

  render() {
    const {
      toggleInvitation,
      teamMembers,
      turtleUserId,
      toggleDirectChat,
      myUserId,
      projectId
    } = this.props

    const teamEls = teamMembers && teamMembers.map(this.renderTeamMember)

    const links = LINKS.filter(this.linkEnabled).map(this.renderLink)

    return (
      <div className="sidebar">
        <div className="sidebar-link-container">{links}</div>
        <div className="team-list-header">
          TEAM
          <span className="invite-button" onClick={toggleInvitation}>
            +
          </span>
        </div>
        <Scrollable className="team-list-container">{teamEls}</Scrollable>
        {turtleUserId && (
          <TurtleChatButton
            toggleTurtleChat={() =>
              toggleDirectChat(generateDirectFeedId(turtleUserId, myUserId))
            }
            unreadCount={this.props.unreadCounts.get(
              generateDirectFeedId(turtleUserId, myUserId)
            )}
          />
        )}
      </div>
    )
  }

  linkEnabled = link => {
    return (
      !link.requiresPermission ||
      this.props.permissions.get(link.requiresPermission)
    )
  }
}

export default Sidebar
