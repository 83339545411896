import React from 'react'
import { noop } from '~/utils/noop'

type TMixpanelContext = {
  trackEvent: (
    app: string
  ) => (eventName: string, payload?: Record<string, unknown>) => void
}

export const MixpanelContext = React.createContext<TMixpanelContext>({
  trackEvent: () => noop,
})
