export const possibleTypes = {
  CardMemberAdded: 'CardMemberAdded',
  CardMemberRemoved: 'CardMemberRemoved',
  CardCompleted: 'CardCompleted',
  CardUncompleted: 'CardUncompleted',
  CardEstimateSet: 'CardEstimateSet',
  CardEstimateUnset: 'CardEstimateUnset',
  FeedMessagePosted: 'FeedMessagePosted',
  FeedMessageEdited: 'FeedMessageEdited',
  FeedMessageDeleted: 'FeedMessageDeleted',
  TimeEntryCreated: 'TimeEntryCreated',
  TimeEntryEdited: 'TimeEntryEdited',
  TimeEntryDeleted: 'TimeEntryDeleted',
  CardBudgetSet: 'CardBudgetSet',
  CardBudgetUnset: 'CardBudgetUnset',
  CardBudgetIncreased: 'CardBudgetIncreased',
  CardBudgetDecreased: 'CardBudgetDecreased',
  CardAssigned: 'CardAssigned',
  CardUnassigned: 'CardUnassigned',
  CardDueDateSet: 'CardDueDateSet',
  CardDueDateUnset: 'CardDueDateUnset',
  CardRenamed: 'CardRenamed',
  UserReadFeedEvent: 'UserReadFeedEvent',
  UserUnreadFeedEvent: 'UserUnreadFeedEvent',
  StripeChargeCreated: 'StripeChargeCreated',
  CardCreated: 'CardCreated',
  CardMoved: 'CardMoved',
  ChargeCreated: 'ChargeCreated',
}

export function getStringFromAction(action) {
  switch (action) {
    case possibleTypes.CardMemberAdded: {
      return ' added a member'
    }
    case possibleTypes.CardMemberRemoved: {
      return ' removed a member'
    }
    case possibleTypes.CardCompleted: {
      return ' completed a card'
    }
    case possibleTypes.CardUncompleted: {
      return ' uncompleted a card'
    }
    case possibleTypes.CardEstimateSet: {
      return ' set an estimate'
    }
    case possibleTypes.CardEstimateUnset: {
      return ' set an estimate'
    }
    case possibleTypes.FeedMessagePosted: {
      return ' posted a message'
    }
    case possibleTypes.FeedMessageEdited: {
      return ' edited a message'
    }
    case possibleTypes.FeedMessageDeleted: {
      return ' deleted a message'
    }
    case possibleTypes.CardBudgetSet: {
      return ' set a budget'
    }
    case possibleTypes.CardBudgetUnset: {
      return ' unset a budget'
    }
    case possibleTypes.CardBudgetIncreased: {
      return ' increased a budget'
    }
    case possibleTypes.CardBudgetDecreased: {
      return ' decreased a budget'
    }
    case possibleTypes.CardAssigned: {
      return ' assigned a card'
    }
    case possibleTypes.CardUnassigned: {
      return ' unassigned a card'
    }
    case possibleTypes.CardDueDateSet: {
      return ' set a due date'
    }
    case possibleTypes.CardDueDateUnset: {
      return ' unset a due date'
    }
    case possibleTypes.CardRenamed: {
      return ' renamed a card'
    }
    case possibleTypes.CardCreated: {
      return ' created a card'
    }
    case possibleTypes.CardMoved: {
      return ' moved a card'
    }
    case possibleTypes.UserReadFeedEvent: {
      return ' read a feed event'
    }
    case possibleTypes.UserUnreadFeedEvent: {
      return ' unread a feed event'
    }
    case possibleTypes.StripeChargeCreated: {
      return ' created a Stripe charge'
    }
    case possibleTypes.TimeEntryCreated: {
      return ' tracked time'
    }
    case possibleTypes.ChargeCreated: {
      return ' created a Stripe charge'
    }

    default: {
      return action
    }
  }
}
