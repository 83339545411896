/* eslint-disable eqeqeq */
import React from 'react'
import PopupWrapper from '~/components/popup-wrapper'

type Props = {
  allowTimeTracking?: boolean
  onHide: () => void
  onAttachFile: (f: File) => void
  onTimeEntryClick: () => void
  projectId: string | null
}

export const Attachments = (props: Props) => {
  return (
    <PopupWrapper onDismiss={props.onHide}>
      <div className="chat-popup attachments">
        <input
          id="upload-file"
          type="file"
          className="attachments--input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              props.onAttachFile(e.target.files[0])
              props.onHide()
            }
          }}
        />
        <label
          key="upload-file"
          htmlFor="upload-file"
          className="attachments--item attachments--item-file"
        >
          Attach file
        </label>
      </div>
    </PopupWrapper>
  )
}
