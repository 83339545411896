export function encodeState(stateObj = null) {
  // FIXME: this won't work with UTF8 strings
  return btoa(JSON.stringify(stateObj))
}


export function decodeState(state) {
  try {
    // FIXME: this won't work with UTF8 strings
    return state ? JSON.parse(atob(state)) : {}
  }
  catch (err) {
    console.error(`[encode-state] Failed to decode state: ${ err && err.message || err }`)
    return {}
  }
}
