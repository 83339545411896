import React from 'react'
import UnreadCountBadge from '../unread-count-badge'

export default class TurtleChatButton extends React.Component {
  render() {
    return <div className='turtle-chat-button-panel'>
      <div className='turtle-chat-button' onClick={this.props.toggleTurtleChat}>
        {!!this.props.unreadCount && <UnreadCountBadge count={this.props.unreadCount} />}
      </div>
      <div className='upper-shadow'/>
    </div>
  }
}