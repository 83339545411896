import CardRelation from '~/utils/card-relation'

const BOUNDARY_ITEM = { level: 1, bounds: { top: -1, bottom: -1 } }
const NULL_ITEM = { id: null }

const MIN_MOUSE_LEVEL = -2

export default class CardMoveManager {
  constructor(paddingLeft, levelIndent, updateDraggingState) {
    this.paddingLeft = paddingLeft
    this.levelIndent = levelIndent
    this.updateDraggingState = updateDraggingState
    this.itemsById = {}
    this.items = []
    this.containerBounds = null
    this.containerBoundsDx = 0
    this.containerBoundsDy = 0
    this.mouseX = null
    this.mouseY = null
    this.stopDragging()
  }

  stopDragging() {
    this.items.length = 0
    this.lastTopLevelItem = null
    this.draggingItem = null
    this.targetRelation = null
    this.targetItem = null
    this.nearestLowerItem = {
      index: -1,
      fromY: -Number.MAX_VALUE,
      toY: -Number.MAX_VALUE,
    }
  }

  registerItem(id, instanceId, level, getBounds) {
    if (getBounds) {
      this.itemsById[id] = { id, instanceId, level, getBounds, bounds: null }
    } else {
      this.deregisterItem(id, instanceId)
    }
  }

  deregisterItem(id, instanceId) {
    // When deregistering an item, we check that we're trying to deregister the same
    // React component instance that was registered. This is a workaround for the
    // following case:
    //
    //   1. We're moving an item to a different position.
    //
    //   2. When actual move happens, React decides to render the item at the new
    //      position first, and only then unmounts the previous component.
    //
    //   3. The new item component renders and registers using its card id.
    //
    //   4. The old item component unmounts and deregisters using the same card id,
    //      since it represents the same card, just in different position.
    //
    // This would lead to the moved item disappearing from itemsById, so we need to
    // skip deregistering a component that is already deregistered.
    //
    const item = this.itemsById[id]
    if (item && item.instanceId === instanceId) {
      delete this.itemsById[id]
    }
  }

  buildSortedItemsList() {
    const { itemsById, items } = this

    items.length = 0

    for (let id in itemsById) {
      const item = itemsById[id]
      item.bounds = item.getBounds()
      items.push(item)
    }

    items.sort(byBoundsTop)
    items.push(BOUNDARY_ITEM)

    let prevItem = BOUNDARY_ITEM
    let middleY = -Number.MAX_VALUE
    let idsByLevel = []
    let lastTopLevelItem

    for (let i = 0; i < items.length; ++i) {
      const item = items[i]
      const { level } = item
      const prevLevel = prevItem.level
      if (prevLevel === 1) {
        lastTopLevelItem = prevItem
      }
      if (level !== prevLevel) {
        idsByLevel = idsByLevel.slice()
        idsByLevel.length = level - 1
        if (level > prevLevel) {
          idsByLevel[level - 2] = prevItem.id
        }
      }
      item.idsByLevel = idsByLevel
      item.fromY = middleY
      item.toY = middleY = (item.bounds.top + item.bounds.bottom) / 2
      item.index = i
      prevItem = item
    }

    items.pop()
    this.lastTopLevelItem = lastTopLevelItem
  }

  startDragging(id, containerBounds, mouseX, mouseY) {
    this.draggingItem = this.itemsById[id]
    this.containerBounds = containerBounds
    this.containerBoundsDx = 0
    this.containerBoundsDy = 0
    this.buildSortedItemsList()
    this.updateMousePosition(mouseX, mouseY)
  }

  updateContainerBounds(containerBounds) {
    if (this.draggingItem) {
      this.containerBoundsDx = containerBounds.left - this.containerBounds.left
      this.containerBoundsDy = containerBounds.top - this.containerBounds.top
      this.updateMousePosition(this.mouseX, this.mouseY)
    }
  }

  updateMousePosition(mouseX, mouseY) {
    this.mouseX = mouseX
    this.mouseY = mouseY

    mouseX -= this.containerBoundsDx
    mouseY -= this.containerBoundsDy

    const { containerBounds, items, draggingItem } = this

    const mouseLevel =
      1 +
      Math.round(
        (mouseX - containerBounds.left - this.paddingLeft) / this.levelIndent
      )

    if (mouseLevel < MIN_MOUSE_LEVEL) {
      return this.update(CardRelation.NOP, NULL_ITEM, 0, 0)
    }

    const lowerItem = this.getLowerItem(mouseY)

    // TODO: refactor. The following three cases should be generalizable
    // enough to produce much less duplicated code.

    if (!lowerItem) {
      const lastItem = items[items.length - 1]
      const draggingMarkY = lastItem.bounds.bottom - containerBounds.top
      if (mouseLevel > lastItem.level) {
        return this.update(
          CardRelation.CHILD,
          lastItem,
          lastItem.level + 1,
          draggingMarkY
        )
      }
      if (lastItem.level > 1) {
        if (mouseLevel < lastItem.level) {
          const level = mouseLevel > 1 ? mouseLevel : 1
          const parentId = lastItem.idsByLevel[level - 1]
          const parentItem = this.itemsById[parentId]
          return this.update(
            CardRelation.AFTER,
            parentItem,
            level,
            draggingMarkY
          )
        } else {
          return this.update(
            CardRelation.AFTER,
            lastItem,
            lastItem.level,
            draggingMarkY
          )
        }
      }
      return this.update(
        CardRelation.AFTER,
        this.lastTopLevelItem,
        1,
        draggingMarkY
      )
    }

    const draggingMarkY = lowerItem.bounds.top - containerBounds.top

    if (lowerItem === draggingItem) {
      const lowerItem = this.getNextItemWithLevelAtMost(
        draggingItem.level,
        draggingItem.index + 1
      )
      const lowerLevel = lowerItem ? lowerItem.level : 1
      if (lowerLevel < draggingItem.level && mouseLevel < draggingItem.level) {
        const level = mouseLevel < lowerLevel ? lowerLevel : mouseLevel
        const parentId = draggingItem.idsByLevel[level - 1]
        const parentItem = this.itemsById[parentId]
        return this.update(CardRelation.AFTER, parentItem, level, draggingMarkY)
      }
    }

    const upperItem = items[lowerItem.index - 1]

    if (upperItem) {
      if (mouseLevel > upperItem.level && upperItem.level >= lowerItem.level) {
        return this.update(
          CardRelation.CHILD,
          upperItem,
          upperItem.level + 1,
          draggingMarkY
        )
      }
      if (upperItem.level > lowerItem.level && mouseLevel > lowerItem.level) {
        if (mouseLevel < upperItem.level) {
          const parentId = upperItem.idsByLevel[mouseLevel - 1]
          const parentItem = this.itemsById[parentId]
          return this.update(
            CardRelation.AFTER,
            parentItem,
            mouseLevel,
            draggingMarkY
          )
        } else {
          return this.update(
            CardRelation.AFTER,
            upperItem,
            upperItem.level,
            draggingMarkY
          )
        }
      }
    }

    return this.update(
      CardRelation.BEFORE,
      lowerItem,
      lowerItem.level,
      draggingMarkY
    )
  }

  update(targetRelation, targetItem, targetLevel, draggingMarkY) {
    if (targetItem === this.draggingItem) {
      targetRelation = CardRelation.NOP
    }

    if (
      targetRelation === this.targetRelation &&
      targetItem === this.targetItem
    ) {
      return
    }

    const draggingMarkX =
      this.paddingLeft + this.levelIndent * (targetLevel - 1)
    this.updateDraggingState(
      targetItem.id,
      targetRelation,
      draggingMarkX,
      draggingMarkY
    )

    this.targetItem = targetItem
    this.targetRelation = targetRelation
  }

  getLowerItem(mouseY) {
    const { draggingItem, items } = this
    const lowerItem = this.getNearestLowerItem(mouseY)

    if (!lowerItem) {
      const lastItem = items[items.length - 1]
      return lastItem === draggingItem || isChildOf(draggingItem, lastItem)
        ? draggingItem
        : null
    }

    if (lowerItem === draggingItem || isChildOf(draggingItem, lowerItem)) {
      return draggingItem
    }

    const prev = items[lowerItem.index - 1]

    if (prev && (prev === draggingItem || isChildOf(draggingItem, prev))) {
      return draggingItem
    }

    return lowerItem
  }

  getNearestLowerItem(mouseY) {
    const { items, nearestLowerItem } = this

    if (mouseY < nearestLowerItem.fromY) {
      for (let i = nearestLowerItem.index - 1; i >= 0; --i) {
        const item = items[i]
        if (mouseY >= item.fromY) {
          this.nearestLowerItem = item
          return item
        }
      }
    } else if (mouseY > nearestLowerItem.toY) {
      for (let i = nearestLowerItem.index + 1; i < items.length; ++i) {
        const item = items[i]
        if (mouseY <= item.toY) {
          this.nearestLowerItem = item
          return item
        }
      }
    } else {
      return nearestLowerItem
    }

    return null
  }

  getNextItemWithLevelAtMost(targetMaxLevel, startIndex) {
    const { items } = this
    for (let i = startIndex; i < items.length; ++i) {
      const item = items[i]
      if (item.level <= targetMaxLevel) {
        return item
      }
    }
    return null
  }
}

function byBoundsTop(itemL, itemR) {
  return itemL.bounds.top - itemR.bounds.top
}

function isChildOf(parentItem, childItem) {
  return (
    childItem.level > parentItem.level &&
    childItem.idsByLevel[parentItem.level - 1] === parentItem.id
  )
}
