
export default function throttleRAF(fn, ctx) {
  let scheduled = false
  return () => {
    if (!scheduled) {
      scheduled = true
      window.requestAnimationFrame(wrapperFn)
    }
  }
  function wrapperFn() {
    scheduled = false
    fn.call(ctx)
  }
}
