import {buffers} from 'redux-saga'
import {all, take, fork, actionChannel} from 'redux-saga/effects'
import {$dispatch} from './utils/effects'
import store from '~/store'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import addShortcut from '~/utils/add-shortcut'


// Card actions that can be undone.
//
const UNDOABLE_ACTIONS = {
  [ActionTypes.DELETE_CARD]: {
    $stackHandler: $deleteCard_stackHandler,
    $undoHandler: $deleteCard_undo
  }
}

// Card actions that cannot be undone. Each of
// these actions flushes the undo stack.
//
const NON_UNDOABLE_ACTIONS = [
  ActionTypes.COMMIT_EDITING_CARD,
  ActionTypes.MOVE_CARD,
  ActionTypes.ASSIGN_CARD,
  ActionTypes.UNASSIGN_CARD,
  ActionTypes.SET_CARD_DUE_DATE,
  ActionTypes.UNSET_CARD_DUE_DATE,
  ActionTypes.MARK_CARD_WITH_ID_COMPLETED,
]


//
// DELETE_CARD
//


function* $deleteCard_stackHandler({cardId}) {
  return {cardId}
}


function* $deleteCard_undo({cardId}) {
  yield* $dispatch(Actions.undeleteCard(cardId))
}


//
// UNDO STACK MANAGEMENT
//


export default function* $undoSaga() {
  let undoStack = []
  yield all([
    fork($populateStack, undoStack),
    fork($handleUndos, undoStack),
  ])
  addShortcut('defmod-z', (e) => {
    store.dispatch(Actions.undo())
  })
}


function* $populateStack(undoStack) {
  const actions = Object.keys(UNDOABLE_ACTIONS).concat(NON_UNDOABLE_ACTIONS)
  const actionChan = yield actionChannel(actions, buffers.fixed(100))
  while (true) {
    const action = yield take(actionChan)
    if (NON_UNDOABLE_ACTIONS.indexOf(action.type) >= 0) {
      undoStack.length = 0
    } else {
      const {$stackHandler, $undoHandler} = UNDOABLE_ACTIONS[action.type]
      const payload = yield* $stackHandler(action)
      undoStack.push({payload, $handler: $undoHandler})
      yield* $dispatch(Actions.newUndoItem(action))
    }
  }
}


function* $handleUndos(undoStack) {
  const actionChan = yield actionChannel([ActionTypes.UNDO], buffers.fixed(100))
  while (true) {
    yield take(actionChan)
    const item = undoStack.pop()
    if (item) {
      yield* item.$handler(item.payload)
    }
  }
}
