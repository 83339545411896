import PropTypes from 'prop-types'
import React from 'react'
import connect from '../utils/connect'
import ImmutablePropTypes from 'react-immutable-proptypes'

import sel from '~/selectors'
const cardSelectors = sel.card

import {createSelector} from 'reselect'
import {FilteringState} from '~/utils/filter-constants'

import RegularCardListItem from './card-list-item-regular'
import EditingCardListItem from './card-list-item-editing'

import {CARD_LIST_CLASS} from './card-list-constants'

import {iterablesShallowEqual} from '~/utils/shallow-compare'
import {nop, isNotNull} from '~/utils/functional'

const selectCardId = (state, props) => props.cardId

const makeSelectCard = () => createSelector(
  sel.cards,
  selectCardId,
  (cards, cardId) => cards.get(cardId)
)

const makeSelectIsExpanded = (selectCard) => createSelector(
  selectCardId, selectCard, sel.cardsViewState, sel.route,
  (cardId, card, cardsViewState, route) => {
    return card.get('children').size > 0 && sel.cardViewState.isExpanded(
      cardsViewState,
      cardId,
      route.get('type'),
      route.get('cardId') // top-level card id
    )
  }
)

const makeSelectUnreadCount = (selectCard) => createSelector(
  selectCard, sel.unreadCounts,
  (card, unreadCounts) => unreadCounts.get(card.get('feedId'), 0)
)

// We need to create customly memoized selector as plain reselect won't help here.
// That's because this component depends on a card and a list of its children, and
// the card itself contains children only as ids, so we need to go to entities and
// map all these ids to actual cards. It means that this selector needs to depend
// on the whole card entities map, which makes it re-calculate each time any card
// changes.
//
// That's ok, but mapping from children ids to actual children would create new list
// each time, even if all child cards are the same references, so react-redux would
// constantly rerender the component.
//
// To fix this, we create a custom selector which obtains all linked entities first
// from the global map, and only updates resulting props when any of these entities
// change.
//
// Note: reselect would help if it supported dynamic dependencies, but it, fortunalely,
// doesn't.
//
const makeMapStateToProps = prevProps => (
  cardId, card,
  users, userId, cards, projectMembers,
  cardsViewState, isExpanded, unreadCount,
  selectedCardId, me
) => {
  const assigneeId = sel.card.optimisticAssigneeId(card)
  const assignee = assigneeId && users.get(assigneeId)
  const children = card.get('children')
  const isSelected = selectedCardId === cardId
  const childCards = children.map(id => cards.get(id))
  const selFilteringInfo = sel.cardViewState.filteringInfo
  const childrenFilteringInfo = children.map(id => selFilteringInfo(cardsViewState, id))

  const prev = prevProps
  const isSame = card === prev.card
    && assignee === prev.assignee
    && isExpanded === prev.isExpanded
    && userId === prev.userId
    && unreadCount === prev.unreadCount
    && isSelected === prev.isSelected
    && iterablesShallowEqual(childCards, prev.childCards)
    && iterablesShallowEqual(childrenFilteringInfo, prev.childrenFilteringInfo)

  if (isSame) {
    return prev
  }

  const newProps = {card, userId, assigneeId, assignee, isExpanded, unreadCount,
    childCards, childrenFilteringInfo, projectMembers, isSelected, me}

  prevProps = newProps
  return newProps
}


let ConnectedCardListItemContainer

class CardListItemContainer extends React.Component {

  static propTypes = {
    cardId: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    limitToThisWeek: PropTypes.bool.isRequired,

    filteringInfo: ImmutablePropTypes.mapContains({
      hasPassingDescendants: PropTypes.bool.isRequired,
      numFailingActiveChildren: PropTypes.number.isRequired,
    }).isRequired,

    selectCard: PropTypes.func.isRequired,
    makeHref: PropTypes.func.isRequired,
    registerItem: PropTypes.func.isRequired,

    expandCollapseAnimationManager: PropTypes.object.isRequired,

    startDragging: PropTypes.func,
    isDraggingPreview: PropTypes.bool,

    revealCardWithId: PropTypes.func,

    cardClass: PropTypes.func,
  }

  static lastInstanceId = -1

  static mapStateToProps() {
    const prevProps = {}
    const selectCard = makeSelectCard()
    return createSelector(
      selectCardId,
      selectCard,
      sel.users,
      sel.userId,
      sel.cards,
      sel.selectedProjectMembers,
      sel.cardsViewState,
      makeSelectIsExpanded(selectCard),
      makeSelectUnreadCount(selectCard),
      sel.selectedCardId,
      sel.me,
      makeMapStateToProps(prevProps)
    )
  }

  componentDidMount() {
    // We need this hacky custom wrappedRef property instead of usual React-provided ref
    // because react-redux's connect() hides the wrapped component, so ref will reference
    // this wrapper component, and not the original one. They provide withRef option
    // of connect() which enables getWrappedInstance() mathod on the wrapper component,
    // but it has a huge performance penalty, which is probably the reason it's disabled
    // by default (see github.com/reactjs/react-redux/blob/3c8032b/src/components/connect.js#L328)
    this.props.wrappedRef && this.props.wrappedRef(this)
    this.revealIfEditing()
  }

  componentWillUnmount() {
    this.props.wrappedRef && this.props.wrappedRef(null)
  }

  componentDidUpdate() {
    this.revealIfEditing()
  }

  constructor() {
    super()
    this.instanceId = ++CardListItemContainer.lastInstanceId
    this.boundActions = {
      toggleExpanded: () => this.toggleExpanded(),
      toggleCompleted: () => this.toggleCompleted(),
      startEditing: () => this.startEditing(),
      cancelEditing: () => this.cancelEditing(),
      changeNestingLevel: this.changeNestingLevel,
      commitEditing: (newName, enterPressed) => this.commitEditing(newName, enterPressed),
      createChildCard: (top) => this.createChildCard(top),
      setTopLevel: () => this.setTopLevel(),
      delete: () => this.delete(),
      setCardDueDate: (dueDate) => this.setCardDueDate(dueDate),
      unsetCardDueDate: () => this.unsetCardDueDate(),
      unassign: () => this.unassign(),
      assign: (assigneeId) => this.assign(assigneeId),
      selectCard: () => this.selectCard(),
      updateEstimate: (estimate) => this.updateEstimate(estimate),
      clearCardSelection: () => this.clearCardSelection(),
    }
    this.nopActions = {
      toggleExpanded: nop,
      toggleCompleted: nop,
      startEditing: nop,
      cancelEditing: this.boundActions.cancelEditing,
      changeNestingLevel: this.boundActions.changeNestingLevel,
      commitEditing: this.boundActions.commitEditing,
      createChildCard: this.boundActions.createChildCard,
      setTopLevel: nop,
      delete: nop,
      setCardDueDate: nop,
      unsetCardDueDate: nop,
      unassign: nop,
      assign: nop,
      selectCard: nop,
      clearCardSelection: nop,
    }
    this.onNode = this.onNode.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.renderChildren = this.renderChildren.bind(this)
    this.isEmpty = true
  }

  toggleExpanded() {
    this.props.actions.setCardExpanded(this.props.cardId, !this.props.isExpanded)
  }

  toggleCompleted() {
    this.props.actions.markCardWithIdCompleted(this.props.cardId, !this.isCompleted)
  }

  delete() {
    this.props.actions.deleteCard(this.props.cardId)
  }

  setCardDueDate(dueDate) {
    this.props.actions.setCardDueDate(this.props.cardId, dueDate)
  }

  unsetCardDueDate() {
    this.props.actions.unsetCardDueDate(this.props.cardId)
  }

  unassign() {
    this.props.actions.unassignCard(this.props.cardId)
  }

  assign(assigneeId) {
    this.props.actions.assignCard(this.props.cardId, assigneeId)
  }

  startEditing() {
    this.props.actions.startEditingCard(this.props.cardId)
  }

  cancelEditing() {
    this.props.actions.cancelEditingCard(this.props.cardId)
  }

  updateEstimate(estimate) {
    this.props.actions.updateEstimate(this.props.cardId, estimate)
  }

  commitEditing(newName, enterPressed) {
    this.props.actions.commitEditingCard(this.props.cardId, newName, enterPressed)
  }

  changeNestingLevel = (doIncrease, currentName, selectionStart, selectionEnd) => {
    this.props.actions.changeCardNestingLevel(
      this.props.cardId, doIncrease, currentName, selectionStart, selectionEnd
    )
  }

  createChildCard(top) {
    this.props.actions.createEphemeralCard(this.props.cardId, top)
  }

  setTopLevel() {
    this.props.actions.showCard(this.props.cardId)
  }

  selectCard() {
    this.props.selectCard(this.props.cardId)
  }

  clearCardSelection() {
    this.props.selectCard()
  }

  onNode(node, getBounds) {
    const {isDraggingPreview, registerItem, card, level} = this.props
    if (!registerItem || isDraggingPreview) return
    const id = card.get('id')
    if (node) {
      registerItem(id, this.instanceId, level, getBounds)
    } else {
      registerItem(id, this.instanceId, null, null)
    }
  }

  onDragStart(desc) {
    const {isDraggingPreview, startDragging, cardId} = this.props
    if (startDragging && !isDraggingPreview) {
      desc.cardId = cardId
      desc.cardProps = this.props
      startDragging(desc)
    }
  }

  revealIfEditing() {
    const {card} = this.props
    if (card.getIn(['optimistic', 'isEditing'])) {
      const revealMe = () => {
        this.props.expandCollapseAnimationManager.callWhenNotAnimating(() => {
          this.props.revealCardWithId(card.get('id'))
        })
      }
      setTimeout(revealMe, 100) // allow animation (if any) to start
    }
  }

  render() {
    const {props} = this
    const {cardId, card, level, assignee} = props
    const {unreadCount, isExpanded} = props
    const filteringInfo = props.filteringInfo.toJS()

    const {isDraggingPreview, renderChildrenOnly} = props
    const {makeHref = nop, startDragging} = props

    let {isSelected} = props

    const isEphemeral = card.get('isEphemeral')
    const actions = isEphemeral ? this.nopActions : this.boundActions

    const isCompleted = cardSelectors.isOptimisticallyCompleted(card)
    const dueDate = cardSelectors.optimisticDueDate(card)

    const hasVisibleChildren = filteringInfo.hasPassingDescendants

    this.isCompleted = isCompleted
    this.isEmpty = !hasVisibleChildren

    if (renderChildrenOnly) {
      if (!hasVisibleChildren) {
        return (props.noItemsMessage || null) && (
          <div className='card-list'>
            <div className='no-items'>{props.noItemsMessage}</div>
          </div>
        )
      }
      return (
        <div className={CARD_LIST_CLASS}>
          {this.renderChildren()}
        </div>
      )
    }

    const optimistic = card.get('optimistic')

    const isEditing = optimistic ? optimistic.get('isEditing') : false
    const CardListItem = this.getCardListItemClass(isEditing)

    const editingState = optimistic ? optimistic.get('editingState') : null
    const allowDragging = !(isDraggingPreview || !startDragging)

    return (
      <CardListItem
          id={card.get('id')}
          name={sel.card.optimisticName(card)}
          href={makeHref(cardId)}
          isEphemeral={isEphemeral}
          isCompleted={isCompleted}
          editingState={editingState}
          isExpanded={isExpanded}
          isSelected={isSelected}
          cardLevel={level - 1}
          assignee={assignee}
          unreadCount={unreadCount}
          filteringInfo={filteringInfo}
          dueDate={dueDate}
          isDraggingPreview={isDraggingPreview}
          projectMembers={this.props.projectMembers}
          me={this.props.me}
          estimateMin={card.getIn(['estimate', 'min'])}
          estimateMax={card.getIn(['estimate', 'max'])}
          renderChildren={this.renderChildren}
          actions={actions}
          onNode={this.onNode}
          onDragStart={allowDragging ? this.onDragStart : null}
          expandCollapseAnimationManager={props.expandCollapseAnimationManager}>
      </CardListItem>
    )
  }

  renderChildren() {
    const {props} = this
    const {selectCard = nop, makeHref = nop} = props
    const {registerItem, startDragging, revealCardWithId, childrenFilteringInfo} = props
    const childLevel = props.level + 1
    const {isVisible} = FilteringState
    const items = props.childCards.map((childCard, i) => {
      const childFilteringInfo = childrenFilteringInfo.get(i)
      if (!isVisible(childFilteringInfo.get('state'))) {
        return null
      }
      const id = childCard.get('id')
      return <ConnectedCardListItemContainer
        key={id}
        cardId={id}
        level={childLevel}
        limitToThisWeek={props.limitToThisWeek}
        filteringInfo={childFilteringInfo}
        cardClass={props.cardClass}
        isDraggingPreview={props.isDraggingPreview}
        selectCard={selectCard}
        makeHref={makeHref}
        registerItem={registerItem}
        startDragging={startDragging}
        revealCardWithId={revealCardWithId}
        expandCollapseAnimationManager={props.expandCollapseAnimationManager}>
      </ConnectedCardListItemContainer>
    })
    const filteredItems = items.filter(isNotNull)
    return filteredItems.size === 0 ? null : filteredItems
  }

  getCardListItemClass(isEditing) {
    return isEditing ? EditingCardListItem : (this.props.cardClass || RegularCardListItem)
  }
}


ConnectedCardListItemContainer = connect(CardListItemContainer)
export default ConnectedCardListItemContainer
