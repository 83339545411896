import {
  ApolloClient,
  createHttpLink,
  from,
  split,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'

import * as AbsintheSocket from '@absinthe/socket'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { Socket as PhoenixSocket } from 'phoenix'

import config from '~/config'
import storage from '~/utils/session-storage'
import { OperationDefinitionNode } from 'graphql'

function getToken() {
  return JSON.parse(storage.getItem('credentials') || '{}').token
}

const socket = new PhoenixSocket(config.WS_PATH, {
  params: () => {
    return { token: getToken() }
  },
})

socket.onError(() => console.debug('there was an error with the connection!'))
socket.onClose(() => console.debug('the connection dropped'))

const absintheWsLink: any = createAbsintheSocketLink(
  AbsintheSocket.create(socket),
  function () {
    console.debug('absintheWsLink: onError')
  },
  function () {
    console.debug('absintheWsLink: onConnect')
  }
)

const httpLink = createHttpLink({
  uri: config.GQL_PATH,
})

const httpAuthLink = setContext((_, { headers }) => {
  const credentials = JSON.parse(storage.getItem('credentials') || '{}')
  const token = credentials ? credentials.token : null
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(
      query
    ) as OperationDefinitionNode
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  from([errorLink, absintheWsLink]),
  from([errorLink, httpAuthLink, httpLink])
)

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    typePolicies: {
      Feed: { fields: { feedEvents: { merge: false } } },
      FeedEvent: { fields: { reactions: { merge: false } } },
      Query: {
        fields: {
          currentUser: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
          me: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming)
            },
          },
        },
      },
    },
  }),
})

export default client
