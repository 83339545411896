import config from '~/config'
import cuid from 'cuid'
import * as ActionTypes from '~/actions/types'

const actionTypesMap = {
  [ActionTypes.LOGIN_SUCCESS]: 'Logged In',
  [ActionTypes.NEEDS_LOGIN]: 'Not logged in visit',
  [ActionTypes.FEED_OPEN]: 'Opened feed',
  [ActionTypes.FEED_POST_MESSAGE]: 'Posted feed message',
  [ActionTypes.CARD_CREATED]: 'Created card',
  [ActionTypes.CARD_RENAMED]: 'Renamed card',
  [ActionTypes.CARD_MOVED]: 'Moved card',
  [ActionTypes.ASSIGN_CARD]: 'Assigned card',
  [ActionTypes.UNASSIGN_CARD]: 'Unassigned card',
  [ActionTypes.MARK_CARD_WITH_ID_COMPLETED]: 'Marked card as completed',
  [ActionTypes.SET_PROJECTS_LIST]: 'Set project list',
}

export const actionTypesToTrack = Object.keys(actionTypesMap)

const segmentAuthToken = config.SEGMENT_KEY
const anonymousId = cuid()

let userContext = {}

export function setUserContext(newUserContext) {
  if (newUserContext) {
    userContext = newUserContext
  }
  if (userContext.name) {
    identifyContextUserSegment()
  }
}

export function trackAction(action) {
  if (action.type === ActionTypes.SET_PROJECTS_LIST) {
    setUserContext(action.entities.user[userContext.id])
  }
  if (segmentAuthToken) {
    trackEventSegment(action)
  } else {
    const eventType = actionTypesMap[action.type]
    console.info(`analytics event: "${eventType}"`)
    // eslint-disable-next-line no-undef
    if (DEBUG_ANALYTICS) {
      console.info(
        formSegmentApiRequestOptions({ event: eventType }, action),
        userContext
      )
    }
  }
}

function trackPage(page) {
  if (segmentAuthToken) {
    trackPageSegment(page)
  } else {
    console.info(`analytics page:`, page)
    // eslint-disable-next-line no-undef
    if (DEBUG_ANALYTICS) {
      console.info(
        formSegmentApiRequestOptions({ name: page.pageName }, page),
        userContext
      )
    }
  }
}

let lastLoggedPathname

export function trackPageOnceAtVisit(page) {
  if (lastLoggedPathname !== page.pathname) {
    lastLoggedPathname = page.pathname
    trackPage(page)
  }
}

function trackEventSegment({ type, ...event }) {
  return segmentCall(
    'track',
    { event: actionTypesMap[type] },
    sendEventParams(type) ? event : {}
  )
}

function trackPageSegment({ pageName, ...event }) {
  return segmentCall('page', { name: pageName }, event)
}

function identifyContextUserSegment() {
  const { name, email } = userContext
  return segmentCall('identify', { traits: { name, email } })
}

function segmentCall(requestType, mix, props = {}) {
  return fetch(
    `https://api.segment.io/v1/${requestType}`,
    formSegmentApiRequestOptions(mix, props)
  )
}

function formSegmentApiRequestOptions(mix, props) {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${segmentAuthToken}`,
    },
    body: JSON.stringify(
      Object.assign(
        {
          [userContext.id ? 'userId' : 'anonymousId']:
            userContext.id || anonymousId,
          context: {
            userAgent: navigator.userAgent,
          },
          timestamp: new Date().toISOString(),
          properties: props,
        },
        mix
      )
    ),
  }
}

function sendEventParams(eventType) {
  return eventType !== ActionTypes.SET_PROJECTS_LIST
}
