import { all, take, call, fork, race } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

// import jstz from 'jstimezonedetect'

import * as persistentStore from '~/utils/persistent-store'
import * as ActionTypes from '~/actions/types'
import * as actions from '~/actions'

import { isAccessError, makeAccessError } from '~/utils/access-error'
// import { fetchMe } from '~/api/fetch-me'
// import { fetchProjectWithIdGQL } from '~/api/fetch-project-with-id'
// import { setTimezoneGQL } from '~/api/set-timezone'

export default function* $project() {
  yield all([fork($fetchMeOnLogin), fork($selectProject)])
}

function* $fetchMeOnLogin() {
  while (true) {
    yield take(ActionTypes.LOGIN_SUCCESS)
    let projectIds
    let entities
    let hasCard

    try {
      // let response = yield call(fetchMe)
      // projectIds = response.result.projects
      // hasCard = response.result.has_card
      // entities = response.entities

      if (!projectIds.length) {
        yield* $dispatch(actions.noProjects())
        return
      }
    } catch (e) {
      if (isAccessError(e)) {
        console.warn(
          `Detected invalid credentials, clearing and showing login form`
        )
        yield* $dispatch(actions.logout())
        continue
      }
      yield* $dispatch(
        actions.fatalError(new Error(`Failed to fetch user: ${e.message}`))
      )
      console.error(`failed fetching user: ${(e && e.message) || e}`)
      return
    }

    yield* $dispatch(actions.setProjectsList(projectIds, entities))
    yield* $dispatch(actions.setHaveBankCard(hasCard))

    try {
      // yield call(setTimezoneGQL, jstz.determine().name())
    } catch (e) {
      console.error(
        `failed to determine and setup timezone: ${(e && e.message) || e}`
      )
    }
  }
}

function* $selectProject() {
  let projectId = yield call($takeNewProjectId)
  while (true) {
    let sel
    try {
      sel = yield race({
        // projectResponse: call(fetchProjectWithIdGQL, projectId),
        newProjectId: call($takeNewProjectId),
      })
    } catch (e) {
      yield* $dispatch(
        actions.fatalError(
          isAccessError(e)
            ? makeAccessError(`Access to project ${projectId} denied`, e)
            : new Error(`Failed to fetch project ${projectId}: ${e.message}`)
        )
      )
      return
    }
    if (sel.newProjectId) {
      // the user has selected another project while request was in-flight
      projectId = sel.newProjectId
    } else {
      if (sel.projectResponse) {
        let { result, entities, permissions } = sel.projectResponse

        yield* $dispatch(actions.setPermissions(permissions))
        yield* $dispatch(actions.selectProjectSuccess(result, entities))
      }
      projectId = yield call($takeNewProjectId)
    }
  }
}

function* $takeNewProjectId() {
  let { projectId } = yield take(ActionTypes.SELECT_PROJECT)
  yield call(persistentStore.setProjectId, projectId)
  return projectId
}
