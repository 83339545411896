import { all, take, call, select, takeEvery, fork } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import selectors from '~/selectors'
import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'
import { fetchCard, inviteMember } from '~/api/cards'

export default function* $invitation(action) {
  yield all([
    fork(takeEvery, ActionTypes.INVITE_MEMBER, $inviteMember),
    fork(takeEvery, ActionTypes.REMOVE_MEMBER, $removeReport),
  ])
}

function* $inviteMember(action) {
  const { memberMail } = action

  let projectId = yield select(selectors.selectedProjectId)
  if (!projectId) {
    yield take(ActionTypes.SELECT_PROJECT_SUCCESS)
    projectId = yield select(selectors.selectedProjectId)
  }

  let error
  let updatedProjectCardEntities

  try {
    const inviteResult = yield call(inviteMember, projectId, memberMail)
    const updatedProjectCard = yield call(fetchCard, projectId)
    //TODO: hack until it's fixed on the backend
    delete updatedProjectCard.entities.card[updatedProjectCard.result].children
    updatedProjectCardEntities = updatedProjectCard.entities
  } catch (e) {
    error = e
  }
  yield* $dispatch(
    Actions.memberInvited(memberMail, error, updatedProjectCardEntities)
  )
}

function* $removeReport(action) {
  const { memberId } = action

  let projectId = yield select(selectors.selectedProjectId)
  if (!projectId) {
    yield take(ActionTypes.SELECT_PROJECT_SUCCESS)
    projectId = yield select(selectors.selectedProjectId)
  }

  let error
  let updatedProjectCardEntities

  try {
    const updatedProjectCard = yield call(fetchCard, projectId)
    //TODO: hack until it's fixed on the backend
    delete updatedProjectCard.entities.card[updatedProjectCard.result].children
    updatedProjectCardEntities = updatedProjectCard.entities
  } catch (e) {
    error = e
  }
  yield* $dispatch(
    Actions.memberRemoved(memberId, error, updatedProjectCardEntities)
  )
}
