import { Map, List } from 'immutable'
import * as ActionTypes from '~/actions/types'

export const INITIAL_STATE = (projectReducer.INITIAL_STATE = Map({
  hasProjects: true,
  selectedId: null,
  selectingId: null,
  allIds: List(),
}))

export default function projectReducer(
  state = INITIAL_STATE,
  action,
  selectCards
) {
  switch (action.type) {
    case ActionTypes.SET_PROJECTS_LIST: {
      let cardsById = selectCards()
      let activeProjects = action.projectIds.filter(
        id => !cardsById.get(id).get('deleted')
      )
      return state.set('allIds', List(activeProjects))
    }
    case ActionTypes.SELECT_PROJECT: {
      return state.merge({
        selectedId: null,
        selectingId: action.projectId,
      })
    }
    case ActionTypes.SELECT_PROJECT_SUCCESS: {
      return state.merge({
        selectedId: action.projectId,
        selectingId: null,
      })
    }
    case ActionTypes.NO_PROJECTS: {
      return state.set('hasProjects', false)
    }
    case ActionTypes.LOGOUT: {
      return INITIAL_STATE
    }
  }
  return state
}
