import animateScrollTo from './animate-scroll-to'


export default function scrollTo(scrollTop, container, animate = false) {
  if (animate == false) {
    container.scrollTop = scrollTop
  } else {
    const animateOpts = animate === true ? undefined : animate
    animateScrollTo(scrollTop, container, animateOpts)
  }
}
