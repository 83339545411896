import nextTick from '~/utils/next-tick'


export default class ExpandCollapseAnimationManager {

  constructor() {
    this.isAnimating = false
    this.callbacks = []
  }

  setAnimating = (isAnimating) => {
    this.isAnimating = isAnimating
    if (!isAnimating && this.callbacks.length) {
      const callbacks = this.callbacks
      this.callbacks = []
      for (let i = 0; i < callbacks.length; ++i) {
        callbacks[i]()
      }
    }
  }

  callWhenNotAnimating = (cb) => {
    if (this.isAnimating) {
      this.callbacks.push(cb)
    } else {
      nextTick(() => {
        if (this.isAnimating) {
          this.callbacks.push(cb)
        } else {
          cb()
        }
      })
    }
  }

}
