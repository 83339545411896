import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { TrackUtmCodes } from "./queries";

interface UTMCodes {
  medium?: string;
  source?: string;
  id?: string;
  campaign?: string;
  content?: string;
}

export function useTrackUTMCodes() {
  const [trackUtmCodes] = useMutation(TrackUtmCodes);

  const track = useCallback(
    (codes: UTMCodes) => {
      const codesKvPairs = Object.entries(codes).map(([key, value]) => {
        return { key: key, value: value };
      });
      // nothing to track
      if (codesKvPairs.length == 0) {
        return Promise.resolve();
      }
      return trackUtmCodes({
        variables: {
          input: { codes: codesKvPairs },
        },
      });
    },
    [trackUtmCodes]
  );

  return track;
}

function decodeMeta(encoded: string): any {
  return JSON.parse(atob(encoded));
}

export function getStoredUTMCodes(): UTMCodes {
  try {
    const hash = window.location.hash;
    if (hash.length == 0) return {};
    const meta = decodeMeta(hash.substring(1));
    if (!meta.utmQuery) return {};
    return parseUTMCodes(meta.utmQuery);
  } catch (e) {
    console.error(e);
  }
  return {};
}

/**
 * Given a href such as window.location.href, return a map of the UTM codes
 * for an ad campaign
 *
 * @param href
 * @returns
 */
export function parseUTMCodes(href: string): UTMCodes {
  const queryParams = getQueryParams(href);
  return removeMissing({
    medium: queryParams.utm_medium,
    source: queryParams.utm_source,
    id: queryParams.utm_id,
    campaign: queryParams.utm_campaign,
    content: queryParams.utm_content,
  });
}

type QueryParams = { [name: string]: string };

/**
 * Parse a url or a path and return a map of query parameters
 *
 * @param href
 * @returns
 */
function getQueryParams(href: string): QueryParams {
  const parts = href.split("?");
  if (parts.length == 1) {
    return {};
  }

  const urlSearchParams = new URLSearchParams(parts[1]);
  const queryParams: QueryParams = {};

  urlSearchParams.forEach((value, name) => {
    queryParams[name] = value;
  });

  return queryParams;
}

type StringMap = { [key: string]: any };

/**
 * Delete all of the falsy values from the map and return a new
 * map with the keys removed.
 */
function removeMissing<T extends StringMap>(obj: T): T {
  const newObj: T = { ...obj };
  for (var k of Object.keys(newObj)) {
    let v = newObj[k];
    if (!v) {
      delete newObj[k];
    }
  }
  return newObj;
}
