import { Map } from 'immutable'

import * as ActionTypes from '~/actions/types'

import { logicalAnd, always, never } from '~/utils/functional'
import { Filters, FilterValues, FilteringState } from '~/utils/filter-constants'
import { parseDateAsLocal } from '~/utils/time'

import sel from '~/selectors'
import dayjs from 'dayjs'
const { isOptimisticallyDeleted, isOptimisticallyCompleted } = sel.card

// We're memoizing a function here, which makes this reducer not strictly a pure
// function. However, you cannot detect this non-pureness from the outside: the
// only thing that memoization changes is that reducer will run slightly faster
// in some situations. And if a tree falls in a forest and no one is around to
// hear it, does it make a sound?
//
const makeFilterPredicates = (() => {
  let lastFilters = null
  let lastPredicates = { inheritable: never, nonInheritable: never }
  return (filters, selectMyUserId) => {
    if (filters !== lastFilters) {
      lastFilters = filters
      lastPredicates = _makeFilterPredicates(filters, selectMyUserId)
    }
    return lastPredicates
  }
})()

export default function filteringViewStateReducer(state, action, boundSel) {
  const { selectFilters, selectCards } = boundSel

  const filters = selectFilters()
  const filtersChanged = filters !== boundSel.selectPrevFilters()

  const cards = selectCards()
  const cardsChanged = cards !== boundSel.selectPrevCards()

  if (
    !filtersChanged &&
    !cardsChanged &&
    action.type !== ActionTypes.SELECT_PROJECT_SUCCESS
  ) {
    return state
  }

  const t = Date.now()
  const { selectProjectId } = boundSel

  const projectCardId = selectProjectId()
  if (projectCardId == null) {
    return state
  }

  state = state.withMutations(state => {
    updateSubtreeFilteringState(projectCardId, false, {
      mutableState: state,
      cards: cards,
      filterPredicates: makeFilterPredicates(filters, boundSel.selectMyUserId),
      isFiltersChange: filtersChanged,
    })
  })

  if (DEBUG) {
    console.debug(`filteringViewStateReducer took ${Date.now() - t} ms`)
  }

  return state
}

function _makeFilterPredicates(filters, selectMyUserId) {
  let completionFilterPredicate
  let filterPredicates = []

  switch (filters.get(Filters.SHOW_COMPLETED)) {
    case FilterValues.ShowCompleted.SHOW_RECENTLY_COMPLETED: {
      completionFilterPredicate = makeFilter_showRecentlyCompleted()
      break
    }
    case FilterValues.ShowCompleted.SHOW_INCOMPLETE: {
      completionFilterPredicate = filter_hideCompleted
      break
    }
    default: {
      completionFilterPredicate = always
      break
    }
  }

  const timeFilteringPeriod = getTimeFilteringPeriod(filters.get(Filters.TIME))
  if (timeFilteringPeriod) {
    filterPredicates.push(makeFilter_timePeriod(timeFilteringPeriod))
  }

  const assigneeFilterValue = filters.get(Filters.ASSIGNEE)
  if (assigneeFilterValue !== null) {
    filterPredicates.push(
      makeFilter_assignee(
        assigneeFilterValue === FilterValues.Assignee.ME
          ? selectMyUserId()
          : assigneeFilterValue
      )
    )
  }

  return {
    nonInheritable: completionFilterPredicate,
    inheritable: logicalAnd(filterPredicates),
  }
}

function filter_hideCompleted(card) {
  return !isOptimisticallyCompleted(card)
}

function makeFilter_showRecentlyCompleted() {
  const showCompletedSince = +dayjs().subtract(7, 'd')
  return card => {
    const optimistic = card.get('optimistic')
    if (optimistic && optimistic.get('isCompleted')) {
      return true
    }
    if (!card.get('completed')) {
      return true
    }
    const completedAt = Date.parse(card.get('completedAt'))
    return completedAt >= showCompletedSince
  }
}

function getTimeFilteringPeriod(filterValue) {
  let dateFrom
  let dateTo

  if (filterValue > 0) {
    dateFrom = dayjs().add((filterValue - 1) * 7, 'd')
    dateTo = dateFrom.clone().add(7, 'd')
  } else if (filterValue < 0) {
    dateFrom = dayjs().add(filterValue * 7, 'd')
    dateTo = dateFrom.clone().add(7, 'd')
  }

  if (dateFrom && dateTo) {
    return { start: +dateFrom, end: +dateTo }
  }

  return null
}

function makeFilter_timePeriod({ start, end }) {
  return card => {
    const optimistic = card.get('optimistic')
    let completedAt =
      optimistic && optimistic.has('completedAt')
        ? optimistic.get('completedAt')
        : card.get('completedAt')
    if (completedAt) {
      completedAt = Date.parse(completedAt)
      if (completedAt >= start && completedAt < end) {
        return true
      }
    }
    const dueDate =
      optimistic && optimistic.has('dueDate')
        ? optimistic.get('dueDate')
        : card.get('dueDate')
    if (dueDate) {
      // We treat due date as a time period spanning the whole day.
      const dueDateStart = +parseDateAsLocal(dueDate, false)
      if (dueDateStart >= start && dueDateStart < end) {
        return true
      }
      const dueDateEnd = +parseDateAsLocal(dueDate, true)
      if (dueDateEnd >= start && dueDateEnd < end) {
        return true
      }
    }
    return false
  }
}

function makeFilter_assignee(targetAssignee) {
  if (targetAssignee instanceof Array) {
    if (targetAssignee.length === 1) {
      targetAssignee = targetAssignee[0]
    } else {
      return card => {
        const assignee = sel.card.optimisticAssigneeId(card)
        return assignee != null && targetAssignee.indexOf(assignee) >= 0
      }
    }
  }
  return card => sel.card.optimisticAssigneeId(card) === targetAssignee
}

function updateSubtreeFilteringState(cardId, ancestorsPass, ctx) {
  const card = ctx.cards.get(cardId)

  if (!card || isOptimisticallyDeleted(card)) {
    markSubtreeAsDeleted(ctx, cardId)
    return FilteringState.DELETED
  }

  const nonInheritableFiltersPass = ctx.filterPredicates.nonInheritable(card)
  const inheritableFiltersPass =
    nonInheritableFiltersPass && ctx.filterPredicates.inheritable(card)
  const passes =
    nonInheritableFiltersPass && (ancestorsPass || inheritableFiltersPass)

  let hasPassingDescendants = false
  let numFailingActiveChildren = 0

  const children = card.get('children')
  const totalChildren = children ? children.size : 0

  if (totalChildren > 0) {
    const { FAILING, FAILING_BUT_ACTIVE } = FilteringState
    for (let i = 0; i < totalChildren; ++i) {
      const filteringState = updateSubtreeFilteringState(
        children.get(i),
        passes,
        ctx
      )
      if (filteringState < FAILING) {
        hasPassingDescendants = true
      } else if (filteringState === FAILING_BUT_ACTIVE) {
        ++numFailingActiveChildren
      }
    }
  }

  const filteringState = passes
    ? inheritableFiltersPass
      ? FilteringState.PASSING
      : FilteringState.ANCESTORS_PASSING
    : hasPassingDescendants
    ? nonInheritableFiltersPass
      ? FilteringState.DESCENDANTS_PASSING
      : FilteringState.FAILING
    : isOptimisticallyCompleted(card)
    ? FilteringState.FAILING
    : FilteringState.FAILING_BUT_ACTIVE

  updateCardFilteringState(
    ctx,
    cardId,
    filteringState,
    hasPassingDescendants,
    numFailingActiveChildren
  )

  return filteringState
}

function markSubtreeAsDeleted(ctx, cardId) {
  updateCardFilteringState(ctx, cardId, FilteringState.DELETED, false, 0)
  const children = ctx.cards.get(cardId).get('children')
  for (let i = 0, t = children.size; i < t; ++i) {
    markSubtreeAsDeleted(ctx, children.get(i))
  }
}

function updateCardFilteringState(
  ctx,
  cardId,
  filteringState,
  hasPassingDescendants,
  numFailingActiveChildren
) {
  const { mutableState } = ctx
  const viewState = mutableState.get(cardId)
  if (!viewState) {
    const filteringInfo = updateFilteringInfo(
      null,
      filteringState,
      hasPassingDescendants,
      numFailingActiveChildren
    )
    mutableState.set(cardId, Map.of('filtering', filteringInfo))
    return
  }
  const filteringInfo = updateFilteringInfo(
    viewState.get('filtering'),
    filteringState,
    hasPassingDescendants,
    numFailingActiveChildren
  )
  let clearExpandedDP = ctx.isFiltersChange
  if (clearExpandedDP) {
    const isExpandedDP = viewState.get('isExpandedDP')
    clearExpandedDP = isExpandedDP != null && isExpandedDP.size > 0
  }
  mutableState.set(
    cardId,
    clearExpandedDP
      ? viewState.withMutations(viewState => {
          viewState.set('filtering', filteringInfo)
          viewState.set('isExpandedDP', Map())
        })
      : viewState.set('filtering', filteringInfo)
  )
}

function updateFilteringInfo(
  filteringInfo,
  filteringState,
  hasPassingDescendants,
  numFailingActiveChildren
) {
  if (filteringInfo == null) {
    return Map.of(
      'state',
      filteringState,
      'hasPassingDescendants',
      hasPassingDescendants,
      'numFailingActiveChildren',
      numFailingActiveChildren
    )
  }
  return filteringInfo.withMutations(filteringInfo => {
    filteringInfo.set('state', filteringState)
    filteringInfo.set('hasPassingDescendants', hasPassingDescendants)
    filteringInfo.set('numFailingActiveChildren', numFailingActiveChildren)
  })
}
