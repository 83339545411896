import React from 'react'
import cn from 'classnames'
import { GoogleSignInButton } from '~/components/sign-in/google-sign-in-button'
import { TurtleAvatar } from '~/components/turtle-avatar'
import { TurtleCredentials } from '~/utils/google-auth-flow'
import { EmbeddableChatHeader, EmbeddableChatHeaderProps } from '../header'

type Props = {
  avatarSrc?: string | null | undefined
  bubbleText?: string
  subBubbleText?: string
  calendarLink?: string
  calendarText?: string
  theme?: string
  onAuthSuccess: (credentils: TurtleCredentials | null) => void
} & EmbeddableChatHeaderProps

const DEFAULT_BUBBLE_TEXT = `Thanks for your interest in working with us. In a few sentences, please tell us a little about yourself and what you’re building, and how we can help. Our team is excited to review your responses and will be in touch.`

export const DEFAULT_SUBBUBLE_TEXT = 'Typical response time is 2 hours.'

const DEFAULT_CALENDAR_TEXT = 'You can also schedule a call with me'

export const BeforeAuthView = (props: Props) => {
  const {
    chatTitle,
    bubbleText,
    subBubbleText,
    avatarSrc,
    headerColor,
    calendarLink,
    calendarText,
    theme = 'default',
    onAuthSuccess,
  } = props
  const isDarkTheme = theme === 'dark'
  return (
    <div className={cn('before-chat-view', { 'theme-dark': isDarkTheme })}>
      <EmbeddableChatHeader
        chatTitle={chatTitle}
        headerColor={headerColor}
        theme={theme}
      />
      <div className="before-chat-view--content">
        {isDarkTheme ? (
          <>
            <div className="before-chat-view--chat-dark">
              <TurtleAvatar source={avatarSrc} size={32} />
              <div className="before-chat-view--chat-dark--message">
                {bubbleText || DEFAULT_BUBBLE_TEXT}
                {calendarLink && (
                  <>
                    <br />
                    <br />
                    {calendarText || DEFAULT_CALENDAR_TEXT}{' '}
                    <a href={calendarLink} target="_blank" rel="noreferrer">
                      here
                    </a>
                    .
                  </>
                )}
              </div>
            </div>
            <div className="before-chat-view--subtitle">
              {subBubbleText || DEFAULT_SUBBUBLE_TEXT}
            </div>
          </>
        ) : (
          <div className="before-chat-view--chat-bubbles">
            <TurtleAvatar source={avatarSrc} size={34} />
            <div className="before-chat-view--bubble first">
              {bubbleText || DEFAULT_BUBBLE_TEXT}
            </div>
            {calendarLink && (
              <div className="before-chat-view--bubble">
                {calendarText || DEFAULT_CALENDAR_TEXT}{' '}
                <a href={calendarLink} target="_blank" rel="noreferrer">
                  here
                </a>
                .
              </div>
            )}
            <div className="before-chat-view--subtitle">
              {subBubbleText || DEFAULT_SUBBUBLE_TEXT}
            </div>
          </div>
        )}

        <div className="before-chat-view--button-block">
          <div className="before-chat-view--subtitle">
            Authenticate below to start a secure group chat.
          </div>
          <GoogleSignInButton
            className="before-chat-view--button"
            onAuthSuccess={onAuthSuccess}
          />
        </div>
      </div>
    </div>
  )
}
