
const SCROLL_MARGIN_PX = 100
const MAX_SCROLL_MARGIN_PERCENT = 0.3

const SCROLL_SPEED_MULT = 15


export default class AutoscrollManager {

  constructor(handleListScroll) {
    this.handleListScroll = handleListScroll
    this.scrollContainer = null
    this.containerBounds = null
    this.dragging = false
    this.mouseY = null
    this.frame = this.frame.bind(this)
  }

  startDragging(scrollContainer, mouseY) {
    const containerBounds = scrollContainer.getBoundingClientRect()
    const containerHeight = containerBounds.height

    const maxScroll = scrollContainer.scrollHeight - containerHeight
    if (maxScroll <= 0) {
      return
    }

    this.dragging = true
    this.maxScroll = maxScroll
    this.scrollContainer = scrollContainer

    this.containerTop = Math.max(0, containerBounds.top)
    this.containerBottom = Math.min(window.innerHeight, containerBounds.bottom)

    const maxScrollMargin = (this.containerBottom - this.containerTop) * MAX_SCROLL_MARGIN_PERCENT
    this.scrollMargin = Math.min(maxScrollMargin, SCROLL_MARGIN_PX)

    this.updateMousePosition(mouseY)
    this.frame()
  }

  updateMousePosition(mouseY) {
    this.mouseY = mouseY
  }

  frame() {
    if (!this.dragging) {
      return
    }
    this.animateScroll()
    window.requestAnimationFrame(this.frame)
  }

  animateScroll() {
    const scrollSpeed = this.getScrollSpeed()
    if (scrollSpeed === 0) {
      return
    }

    const {scrollContainer} = this
    const {scrollTop} = scrollContainer

    let newScrollTop = scrollTop + scrollSpeed * SCROLL_SPEED_MULT

    if (newScrollTop < 0) {
      newScrollTop = 0
    } else {
      const {maxScroll} = this
      if (newScrollTop > maxScroll) {
        newScrollTop = maxScroll
      }
    }

    if (newScrollTop !== scrollTop) {
      scrollContainer.scrollTop = newScrollTop
    }
  }

  stopDragging() {
    this.dragging = false
  }

  getScrollSpeed() {
    const {scrollMargin, mouseY} = this
    const dTop = this.containerTop + scrollMargin - mouseY

    if (dTop >= 0) {
      return -easingF(dTop / scrollMargin)
    }

    const dBtm = mouseY - this.containerBottom + scrollMargin

    if (dBtm >= 0) {
      return easingF(dBtm / scrollMargin)
    }

    return 0
  }

}


function easingF(x) {
  if (x < 0) {
    return 0
  }
  if (x > 1) {
    return 1
  }
  const xSq = x * x
  return xSq * xSq
}
