export const DIRECT_FEED_ID_PREFIX = 'conversation'

export function generateDirectFeedId(userId, otherUserId) {
  return userId < otherUserId
  ? `${DIRECT_FEED_ID_PREFIX}-${userId}-${otherUserId}`
  : `${DIRECT_FEED_ID_PREFIX}-${otherUserId}-${userId}`
}

export function isUserFeed(feedId) {
  return feedId && feedId.indexOf(DIRECT_FEED_ID_PREFIX) !== -1
}

const CARD_FEED_ID_PREFIX = 'card'

export function isCardFeed(feedId) {
  return feedId && feedId.indexOf(CARD_FEED_ID_PREFIX) !== -1
}
