/* eslint-disable */
import React from 'react'
import PopupWrapper from '../popup-wrapper'
import Loader from '../small-loader'
import { preventDefault } from '../utils/event-utils'
import { isMail } from '~/utils/validation'

const invitationStates = {
  default: mail => ({
    status: '',
    statusIcon: '',
    invitationMail: mail || '',
    nextInviteButton: false
  }),
  invalidEmail: mail => ({
    status: 'We need a valid email to invite your friend',
    statusIcon: 'error'
  }),
  alreadyMember: mail => ({
    status: `${mail} is already in the team`,
    statusIcon: 'duplicate'
  }),
  pending: mail => ({
    status: `Sending invite to ${mail}`,
    statusIcon: ''
  }),
  success: mail => ({
    status: `Successfully invited ${mail}`,
    statusIcon: 'success',
    nextInviteButton: true
  })
}

export default class InvitationPopup extends React.Component {
  constructor() {
    super()
    this.state = {}
    this.toggleInvitation = this.toggleInvitation.bind(this)
    this.focus = this.focus.bind(this)
    this.inviteMember = this.inviteMember.bind(this)
    this.isAlreadyInProject = this.isAlreadyInProject.bind(this)
    this.resetInvitation = this.resetInvitation.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.invitation.get('successfull') &&
      nextProps.invitation.get('successfull')
    ) {
      this.setState(invitationStates.success(this.state.invitationMail))
    }
  }

  render() {
    return (
      this.props.invitation.get('inviting') && (
        <PopupWrapper
          onDismiss={this.toggleInvitation}
          overlayClassName="invitation-popup-overlay"
        >
          <form
            className="invitation-form"
            onClick={preventDefault(this.focus)}
          >
            <div className="invitation-close" onClick={this.toggleInvitation} />
            <label htmlFor="invite-input" className="invitation-title">
              Invite new teammember
            </label>
            {this.props.project && this.props.project.get('name') && (
              <label htmlFor="invite-input" className="invitation-title">
                to {this.props.project.get('name')}
              </label>
            )}
            <input
              id="invite-input"
              name="invite-input"
              type="email"
              autoComplete="off"
              autoFocus={true}
              ref={input => (this.state.input = input)}
              className="invitation-input"
              onChange={this.handleInput}
              placeholder="your teammate’s email"
            ></input>
            <div className="status-updates">
              {this.props.invitation.get('pending') && (
                <Loader className="status-item status-loader" />
              )}
              {this.state.statusIcon && (
                <div
                  className={`${this.state.statusIcon} status-icon status-item`}
                />
              )}
              {this.state.status && (
                <div className="invitation-status status-item">
                  {this.state.status}
                </div>
              )}
            </div>
            {this.state.nextInviteButton ? (
              <button
                className="invite-button"
                type="submit"
                onClick={this.resetInvitation}
              >
                Invite next teammate
              </button>
            ) : (
              <button
                className="invite-button"
                type="submit"
                onClick={this.inviteMember}
              >
                Send invite
              </button>
            )}
          </form>
        </PopupWrapper>
      )
    )
  }

  handleInput(e) {
    this.setState(invitationStates.default(e.target.value))
    if (this.props.invitation.get('successfull')) {
      this.props.resetInvitation()
    }
  }

  toggleInvitation() {
    this.reset()
    this.props.toggleInvitation()
  }

  inviteMember() {
    if (this.props.invitation.get('pending')) {
      return
    }
    const { invitationMail } = this.state

    if (!isMail(invitationMail)) {
      return this.setState(invitationStates.invalidEmail(invitationMail))
    }

    if (this.isAlreadyInProject(invitationMail)) {
      return this.setState(invitationStates.alreadyMember(invitationMail))
    }

    this.setState(invitationStates.pending(invitationMail))
    this.props.inviteMember(invitationMail)
  }

  focus() {
    return this.state.input && this.state.input.focus()
  }

  isAlreadyInProject(mail) {
    return this.props.memberMails.some(memberMail => memberMail === mail)
  }

  resetInvitation() {
    this.reset()
    this.props.resetInvitation()
  }

  reset() {
    this.state.input.value = ''
    this.setState(invitationStates.default())
  }
}
