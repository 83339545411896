/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignCardMutation
// ====================================================

export interface AssignCardMutation_cardAssign_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface AssignCardMutation_cardAssign_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface AssignCardMutation_cardAssign {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: AssignCardMutation_cardAssign_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: AssignCardMutation_cardAssign_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface AssignCardMutation {
  /**
   * Assign a card to a user
   */
  cardAssign: AssignCardMutation_cardAssign | null;
}

export interface AssignCardMutationVariables {
  input: CardAssignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnassignCardMutation
// ====================================================

export interface UnassignCardMutation_cardUnassign_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UnassignCardMutation_cardUnassign_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UnassignCardMutation_cardUnassign {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UnassignCardMutation_cardUnassign_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UnassignCardMutation_cardUnassign_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UnassignCardMutation {
  /**
   * Remove the assignee for a card (task)
   */
  cardUnassign: UnassignCardMutation_cardUnassign | null;
}

export interface UnassignCardMutationVariables {
  input: CardUnassignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteCardMutation
// ====================================================

export interface CompleteCardMutation_cardComplete_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface CompleteCardMutation_cardComplete_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface CompleteCardMutation_cardComplete {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: CompleteCardMutation_cardComplete_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: CompleteCardMutation_cardComplete_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface CompleteCardMutation {
  /**
   * Mark a card as complete
   */
  cardComplete: CompleteCardMutation_cardComplete | null;
}

export interface CompleteCardMutationVariables {
  input: CardCompleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UncompleteCardMutation
// ====================================================

export interface UncompleteCardMutation_cardUncomplete_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UncompleteCardMutation_cardUncomplete_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UncompleteCardMutation_cardUncomplete {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UncompleteCardMutation_cardUncomplete_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UncompleteCardMutation_cardUncomplete_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UncompleteCardMutation {
  /**
   * Mark a completed task as incomplete again
   */
  cardUncomplete: UncompleteCardMutation_cardUncomplete | null;
}

export interface UncompleteCardMutationVariables {
  input: CardUncompleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IncreaseBudgetMutation
// ====================================================

export interface IncreaseBudgetMutation_cardBudgetIncrease_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface IncreaseBudgetMutation_cardBudgetIncrease_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface IncreaseBudgetMutation_cardBudgetIncrease {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: IncreaseBudgetMutation_cardBudgetIncrease_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: IncreaseBudgetMutation_cardBudgetIncrease_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface IncreaseBudgetMutation {
  /**
   * Decrease the budget of a card. To set the initial budget of a card, use this command.
   * Every card can have a budget. This is used so a customer can budget a feature.
   * For example, a customer could limit the cost of a feature to 100h.
   * Budgets operations are relative so the user can work relative to the current budget.
   */
  cardBudgetIncrease: IncreaseBudgetMutation_cardBudgetIncrease | null;
}

export interface IncreaseBudgetMutationVariables {
  input: CardBudgetIncreaseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DecreaseBudgetMutation
// ====================================================

export interface DecreaseBudgetMutation_cardBudgetDecrease_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface DecreaseBudgetMutation_cardBudgetDecrease_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface DecreaseBudgetMutation_cardBudgetDecrease {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: DecreaseBudgetMutation_cardBudgetDecrease_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: DecreaseBudgetMutation_cardBudgetDecrease_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface DecreaseBudgetMutation {
  /**
   * Decrease the budget of a card.
   * Every card can have a budget. This is used so a customer can budget a feature.
   * For example, a customer could limit the cost of a feature to 100h.
   * Budgets operations are relative so the user can work relative to the current budget.
   */
  cardBudgetDecrease: DecreaseBudgetMutation_cardBudgetDecrease | null;
}

export interface DecreaseBudgetMutationVariables {
  input: CardBudgetDecreaseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCardMutation
// ====================================================

export interface DeleteCardMutation_cardDelete_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface DeleteCardMutation_cardDelete_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface DeleteCardMutation_cardDelete {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: DeleteCardMutation_cardDelete_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: DeleteCardMutation_cardDelete_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface DeleteCardMutation {
  /**
   * Delete a card (project/task)
   * Deleting a card which has subtasks will also mark those subtasks as deleted.
   * Rsetoring those tasks with [CardUndelete] will also restore those subtasks.
   */
  cardDelete: DeleteCardMutation_cardDelete | null;
}

export interface DeleteCardMutationVariables {
  input: CardDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UndeleteCardMutation
// ====================================================

export interface UndeleteCardMutation_cardUndelete_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UndeleteCardMutation_cardUndelete_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UndeleteCardMutation_cardUndelete {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UndeleteCardMutation_cardUndelete_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UndeleteCardMutation_cardUndelete_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UndeleteCardMutation {
  /**
   * Restore a deleted card and all of its subtasks.
   */
  cardUndelete: UndeleteCardMutation_cardUndelete | null;
}

export interface UndeleteCardMutationVariables {
  input: CardUndeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsetBudgetMutation
// ====================================================

export interface UnsetBudgetMutation_cardBudgetUnset_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UnsetBudgetMutation_cardBudgetUnset_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UnsetBudgetMutation_cardBudgetUnset {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UnsetBudgetMutation_cardBudgetUnset_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UnsetBudgetMutation_cardBudgetUnset_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UnsetBudgetMutation {
  /**
   * Completely remove the budget for a card.
   * Usually used if a customer decides they don't want a budget anymore.
   */
  cardBudgetUnset: UnsetBudgetMutation_cardBudgetUnset | null;
}

export interface UnsetBudgetMutationVariables {
  input: CardBudgetUnsetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveCardMutation
// ====================================================

export interface MoveCardMutation_cardMove_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface MoveCardMutation_cardMove_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface MoveCardMutation_cardMove {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: MoveCardMutation_cardMove_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: MoveCardMutation_cardMove_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface MoveCardMutation {
  /**
   * Move a card (task) from one location to another.
   * Currently only possible to move cards that belong to a project within their own project.
   */
  cardMove: MoveCardMutation_cardMove | null;
}

export interface MoveCardMutationVariables {
  input: CardMoveInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEstimateMutation
// ====================================================

export interface UpdateEstimateMutation_cardEstimateSet_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UpdateEstimateMutation_cardEstimateSet_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UpdateEstimateMutation_cardEstimateSet {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UpdateEstimateMutation_cardEstimateSet_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UpdateEstimateMutation_cardEstimateSet_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UpdateEstimateMutation {
  /**
   * Set (or update) an estimated for how long you think a card (task) will take to complete. For example 5-10h.
   */
  cardEstimateSet: UpdateEstimateMutation_cardEstimateSet | null;
}

export interface UpdateEstimateMutationVariables {
  input: CardEstimateSetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsetEstimateMutation
// ====================================================

export interface UnsetEstimateMutation_cardEstimateUnset_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UnsetEstimateMutation_cardEstimateUnset_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UnsetEstimateMutation_cardEstimateUnset {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UnsetEstimateMutation_cardEstimateUnset_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UnsetEstimateMutation_cardEstimateUnset_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UnsetEstimateMutation {
  /**
   * Remove an estimate a card (task).
   */
  cardEstimateUnset: UnsetEstimateMutation_cardEstimateUnset | null;
}

export interface UnsetEstimateMutationVariables {
  input: CardEstimateUnsetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCardDueDateMutation
// ====================================================

export interface SetCardDueDateMutation_cardDueDateSet_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface SetCardDueDateMutation_cardDueDateSet_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface SetCardDueDateMutation_cardDueDateSet {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: SetCardDueDateMutation_cardDueDateSet_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: SetCardDueDateMutation_cardDueDateSet_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface SetCardDueDateMutation {
  /**
   * Set the due date of a card.
   * Use CardDueDateUnset to remove the due date.
   */
  cardDueDateSet: SetCardDueDateMutation_cardDueDateSet | null;
}

export interface SetCardDueDateMutationVariables {
  input: CardDueDateSetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsetCardDueDateMutation
// ====================================================

export interface UnsetCardDueDateMutation_cardDueDateUnset_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface UnsetCardDueDateMutation_cardDueDateUnset_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface UnsetCardDueDateMutation_cardDueDateUnset {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: UnsetCardDueDateMutation_cardDueDateUnset_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: UnsetCardDueDateMutation_cardDueDateUnset_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface UnsetCardDueDateMutation {
  /**
   * Clear the due date of a card
   */
  cardDueDateUnset: UnsetCardDueDateMutation_cardDueDateUnset | null;
}

export interface UnsetCardDueDateMutationVariables {
  input: CardDueDateUnsetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameCardMutation
// ====================================================

export interface RenameCardMutation_cardRename_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface RenameCardMutation_cardRename_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface RenameCardMutation_cardRename {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: RenameCardMutation_cardRename_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: RenameCardMutation_cardRename_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface RenameCardMutation {
  /**
   * Change the name of a card (or project)
   */
  cardRename: RenameCardMutation_cardRename | null;
}

export interface RenameCardMutationVariables {
  input: CardRenameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCardMutation
// ====================================================

export interface CreateCardMutation_cardCreate_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface CreateCardMutation_cardCreate_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface CreateCardMutation_cardCreate_descendants {
  __typename: "Card";
  id: string;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

export interface CreateCardMutation_cardCreate {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: CreateCardMutation_cardCreate_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: CreateCardMutation_cardCreate_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
  /**
   * Child cards, grandchild cards, and so on. Can control depth and filters.
   */
  descendants: CreateCardMutation_cardCreate_descendants[];
}

export interface CreateCardMutation {
  /**
   * Create a new card. This could either mean creating a project or a task.
   */
  cardCreate: CreateCardMutation_cardCreate | null;
}

export interface CreateCardMutationVariables {
  input: CardCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchCardQuery
// ====================================================

export interface FetchCardQuery_card_descendants {
  __typename: "Card";
  id: string;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
}

export interface FetchCardQuery_card_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface FetchCardQuery_card_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface FetchCardQuery_card {
  __typename: "Card";
  id: string;
  /**
   * Child cards, grandchild cards, and so on. Can control depth and filters.
   */
  descendants: FetchCardQuery_card_descendants[];
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: FetchCardQuery_card_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: FetchCardQuery_card_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
}

export interface FetchCardQuery {
  /**
   * Fetch a card by its id
   */
  card: FetchCardQuery_card | null;
}

export interface FetchCardQueryVariables {
  id?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteMemberMutation
// ====================================================

export interface InviteMemberMutation_cardInviteUser {
  __typename: "User";
  id: string;
}

export interface InviteMemberMutation {
  /**
   * Add a user to a project and send them an e-mail notification saying they were invited to the project.
   * 
   * If the user exists by e-mail, this user will be invited.
   * If the user doesn't exist by e-mail, an account will be created for the user.
   */
  cardInviteUser: InviteMemberMutation_cardInviteUser | null;
}

export interface InviteMemberMutationVariables {
  input: CardInviteUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthorizeFileUpload
// ====================================================

export interface AuthorizeFileUpload_authorizeFileUpload {
  __typename: "FileUploadAuthorization";
  /**
   * The id assigned to the file.
   */
  fileId: any | null;
  /**
   * The Presigned URL to where the file should be uploaded.
   */
  presignedUrl: string | null;
}

export interface AuthorizeFileUpload {
  /**
   * Creates a temporary authorization for a user to upload a file
   */
  authorizeFileUpload: AuthorizeFileUpload_authorizeFileUpload | null;
}

export interface AuthorizeFileUploadVariables {
  input: AuthorizeFileUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthenticateUser
// ====================================================

export interface AuthenticateUser_authenticate_user {
  __typename: "User";
  id: string;
}

export interface AuthenticateUser_authenticate {
  __typename: "AuthResult";
  accessToken: string;
  user: AuthenticateUser_authenticate_user;
}

export interface AuthenticateUser {
  /**
   * Sign into a user account associated with the identity (as identified by the provider and token)
   * if a user already exists.
   * If a user does not exist a user is automatically created.
   */
  authenticate: AuthenticateUser_authenticate | null;
}

export interface AuthenticateUserVariables {
  idToken: string;
  provider: AuthProvider;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestToWorkWith
// ====================================================

export interface requestToWorkWith_requestToWorkWith {
  __typename: "GroupChat";
  id: string;
  topic: string | null;
  feedId: string | null;
}

export interface requestToWorkWith {
  /**
   * Request to work with a contractor.
   * This starts a group chat between the customer, contractor, and Turtle.
   */
  requestToWorkWith: requestToWorkWith_requestToWorkWith | null;
}

export interface requestToWorkWithVariables {
  input: RequestToWorkWithInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePlaidLInkTokenMutation
// ====================================================

export interface CreatePlaidLInkTokenMutation_createPlaidLinkToken {
  __typename: "PlaidLinkToken";
  linkToken: string | null;
}

export interface CreatePlaidLInkTokenMutation {
  /**
   * Create a link token from Plaid which will be used by PlaidLink to communicate with Plaid's API.
   */
  createPlaidLinkToken: CreatePlaidLInkTokenMutation_createPlaidLinkToken | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetChatData
// ====================================================

export interface GetChatData_currentUser {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
}

export interface GetChatData_feed_members {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
}

export interface GetChatData_feed_source_Card_workspace {
  __typename: "Workspace";
  id: string;
}

export interface GetChatData_feed_source_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The workspace to which this card belongs.
   * If null, means this cards doesn't belong to a workspace.
   */
  workspace: GetChatData_feed_source_Card_workspace | null;
}

export interface GetChatData_feed_source_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
}

export interface GetChatData_feed_source_Feed {
  __typename: "Feed";
  /**
   * Unique identifier for an activity feed.
   * Generated based on what it is attached to like conversation-123-987 or card-556
   */
  feedId: string | null;
  /**
   * Same as card name when feed belongs to a Card.
   * Null when feed is associated to a 1-on-1 conversation.
   */
  feedName: string | null;
}

export interface GetChatData_feed_source_GroupChat {
  __typename: "GroupChat";
  id: string;
  topic: string | null;
}

export type GetChatData_feed_source = GetChatData_feed_source_Card | GetChatData_feed_source_User | GetChatData_feed_source_Feed | GetChatData_feed_source_GroupChat;

export interface GetChatData_feed_feedEvents_sender {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface GetChatData_feed_feedEvents_reactions_user {
  __typename: "User";
  id: string;
}

export interface GetChatData_feed_feedEvents_reactions {
  __typename: "Reaction";
  /**
   * The emoji of the reaction like "🐢".
   */
  emoji: string;
  /**
   * The user that send the reaction
   */
  user: GetChatData_feed_feedEvents_reactions_user;
}

export interface GetChatData_feed_feedEvents_bodyTokens_DurationObject {
  __typename: "DurationObject" | "Organization" | "Workspace";
}

export interface GetChatData_feed_feedEvents_bodyTokens_StringObject {
  __typename: "StringObject";
  string: string | null;
}

export interface GetChatData_feed_feedEvents_bodyTokens_DateObject {
  __typename: "DateObject";
  dateObj: any | null;
}

export interface GetChatData_feed_feedEvents_bodyTokens_DurationRange {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface GetChatData_feed_feedEvents_bodyTokens_Link {
  __typename: "Link";
  /**
   * The http: // ... url this goes to
   */
  uri: string;
}

export interface GetChatData_feed_feedEvents_bodyTokens_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  completed: boolean;
}

export interface GetChatData_feed_feedEvents_bodyTokens_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface GetChatData_feed_feedEvents_bodyTokens_File {
  __typename: "File";
  id: string;
  /**
   * The original file name.
   */
  name: string;
  /**
   * File's public URL.
   */
  uri: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
  /**
   * Size of the file in bytes.
   */
  size: number | null;
}

export interface GetChatData_feed_feedEvents_bodyTokens_TimeEntry {
  __typename: "TimeEntry";
  id: string;
  /**
   * The amount of time that was worked.
   */
  duration: any;
  /**
   * Some notes about the work done. (Optional)
   */
  memo: string | null;
  /**
   * The date the work was done.
   */
  workDoneAt: any | null;
}

export interface GetChatData_feed_feedEvents_bodyTokens_DatetimeObject {
  __typename: "DatetimeObject";
  datetime: any | null;
}

export type GetChatData_feed_feedEvents_bodyTokens = GetChatData_feed_feedEvents_bodyTokens_DurationObject | GetChatData_feed_feedEvents_bodyTokens_StringObject | GetChatData_feed_feedEvents_bodyTokens_DateObject | GetChatData_feed_feedEvents_bodyTokens_DurationRange | GetChatData_feed_feedEvents_bodyTokens_Link | GetChatData_feed_feedEvents_bodyTokens_Card | GetChatData_feed_feedEvents_bodyTokens_User | GetChatData_feed_feedEvents_bodyTokens_File | GetChatData_feed_feedEvents_bodyTokens_TimeEntry | GetChatData_feed_feedEvents_bodyTokens_DatetimeObject;

export interface GetChatData_feed_feedEvents {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
  /**
   * The (optional) client-side id that the client passed in to create this item.
   * Short lived, expires in 60 seconds and is null after that.
   */
  tempId: string | null;
  /**
   * The that sent the messages.
   * Activity-style messages will have this omitted.s
   */
  sender: GetChatData_feed_feedEvents_sender | null;
  /**
   * An array of individual reactions to messages. Multiple users reacting with the same emoji results in distinct records.
   */
  reactions: GetChatData_feed_feedEvents_reactions[];
  /**
   * The time the feed event was posted if it was a message or the time the event was generated.
   */
  createdAt: string | null;
  /**
   * Whether this item has been deleted.
   */
  deleted: boolean | null;
  /**
   * Whether the message was edited. Also applies to other edits like time entries.
   */
  edited: boolean | null;
  /**
   * The feed id this belongs to
   */
  feedId: string | null;
  /**
   * An array of tokens used when rendering a message body.
   * It may look like types of [string, date, file, ...]
   * On the client, you could map over the array and convert each item to a widget that is rendered from left to right.
   */
  bodyTokens: GetChatData_feed_feedEvents_bodyTokens[];
}

export interface GetChatData_feed {
  __typename: "Feed";
  /**
   * Unique identifier for an activity feed.
   * Generated based on what it is attached to like conversation-123-987 or card-556
   */
  id: string | null;
  /**
   * Same as card name when feed belongs to a Card.
   * Null when feed is associated to a 1-on-1 conversation.
   */
  name: string | null;
  members: GetChatData_feed_members[];
  /**
   * Every time this entity is modified, its version will be updated with a larger value than before. Useful for syncing.
   */
  version: string | null;
  /**
   * The entity the activity feed is attached to.
   * Or it's the card it's attached to.
   * It's either the other user in the conversation if it's a private message.
   */
  source: GetChatData_feed_source | null;
  /**
   * List of feed events that belong to the activity feed. Sorted by time created ascending.
   */
  feedEvents: GetChatData_feed_feedEvents[];
}

export interface GetChatData {
  currentUser: GetChatData_currentUser;
  /**
   * Fetch a feed by its id
   */
  feed: GetChatData_feed | null;
}

export interface GetChatDataVariables {
  chatId?: any | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ChatSubscription
// ====================================================

export interface ChatSubscription_chatUpdated_members {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
}

export interface ChatSubscription_chatUpdated_feedEvents_sender {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatSubscription_chatUpdated_feedEvents_reactions_user {
  __typename: "User";
  id: string;
}

export interface ChatSubscription_chatUpdated_feedEvents_reactions {
  __typename: "Reaction";
  /**
   * The emoji of the reaction like "🐢".
   */
  emoji: string;
  /**
   * The user that send the reaction
   */
  user: ChatSubscription_chatUpdated_feedEvents_reactions_user;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_DurationObject {
  __typename: "DurationObject" | "Organization" | "Workspace";
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_StringObject {
  __typename: "StringObject";
  string: string | null;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_DateObject {
  __typename: "DateObject";
  dateObj: any | null;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_DurationRange {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_Link {
  __typename: "Link";
  /**
   * The http: // ... url this goes to
   */
  uri: string;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  completed: boolean;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_File {
  __typename: "File";
  id: string;
  /**
   * The original file name.
   */
  name: string;
  /**
   * File's public URL.
   */
  uri: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
  /**
   * Size of the file in bytes.
   */
  size: number | null;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_TimeEntry {
  __typename: "TimeEntry";
  id: string;
  /**
   * The amount of time that was worked.
   */
  duration: any;
  /**
   * Some notes about the work done. (Optional)
   */
  memo: string | null;
  /**
   * The date the work was done.
   */
  workDoneAt: any | null;
}

export interface ChatSubscription_chatUpdated_feedEvents_bodyTokens_DatetimeObject {
  __typename: "DatetimeObject";
  datetime: any | null;
}

export type ChatSubscription_chatUpdated_feedEvents_bodyTokens = ChatSubscription_chatUpdated_feedEvents_bodyTokens_DurationObject | ChatSubscription_chatUpdated_feedEvents_bodyTokens_StringObject | ChatSubscription_chatUpdated_feedEvents_bodyTokens_DateObject | ChatSubscription_chatUpdated_feedEvents_bodyTokens_DurationRange | ChatSubscription_chatUpdated_feedEvents_bodyTokens_Link | ChatSubscription_chatUpdated_feedEvents_bodyTokens_Card | ChatSubscription_chatUpdated_feedEvents_bodyTokens_User | ChatSubscription_chatUpdated_feedEvents_bodyTokens_File | ChatSubscription_chatUpdated_feedEvents_bodyTokens_TimeEntry | ChatSubscription_chatUpdated_feedEvents_bodyTokens_DatetimeObject;

export interface ChatSubscription_chatUpdated_feedEvents {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
  /**
   * The (optional) client-side id that the client passed in to create this item.
   * Short lived, expires in 60 seconds and is null after that.
   */
  tempId: string | null;
  /**
   * The that sent the messages.
   * Activity-style messages will have this omitted.s
   */
  sender: ChatSubscription_chatUpdated_feedEvents_sender | null;
  /**
   * An array of individual reactions to messages. Multiple users reacting with the same emoji results in distinct records.
   */
  reactions: ChatSubscription_chatUpdated_feedEvents_reactions[];
  /**
   * The time the feed event was posted if it was a message or the time the event was generated.
   */
  createdAt: string | null;
  /**
   * Whether this item has been deleted.
   */
  deleted: boolean | null;
  /**
   * Whether the message was edited. Also applies to other edits like time entries.
   */
  edited: boolean | null;
  /**
   * The feed id this belongs to
   */
  feedId: string | null;
  /**
   * An array of tokens used when rendering a message body.
   * It may look like types of [string, date, file, ...]
   * On the client, you could map over the array and convert each item to a widget that is rendered from left to right.
   */
  bodyTokens: ChatSubscription_chatUpdated_feedEvents_bodyTokens[];
}

export interface ChatSubscription_chatUpdated {
  __typename: "Feed";
  /**
   * Unique identifier for an activity feed.
   * Generated based on what it is attached to like conversation-123-987 or card-556
   */
  id: string | null;
  /**
   * Same as card name when feed belongs to a Card.
   * Null when feed is associated to a 1-on-1 conversation.
   */
  name: string | null;
  members: ChatSubscription_chatUpdated_members[];
  /**
   * Every time this entity is modified, its version will be updated with a larger value than before. Useful for syncing.
   */
  version: string | null;
  /**
   * List of feed events that belong to the activity feed. Sorted by time created ascending.
   */
  feedEvents: ChatSubscription_chatUpdated_feedEvents[];
}

export interface ChatSubscription {
  /**
   * One or more attributes of a chat was updated
   */
  chatUpdated: ChatSubscription_chatUpdated | null;
}

export interface ChatSubscriptionVariables {
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ChatMessageSubscription
// ====================================================

export interface ChatMessageSubscription_messageUpdated_sender {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatMessageSubscription_messageUpdated_reactions_user {
  __typename: "User";
  id: string;
}

export interface ChatMessageSubscription_messageUpdated_reactions {
  __typename: "Reaction";
  /**
   * The emoji of the reaction like "🐢".
   */
  emoji: string;
  /**
   * The user that send the reaction
   */
  user: ChatMessageSubscription_messageUpdated_reactions_user;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_DurationObject {
  __typename: "DurationObject" | "Organization" | "Workspace";
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_StringObject {
  __typename: "StringObject";
  string: string | null;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_DateObject {
  __typename: "DateObject";
  dateObj: any | null;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_DurationRange {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_Link {
  __typename: "Link";
  /**
   * The http: // ... url this goes to
   */
  uri: string;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  completed: boolean;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_File {
  __typename: "File";
  id: string;
  /**
   * The original file name.
   */
  name: string;
  /**
   * File's public URL.
   */
  uri: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
  /**
   * Size of the file in bytes.
   */
  size: number | null;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_TimeEntry {
  __typename: "TimeEntry";
  id: string;
  /**
   * The amount of time that was worked.
   */
  duration: any;
  /**
   * Some notes about the work done. (Optional)
   */
  memo: string | null;
  /**
   * The date the work was done.
   */
  workDoneAt: any | null;
}

export interface ChatMessageSubscription_messageUpdated_bodyTokens_DatetimeObject {
  __typename: "DatetimeObject";
  datetime: any | null;
}

export type ChatMessageSubscription_messageUpdated_bodyTokens = ChatMessageSubscription_messageUpdated_bodyTokens_DurationObject | ChatMessageSubscription_messageUpdated_bodyTokens_StringObject | ChatMessageSubscription_messageUpdated_bodyTokens_DateObject | ChatMessageSubscription_messageUpdated_bodyTokens_DurationRange | ChatMessageSubscription_messageUpdated_bodyTokens_Link | ChatMessageSubscription_messageUpdated_bodyTokens_Card | ChatMessageSubscription_messageUpdated_bodyTokens_User | ChatMessageSubscription_messageUpdated_bodyTokens_File | ChatMessageSubscription_messageUpdated_bodyTokens_TimeEntry | ChatMessageSubscription_messageUpdated_bodyTokens_DatetimeObject;

export interface ChatMessageSubscription_messageUpdated {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
  /**
   * The (optional) client-side id that the client passed in to create this item.
   * Short lived, expires in 60 seconds and is null after that.
   */
  tempId: string | null;
  /**
   * The that sent the messages.
   * Activity-style messages will have this omitted.s
   */
  sender: ChatMessageSubscription_messageUpdated_sender | null;
  /**
   * An array of individual reactions to messages. Multiple users reacting with the same emoji results in distinct records.
   */
  reactions: ChatMessageSubscription_messageUpdated_reactions[];
  /**
   * The time the feed event was posted if it was a message or the time the event was generated.
   */
  createdAt: string | null;
  /**
   * Whether this item has been deleted.
   */
  deleted: boolean | null;
  /**
   * Whether the message was edited. Also applies to other edits like time entries.
   */
  edited: boolean | null;
  /**
   * The feed id this belongs to
   */
  feedId: string | null;
  /**
   * An array of tokens used when rendering a message body.
   * It may look like types of [string, date, file, ...]
   * On the client, you could map over the array and convert each item to a widget that is rendered from left to right.
   */
  bodyTokens: ChatMessageSubscription_messageUpdated_bodyTokens[];
}

export interface ChatMessageSubscription {
  /**
   * A chat was created/updated/deleted
   */
  messageUpdated: ChatMessageSubscription_messageUpdated | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadFile
// ====================================================

export interface UploadFile_authorizeFileUpload {
  __typename: "FileUploadAuthorization";
  /**
   * The id assigned to the file.
   */
  fileId: any | null;
  /**
   * The Presigned URL to where the file should be uploaded.
   */
  presignedUrl: string | null;
}

export interface UploadFile {
  /**
   * Creates a temporary authorization for a user to upload a file
   */
  authorizeFileUpload: UploadFile_authorizeFileUpload | null;
}

export interface UploadFileVariables {
  input: AuthorizeFileUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedAddReactionMutation
// ====================================================

export interface FeedAddReactionMutation {
  /**
   * Add a reaction to a feed event.
   * All feed events within a feed can have reactions. Just like Slack, HipChat, etc.
   */
  addReaction: any | null;
}

export interface FeedAddReactionMutationVariables {
  input: AddReactionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedRemoveReactionMutation
// ====================================================

export interface FeedRemoveReactionMutation {
  /**
   * Remove a reaction from a feed event.
   * All feed events within a feed can have reactions. Just like Slack, HipChat, etc.
   */
  removeReaction: any | null;
}

export interface FeedRemoveReactionMutationVariables {
  input: RemoveReactionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedPostMessageMutation
// ====================================================

export interface FeedPostMessageMutation_feedPostMessage {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
}

export interface FeedPostMessageMutation {
  /**
   * Post a message to a feed. Similar to posting a message in Slack or HipChat.
   */
  feedPostMessage: FeedPostMessageMutation_feedPostMessage | null;
}

export interface FeedPostMessageMutationVariables {
  input: FeedPostMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedDeleteMessageMutation
// ====================================================

export interface FeedDeleteMessageMutation {
  /**
   * Delete a feed event (message) in a feed.
   */
  feedDeleteMessage: any | null;
}

export interface FeedDeleteMessageMutationVariables {
  input: FeedDeleteMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedEditMessageMutation
// ====================================================

export interface FeedEditMessageMutation_feedEditMessage {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
}

export interface FeedEditMessageMutation {
  /**
   * Edit the message body of a feed event
   */
  feedEditMessage: FeedEditMessageMutation_feedEditMessage | null;
}

export interface FeedEditMessageMutationVariables {
  input: FeedEditMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FeedMarkAsReadMutation
// ====================================================

export interface FeedMarkAsReadMutation {
  /**
   * Mark all unread feed events (unread by you) in a feed as read.
   * If all feed events have already been read, this results in a no-op.
   */
  feedMarkAsRead: any | null;
}

export interface FeedMarkAsReadMutationVariables {
  input: FeedMarkAsReadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProcessUploadedFile
// ====================================================

export interface ProcessUploadedFile_processUploadedFile_user {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
}

export interface ProcessUploadedFile_processUploadedFile_meta {
  __typename: "FileMetadata";
  /**
   * Image height.
   */
  height: number | null;
  /**
   * Image width.
   */
  width: number | null;
}

export interface ProcessUploadedFile_processUploadedFile {
  __typename: "File";
  id: string;
  /**
   * The user who uploaded the file.
   */
  user: ProcessUploadedFile_processUploadedFile_user | null;
  /**
   * The original file name.
   */
  name: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
  /**
   * Size of the file in bytes.
   */
  size: number | null;
  /**
   * File metadata (e.g. image size).
   */
  meta: ProcessUploadedFile_processUploadedFile_meta | null;
  /**
   * File's public URL.
   */
  uri: string;
  createdAt: any | null;
}

export interface ProcessUploadedFile {
  /**
   * Performs post upload operations agains a file, making it ready for use.
   * - Moves file from temp to user uploads dir;
   * - Updates the file's metadata;
   * - Updates the file's status to "uploaded".
   */
  processUploadedFile: ProcessUploadedFile_processUploadedFile | null;
}

export interface ProcessUploadedFileVariables {
  input: ProcessUploadedFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestToWorkWithQuery
// ====================================================

export interface RequestToWorkWithQuery_requestToWorkWith {
  __typename: "GroupChat";
  id: string;
  topic: string | null;
  feedId: string | null;
}

export interface RequestToWorkWithQuery {
  /**
   * Request to work with a contractor.
   * This starts a group chat between the customer, contractor, and Turtle.
   */
  requestToWorkWith: RequestToWorkWithQuery_requestToWorkWith | null;
}

export interface RequestToWorkWithQueryVariables {
  input: RequestToWorkWithInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserTimezone
// ====================================================

export interface SetUserTimezone {
  /**
   * Sets a given user's timezone.
   * Will be used by managers and the Turtle platform to help with coordinating meetings/communication.
   */
  setUserTimezone: any | null;
}

export interface SetUserTimezoneVariables {
  input: SetUserTimezoneInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: NotificationsChatSubscription
// ====================================================

export interface NotificationsChatSubscription_chatUpdated_source_Card_workspace {
  __typename: "Workspace";
  id: string;
}

export interface NotificationsChatSubscription_chatUpdated_source_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  completed: boolean;
  /**
   * The workspace to which this card belongs.
   * If null, means this cards doesn't belong to a workspace.
   */
  workspace: NotificationsChatSubscription_chatUpdated_source_Card_workspace | null;
}

export interface NotificationsChatSubscription_chatUpdated_source_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
}

export interface NotificationsChatSubscription_chatUpdated_source_GroupChat {
  __typename: "GroupChat";
  id: string;
  topic: string | null;
}

export interface NotificationsChatSubscription_chatUpdated_source_Feed {
  __typename: "Feed";
  /**
   * Unique identifier for an activity feed.
   * Generated based on what it is attached to like conversation-123-987 or card-556
   */
  feedId: string | null;
  /**
   * Same as card name when feed belongs to a Card.
   * Null when feed is associated to a 1-on-1 conversation.
   */
  feedName: string | null;
}

export type NotificationsChatSubscription_chatUpdated_source = NotificationsChatSubscription_chatUpdated_source_Card | NotificationsChatSubscription_chatUpdated_source_User | NotificationsChatSubscription_chatUpdated_source_GroupChat | NotificationsChatSubscription_chatUpdated_source_Feed;

export interface NotificationsChatSubscription_chatUpdated_feedSubscription {
  __typename: "FeedSubscription";
  id: string;
  /**
   * The number of unread messages in the feed for the user.
   */
  unreadCount: number;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_sender {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DatetimeObject {
  __typename: "DatetimeObject" | "DurationObject" | "Organization" | "Workspace";
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_StringObject {
  __typename: "StringObject";
  string: string | null;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DateObject {
  __typename: "DateObject";
  date: any | null;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DurationRange {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_Link {
  __typename: "Link";
  /**
   * The http: // ... url this goes to
   */
  uri: string;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_File {
  __typename: "File";
  id: string;
  /**
   * The original file name.
   */
  name: string;
  /**
   * File's public URL.
   */
  uri: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
}

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_TimeEntry {
  __typename: "TimeEntry";
  id: string;
  /**
   * The amount of time that was worked.
   */
  duration: any;
}

export type NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens = NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DatetimeObject | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_StringObject | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DateObject | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_DurationRange | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_Link | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_Card | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_User | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_File | NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens_TimeEntry;

export interface NotificationsChatSubscription_chatUpdated_lastFeedEvent {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
  /**
   * The that sent the messages.
   * Activity-style messages will have this omitted.s
   */
  sender: NotificationsChatSubscription_chatUpdated_lastFeedEvent_sender | null;
  /**
   * An array of tokens used when rendering a message body.
   * It may look like types of [string, date, file, ...]
   * On the client, you could map over the array and convert each item to a widget that is rendered from left to right.
   */
  bodyTokens: NotificationsChatSubscription_chatUpdated_lastFeedEvent_bodyTokens[];
  /**
   * The time the feed event was posted if it was a message or the time the event was generated.
   */
  createdAt: string | null;
}

export interface NotificationsChatSubscription_chatUpdated {
  __typename: "Feed";
  /**
   * Unique identifier for an activity feed.
   * Generated based on what it is attached to like conversation-123-987 or card-556
   */
  id: string | null;
  /**
   * The entity the activity feed is attached to.
   * Or it's the card it's attached to.
   * It's either the other user in the conversation if it's a private message.
   */
  source: NotificationsChatSubscription_chatUpdated_source | null;
  /**
   * The current user's subscription of this activity feed.
   */
  feedSubscription: NotificationsChatSubscription_chatUpdated_feedSubscription | null;
  /**
   * The most recent event/message in the activity feed
   */
  lastFeedEvent: NotificationsChatSubscription_chatUpdated_lastFeedEvent | null;
}

export interface NotificationsChatSubscription {
  /**
   * One or more attributes of a chat was updated
   */
  chatUpdated: NotificationsChatSubscription_chatUpdated | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: NotificationsChatSubscriptionUpdated
// ====================================================

export interface NotificationsChatSubscriptionUpdated_chatSubscriptionUpdated {
  __typename: "FeedSubscription";
  id: string;
  /**
   * The user subscribed to the feed
   */
  userId: string;
  /**
   * The feed being subscribed to
   */
  feedId: string;
  /**
   * The number of unread messages in the feed for the user.
   */
  unreadCount: number;
}

export interface NotificationsChatSubscriptionUpdated {
  /**
   * A subscription was updated
   */
  chatSubscriptionUpdated: NotificationsChatSubscriptionUpdated_chatSubscriptionUpdated | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrackingUserData
// ====================================================

export interface GetTrackingUserData_currentUser {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  email: string;
}

export interface GetTrackingUserData {
  currentUser: GetTrackingUserData_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUserData
// ====================================================

export interface GetCurrentUserData_currentUser {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
}

export interface GetCurrentUserData {
  currentUser: GetCurrentUserData_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  email: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  /**
   * The registration status of the user. Can currently be either invited or active.
   */
  status: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardFragment
// ====================================================

export interface CardFragment_estimate {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface CardFragment_budget {
  __typename: "CardBudget";
  /**
   * The max number of hours allocated
   */
  duration: any | null;
}

export interface CardFragment {
  __typename: "Card";
  completed: boolean;
  /**
   * The time when the card was most recently marked completed.
   */
  completedAt: any | null;
  /**
   * The time the card was reated.
   */
  createdAt: any;
  /**
   * Whether the card is marked deleted or not. If a card is deleted, its children are also marked deleted.
   */
  deleted: boolean;
  dueDate: any | null;
  /**
   * Estimate for how long a developer this this task will take. For example 10-15h.
   * Does not include estimates of child cards.
   */
  estimate: CardFragment_estimate | null;
  /**
   * The budget specific to this card.
   * For example a customer may set a 150h budget for a feature.
   * Does not include budgets of child cards. For reporting, aggregations must be done on the client.
   */
  budget: CardFragment_budget | null;
  /**
   * The associated feed id for this card. Every card has an activity feed.
   */
  feedId: string;
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  /**
   * The id of the user this card is assigned to.
   */
  assigneeId: string | null;
  /**
   * The id of the parent card. The root project card will not have this set.
   */
  parentId: string | null;
  /**
   * The total time tracked with time entries this card. Does not include time tracked on children.
   */
  timeTracked: any | null;
  /**
   * An float representing the order of this card with respect to its siblings.
   * Frontend should sort children of a card by this propertly. There may be spaces
   * and fractional numbers like [1, 1.25, 2, 10, 15]
   */
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChatEventFragment
// ====================================================

export interface ChatEventFragment_sender {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatEventFragment_reactions_user {
  __typename: "User";
  id: string;
}

export interface ChatEventFragment_reactions {
  __typename: "Reaction";
  /**
   * The emoji of the reaction like "🐢".
   */
  emoji: string;
  /**
   * The user that send the reaction
   */
  user: ChatEventFragment_reactions_user;
}

export interface ChatEventFragment_bodyTokens_DurationObject {
  __typename: "DurationObject" | "Organization" | "Workspace";
}

export interface ChatEventFragment_bodyTokens_StringObject {
  __typename: "StringObject";
  string: string | null;
}

export interface ChatEventFragment_bodyTokens_DateObject {
  __typename: "DateObject";
  dateObj: any | null;
}

export interface ChatEventFragment_bodyTokens_DurationRange {
  __typename: "DurationRange";
  /**
   * The lower bound of the range
   */
  min: any;
  /**
   * The upper bound of the range. If max is the same as min, then it represents a single value like 2h.
   */
  max: any;
}

export interface ChatEventFragment_bodyTokens_Link {
  __typename: "Link";
  /**
   * The http: // ... url this goes to
   */
  uri: string;
}

export interface ChatEventFragment_bodyTokens_Card {
  __typename: "Card";
  id: string;
  /**
   * The title of the card. This is what is usually rendered in a task list.
   */
  name: string;
  completed: boolean;
}

export interface ChatEventFragment_bodyTokens_User {
  __typename: "User";
  id: string;
  /**
   * The full name of the user.
   * Will be taken from their Google account on initial login.
   * Can be overridden in their profile.
   */
  name: string;
  /**
   * The profile pic photo of the user.
   * Defaults to 3rd party services but can also be replaced via SetUserProfilePhoto.
   */
  photo: string | null;
  email: string;
}

export interface ChatEventFragment_bodyTokens_File {
  __typename: "File";
  id: string;
  /**
   * The original file name.
   */
  name: string;
  /**
   * File's public URL.
   */
  uri: string;
  /**
   * The mime content type.
   */
  mimetype: string | null;
  /**
   * Size of the file in bytes.
   */
  size: number | null;
}

export interface ChatEventFragment_bodyTokens_TimeEntry {
  __typename: "TimeEntry";
  id: string;
  /**
   * The amount of time that was worked.
   */
  duration: any;
  /**
   * Some notes about the work done. (Optional)
   */
  memo: string | null;
  /**
   * The date the work was done.
   */
  workDoneAt: any | null;
}

export interface ChatEventFragment_bodyTokens_DatetimeObject {
  __typename: "DatetimeObject";
  datetime: any | null;
}

export type ChatEventFragment_bodyTokens = ChatEventFragment_bodyTokens_DurationObject | ChatEventFragment_bodyTokens_StringObject | ChatEventFragment_bodyTokens_DateObject | ChatEventFragment_bodyTokens_DurationRange | ChatEventFragment_bodyTokens_Link | ChatEventFragment_bodyTokens_Card | ChatEventFragment_bodyTokens_User | ChatEventFragment_bodyTokens_File | ChatEventFragment_bodyTokens_TimeEntry | ChatEventFragment_bodyTokens_DatetimeObject;

export interface ChatEventFragment {
  __typename: "FeedEvent";
  /**
   * Unique identifier of the feed event. It is time-ordered so sorting by ids results in order of creation.
   */
  id: string | null;
  /**
   * The (optional) client-side id that the client passed in to create this item.
   * Short lived, expires in 60 seconds and is null after that.
   */
  tempId: string | null;
  /**
   * The that sent the messages.
   * Activity-style messages will have this omitted.s
   */
  sender: ChatEventFragment_sender | null;
  /**
   * An array of individual reactions to messages. Multiple users reacting with the same emoji results in distinct records.
   */
  reactions: ChatEventFragment_reactions[];
  /**
   * The time the feed event was posted if it was a message or the time the event was generated.
   */
  createdAt: string | null;
  /**
   * Whether this item has been deleted.
   */
  deleted: boolean | null;
  /**
   * Whether the message was edited. Also applies to other edits like time entries.
   */
  edited: boolean | null;
  /**
   * The feed id this belongs to
   */
  feedId: string | null;
  /**
   * An array of tokens used when rendering a message body.
   * It may look like types of [string, date, file, ...]
   * On the client, you could map over the array and convert each item to a widget that is rendered from left to right.
   */
  bodyTokens: ChatEventFragment_bodyTokens[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthProvider {
  APPLE = "APPLE",
  EMAIL = "EMAIL",
  GOOGLE = "GOOGLE",
}

export enum MoveRelation {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
  FIRST_CHILD = "FIRST_CHILD",
  LAST_CHILD = "LAST_CHILD",
}

export interface AddReactionInput {
  emoji: string;
  feedEventId: any;
}

export interface AuthorizeFileUploadInput {
  filename: string;
}

export interface CardAssignInput {
  assigneeId: any;
  cardId: any;
}

export interface CardBudgetDecreaseInput {
  budgetDuration: any;
  cardId: any;
}

export interface CardBudgetIncreaseInput {
  budgetDuration: any;
  cardId: any;
}

export interface CardBudgetUnsetInput {
  cardId: any;
}

export interface CardCompleteInput {
  cardId: any;
}

export interface CardCreateInput {
  afterId?: any | null;
  assigneeId?: any | null;
  beforeId?: any | null;
  cardId?: any | null;
  cardName: string;
  meta?: any | null;
  parentId: any;
  quickAdd?: boolean | null;
  tempId?: any | null;
}

export interface CardDeleteInput {
  cardId: any;
}

export interface CardDueDateSetInput {
  cardId: any;
  dueDate: any;
}

export interface CardDueDateUnsetInput {
  cardId: any;
}

export interface CardEstimateSetInput {
  cardId: any;
  estimate: string;
}

export interface CardEstimateUnsetInput {
  cardId: any;
}

export interface CardInviteUserInput {
  cardId: any;
  email: any;
}

export interface CardMoveInput {
  cardId: any;
  relation: MoveRelation;
  targetId: any;
}

export interface CardRenameInput {
  cardId: any;
  cardName: string;
}

export interface CardUnassignInput {
  cardId: any;
}

export interface CardUncompleteInput {
  cardId: any;
}

export interface CardUndeleteInput {
  cardId: any;
}

export interface FeedDeleteMessageInput {
  feedEventId: any;
}

export interface FeedEditMessageInput {
  feedEventId: any;
  messageBody: string;
}

export interface FeedMarkAsReadInput {
  feedId: any;
}

export interface FeedPostMessageInput {
  feedEventId?: any | null;
  feedId: any;
  messageBody: string;
  tags?: string[] | null;
}

export interface ProcessUploadedFileInput {
  fileId?: any | null;
}

export interface RemoveReactionInput {
  emoji: string;
  feedEventId: any;
}

export interface RequestToWorkWithInput {
  contractorId: any;
  ref?: string | null;
}

export interface SetUserTimezoneInput {
  source: string;
  timezone: any;
  userId?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
