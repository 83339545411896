import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'


export const INITIAL_STATE = videoReducer.INITIAL_STATE = Map({
  activeRoom: null,
  dominantSpeaker: null,
  fullScreenActive: false,
  joiningCall: false,
  localAudioTrack: null,
  localNetworkQualityLevel: 1,
  localNetworkQualityStats: null,
  localVideoTrack: null,
  participantSharingScreen: null,
  remoteParticipants: null,
  reconnecting: false,
  screenLocalTrack: null,
  screenRemoteTrack: null,
  token: null
})

export default function videoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.JOINING_MEETING_ROOM: {
      return state.update('joiningCall', flag => !flag)
    }
    case ActionTypes.VIDEO_CONNECTED: {
      return state.update('joiningCall', flag => !flag)
    }
    case ActionTypes.TOGGLE_FULL_SCREEN: {
      return state.update('fullScreenActive', flag => !flag)
    }
    case ActionTypes.ADD_LOCAL_AUDIO_TRACK: {
      return state.update('localAudioTrack', () => action.tracks)
    }
    case ActionTypes.REMOVE_LOCAL_AUDIO_TRACK: {
      return state.update('localAudioTrack', () => null)
    }
    case ActionTypes.ADD_LOCAL_VIDEO_TRACK: {
      return state.update('localVideoTrack', () => action.tracks)
    }
    case ActionTypes.REMOVE_LOCAL_VIDEO_TRACK: {
      return state.update('localVideoTrack', () => null)
    }
    case ActionTypes.SHARE_SCREEN: {
      return state.update('screenLocalTrack', () => action.tracks)
    }
    case ActionTypes.STOP_SHARE_SCREEN: {
      return state.update('screenLocalTrack', () => null)
    }
    case ActionTypes.SET_ACTIVE_ROOM: {
      return state.update('activeRoom', () => action.activeRoom)
    }
    case ActionTypes.REMOVE_ACTIVE_ROOM: {
      return state.update('activeRoom', () => null)
    }
    case ActionTypes.MEETING_ROOM_JOINED: {
      return state.update('token', () => action.token)
    }
    case ActionTypes.MEETING_ROOM_LEFT: {
      return state.update('token', () => null)
    }
    case ActionTypes.UPDATE_DOMINANT_SPEAKER: {
      return state.update('dominantSpeaker', () => action.participant)
    }
    case ActionTypes.REMOVE_DOMINANT_SPEAKER: {
      return state.update('dominantSpeaker', () => null)
    }
    case ActionTypes.UPDATE_LOCAL_NETWORK_QUALITY_INFO: {
      return state.update('localNetworkQualityLevel', () => action.localNetworkQualityLevel)
                  .update('localNetworkQualityStats', () => action.localNetworkQualityStats)
    }
    case ActionTypes.REMOVE_LOCAL_NETWORK_QUALITY_INFO: {
      return state.update('localNetworkQualityLevel', () => 1)
                  .update('localNetworkQualityStats', () => null)
    }
    case ActionTypes.ADD_REMOTE_TRACK: {
      return state.update('remoteParticipants', (remoteParticipants) => {
        if (remoteParticipants === null) {
          if (action.track.kind === 'video') {
            return[{id: action.participant, audioTrack: null, videoTrack: action.track}]
          } else {
            return[{id: action.participant, audioTrack: action.track, videoTrack: null}]
          }
        }

        return remoteParticipants.map((participant) => {
          if (participant.id === action.participant) {
            if (action.track.kind === 'video') {
              participant.videoTrack = action.track
            } else {
              participant.audioTrack = action.track
            }
          }
          return participant
        })
      })
    }
    case ActionTypes.REMOVE_REMOTE_TRACK: {
      return state.update('remoteParticipants', (remoteParticipants) => {
        return remoteParticipants.map((participant) => {
          if (participant.id === action.participant) {
            if (action.track.kind === 'video') {
              participant.videoTrack = null
            } else {
              participant.audioTrack = null
            }
          }
          return participant
        })
      })
    }
    case ActionTypes.ADD_REMOTE_SCREEN_TRACK: {
      return state.update('screenRemoteTrack', () => action.track)
                  .update('participantSharingScreen', () => action.participant)
    }
    case ActionTypes.REMOVE_REMOTE_SCREEN_TRACK: {
      return state.update('screenRemoteTrack', () => null)
                  .update('participantSharingScreen', () => null)
    }
    case ActionTypes.ADD_REMOTE_PARTICIPANT: {
      return state.update('remoteParticipants', (remoteParticipants) => {
        if (remoteParticipants === null) {
          return [{id: action.participant, audioTrack: null, videoTrack: null}]
        } else {
          return [...remoteParticipants, {id: action.participant, audioTrack: null, videoTrack: null}]
        }
      })
    }
    case ActionTypes.REMOVE_REMOTE_PARTICIPANT: {
      return state.update('remoteParticipants', (remoteParticipants) => {
        let filteredParticipants
        if (remoteParticipants) {
          filteredParticipants = remoteParticipants.filter(el => action.participant !== el.id)
        }
        return filteredParticipants ? [...filteredParticipants] : []
      })
    }
    case ActionTypes.REMOVE_ALL_REMOTE_PARTICIPANTS: {
      return state.update('remoteParticipants', () => null)
    }
    case ActionTypes.TRYING_TO_RECONNECT_TO_CALL: {
      return state.update('reconnecting', () => true)
    }
    case ActionTypes.RECONNECTED_TO_CALL: {
      return state.update('reconnecting', () => false)
    }
  }
  return state
}
