import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'

export const ESCAPE_PRESSED = 'ESCAPE_PRESSED'
export const CLICKED_OUTSIDE = 'CLICKED_OUTSIDE'

export default class PopupWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onDismiss: PropTypes.func,
    overlayClassName: PropTypes.string,
  }

  constructor() {
    super()
    this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this)
    this.dismiss = this.dismiss.bind(this)
  }

  componentDidMount() {
    document.body.className += ' no-scroll'
    window.addEventListener('keydown', this.onDocumentKeyDown)
  }

  componentWillUnmount() {
    document.body.className = document.body.className
      .split(/\s+/)
      .filter(c => c !== 'no-scroll')
      .join(' ')
    window.removeEventListener('keydown', this.onDocumentKeyDown)
  }

  render() {
    return (
      <div className="popup-wrapper">
        <div
          className={cn('popup-overlay', this.props.overlayClassName)}
          onClick={() => this.dismiss(CLICKED_OUTSIDE)}
        />
        <div className="popup-content">{this.props.children}</div>
      </div>
    )
  }

  onDocumentKeyDown(e) {
    if (e.key === 'Escape') {
      this.dismiss(ESCAPE_PRESSED)
    }
  }

  dismiss(dismissReason) {
    this.props.onDismiss && this.props.onDismiss(dismissReason)
  }
}
