import React, { useMemo, useRef, useState } from 'react'
import {
  GetChatData_feed_feedEvents,
  GetChatData_feed_feedEvents_bodyTokens,
} from '~/types/api'
import { isMessageOptimistic } from './util/helpers'
import { formatDuration } from '~/utils/time'
import { formatInterval } from '~/utils/duration'
import dayjs from '~/utils/dayjs'
import { HoverMenu } from './hover-menu'
import cn from 'classnames'

type DateSeparatorProps = {
  date: string
  theme?: string
}

type ServiceMessageProps = {
  onUserClick: (
    userId: string | null | undefined,
    sourceRef: React.RefObject<HTMLDivElement>
  ) => void
  onCardClick: (cardId: string) => void
  onEditMessage: (
    feedEventId: string,
    bodyTokens: (GetChatData_feed_feedEvents_bodyTokens | null)[] | null
  ) => void
  onDeleteMessage: (feedEventId: string, timeEntryId?: string | null) => void
  message: GetChatData_feed_feedEvents | null
  currentUserId: string
  theme?: string
}

export const DateSeparator = ({ date, theme }: DateSeparatorProps) => (
  <div className={cn('date-separator', { 'theme-dark': theme === 'dark' })}>
    <span>{dayjs(date).format('MMM D')}</span>
  </div>
)

export const ServiceMessage = ({
  message,
  theme,
  ...props
}: ServiceMessageProps) => {
  if (!message) {
    return null
  }
  const [timeEntryId, setTimeEntryId] = useState<string | null>(null)
  const messageRef = useRef<HTMLDivElement>(null)
  const messageTime = dayjs(message.createdAt).format('h:mm a')

  const messageActorId = useMemo(() => {
    if (
      message.bodyTokens?.length &&
      message.bodyTokens[0]?.__typename === 'User'
    ) {
      return message.bodyTokens[0].id
    }
    return null
  }, [message.bodyTokens])

  const tokenizedMessage = useMemo(() => {
    const result = message.bodyTokens?.map((token, index) => {
      if (!token) {
        return null
      }
      switch (token.__typename) {
        case 'StringObject':
          return <span key={index}>{token.string}</span>
        case 'DateObject':
          return <b key={index}>{dayjs(token.dateObj).format('M/DD/YYYY')}</b>
        case 'DurationObject':
          return null
        // return <b key={index}>{formatDuration(token.duration)}</b>
        case 'DurationRange':
          return (
            <span key={index}>
              {formatInterval(token.min, token.max, false)}
            </span>
          )
        case 'User':
          return (
            <span
              className="mention"
              key={index}
              onClick={() => props.onUserClick(token.id, messageRef)}
            >
              {token.name}
            </span>
          )
        case 'Card':
          return (
            <a
              href="#"
              onClick={() => props.onCardClick(token.id!)}
              key={index}
            >
              {' ' + token.name}
            </a>
          )
        case 'TimeEntry':
          const createdAt = dayjs(token.workDoneAt).format('MMM D')
          setTimeEntryId(token.id)
          return (
            <React.Fragment key={index}>
              <span>time</span>
              <div className="new-time-entry">
                <div className="duration">
                  <div className="timestamp">
                    {formatDuration(token.duration)}
                  </div>
                  <div>{createdAt}</div>
                </div>
                {token.memo?.length ? (
                  <div className="memo">{token.memo}</div>
                ) : null}
              </div>
            </React.Fragment>
          )
        case 'Link':
          return (
            <a key={index} href={token.uri!} target="blank">
              {token.uri}
            </a>
          )
        case 'File':
          return (
            <a key={index} href={token.uri!} target="blank">
              {token.name}
            </a>
          )
        case 'DatetimeObject':
          return <span key={index}>{token.datetime}</span>
        default:
          return null
      }
    })
    return result
  }, [message.bodyTokens])
  return (
    <div
      className={cn('service-message', {
        'with-time-entry': !!timeEntryId,
        optimistic: isMessageOptimistic(message.id),
        'self-posted': messageActorId === props.currentUserId,
        'theme-dark': theme === 'dark',
      })}
      ref={messageRef}
    >
      <span className="body">{tokenizedMessage}</span>
      <span className="time">{messageTime}</span>
      {!isMessageOptimistic(message.id) && timeEntryId ? (
        <HoverMenu
          onThumbClick={() => {}}
          onReactionClick={() => {}}
          onDeleteClick={() => props.onDeleteMessage(message.id!, timeEntryId)}
          onEditClick={() =>
            props.onEditMessage(message.id!, message.bodyTokens)
          }
          isSendersMessage={messageActorId === props.currentUserId}
          isMessageLiked={false}
          isEditable
          hideReactions
          theme={theme}
        />
      ) : null}
    </div>
  )
}
