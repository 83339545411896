import React from 'react'
import cn from 'classnames'

type Props = {
  isSendersMessage: boolean
  isMessageLiked: boolean
  isEditable: boolean
  hideReactions?: boolean
  theme?: string
  onThumbClick: () => void
  onReactionClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
}

export const HoverMenu = (p: Props) => {
  return (
    <div className={cn('hover-menu', { 'theme-dark': p.theme === 'dark' })}>
      {!p.hideReactions ? (
        <>
          <span
            className={cn('action', 'thumb', { liked: p.isMessageLiked })}
            onClick={p.onThumbClick}
          />
          <span className="spacer" />{' '}
          <span
            className={cn('action', 'emoji-reaction')}
            onClick={p.onReactionClick}
          />
        </>
      ) : null}

      {p.isSendersMessage ? (
        <>
          {p.isEditable ? (
            <>
              <span className="spacer" />
              <span className={cn('action', 'edit')} onClick={p.onEditClick} />
            </>
          ) : null}
          <span className="spacer" />
          <span className={cn('action', 'delete')} onClick={p.onDeleteClick} />
        </>
      ) : null}
    </div>
  )
}
