import { all, take, call, fork, select } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import sel from '~/selectors'

import { canCardBeShown } from '~/utils/cards'

import store from '~/utils/session-storage'
import dayjs from '~/utils/dayjs'

export default function* $cardsViewStatePersistenceSaga() {
  yield all([fork($storeSaga), fork($restoreSaga)])
}

function* $storeSaga() {
  while (true) {
    const { cardId } = yield take(ActionTypes.SET_CARD_EXPANDED)

    const [cardsViewState, projectId] = yield all([
      select(sel.cardsViewState),
      select(sel.selectedProjectId),
    ])

    const expandState = cardsViewState.get(cardId).get('isExpanded').toJS()
    const lsKey = 'e-' + cardId

    if (Object.keys(expandState).length) {
      store.setItem(lsKey, JSON.stringify({ projectId, expandState }))
    } else {
      store.removeItem(lsKey)
    }
  }
}

function* $restoreSaga() {
  const cardsExpandState = yield call(restoreCardsExpandState)
  if (cardsExpandState.length) {
    yield* $dispatch(Actions.restoreCardsExpandState(cardsExpandState))
    yield* $cleanupCardsExpandState(cardsExpandState)
  }
}

function* $cleanupCardsExpandState(cardsExpandState) {
  let projectId = yield select(sel.selectedProjectId)
  if (projectId == null) {
    yield take(ActionTypes.SELECT_PROJECT_SUCCESS)
    projectId = yield select(sel.selectedProjectId)
  }
  const cards = yield select(sel.cards)
  yield call(cleanupCardsExpandState, projectId, cards, cardsExpandState)
}

function restoreCardsExpandState() {
  const lsKeys = store.getKeys()
  const cardsExpandState = []

  for (let i = 0; i < lsKeys.length; ++i) {
    const key = lsKeys[i]
    if (key[0] == 'e' && key[1] == '-') {
      const { projectId, expandState } = JSON.parse(store.getItem(key) || '{}')
      cardsExpandState.push({ cardId: key.substr(2), expandState, projectId })
    }
  }

  return cardsExpandState
}

function cleanupCardsExpandState(projectId, cards, cardsExpandState) {
  const keysToClear = []
  const monthAgo = dayjs().subtract(1, 'M')

  for (let i = 0; i < cardsExpandState.length; ++i) {
    const entry = cardsExpandState[i]
    if (entry.projectId == projectId) {
      const { cardId } = entry
      if (!canCardBeShown(cardId, cards, monthAgo)) {
        keysToClear.push('e-' + cardId)
      }
    }
  }

  keysToClear.forEach(key => store.removeItem(key))
}
