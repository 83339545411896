import { gql } from '@apollo/client'

const ChatEventFragment = gql`
  fragment ChatEventFragment on FeedEvent {
    id
    tempId
    sender {
      id
      name
      photo
      email
    }
    reactions {
      emoji
      user {
        id
      }
    }
    createdAt
    deleted
    edited
    feedId
    bodyTokens {
      __typename
      ... on StringObject {
        string
      }
      ... on DateObject {
        dateObj: date
      }
      ... on DurationRange {
        min
        max
      }
      ... on Link {
        uri
      }
      ... on Card {
        id
        name
        completed
      }
      ... on User {
        id
        name
        photo
        email
        # profile {
        #   timezone
        # }
      }
      ... on File {
        id
        name
        uri
        mimetype
        size
      }
      ... on TimeEntry {
        id
        duration
        memo
        workDoneAt
      }
      ... on DatetimeObject {
        datetime
      }
    }
  }
`

export const GetChatData = gql`
  query GetChatData($chatId: FeedId, $offset: Int, $limit: Int) {
    currentUser {
      id
      name
      photo
    }
    feed(id: $chatId) {
      id
      name
      members {
        id
        name
        photo
      }
      version
      source {
        ... on Card {
          id
          name
          workspace {
            id
          }
        }
        ... on User {
          id
          name
        }
        ... on Feed {
          feedId: id
          feedName: name
        }
        ... on GroupChat {
          id
          topic
        }
      }
      feedEvents(offset: $offset, limit: $limit) {
        ...ChatEventFragment
      }
    }
  }
  ${ChatEventFragment}
`

export const ChatSubscription = gql`
  subscription ChatSubscription($limit: Int) {
    chatUpdated {
      id
      name
      members {
        id
        name
        photo
      }
      version
      feedEvents(limit: $limit) {
        ...ChatEventFragment
      }
    }
  }
  ${ChatEventFragment}
`

export const ChatMessageSubscription = gql`
  subscription ChatMessageSubscription {
    messageUpdated {
      ...ChatEventFragment
    }
  }
  ${ChatEventFragment}
`

export const UploadFile = gql`
  mutation UploadFile($input: AuthorizeFileUploadInput!) {
    authorizeFileUpload(input: $input) {
      fileId
      presignedUrl
    }
  }
`

export const FeedAddReactionMutation = gql`
  mutation FeedAddReactionMutation($input: AddReactionInput!) {
    addReaction(input: $input)
  }
`

export const FeedRemoveReactionMutation = gql`
  mutation FeedRemoveReactionMutation($input: RemoveReactionInput!) {
    removeReaction(input: $input)
  }
`

export const FeedPostMessageMutation = gql`
  mutation FeedPostMessageMutation($input: FeedPostMessageInput!) {
    feedPostMessage(input: $input) {
      id
    }
  }
`

export const FeedDeleteMessageMutation = gql`
  mutation FeedDeleteMessageMutation($input: FeedDeleteMessageInput!) {
    feedDeleteMessage(input: $input)
  }
`

export const FeedEditMessageMutation = gql`
  mutation FeedEditMessageMutation($input: FeedEditMessageInput!) {
    feedEditMessage(input: $input) {
      id
    }
  }
`

export const FeedMarkAsReadMutation = gql`
  mutation FeedMarkAsReadMutation($input: FeedMarkAsReadInput!) {
    feedMarkAsRead(input: $input)
  }
`

export const ProcessUploadedFile = gql`
  mutation ProcessUploadedFile($input: ProcessUploadedFileInput!) {
    processUploadedFile(input: $input) {
      id
      user {
        id
        name
      }
      name
      mimetype
      size
      meta {
        height
        width
      }
      uri
      createdAt
    }
  }
`
