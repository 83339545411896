import Immutable, {fromJS} from 'immutable'

import * as ActionTypes from '~/actions/types'

import {Filters, FilterValues} from '~/utils/filter-constants'

import {ROUTE_PLAN, ROUTE_MY_TASKS, ROUTE_TEAM_WEEK} from '~/utils/routing'
const ROUTE_OTHER = 'other'



const DEFAULT_FILTERS_BY_ROUTE = fromJS({
  [ROUTE_PLAN]: {
    [Filters.SHOW_COMPLETED]: FilterValues.ShowCompleted.SHOW_RECENTLY_COMPLETED,
    [Filters.TIME]: null,
    [Filters.ASSIGNEE]: null
  },
  [ROUTE_MY_TASKS]: {
    [Filters.SHOW_COMPLETED]: FilterValues.ShowCompleted.SHOW_RECENTLY_COMPLETED,
    [Filters.TIME]: null,
    [Filters.ASSIGNEE]: FilterValues.Assignee.ME
  },
  [ROUTE_TEAM_WEEK]: {
    [Filters.SHOW_COMPLETED]: FilterValues.ShowCompleted.SHOW_ALL_COMPLETED,
    [Filters.TIME]: FilterValues.Time.ALL,
    [Filters.ASSIGNEE]: null
  },
  [ROUTE_OTHER]: {}
})


const INITIAL_STATE = filtersReducer.INITIAL_STATE = fromJS({
  byRoute: DEFAULT_FILTERS_BY_ROUTE,
  current: DEFAULT_FILTERS_BY_ROUTE.get(ROUTE_PLAN),
  usingDefaultFilters: true
})


export default function filtersReducer(state = INITIAL_STATE, action, boundSel){
  switch (action.type) {
    case ActionTypes.SET_FILTER: {
      return updateFilters(state, fixRouteType(boundSel.selectRouteType()), {
        [action.filter]: action.value
      })
    }
    case ActionTypes.SELECT_PROJECT:
    case ActionTypes.RESET_ALL_FILTERS: {
      const routeType = fixRouteType(boundSel.selectRouteType())
      return updateFilters(state, routeType, DEFAULT_FILTERS_BY_ROUTE.get(routeType))
    }
    case ActionTypes.ROUTE_CHANGED: {
      const routeType = fixRouteType(boundSel.selectRouteType())
      if (fixRouteType(boundSel.selectPreviousRouteType()) == routeType) {
        return state
      }
      const filters = state.get('byRoute').get(routeType)
      return state.merge({
        current: filters,
        usingDefaultFilters: Immutable.is(filters, DEFAULT_FILTERS_BY_ROUTE.get(routeType))
      })
    }
  }
  return state
}


function updateFilters(state, routeType, newFilters) {
  const filtersByRoute = state.get('byRoute')
  const filters = filtersByRoute.get(routeType).merge(newFilters)
  return state.withMutations(state => {
    state.set('byRoute', filtersByRoute.set(routeType, filters))
    state.set('current', filters)
    state.set('usingDefaultFilters', Immutable.is(filters, DEFAULT_FILTERS_BY_ROUTE.get(routeType)))
  })
}


function fixRouteType(routeType) {
  return DEFAULT_FILTERS_BY_ROUTE.get(routeType) ? routeType : ROUTE_OTHER
}
