import React, { useState } from 'react'

interface DroppableContainerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode | React.ReactNodeArray
  setDraggedOver: (draggedStatus: boolean) => void
  handleFileDrop: (fileToUpload: File) => void
  allowAttachments?: boolean
}

const DroppableContainer: React.FC<DroppableContainerProps> = ({
  children,
  setDraggedOver,
  handleFileDrop,
  allowAttachments,
  ...otherProps
}) => {
  // Used as a workaround to prevent multiple onDragEnter events when user drags to child element
  const [dragCounter, setDragCounter] = useState<number>(0)

  const handleDragEnter = () => {
    if (dragCounter === 0) {
      setDraggedOver(true)
    }

    setDragCounter(dragCounter + 1)
  }

  const handleDragLeave = () => {
    setDragCounter(dragCounter - 1)

    if (dragCounter - 1 === 0) {
      setDraggedOver(false)
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDraggedOver(false)
    setDragCounter(0)

    const files = event.dataTransfer.files

    if (files.length > 0) {
      const firstFile = files[0]
      handleFileDrop(firstFile)
    }
  }

  if (allowAttachments) {
    return (
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={e => e.preventDefault()}
        onDrop={handleDrop}
        {...otherProps}
      >
        {children}
      </div>
    )
  } else {
    return <div {...otherProps}>{children}</div>
  }
}

export default DroppableContainer
