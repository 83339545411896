import PropTypes from 'prop-types'
import React from 'react'
import connect from '../utils/connect'
import cn from 'classnames'

import {ROUTE_MY_TASKS} from '~/utils/routing'
import {Filters, FilterValues} from '~/utils/filter-constants'
import sel from '~/selectors'
import {stopPropagation} from '~/utils/dom'

import PopupWrapper from '../popup-wrapper'
import SelectItems from '../select-items'
import Tooltip from '../tooltip'


const EMPTY_OBJECT = {}

const SHOW_COMPLETED_FILTER_OPTIONS = [
  {title: 'past 7 days', value: FilterValues.ShowCompleted.SHOW_RECENTLY_COMPLETED},
  {title: 'show', value: FilterValues.ShowCompleted.SHOW_ALL_COMPLETED},
  {title: 'hide', value: FilterValues.ShowCompleted.SHOW_INCOMPLETE}
]

const TIME_FILTER_OPTIONS = [
  {title: 'all', value: null},
  {title: 'next 7 days', value: FilterValues.Time.NEXT_WEEK},
  {title: 'past 7 days', value: FilterValues.Time.PREV_WEEK},
]


export class CardListHeaderFilters extends React.Component {

  static mapStateToProps(state) {
    const filters = sel.filters(state)
    return {
      showCompletedFilterValue: filters.get(Filters.SHOW_COMPLETED),
      timeFilterValue: filters.get(Filters.TIME),
      assigneeFilterValue: filters.get(Filters.ASSIGNEE),
      usingDefaultFilters: sel.usingDefaultFilters(state),
      projectMembers: sel.selectedProjectMembers(state),
      myUserId: sel.userId(state),
      routeType: sel.routeType(state)
    }
  }

  constructor() {
    super()
    this.state = {
      completionStateFilterOptionIndex: 1,
      timeFilterOptionIndex: 0,
      assigneeFilterOptions: [{title: 'Off', userId: null}],
      assigneeFilterOptionIndex: 0,
      showingPopup: false
    }
    this.onShowCompletedFilterSelected = this.onShowCompletedFilterSelected.bind(this)
    this.onTimeFilterSelected = this.onTimeFilterSelected.bind(this)
    this.onAssigneeFilterSelected = this.onAssigneeFilterSelected.bind(this)
    this.onShowingPopup = this.onShowingPopup.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
  }

  render() {    
    const filtersContainerClasses = cn('filters-container',
      this.props.usingDefaultFilters && '-default',
      this.state.showingPopup && '-showing-popup')
    return (
      <div className='header-filters'>
        <div className={filtersContainerClasses}>
          {this.renderAssigneeFilter()}
          {this.renderTimeFilter()}
          {this.renderCompletionStateFilter()}
        </div>
        {!this.props.usingDefaultFilters && (
          <div className='btn-reset' onClick={this.resetFilters}>Reset filters</div>
        )}
      </div>
    )
  }

  renderCompletionStateFilter() {
    const selectedOptionIndex = this.state.completionStateFilterOptionIndex
    const selectedOption = SHOW_COMPLETED_FILTER_OPTIONS[selectedOptionIndex]
    const options = SHOW_COMPLETED_FILTER_OPTIONS.map(option => {
      const className = cn('option', option === selectedOption && 'selected')
      const title = capitalizeFirstLetter(option.title)
      return <div key={option.value} className={className}>{title}</div>
    })
    const selectedTimeFilterOptionIndex = this.state.timeFilterOptionIndex
    const selectedTimeFilterOption = TIME_FILTER_OPTIONS[selectedTimeFilterOptionIndex]
    const enabled = selectedTimeFilterOption.title === 'all'
    return (
      <CardListHeaderFilter className='filter-show-completed'
          title={'Completed: ' + selectedOption.title}
          onItemSelected={this.onShowCompletedFilterSelected}
          initialHighlightedIndex={selectedOptionIndex}
          onShowingPopup={this.onShowingPopup}
          hideOnSelected={true}
          enabled={enabled}>
        {options}
      </CardListHeaderFilter>
    )
  }

  renderTimeFilter() {
    const selectedOptionIndex = this.state.timeFilterOptionIndex
    const selectedOption = TIME_FILTER_OPTIONS[selectedOptionIndex]
    const options = TIME_FILTER_OPTIONS.map((option, i) => {
      const className = cn('option', option === selectedOption && 'selected', i === 0 && 'off')
      const title = capitalizeFirstLetter(option.title)
      return <div key={option.value} className={className}>{title}</div>
    })
    return (
      <CardListHeaderFilter className='filter-time'
          title={'Time: ' + selectedOption.title}
          onItemSelected={this.onTimeFilterSelected}
          initialHighlightedIndex={selectedOptionIndex}
          onShowingPopup={this.onShowingPopup}
          hideOnSelected={true}>
        {options}
      </CardListHeaderFilter>
    )
  }

  renderAssigneeFilter() {
    const {assigneeFilterOptions, assigneeFilterOptionIndex} = this.state
    const selectedOption = assigneeFilterOptions[assigneeFilterOptionIndex]
    const optionEls = assigneeFilterOptions.map((option, i) => {
      const className = cn('option', option === selectedOption && 'selected', i === 0 && 'off')
      return <div key={option.userId} className={className}>{option.title}</div>
    })
    const [enabled, tooltipText] = this.props.routeType === ROUTE_MY_TASKS
      ? [false, 'You can filter by assignee in All Tasks view']
      : [true, null]
    return (
      <CardListHeaderFilter className='filter-assignee'
          title={'Assignee: ' + selectedOption.title}
          onItemSelected={this.onAssigneeFilterSelected}
          initialHighlightedIndex={assigneeFilterOptionIndex}
          onShowingPopup={this.onShowingPopup}
          hideOnSelected={true}
          enabled={enabled}
          tooltipText={tooltipText}>
        {optionEls}
      </CardListHeaderFilter>
    )
  }

  componentDidMount() {
    this.updateFilters(this.props, EMPTY_OBJECT)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.assigneeFilterValue === undefined ||
        nextProps.showCompletedFilterValue === undefined ||
        nextProps.timeFilterValue === undefined) {
      return false
    }
    if (nextProps === this.props && nextState === this.state) {
      return false
    } else {
      return true
    }
  }

  componentDidUpdate(prevProps) {
    this.updateFilters(this.props, prevProps)
  }

  updateFilters(newProps, prevProps) {
    this.updateShowCompletedFilter(newProps, prevProps)
    this.updateTimeFilter(newProps, prevProps)
    this.updateAssigneeFilter(newProps, prevProps)
  }

  updateShowCompletedFilter(newProps, prevProps) {
    const {showCompletedFilterValue} = newProps
    if (showCompletedFilterValue !== prevProps.showCompletedFilterValue) {
      const index = SHOW_COMPLETED_FILTER_OPTIONS.findIndex(
        option => option.value === showCompletedFilterValue)
      this.setState({completionStateFilterOptionIndex: index})
    }
  }

  updateTimeFilter(newProps, prevProps) {
    const {timeFilterValue} = newProps
    if (timeFilterValue !== prevProps.timeFilterValue) {
      const index = TIME_FILTER_OPTIONS.findIndex(option => option.value === timeFilterValue)
      this.setState({timeFilterOptionIndex: index})
    }
  }

  updateAssigneeFilter(newProps, prevProps) {
    const assigneeFilterOptions = this.updateAssigneeFilterOptions(newProps, prevProps)
    let {assigneeFilterValue} = newProps
    if (assigneeFilterValue !== prevProps.assigneeFilterValue) {
      if (assigneeFilterValue instanceof Array) {
        assigneeFilterValue = assigneeFilterValue[0]
      }
      if (assigneeFilterValue === FilterValues.Assignee.ME) {
        assigneeFilterValue = newProps.myUserId
      }
      const index = assigneeFilterOptions.findIndex(option => option.userId === assigneeFilterValue)
      this.setState({assigneeFilterOptionIndex: index})
    }
  }

  updateAssigneeFilterOptions(newProps, prevProps) {
    const {projectMembers, myUserId} = newProps

    if (projectMembers !== prevProps.projectMembers || myUserId !== prevProps.myUserId) {
      const optsWithoutMe = projectMembers.map(user => {
          const userId = user.get('id')
          return userId === myUserId ? null : {title: user.get('name'), userId}
        })
        .filter(o => o !== null)
        .toJS()
      const assigneeFilterOptions = [
          {title: 'Off', userId: null},
          {title: 'Me', userId: newProps.myUserId}
        ].concat(optsWithoutMe)
      this.setState({assigneeFilterOptions})
      return assigneeFilterOptions
    }

    return this.state.assigneeFilterOptions
  }

  onShowCompletedFilterSelected(optionIndex) {
    const filterValue = SHOW_COMPLETED_FILTER_OPTIONS[optionIndex].value
    this.props.actions.setFilter(Filters.SHOW_COMPLETED, filterValue)
  }

  onTimeFilterSelected(optionIndex) {
    const filterValue = TIME_FILTER_OPTIONS[optionIndex].value
    this.props.actions.setFilter(Filters.TIME, filterValue)

    if (filterValue !== null) {
      this.props.actions.setFilter(Filters.SHOW_COMPLETED, FilterValues.ShowCompleted.SHOW_ALL_COMPLETED)
    }
  }

  onAssigneeFilterSelected(optionIndex) {
    const filterValue = this.state.assigneeFilterOptions[optionIndex].userId
    this.props.actions.setFilter(Filters.ASSIGNEE, filterValue === this.props.myUserId
      ? FilterValues.Assignee.ME
      : filterValue
    )
  }

  onShowingPopup(showingPopup) {
    this.setState({showingPopup})
  }

  resetFilters(event) {
    stopPropagation(event) // prevent header card selection on reset
    this.props.actions.resetAllFilters()
  }

}


class CardListHeaderFilter extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onItemSelected: PropTypes.func,
    onShowingPopup: PropTypes.func,
    hideOnSelected: PropTypes.bool,
    enabled: PropTypes.bool,
    tooltipText: PropTypes.string
  }

  constructor() {
    super()
    this.state = {showPopup: false}
    this.togglePopup = this.togglePopup.bind(this)
    this.onItemSelected = this.onItemSelected.bind(this)
  }

  render() {
    const {props} = this
    const {showPopup} = this.state
    const className = cn('filter', props.className,
      showPopup && 'expanded',
      !this.isEnabled && 'disabled')
    return (
      <Tooltip label={showPopup ? null : this.props.tooltipText} dy={4} showDelayMs={0}>
        <div className={className} onClick={stopPropagation}>
          <span className='filter-title' onClick={this.togglePopup}>
            <div className='arrow' />
            {props.title}
          </span>
          {showPopup && <PopupWrapper onDismiss={this.togglePopup}>
            <SelectItems containerClassName='selection-popup'
                onItemSelected={this.onItemSelected}
                initialHighlightedIndex={props.initialHighlightedIndex}>
              {props.children}
            </SelectItems>
          </PopupWrapper>}
        </div>
      </Tooltip>
    )
  }

  get isEnabled() {
    const {enabled} = this.props
    return enabled === undefined || !!enabled
  }

  togglePopup() {
    if (this.isEnabled || this.state.showPopup) {
      this.setShowPopup(!this.state.showPopup)
    }
  }

  onItemSelected(index) {
    const {hideOnSelected, onItemSelected} = this.props
    if (hideOnSelected) {
      this.setShowPopup(false)
    }
    onItemSelected && onItemSelected(index)
  }

  setShowPopup(showPopup) {
    this.setState({showPopup})
    this.props.onShowingPopup && this.props.onShowingPopup(showPopup)
  }

}


function capitalizeFirstLetter(str) {
  return str && (str[0].toLocaleUpperCase() + str.substr(1))
}


export default connect(CardListHeaderFilters)
