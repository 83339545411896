import React, { memo } from 'react'
import DefaultAvatar from '~assets/svg/default-avatar.svg'

type TurtleAvatarProps = {
  source: string | null | undefined
  size?: number
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const TurtleAvatar = memo(
  ({ source, size = 48, ...rest }: TurtleAvatarProps) => {
    const style = {
      display: 'block',
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
    }

    if (!source) {
      return <DefaultAvatar className="turtle-avatar" style={style} {...rest} />
    }

    return (
      <div
        className="turtle-avatar"
        style={{
          ...style,
          backgroundImage: `url(${source})`,
          borderRadius: 100,
          backgroundSize: 'contain',
        }}
        {...rest}
      />
    )
  }
)
