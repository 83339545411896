import React from 'react'
import cn from 'classnames'
import EmojiPicker from '~/components/emoji-picker'
import PopupWrapper from '~/components/popup-wrapper'

type EmojiPopupProps = {
  isBottomPadded?: boolean
  theme?: string
  onClose: () => void
  onEmojiInsert: (s: string) => void
}

export const EmojiPopup = ({
  isBottomPadded,
  theme,
  onClose,
  onEmojiInsert,
}: EmojiPopupProps) => {
  return (
    <PopupWrapper onDismiss={onClose}>
      <div
        className={cn('chat-popup', 'emojis', {
          bottom: isBottomPadded,
          'theme-dark': theme === 'dark',
        })}
      >
        <EmojiPicker insert={onEmojiInsert} />
      </div>
    </PopupWrapper>
  )
}
