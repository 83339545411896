// auth actions

export const NEEDS_LOGIN = 'NEEDS_LOGIN'
export const SHOW_PROVIDER_AUTH_PAGE = 'SHOW_PROVIDER_AUTH_PAGE'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'

export const SET_PERMISSIONS = 'SET_PERMISSIONS'

// project-related actions

export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'

export const SELECT_PROJECT = 'SELECT_PROJECT'
export const SELECT_PROJECT_SUCCESS = 'SELECT_PROJECT_SUCCESS'

export const SELECT_USER = 'SELECT_USER'
export const SELECT_USER_SUCCESS = 'SELECT_USER_SUCCESS'

export const SELECT_RECORD = 'SELECT_RECORD'
export const SELECT_RECORD_SUCCESS = 'SELECT_RECORD_SUCCESS'

export const PROJECT_REMOTELY_UPDATED = 'PROJECT_REMOTELY_UPDATED'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'

// members actions

export const TOGGLE_INVITATION = 'TOGGLE_INVITATION'
export const INVITE_MEMBER = 'INVITE_MEMBER'
export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const MEMBER_INVITED = 'MEMBER_INVITED'
export const MEMBER_REMOVED = 'MEMBER_REMOVED'
export const RESET_INVITATION = 'RESET_INVITATION'

// card actions

export const START_MODIFYING_CARD = 'START_MODIFYING_CARD'
export const CANCEL_MODIFYING_CARD = 'CANCEL_MODIFYING_CARD'

export const START_EDITING_CARD = 'START_EDITING_CARD'
export const CANCEL_EDITING_CARD = 'CANCEL_EDITING_CARD'
export const COMMIT_EDITING_CARD = 'COMMIT_EDITING_CARD'

export const CHANGE_CARD_NESTING_LEVEL = 'CHANGE_CARD_NESTING_LEVEL'

export const CREATE_EPHEMERAL_CARD = 'CREATE_EPHEMERAL_CARD'
export const DELETE_EPHEMERAL_CARD = 'DELETE_EPHEMERAL_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const UNDELETE_CARD = 'UNDELETE_CARD'
export const MOVE_CARD = 'MOVE_CARD'
export const QUICK_ADD_CARD = 'QUICK_ADD_CARD'
export const UNASSIGN_CARD = 'UNASSIGN_CARD'
export const ASSIGN_CARD = 'ASSIGN_CARD'
export const SET_CARD_DUE_DATE = 'SET_CARD_DUE_DATE'
export const UNSET_CARD_DUE_DATE = 'UNSET_CARD_DUE_DATE'

export const MARK_CARD_WITH_ID_COMPLETED = 'MARK_CARD_WITH_ID_COMPLETED'

export const UPDATE_CARD = 'UPDATE_CARD'
export const REPLACE_EPHEMERAL_CARD = 'REPLACE_EPHEMERAL_CARD'
export const CLEAR_CARD_OPTIMISTIC_STATE = 'CLEAR_CARD_OPTIMISTIC_STATE'

export const CARD_RENAMED = 'CARD_RENAMED'
export const CARD_CREATED = 'CARD_CREATED'
export const CARD_MOVED = 'CARD_MOVED'

export const UPDATE_ESTIMATE = 'UPDATE_ESTIMATE'

export const INCREASE_BUDGET = 'INCREASE_BUDGET'
export const DECREASE_BUDGET = 'DECREASE_BUDGET'

// navigation actions

export const ROUTE_CHANGED = 'ROUTE_CHANGED'

export const SHOW_CARD = 'SHOW_CARD'
export const REVEAL_CARD = 'REVEAL_CARD'
export const REVEAL_CARD_FINISHED = 'REVEAL_CARD_FINISHED'

export const SELECT_CARD = 'SELECT_CARD'
export const CLEAR_CARD_SELECTION = 'CLEAR_CARD_SELECTION'

export const SET_CARD_EXPANDED = 'SET_CARD_EXPANDED'
export const SET_CARDS_EXPANDED = 'SET_CARDS_EXPANDED'
export const RESTORE_CARDS_EXPAND_STATE = 'RESTORE_CARDS_EXPAND_STATE'

export const SET_FILTER = 'SET_FILTER'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'

// search actions

export const DO_SEARCH = 'DO_SEARCH'
export const SEARCH_RESULTS = 'SEARCH_RESULTS'

// feed-related actions

export const FEED_OPEN = 'FEED_OPEN'
export const FEED_CLOSE = 'FEED_CLOSE'

export const ENTITIES_UPDATED = 'ENTITIES_UPDATED'
export const ENTITIES_DELETED = 'ENTITIES_DELETED'

export const FEED_NEW_EVENTS = 'FEED_NEW_EVENTS'
export const FEED_UPDATED_EVENTS = 'FEED_UPDATED_EVENTS'
export const FEED_DELETED_EVENTS = 'FEED_DELETED_EVENTS'

export const FEED_FETCH_EVENTS = 'FEED_FETCH_EVENTS'
export const FEED_POST_MESSAGE = 'FEED_POST_MESSAGE'
export const FEED_POST_MESSAGE_OPTIMISTICALLY =
  'FEED_POST_MESSAGE_OPTIMISTICALLY'
export const FEED_TOGGLE_REACTION = 'FEED_TOGGLE_REACTION'
export const FEED_EDIT_MESSAGE = 'FEED_EDIT_MESSAGE'
export const FEED_DELETE_MESSAGE = 'FEED_DELETE_MESSAGE'
export const FEED_DELETE_MESSAGE_OPTIMISTICALLY =
  'FEED_DELETE_MESSAGE_OPTIMISTICALLY'
export const FEED_MARK_AS_READ = 'FEED_MARK_AS_READ'

export const TIME_ENTRY_CREATE = 'TIME_ENTRY_CREATE'
export const TIME_ENTRY_CREATE_OPTIMISTICALLY =
  'TIME_ENTRY_CREATE_OPTIMISTICALLY'

export const TIME_ENTRY_DELETE = 'TIME_ENTRY_DELETE'

export const TIME_ENTRY_EDIT = 'TIME_ENTRY_EDIT'

export const UNREAD_COUNTS_CHANGED = 'UNREAD_COUNTS_CHANGED'

export const UPDATE_DRAFT = 'UPDATE_DRAFT'

export const BEGIN_UPLOAD = 'BEGIN_UPLOAD'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'

export const TOGGLE_DIRECT_CHAT = 'TOGGLE_DIRECT_CHAT'

export const OPEN_DIRECT_CHAT = 'OPEN_DIRECT_CHAT'

export const TOGGLE_FULLSCREEN_CHAT = 'TOGGLE_FULLSCREEN_CHAT'

// misc actions

export const FATAL_ERROR = 'FATAL_ERROR'
export const NO_PROJECTS = 'NO_PROJECTS'
export const OPEN_INAPP_URL = 'OPEN_INAPP_URL'
export const GET_REPORT = 'GET_REPORT'
export const GET_CUSTOM_REPORT = 'GET_CUSTOM_REPORT'
export const REPORT_READY = 'REPORT_READY'
export const GET_TRACKED_HOURS = 'GET_TRACKED_HOURS'
export const GET_CUSTOM_TRACKED_HOURS = 'GET_CUSTOM_TRACKED_HOURS'
export const TRACKED_HOURS_READY = 'TRACKED_HOURS_READY'

export const NEW_UNDO_ITEM = 'NEW_UNDO_ITEM'
export const UNDO = 'UNDO'

export const TOGGLE_PAYMENT_FORM = 'TOGGLE_PAYMENT_FORM'
export const TOGGLE_BUDGET_FORM = 'TOGGLE_BUDGET_FORM'
export const SHOW_ROLLUPS = 'SHOW_ROLLUPS'
export const HIDE_ROLLUPS = 'HIDE_ROLLUPS'
export const SEND_PAYMENT = 'SEND_PAYMENT'
export const SEND_PAYMENT_V2 = 'SEND_PAYMENT_V2'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_FAIL = 'PAYMENT_FAIL'
export const SET_HAVE_BANK_CARD = 'SET_HAVE_BANK_CARD'

export const SUBMIT_AVAILABILITY = 'SUBMIT_AVAILABILITY'
export const AVAILABILITY_SUBMITTED = 'AVAILABILITY_SUBMITTED'
export const AVAILABILITY_SUBMISSION_FAILED = 'AVAILABILITY_SUBMISSION_FAILED'
export const RESET_AVAILABILITY_FORM = 'RESET_AVAILABILITY_FORM'

// video actions

export const TOGGLE_MY_AUDIO = 'TOGGLE_MY_AUDIO'
export const TOGGLE_MY_VIDEO = 'TOGGLE_MY_VIDEO'
export const SHOW_PREVIEW = 'SHOW_PREVIEW'
export const HIDE_PREVIEW = 'HIDE_PREVIEW'
export const SHARE_SCREEN = 'SHARE_SCREEN'
export const ADD_LOCAL_AUDIO_TRACK = 'ADD_LOCAL_AUDIO_TRACK'
export const REMOVE_LOCAL_AUDIO_TRACK = 'REMOVE_LOCAL_AUDIO_TRACK'
export const ADD_LOCAL_VIDEO_TRACK = 'ADD_LOCAL_VIDEO_TRACK'
export const REMOVE_LOCAL_VIDEO_TRACK = 'REMOVE_LOCAL_VIDEO_TRACK'
export const STOP_SHARE_SCREEN = 'STOP_SHARE_SCREEN'
export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM'
export const REMOVE_ACTIVE_ROOM = 'REMOVE_ACTIVE_ROOM'
export const JOINING_MEETING_ROOM = 'JOINING_MEETING_ROOM'
export const MEETING_ROOM_JOINED = 'MEETING_ROOM_JOINED'
export const MEETING_ROOM_LEFT = 'MEETING_ROOM_LEFT'
export const ADD_REMOTE_TRACK = 'ADD_REMOTE_TRACK'
export const REMOVE_REMOTE_TRACK = 'REMOVE_REMOTE_TRACK'
export const ADD_REMOTE_SCREEN_TRACK = 'ADD_REMOTE_SCREEN_TRACK'
export const REMOVE_REMOTE_SCREEN_TRACK = 'REMOVE_REMOTE_SCREEN_TRACK'
export const VIDEO_CONNECTED = 'VIDEO_CONNECTED'
export const UPDATE_LOCAL_NETWORK_QUALITY_INFO =
  'UPDATE_LOCAL_NETWORK_QUALITY_INFO'
export const REMOVE_LOCAL_NETWORK_QUALITY_INFO =
  'REMOVE_LOCAL_NETWORK_QUALITY_INFO'
export const UPDATE_DOMINANT_SPEAKER = 'UPDATE_DOMINANT_SPEAKER'
export const REMOVE_DOMINANT_SPEAKER = 'REMOVE_DOMINANT_SPEAKER'
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN'
export const ADD_REMOTE_PARTICIPANT = 'ADD_REMOTE_PARTICIPANT'
export const REMOVE_REMOTE_PARTICIPANT = 'REMOVE_REMOTE_PARTICIPANT'
export const REMOVE_ALL_REMOTE_PARTICIPANTS = 'REMOVE_ALL_REMOTE_PARTICIPANTS'
export const TRYING_TO_RECONNECT_TO_CALL = 'TRYING_TO_RECONNECT_TO_CALL'
export const RECONNECTED_TO_CALL = 'RECONNECTED_TO_CALL'
