import {fromJS} from 'immutable'
import * as ActionTypes from '~/actions/types'


const INITIAL_STATE = searchReducer.INITIAL_STATE = fromJS({
  query: '',
  done: true,
  results: []
})


export default function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.DO_SEARCH: {
      return state.merge({
        query: action.query,
        done: false
      })
    }
    case ActionTypes.SEARCH_RESULTS: {
      return state.merge({
        done: true,
        results: fromJS(action.results)
      })
    }
    case ActionTypes.SELECT_PROJECT: {
      return INITIAL_STATE
    }
  }
  return state
}
