import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cn from 'classnames'

import sel from '~/selectors'
import Layout from '~/components/layout/layout-with-single-frame'
import TaskListsLinks from '~/components/task-lists-links'
import dayjs from '~/utils/dayjs'

TeamWeek.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.oneOfType([
        ImmutablePropTypes.map.isRequired,
        PropTypes.oneOf([null]), // hack to say it should be null
      ]),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          card: ImmutablePropTypes.map.isRequired,
          path: PropTypes.string.isRequired,
        })
      ).isRequired,
      totalCompleted: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  expandStatus: PropTypes.objectOf(PropTypes.bool).isRequired,
  projectId: PropTypes.string.isRequired,
  timeFilterValue: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.oneOf([null]),
  ]),
  actions: PropTypes.shape({
    toggleExpandCollapse: PropTypes.func.isRequired,
    revealCardWithId: PropTypes.func.isRequired,
    showNextWeek: PropTypes.func.isRequired,
    showPrevWeek: PropTypes.func.isRequired,
    showAll: PropTypes.func.isRequired,
  }),
}

export default function TeamWeek({
  sections,
  expandStatus,
  timeFilterValue,
  actions,
  projectId,
}) {
  const itemEls = sections.map(({ person, items, totalCompleted, id }) => {
    const isExpanded = expandStatus[id]
    const avatarStyle = person
      ? { backgroundImage: `url(${person.get('photo')})` }
      : null
    return (
      <div
        key={id}
        className={cn('section', isExpanded ? 'expanded' : 'collapsed')}
      >
        <div
          className="team-week-member"
          onClick={() => actions.toggleExpandCollapse(id)}
        >
          <div className="expand-collapse-icon"></div>
          <div
            className={cn('user-avatar', !person && 'no-assignee')}
            style={avatarStyle}
          />
          <div className="user-name">
            {person ? person.get('name') : 'Assigned to nobody'}
          </div>
        </div>
        <div className="team-week-card-list">
          {items.length ? (
            items.map(item => (
              <TeamWeekCard
                item={item}
                key={item.card.get('id')}
                revealCardWithId={actions.revealCardWithId}
              ></TeamWeekCard>
            ))
          ) : (
            <div className="no-items">Nothing planned for this week</div>
          )}
        </div>
      </div>
    )
  })
  const emptyPlaceholder = sections.length === 0 && (
    <div className="section placeholder">
      Seems like nothing is planned for this week
    </div>
  )

  return (
    <Layout narrow={true}>
      <div className="main">
        <div className="team-week">
          {renderHeader(timeFilterValue, actions, projectId)}
          {itemEls}
          {emptyPlaceholder}
        </div>
      </div>
    </Layout>
  )
}

function TeamWeekCard({ item, revealCardWithId }) {
  const { card, path } = item
  const isCompleted = sel.card.isOptimisticallyCompleted(card)
  const revealCard = () => revealCardWithId(card.get('id'))
  const dueDate = card.get('dueDate')
  const momentDueDate = dayjs(dueDate)
  const isMissed = !isCompleted && !momentDueDate.endOf('day').isAfter(dayjs())
  return (
    <div
      className={cn('card', isCompleted && 'completed')}
      onClick={revealCard}
    >
      <div className="title">
        <div className="row">
          <div className="name">{card.get('name')}</div>
          <div className={`due-date ${isMissed ? '-missed' : ''}`}>
            {dueDate && momentDueDate.format('MMM, D')}
          </div>
        </div>
        <div className="path">{path}</div>
      </div>
    </div>
  )
}

function renderHeader(timeFilterValue, actions, projectId) {
  const [prevBtnLabel, nextBtnLabel] =
    timeFilterValue == 0
      ? ['Past 7 days', 'Next 7 days']
      : [
          getNavButtonLabel(timeFilterValue - 1),
          getNavButtonLabel(timeFilterValue + 1),
        ]
  return (
    <div className="team-week-header">
      <div className="hint">
        <div className="hint-body">
          {timeFilterValue == 0
            ? 'Showing all tasks'
            : 'Showing all tasks that are scheduled, completed or due within the selected week'}
        </div>
        <TaskListsLinks projectId={projectId} />
      </div>
      {renderTitle(timeFilterValue)}
      <div className="time-navigation">
        <div className="nav-btn prev" onClick={actions.showPrevWeek}>
          {prevBtnLabel}
        </div>
        {timeFilterValue != 0 && (
          <div className="nav-btn curr" onClick={actions.showAll}>
            Jump to all
          </div>
        )}
        <div className="nav-btn next" onClick={actions.showNextWeek}>
          {nextBtnLabel}
        </div>
      </div>
    </div>
  )
}

function renderTitle(timeFilterValue) {
  if (timeFilterValue == 0) {
    return <div className="title">Team view</div>
  }
  return (
    <div className="title">
      Team Week:&nbsp;
      <span className="time-period">
        {formatFilterWeekPeriod(timeFilterValue, 'MMM D', 'MMM D, YYYY')}
      </span>
    </div>
  )
}

function getNavButtonLabel(timeFilterValue) {
  switch (timeFilterValue) {
    case 0:
      return 'Show all'
    default:
      return formatFilterWeekPeriod(timeFilterValue, 'M/D', 'M/D/YYYY')
  }
}

function formatFilterWeekPeriod(
  timeFilterValue,
  dateFormatThisYear,
  dateFormatOtherYear
) {
  let startDate
  let endDate
  if (timeFilterValue > 0) {
    startDate = dayjs().add((timeFilterValue - 1) * 7, 'd')
    endDate = startDate.clone().add(7, 'd')
  } else if (timeFilterValue < 0) {
    startDate = dayjs().add(timeFilterValue * 7, 'd')
    endDate = startDate.clone().add(7, 'd')
  }

  return formatWeekPeriod(
    startDate,
    endDate,
    dateFormatThisYear,
    dateFormatOtherYear
  )
}

function formatWeekPeriod(
  mStartDate,
  mEndDate,
  dateFormatThisYear,
  dateFormatOtherYear
) {
  const currentYear = dayjs().year()

  const startDateYear = mStartDate.year()
  const endDateYear = mEndDate.year()

  const endDateFormatted =
    endDateYear == currentYear && endDateYear == startDateYear
      ? mEndDate.format(dateFormatThisYear)
      : mEndDate.format(dateFormatOtherYear)

  const startDateFormatted =
    startDateYear == endDateYear
      ? mStartDate.format(dateFormatThisYear)
      : mStartDate.format(dateFormatOtherYear)

  return startDateFormatted + '–' + endDateFormatted
}
