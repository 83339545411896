import keymage from 'keymage'


export default function addShortcut(shortcut, handler) {
  keymage(shortcut, function(e, data) {
    if (filterShortcutEvents(e)) {
      handler(e, data)
    }
  })
}


function filterShortcutEvents(e) {
  const tagName = e.target.tagName
  return !(tagName == 'INPUT' || tagName == 'SELECT' || tagName == 'TEXTAREA')
}
