import React from 'react'
import classnames from 'classnames'

type LoaderProps = {
  colorScheme?: 'light' | 'dark'
  expandOnFullHeight?: boolean
}

export const Loader = ({ colorScheme, expandOnFullHeight }: LoaderProps) => {
  const classNames = classnames({
    'loader-wrapper': true,
    'la-ball-scale-ripple-multiple': true,
    'la-dark': colorScheme === 'dark',
  })

  const loader = (
    <div className={classNames}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )

  if (expandOnFullHeight) {
    return <div className="fullheight-loader-wrapper">{loader}</div>
  } else {
    return loader
  }
}
