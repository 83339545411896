import React from 'react'

import PageError from '~/components/pages/page-error'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <PageError title="We're sorry" errorDetails={JSON.stringify(this.state.errorInfo)}>
          <h2>Something went wrong.</h2>
          <a href='/'>Please, go to home page</a>
        </PageError>
      )
    }
    return this.props.children
  }
}
