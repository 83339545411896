import React, { memo } from 'react'
import GoogleIcon from '~assets/svg/google-icon.svg'
import {
  handleSignInWithGoogle,
  TurtleCredentials,
} from '~/utils/google-auth-flow'
import cn from 'classnames'

type Props = {
  className?: string
  onAuthSuccess: (credentils: TurtleCredentials | null) => void
  onAuthError?: () => void
}

export const GoogleSignInButton = memo((props: Props) => {
  const handleButtonPress = async () => {
    const credentials = await handleSignInWithGoogle()
    if (!credentials && !!props.onAuthError) {
      props.onAuthError()
      return
    }
    props.onAuthSuccess(credentials)
  }
  return (
    <button
      className={cn('google-button', props.className)}
      onClick={handleButtonPress}
    >
      <GoogleIcon className="google-button__icon" />
      Sign in with Google
    </button>
  )
})
