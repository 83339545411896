
export function hasClass(className, node) {
  const classList = node.classList
  return (classList
    ? classList.contains(className)
    : node.className.split(/\s+/).indexOf(className) >= 0
  )
}

export function stopPropagation(event) {
  event.stopPropagation()
}
