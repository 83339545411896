import React from 'react'

export default class CardListDraggingIndicator extends React.Component {

  constructor() {
    super()
    this.state = {
      visible: false,
      x: null,
      y: null
    }
  }

  showAt(x, y) {
    this.setState({visible: true, x, y})
  }

  hide() {
    this.setState({visible: false})
  }

  render() {
    if (!this.state.visible) {
      return null
    }
    const style = {left: this.state.x, top: this.state.y}
    return (
      <div className='card-dragging-indicator' style={style}>
        <div className='line' />
        <div className='circle' />
      </div>
    )
  }

}
