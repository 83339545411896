import client from '~/api/apollo-client'
import * as Types from '~/types/api'
import { AuthenticateUser } from '~/api/queries'
import storage from '~/utils/session-storage'
import { nanoid } from 'nanoid'
import config from '~/config'

export const handleSignInWithGoogle = async (): Promise<TurtleCredentials | null> => {
  const googleAuthResult = await showGoogleAuthDialog(googleSignInOptions)
  if (!googleAuthResult) {
    return null
  }

  const turtleAuthResult = await client.mutate<
    Types.AuthenticateUser,
    Types.AuthenticateUserVariables
  >({
    mutation: AuthenticateUser,
    variables: {
      idToken: googleAuthResult.idToken,
      provider: AuthProvider.GOOGLE,
    },
  })

  if (!turtleAuthResult.data || turtleAuthResult.errors) {
    return null
  }
  const { authenticate } = turtleAuthResult.data

  const credentials = {
    user_id: authenticate?.user?.id,
    token: authenticate?.accessToken,
  }

  storage.setItem('credentials', JSON.stringify(credentials))
  return credentials
}

type QueryParameterValue = string | number | boolean | undefined | null

type QueryParameters = Record<string, QueryParameterValue>

export type TurtleCredentials = {
  user_id: string | null | undefined
  token: string | null | undefined
}

enum AuthProvider {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

// docs for fields here:
// https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#oauth-2.0-endpoints_1
interface GoogleAuthSignInOptions {
  clientId: string
  redirectURI: string
  responseType: 'code' | 'token' | 'id_token'
  scope: string
  accessType?: 'online' | 'offline'
  includeGrantedScopes?: boolean
  prompt?: 'none' | 'consent' | 'select_account'
  loginHint?: string
}

const googleSignInOptions: GoogleAuthSignInOptions = {
  responseType: 'id_token',
  scope: 'email profile',
  clientId: config.GOOGLE_AUTH_ID,
  redirectURI: `${window.location.protocol}//${window.location.host}/google-sign-in-callback.html`,
  prompt: 'select_account',
}

const buildGoogleSignInURI = (
  opts: GoogleAuthSignInOptions,
  state: string,
  nonce: string
): string => {
  return encodeURI('https://accounts.google.com/o/oauth2/v2/auth', {
    client_id: opts.clientId,
    redirect_uri: opts.redirectURI,
    response_type: opts.responseType,
    scope: opts.scope,
    access_type: opts.accessType,
    prompt: opts.prompt,
    include_granted_scopes: opts.includeGrantedScopes,
    login_hint: opts.loginHint,
    state: state,
    nonce: nonce,
  })
}

const showGoogleAuthDialog = async (
  opts: GoogleAuthSignInOptions
): Promise<{ idToken: string } | null> => {
  const state = nanoid()
  const nonce = nanoid() // @TODO: improve this to be more secure?

  const authURI = buildGoogleSignInURI(opts, state, nonce)

  const popupWindow = openPopup(authURI, 'Sign in - Google Accounts', 450, 600)

  const data = await new Promise<string>(resolve => {
    ;(window as any).onPopupResult = (data: string) => resolve(data)
  })
  const idToken = getQueryStringParameter(data, 'id_token')

  if (!idToken) {
    return null
  }
  popupWindow?.close()
  return { idToken }
}

// utils

const openPopup = (
  uri: string,
  title: string,
  width: number,
  height: number
): Window | null => {
  const left = (window.screen.width - width) / 2
  const top = (window.screen.height - height) / 2

  const popupOptions = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`
  return window.open(uri, title, popupOptions)
}

const encodeURI = (baseURI: string, query: QueryParameters): string => {
  return baseURI + '?' + encodeQueryParams(query)
}

const encodeQueryParams = (query: QueryParameters): string => {
  const keys = Object.keys(query)
  keys.sort()

  const encodedPairs: string[] = []
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = query[key]
    if (value === null || value === undefined) continue
    encodedPairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
  }

  return encodedPairs.join('&')
}

const getQueryStringParameter = (queryString: string, name: string) => {
  const vars = queryString.split('&')
  const foundVar = vars.find(v => v.includes(name))
  if (!foundVar) {
    return null
  }
  return foundVar.split('=')[1]
}
