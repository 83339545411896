import { gql } from '@apollo/client'
import { CardFragment, UserFragment } from './fragments'

export const AssignCardMutation = gql`
  mutation AssignCardMutation($input: CardAssignInput!) {
    cardAssign(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UnassignCardMutation = gql`
  mutation UnassignCardMutation($input: CardUnassignInput!) {
    cardUnassign(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const CompleteCardMutation = gql`
  mutation CompleteCardMutation($input: CardCompleteInput!) {
    cardComplete(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UncompleteCardMutation = gql`
  mutation UncompleteCardMutation($input: CardUncompleteInput!) {
    cardUncomplete(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const IncreaseBudgetMutation = gql`
  mutation IncreaseBudgetMutation($input: CardBudgetIncreaseInput!) {
    cardBudgetIncrease(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const DecreaseBudgetMutation = gql`
  mutation DecreaseBudgetMutation($input: CardBudgetDecreaseInput!) {
    cardBudgetDecrease(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const DeleteCardMutation = gql`
  mutation DeleteCardMutation($input: CardDeleteInput!) {
    cardDelete(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UndeleteCardMutation = gql`
  mutation UndeleteCardMutation($input: CardUndeleteInput!) {
    cardUndelete(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UnsetBudgetMutation = gql`
  mutation UnsetBudgetMutation($input: CardBudgetUnsetInput!) {
    cardBudgetUnset(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const MoveCardMutation = gql`
  mutation MoveCardMutation($input: CardMoveInput!) {
    cardMove(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UpdateEstimateMutation = gql`
  mutation UpdateEstimateMutation($input: CardEstimateSetInput!) {
    cardEstimateSet(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UnsetEstimateMutation = gql`
  mutation UnsetEstimateMutation($input: CardEstimateUnsetInput!) {
    cardEstimateUnset(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const SetCardDueDateMutation = gql`
  mutation SetCardDueDateMutation($input: CardDueDateSetInput!) {
    cardDueDateSet(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const UnsetCardDueDateMutation = gql`
  mutation UnsetCardDueDateMutation($input: CardDueDateUnsetInput!) {
    cardDueDateUnset(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const RenameCardMutation = gql`
  mutation RenameCardMutation($input: CardRenameInput!) {
    cardRename(input: $input) {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const CreateCardMutation = gql`
  mutation CreateCardMutation($input: CardCreateInput!) {
    cardCreate(input: $input) {
      ...CardFragment
      descendants(depth: 15) {
        id
        parentId
        position
      }
      position
      parentId
      timeTracked
    }
  }
  ${CardFragment}
`

export const FetchCardQuery = gql`
  query FetchCardQuery($id: CardId) {
    card(id: $id) {
      id
      # members {
      #   ...UserFragment
      # }
      descendants(depth: 0) {
        id
        parentId
      }
      parentId
      position
      timeTracked
      ...CardFragment
    }
  }
  ${UserFragment}
  ${CardFragment}
`

export const InviteMemberMutation = gql`
  mutation InviteMemberMutation($input: CardInviteUserInput!) {
    cardInviteUser(input: $input) {
      id
    }
  }
`

export const AuthorizeFileUpload = gql`
  mutation AuthorizeFileUpload($input: AuthorizeFileUploadInput!) {
    authorizeFileUpload(input: $input) {
      fileId
      presignedUrl
    }
  }
`

export const AuthenticateUser = gql`
  mutation AuthenticateUser($idToken: String!, $provider: AuthProvider!) {
    authenticate(idToken: $idToken, provider: $provider) {
      accessToken
      user {
        id
      }
    }
  }
`
export const RequestToWorkWithMutation = gql`
  mutation requestToWorkWith($input: RequestToWorkWithInput!) {
    requestToWorkWith(input: $input) {
      id
      topic
      feedId
    }
  }
`

export const CreatePlaidLInkTokenMutation = gql`
  mutation CreatePlaidLInkTokenMutation {
    createPlaidLinkToken {
      linkToken
    }
  }
`
