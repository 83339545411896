/* eslint-disable */
import React from 'react'
import Tooltip from '../tooltip'
import cn from 'classnames'
import dayjs from '~/utils/dayjs'

const DUE_DATE_BTN_CLASS = 'due-date-badge'
const DUE_DATE_BTN_LABEL = 'Change due date'

const DUE_DATE_BADGE_REGULAR = ''
const DUE_DATE_BADGE_MISSED = '-missed'
const DUE_DATE_BADGE_FOR_COMPLETED_TASK = '-completed'

export const DueDateBadgeState = {
  DUE_DATE_BADGE_REGULAR,
  DUE_DATE_BADGE_MISSED,
  DUE_DATE_BADGE_FOR_COMPLETED_TASK
}

export const Flavor = {
  IN_LIST: 0,
  IN_HEADER: 1
}

DueDateBadge.Flavor = Flavor

export default function DueDateBadge({
  dueDate,
  dueDateBadgeState,
  isActive,
  openCalendar,
  className,
  flavor = Flavor.IN_LIST
}) {
  const displayDueDate = dueDate ? (
    dayjs(dueDate).format('MMM, D')
  ) : (
    <span className="due-date-icon" />
  )

  const componentClassNames = cn(
    DUE_DATE_BTN_CLASS,
    dueDateBadgeState,
    isActive && '-active',
    className
  )
  const dueDateBtnLabel =
    dueDateBadgeState != DUE_DATE_BADGE_FOR_COMPLETED_TASK
      ? DUE_DATE_BTN_LABEL
      : null

  const tooltipDy = flavor == Flavor.IN_LIST ? -4 : 8

  return (
    <Tooltip label={dueDateBtnLabel} dy={tooltipDy}>
      <div className={componentClassNames} onClick={openCalendar}>
        {displayDueDate}
      </div>
    </Tooltip>
  )
}
