import PropTypes from 'prop-types'
import React from 'react'

WrapWithText.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node
}

export default function WrapWithText(props) {

    let splittedText = props.text.split(" ")
    let titleLastWord = splittedText.splice(-1)[0]
    let titleWithoutLastWord = splittedText.join(" ")

    return (
        <div className='wrap-with-text'>
            {titleWithoutLastWord ? <span className='title-text'>{titleWithoutLastWord + " "}</span> : null}
            <span style={{whiteSpace: "nowrap"}} className="last-word-children-wrapper">
                    <span className='title-text last-word'>{titleLastWord}</span>
                {props.children}
                </span>
        </div>
    )
}