import React from 'react'
import config from '~/config'
import { hoursToSeconds, formatDuration } from '~/utils/time'
import { dollarsToCents, formatDollars } from '~/utils/money'
import TurtleAvatar from '~assets/images/turtle-user-avatar.png'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import { ClickOutside } from '~/components/utils/click-outside'
import { createPlaidLink } from '~/api/plaid'

const PLAID_SCRIPT_URL =
  'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
const STRIPE_SCRIPT_URL = 'https://checkout.stripe.com/checkout.js'
const DEFAULT_HOURLY_RATE = 50 * 100

function calculateHoursEstimate(amount, hourlyRate) {
  return hoursToSeconds(amount / hourlyRate)
}

export default class Budget extends React.Component {
  state = { value: 500, blocked: false }

  attachStripeHandler = () => {
    // eslint-disable-next-line
    this.stripeHandler = StripeCheckout.configure({
      key: config.STRIPE_PUBLIC_KEY,
      locale: 'auto',
      email: this.props.user.get('email'),
      image: TurtleAvatar,
      zipCode: true,
      allowRememberMe: false,
      token: ({ id: token }) =>
        this.props.sendPaymentV2({
          amount: this.state.value,
          paymentMethod: 'CREDIT_CARD',
          creditCardToken: token,
        }),
    })
  }

  loadScriptAndSetupStripeHandler() {
    const script = document.querySelector(`script[src="${STRIPE_SCRIPT_URL}"]`)

    if (script) {
      return this.attachStripeHandler()
    }

    const scriptNode = document.createElement('script')
    scriptNode.src = STRIPE_SCRIPT_URL

    return new Promise(resolve => {
      scriptNode.addEventListener('load', () => {
        this.attachStripeHandler()
        resolve()
      })

      document.body.appendChild(scriptNode)
    })
  }

  attachPlaidHandler = async () => {
    const { linkToken: token } = await createPlaidLink()

    const config = {
      token,
      onSuccess: (public_token, { account_id }) =>
        this.props.sendPaymentV2({
          amount: this.state.value,
          paymentMethod: 'ACH_DEBIT',
          bankAccountId: account_id,
          bankAccountPublicToken: public_token,
        }),
      onExit: err => {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
          console.error(err)
        }
      },
    }
    // eslint-disable-next-line
    this.plaidHandler = Plaid.create(config)
  }

  loadScriptAndSetupPlaidHandler() {
    const script = document.querySelector(`script[src="${PLAID_SCRIPT_URL}"]`)

    if (script) {
      return this.attachPlaidHandler()
    }

    const scriptNode = document.createElement('script')
    scriptNode.src = PLAID_SCRIPT_URL

    return new Promise(resolve => {
      scriptNode.addEventListener('load', () => {
        this.attachPlaidHandler().then(resolve)
      })

      document.body.appendChild(scriptNode)
    })
  }

  componentDidMount() {
    return Promise.all([
      this.loadScriptAndSetupStripeHandler(),
      this.loadScriptAndSetupPlaidHandler(),
    ])
  }

  componentWillUnmount() {
    if (this.stripeHandler) {
      this.stripeHandler.close()
    }
  }

  getReportTimeEstimate(report, delta) {
    const extra = delta || 0
    const estimatedHourlyRate =
      report.estimated_hourly_rate || DEFAULT_HOURLY_RATE
    return calculateHoursEstimate(
      report.balance.amount + extra,
      estimatedHourlyRate
    )
  }

  render() {
    const { report } = this.props
    const timeEstimate = this.getReportTimeEstimate(report)

    const refillEnabled = true
    const refillElement = refillEnabled ? (
      <div className="refill" onClick={this.toggle}>
        refill
      </div>
    ) : null

    return (
      <div className="budget-component">
        {this.props.paymentStatus.showForm && (
          <div className="budget-overlay" />
        )}
        <ClickOutside
          onClickOutside={() =>
            this.props.paymentStatus.showForm && this.toggle()
          }
          className={`budget-panel ${
            this.props.paymentStatus.showForm ? 'show-refill' : ''
          }`}
        >
          <div className="budget-content">
            <div className="budget-buttons">
              <div className="budget-label">Budget remaining</div>
              {refillElement}
            </div>
            <div className="budget-header-value">
              <span>{formatDuration(timeEstimate)}</span>
              <span className="budget-money">
                {formatDollars(report.balance.amount)}
              </span>
            </div>
          </div>
          {this.props.paymentStatus.showForm && this.renderRefillForm()}
          {!this.props.paymentStatus.showForm && (
            <div className="ask-panel">
              Any questions?
              <a
                className="ask-button"
                href="mailto:help@turtleos.com?Subject=Help"
              >
                Let us know
              </a>
            </div>
          )}
        </ClickOutside>
      </div>
    )
  }

  renderRefillForm = () => {
    const { report, paymentStatus } = this.props
    const { error, success, sending } = paymentStatus
    const showForm = !error && !success && !sending

    const additionalAmount = dollarsToCents(this.state.value)
    const timeEstimate = this.getReportTimeEstimate(report, additionalAmount)

    return (
      <div className="refill-form v2">
        <div className="refill-header">
          <div className="refill-label">Refill</div>
          <div className="refill-close" onClick={this.toggle}>
            {!showForm ? 'close' : 'cancel'}
          </div>
        </div>
        {showForm && (
          <div className="refill-body">
            <div className="refill-line">
              <div className="refill-text">
                How much would
                <br />
                you like to add?
              </div>
              <div className="budget-input-container">
                <input
                  className="budget-input"
                  defaultValue={this.state.value}
                  onChange={this.valueChange}
                  onKeyDown={this.handleDown}
                  autoFocus
                />
              </div>
            </div>
            <div className="refill-line">
              <div className="refill-text">New balance:</div>
              <div className="refill-amount">
                <span className="new-duration">
                  {formatDuration(timeEstimate)}
                </span>
                <span className="budget-money">
                  {formatDollars(report.balance.amount + additionalAmount)}
                </span>
              </div>
            </div>
          </div>
        )}
        {showForm && (
          <div>
            <OptimizelyFeature feature="pay-with-ach">
              {isEnabled =>
                isEnabled && (
                  <div>
                    <div className="refill-footer">
                      <div
                        onClick={this.payWithAchDebit}
                        className={`refill-button full-width ${
                          this.state.blocked ? 'blocked' : ''
                        }`}
                      >
                        Confirm &amp; Pay with ACH Debit
                      </div>
                    </div>
                    <div className="refill-footer or-line">or</div>
                  </div>
                )
              }
            </OptimizelyFeature>
            <div className="refill-footer">
              <div
                onClick={this.payWithCreditCard}
                className={`refill-button full-width ${
                  this.state.blocked ? 'blocked' : ''
                }`}
              >
                Confirm &amp; Pay with Credit Card
              </div>
            </div>
            <div className="refill-footer">
              <div>
                All payments are secure. You can access past payments any time.
              </div>
            </div>
          </div>
        )}
        {success && (
          <div className="refill-status">
            <div className="refill-status-internal success">
              Successfully added ${this.state.value} to the balance!
            </div>
          </div>
        )}
        {sending && (
          <div className="refill-status">
            <div className="refill-status-internal sending">
              Sending ${this.state.value} payment
            </div>
          </div>
        )}
        {error && (
          <div className="refill-status">
            <div className="refill-status-internal error">
              Error while sending payment: {error}
            </div>
          </div>
        )}
      </div>
    )
  }

  toggle = () => {
    if (!this.props.paymentStatus.sending) {
      this.props.togglePaymentForm()
    }
  }

  valueChange = e => {
    const { target } = e
    const { value } = target
    const numberValue = Number(value)
    if (!isNaN(numberValue) && value !== '') {
      this.setState({ value: numberValue, blocked: false })
    } else {
      this.setState({ blocked: true })
    }
  }

  handleDown = e => {
    if (e.key === 'Escape') {
      this.toggle()
    }
  }

  payWithCreditCard = () => {
    if (this.state.blocked) {
      return
    }

    if (!this.stripeHandler) {
      throw new Error(
        'Unable to pay with credit card because there is no stripeHandler'
      )
    }

    this.stripeHandler.open({
      name: 'Turtle.dev',
      description: 'Add hours',
      amount: this.state.value * 100,
    })
  }

  payWithAchDebit = () => {
    if (this.state.blocked) {
      return
    }

    if (!this.plaidHandler) {
      throw new Error('Unable to pay with ach because there is no plaidHandler')
    }

    this.plaidHandler.open()
  }
}
