import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'


const INITIAL_STATE = authReducer.INITIAL_STATE = Map({
  isLoggedIn: undefined,
  isLoggingIn: false,
  credentials: {},
  error: null
})


export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.LOGIN: {
      return state.set('isLoggingIn', true)
    }
    case ActionTypes.LOGIN_SUCCESS: {
      return setLoggedIn(state, action.credentials)
    }
    case ActionTypes.LOGIN_ERROR: {
      return setNotLoggedIn(state, action.error)
    }
    case ActionTypes.NEEDS_LOGIN:
    case ActionTypes.LOGOUT: {
      return setNotLoggedIn(state)
    }
  }
  return state
}


function setLoggedIn(state, credentials) {
  return state.merge({ isLoggedIn: true, isLoggingIn: false, credentials, error: null })
}


function setNotLoggedIn(state, error = null) {
  return state.merge({ isLoggedIn: false, isLoggingIn: false, credentials: {}, error })
}
