import React, { ChangeEvent, useMemo, useState } from 'react'
import cn from 'classnames'
import Textarea from 'react-textarea-autosize'
import { noop } from '~/utils/noop'
import CloseIcon from '~assets/svg/close-icon.svg'

export interface TurtleInputProps {
  value?: string
  containerClassName?: string
  inputClassName?: string
  fontSize?: 14 | 16
  withSideLabel?: boolean
  withEraseButton?: boolean
  label?: string
  isTextarea?: boolean
  placeholder?: string
  maxRows?: number
  invalid?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  disabled?: boolean
  readOnly?: boolean
  errorMessage?: string | null
  isInline?: boolean
  onChange?: (value: string) => void
  onBlur?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onFocus?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onKeyDown?:
    | ((
        event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void)
    | undefined
  onKeyUp?:
    | ((
        event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void)
    | undefined
  onClick?:
    | ((
        event: React.MouseEvent<
          HTMLTextAreaElement | HTMLInputElement,
          MouseEvent
        >
      ) => void)
    | undefined
}

export const TurtleInput: React.FC<TurtleInputProps> = ({
  containerClassName,
  inputClassName,
  label = '',
  withSideLabel,
  withEraseButton,
  isTextarea,
  invalid,
  inputRef,
  errorMessage,
  isInline,
  fontSize = 16,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  ...restProps
}) => {
  const [isDirty, setIsDirty] = useState(true)
  const [isFocused, setIsFocused] = useState(false)

  const handleOnBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onBlur(event)
    setIsDirty(true)
    setIsFocused(false)
  }

  const handleOnFocus = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onFocus(event)
    setIsDirty(false)
    setIsFocused(true)
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e.target.value)
  }

  const handleErase = () => onChange('')

  const isInvalid = useMemo(() => {
    return Boolean(invalid || errorMessage?.length)
  }, [invalid, errorMessage])

  return (
    <>
      {label.length && !withSideLabel ? (
        <label
          className={cn('turtle-label', {
            invalid: isInvalid && isDirty,
            disabled: restProps.disabled,
            focused: isFocused,
          })}
        >
          {errorMessage ?? label}
        </label>
      ) : null}
      <div
        className={cn('turtle-input', containerClassName, {
          invalid: isInvalid && isDirty,
          smallFont: fontSize === 14,
          withEraseButton,
          inline: isInline,
        })}
      >
        {label.length && withSideLabel ? (
          <label className="side-label">{label}</label>
        ) : null}
        {isTextarea ? (
          <Textarea
            className={cn('input', '-textarea')}
            onChange={handleChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            {...restProps}
          />
        ) : (
          <input
            className={cn('input', inputClassName)}
            onChange={handleChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            ref={inputRef}
            {...restProps}
          />
        )}
        {withEraseButton && restProps.value?.length ? (
          <CloseIcon className="close-icon" onClick={handleErase} />
        ) : null}
      </div>
    </>
  )
}
