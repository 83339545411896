import { all, take, select } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { $dispatch } from './utils/effects'

import * as Actions from '~/actions'
import sel from '~/selectors'

import { FilteringState } from '~/utils/filter-constants'
import { isRouteToCard, doesRouteAllowCardSelection } from '~/utils/routing'

const KEY_ARROW_UP = 'ArrowUp'
const KEY_ARROW_DOWN = 'ArrowDown'

const KEY_ARROW_RIGHT = 'ArrowRight'
const KEY_ARROW_LEFT = 'ArrowLeft'

const ACTIONS_BY_KEY = {
  [KEY_ARROW_UP]: $moveSelectionUp,
  [KEY_ARROW_DOWN]: $moveSelectionDown,
  [KEY_ARROW_RIGHT]: $expandSelectedCard,
  [KEY_ARROW_LEFT]: $collapseSelectedCard,
}

const KEYS = Object.keys(ACTIONS_BY_KEY)

export default function* $keyboardNavigationSaga() {
  const pressedKeys = createPressedKeysChannel()
  while (true) {
    const { key } = yield take(pressedKeys)
    const $fn = ACTIONS_BY_KEY[key]
    if ($fn) {
      const [route, selectedCardId] = yield all([
        select(sel.route),
        select(sel.selectedCardId),
      ])
      const routeType = route.get('type')
      if (!doesRouteAllowCardSelection(routeType) || selectedCardId == null) {
        return
      }
      yield* $fn(route.toJS(), selectedCardId)
    }
  }
}

function* $moveSelectionUp(route, selectedCardId) {
  return yield* $moveSelection(true, route, selectedCardId)
}

function* $moveSelectionDown(route, selectedCardId) {
  return yield* $moveSelection(false, route, selectedCardId)
}

function* $expandSelectedCard(route, selectedCardId) {
  return yield* $setSelectedCardExpanded(true, route, selectedCardId)
}

function* $collapseSelectedCard(route, selectedCardId) {
  return yield* $setSelectedCardExpanded(false, route, selectedCardId)
}

function* $setSelectedCardExpanded(makeExpanded, route, cardId) {
  const topLevelCardId = isRouteToCard(route.type)
    ? route.cardId
    : route.projectId
  const [card, cardsViewState] = yield all([
    select(sel.cardWithId, cardId),
    select(sel.cardsViewState),
  ])
  const visibleChildren = getVisibleChildrenIds(card, cardsViewState)
  const hasChildren = visibleChildren.size > 0
  const currentlyExpanded =
    hasChildren &&
    sel.cardViewState.isExpanded(
      cardsViewState,
      cardId,
      route.type,
      topLevelCardId
    )
  if (makeExpanded) {
    if (!hasChildren) {
      return
    }
  } else if (cardId == topLevelCardId) {
    return
  }
  if (makeExpanded == currentlyExpanded) {
    if (makeExpanded) {
      if (hasChildren) {
        const firstChildId = visibleChildren.get(0)
        yield* $dispatch(Actions.selectCard(firstChildId))
      }
    } else {
      const parentId = sel.card.parentId(card)
      if (canSelectParent(parentId, route.type, topLevelCardId)) {
        yield* $dispatch(Actions.selectCard(parentId))
      }
    }
    return
  }
  yield* $dispatch(Actions.setCardExpanded(cardId, makeExpanded))
}

function* $moveSelection(isMovingUp, route, selectedCardId) {
  const topLevelCardId = isRouteToCard(route.type)
    ? route.cardId
    : route.projectId
  const [cards, cardsViewState] = yield all([
    select(sel.cards),
    select(sel.cardsViewState),
  ])
  const nextSelectedCardId = getNextSelectedCardId(
    route.type,
    topLevelCardId,
    selectedCardId,
    isMovingUp,
    cards,
    cardsViewState
  )
  if (nextSelectedCardId) {
    yield* $dispatch(Actions.selectCard(nextSelectedCardId))
  }
}

function getNextSelectedCardId(
  routeType,
  topLevelCardId,
  selectedCardId,
  isMovingUp,
  cards,
  cardsViewState
) {
  const topLevelCard = cards.get(topLevelCardId)
  if (topLevelCardId == selectedCardId) {
    const visibleTopLevelChildrenIds = getVisibleChildrenIds(
      topLevelCard,
      cardsViewState
    )
    return isMovingUp ? null : visibleTopLevelChildrenIds.get(0)
  }
  const selectedCard = cards.get(selectedCardId)
  const parentCardId = sel.card.parentId(selectedCard)
  const parentCard = cards.get(parentCardId)
  const visibleSiblingIds = getVisibleChildrenIds(parentCard, cardsViewState)
  const selectedChildIndex = visibleSiblingIds.indexOf(selectedCardId)
  const { isExpanded } = sel.cardViewState
  if (isMovingUp) {
    if (selectedChildIndex == 0) {
      return canSelectParent(parentCardId, routeType, topLevelCardId)
        ? parentCardId
        : null
    }
    let levelCardId = visibleSiblingIds.get(selectedChildIndex - 1)
    let shouldDescend
    do {
      shouldDescend = false
      const levelCardIsExpanded = isExpanded(
        cardsViewState,
        levelCardId,
        routeType,
        topLevelCardId
      )
      if (levelCardIsExpanded) {
        const levelCard = cards.get(levelCardId)
        const levelVisibleChildrenIds = getVisibleChildrenIds(
          levelCard,
          cardsViewState
        )
        if (levelVisibleChildrenIds.size > 0) {
          levelCardId = levelVisibleChildrenIds.get(
            levelVisibleChildrenIds.size - 1
          )
          shouldDescend = true
        }
      }
    } while (shouldDescend)
    return levelCardId
  } else {
    if (isExpanded(cardsViewState, selectedCardId, routeType, topLevelCardId)) {
      const visibleChildrenIds = getVisibleChildrenIds(
        selectedCard,
        cardsViewState
      )
      if (visibleChildrenIds.size > 0) {
        return visibleChildrenIds.get(0)
      }
    }
    let levelCard = selectedCard
    let levelParentCard = parentCard
    let levelVisibleSiblingIds = visibleSiblingIds
    let levelChildIndex = selectedChildIndex
    while (levelVisibleSiblingIds.size <= levelChildIndex + 1) {
      levelCard = levelParentCard
      if (levelCard == topLevelCard || !levelCard) {
        return null
      }
      levelParentCard = cards.get(sel.card.parentId(levelCard))
      levelVisibleSiblingIds = getVisibleChildrenIds(
        levelParentCard,
        cardsViewState
      )
      levelChildIndex = levelVisibleSiblingIds.indexOf(levelCard.get('id'))
    }
    return levelVisibleSiblingIds.get(levelChildIndex + 1)
  }
}

function canSelectParent(parentId, routeType, topLevelCardId) {
  // Non-card routes, like My Week, don't support selecting top-level card (as
  // there is no such card in these routes).
  return parentId && (isRouteToCard(routeType) || parentId != topLevelCardId)
}

function getVisibleChildrenIds(parentCard, cardsViewState) {
  return parentCard.get('children').filter(childId => {
    const filteringInfo = sel.cardViewState.filteringInfo(
      cardsViewState,
      childId
    )
    return FilteringState.isVisible(filteringInfo.get('state'))
  })
}

function createPressedKeysChannel() {
  return eventChannel(emit => {
    const onKeyDown = event => handleKeyDown(event, emit)
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  })
}

function handleKeyDown(event, emit) {
  if (KEYS.indexOf(event.key) == -1 || event.altKey || event.metaKey) {
    return
  }
  const { target } = event
  const targetTagName = target.tagName && target.tagName.toLowerCase()
  const shouldHandle =
    !targetTagName ||
    (targetTagName != 'input' && targetTagName != 'textarea') ||
    (target.getAttribute('data-input-type') == 'chat-message-input' &&
      target.value == '')
  if (shouldHandle) {
    event.stopPropagation()
    event.preventDefault()
    emit(event)
  }
}
