import scrollTo from './scroll-to'


export default function scrollIntoView(element, container, paddingPx = 10, animate = false) {
  const containerBounds = container.getBoundingClientRect()
  const elementBounds = element.getBoundingClientRect()

  const dTop = elementBounds.top - containerBounds.top
  if (dTop < 0) {
    const newScrollTop = Math.max(container.scrollTop + dTop - paddingPx, 0)
    scrollTo(newScrollTop, container, animate)
    return
  }

  const dBtm = elementBounds.bottom - containerBounds.bottom
  if (dBtm > 0) {
    const scrollTopMax = Math.max(0, container.scrollHeight - containerBounds.height)
    const newScrollTop = Math.min(container.scrollTop + dBtm + paddingPx, scrollTopMax)
    scrollTo(newScrollTop, container, animate)
  }
}
