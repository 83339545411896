import React, { useRef } from 'react'
import { useClickOutside } from '~/hooks/use-click-outside'

type Props = {
  className?: string
  onClickOutside: () => void
}

export const ClickOutside: React.FC<Props> = p => {
  const wrapperRef = useRef(null)
  useClickOutside({ ref: wrapperRef, onClickOutside: p.onClickOutside })
  return (
    <div ref={wrapperRef} className={p.className}>
      {p.children}
    </div>
  )
}
