import { Map, fromJS } from 'immutable'

import { LOCATION_CHANGE } from 'connected-react-router'
import { ROUTE_CHANGED } from '~/actions/types'
import history from '~/utils/history'

const INITIAL_STATE = (routerReducer.INITIAL_STATE = Map({
  location: history.location,
  action: history.action,
  route: Map(),
  uri: null,
}))

export default function routerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ROUTE_CHANGED: {
      return state.merge({
        route: fromJS(action.route),
        location: fromJS(action.location),
        uri: action.uri,
      })
    }
    case LOCATION_CHANGE: {
      return state.mergeDeep(action.payload)
    }
  }
  return state
}
