import storage from '~/utils/session-storage'

export function setCredentials(credentials) {
  // eslint-disable-next-line
  if (DEBUG) console.log(`[auth-store] persisting credentials`)
  return storage.setItem('credentials', JSON.stringify(credentials))
}

export function getCredentials() {
  return JSON.parse(storage.getItem('credentials') || '{}')
}

export function hasCredentials() {
  return !!getToken()
}

export function clearCredentials() {
  // eslint-disable-next-line
  if (DEBUG) console.log(`[auth-store] clearing credentials`)
  storage.removeItem('credentials')
}

export function getToken() {
  return getCredentials().token
}

export function hasToken() {
  return !!getToken()
}

export function setProjectId(id) {
  storage.setItem('projectId', id)
}

export function getProjectId() {
  return storage.getItem('projectId')
}

export function setUserId(id) {
  storage.setItem('userId', id)
}

export function getUserId() {
  return storage.getItem('userId')
}

export function setRecordId(id) {
  storage.setItem('recordId', id)
}

export function getRecordId() {
  return storage.getItem('recordId')
}

export function getAgencyId() {
  return storage.getItem('agencyId')
}

export function setAgencyId(id) {
  storage.setItem('agencyId', id)
}
