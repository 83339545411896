import { gql } from '@apollo/client'

export const GetTrackingUserData = gql`
  query GetTrackingUserData {
    currentUser {
      id
      name
      email
    }
  }
`
