import mixpanel from 'mixpanel-browser'
import config from '~/config'

export const trackMixpanelEvent = (
  app: string,
  eventName: string,
  payload?: Record<string, unknown>
) => {
  if (config.IS_DEV) {
    console.log('Track event:', app, eventName, payload)
  } else {
    mixpanel.track(eventName, { ...payload, app })
  }
}
