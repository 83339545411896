import { fromJS, is, Map } from 'immutable'
import * as ActionTypes from '~/actions/types'
import { id } from '~/utils/functional'

export const INITIAL_STATE = entitiesReducer.INITIAL_STATE = Map({
  optimistic: Map({
    timeEntry: Map(),
  })
})

/**
 * This reducer handles all actions that have `entities` field,
 * including UPDATE_CARD action.
 */
export default function entitiesReducer(state = INITIAL_STATE, action) {
  if (action.type == ActionTypes.LOGOUT) {
    return INITIAL_STATE
  }
  let entities = action.entities
  if (entities) {
    // merge each entity of each type, individually, so each existing key of each
    // entity gets entirely replaced by the new value, but no existing keys get
    // removed if they are missing in the new data
    state = state.withMutations(state => {
      for (let name in entities) {
        let newEntitiesOfType = entities[name]
        let entitiesOfType = state.get(name) || Map()
        state.set(name, entitiesOfType
          ? processAndMergeEntities(entitiesOfType, newEntitiesOfType)
          : processEntities(newEntitiesOfType)
        )
      }
    })
  }


  // this is a workaround because ENTITIES_UPDATED message isn't set with feed_event
  // when a time entry is edited
  if (entities && entities.time_entry) {
    for (var entry in Object.entries(action.entities.time_entry)) {
      var [entry] = Object.entries(action.entities.time_entry);
      var [timeEntryId, timeEntry] = entry;
      if (timeEntry.isOptimistic) {
        state = state.setIn(['optimistic', 'time_entry', timeEntryId], timeEntry)
      }
      else {
        var optimisticEntry = state.getIn(['optimistic', 'time_entry', timeEntryId])
        if (optimisticEntry) {
          state = state.deleteIn(['optimistic', 'time_entry', timeEntryId])
          state = state.setIn(['feed_event', optimisticEntry.feedEventId, 'isOptimistic'], false)
        }
      }
    }
  }

  return state
}

function processEntities(newEntitiesOfType) {
  return fromJS(newEntitiesOfType)
}

function processAndMergeEntities(entities, newEntities, processNewEntity) {
  processNewEntity = processNewEntity || id
  return entities.withMutations(entities => {
    for (let id in newEntities) {
      let newEntity = newEntities[id]
      let entity = entities.get(id)
      let needsMerge = entity && !entity.get('isEphemeral')
      let processedNewEntity = processNewEntity(fromJS(newEntity))
      entities.set(id, needsMerge
        ? mergeEntity(entity, processedNewEntity)
        : processedNewEntity
      )
    }
  })
}


function mergeEntity(entity, newEntity) {
  const mergedEntity = entity.withMutations(entity => {
    entity.set('isOptimistic', false)
    const keySeq = newEntity.keySeq()
    for (let i = 0, s = keySeq.size; i < s; ++i) {
      const key = keySeq.get(i)
      const newVal = newEntity.get(key)
      if (!is(entity.get(key), newVal)) {
        entity.set(key, newVal)
      }
    }
  })
  return mergedEntity
}
