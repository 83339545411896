import { fromJS, Map, List, Set } from 'immutable'
import * as ActionTypes from '~/actions/types'
import {convertDurationFromStringToSeconds} from '~/utils/duration'
import {isCardFeed} from '~/utils/feeds'

export const INITIAL_STATE = feedsReducer.INITIAL_STATE = Map({
  byId: Map(),
  openIds: Set()
})

export default function feedsReducer(state = INITIAL_STATE, action, boundSelectors) {
  switch (action.type) {
    case ActionTypes.ENTITIES_UPDATED: {
      if (action.entities.feed_event) {
        const incomingFeedEventIds = Object.keys(action.entities.feed_event)
        return mergeFeedEventEntities(state, incomingFeedEventIds, boundSelectors.feedEvents(),
          boundSelectors.selectedProjectActivityFeedId())
      }
      return state
    }
    case ActionTypes.ENTITIES_DELETED: {
      const feedEvents = boundSelectors.feedEvents()
      const feedEventIds = action.refs.filter(r => r.type === 'feed_event').map(ref => ref.id)
      const deleteOps = feedEventIds.reduce((acc, feedEventId) => {
        const feedId = feedEvents.getIn([feedEventId, 'feed_id'])
        acc.push({feedId, feedEventId})
        if (isCardFeed(feedId)) {
          const activityFeedId = boundSelectors.selectedProjectActivityFeedId()
          acc.push({feedId: activityFeedId, feedEventId})
        }
        return acc
      }, [])
      return deleteEvents(state, deleteOps)
    }
    case ActionTypes.FEED_NEW_EVENTS: {
      return mergeFeed(state, action.feedId, getNewEventIds(action), boundSelectors.feedEvents())
    }
    case ActionTypes.FEED_DELETE_MESSAGE_OPTIMISTICALLY: {
      const {feedId, feedEventId} = action
      return deleteEvents(state, [{feedId, feedEventId}])
    }
    case ActionTypes.FEED_OPEN: {
      return state.update('openIds', openIds => openIds.add(action.feedId))
    }
    case ActionTypes.FEED_CLOSE: {
      return state.update('openIds', openIds => openIds.delete(action.feedId))
    }
    case ActionTypes.FEED_POST_MESSAGE_OPTIMISTICALLY: {
      const incomingFeedEventIds = Object.keys(action.entities.feed_event)
      return mergeFeedEventEntities(state, incomingFeedEventIds, boundSelectors.feedEvents(),
        boundSelectors.selectedProjectActivityFeedId())
    }
  }
  return state
}

function getNewEventIds(action) {
  return action.events.map(({id}) => id)
}

function getFeedToEventIdsMap(incomingFeedEventIds, feedEvents, activityFeedId) {
  return incomingFeedEventIds.reduce((acc, feedEventId) => {
    const feedId = feedEvents.getIn([feedEventId, 'feed_id'])
    if (!acc[feedId]) {
      acc[feedId] = []
    }
    acc[feedId].push(feedEventId)
    if (isCardFeed(feedId)) {
      if (!acc[activityFeedId]) {
        acc[activityFeedId] = []
      }
      acc[activityFeedId].push(feedEventId)
    }
    return acc
  }, {})
}

function mergeFeedEventEntities(state = INITIAL_STATE, incomingFeedEventIds, feedEvents,
 activityFeedId) {
  const feedToEventIdsMap = getFeedToEventIdsMap(incomingFeedEventIds, feedEvents, activityFeedId)
  return Object.keys(feedToEventIdsMap).reduce((acc, feedId) => {
    const newFeedEventIds = feedToEventIdsMap[feedId]
    return mergeFeed(acc, feedId, newFeedEventIds, feedEvents)
  }, state)
}

function mergeFeed(state = INITIAL_STATE, feedId, incomingEventIds, feedEvents) {
  return state.updateIn(['byId', feedId, 'events'], (existingEventIds) => {
    return mergeEvents(existingEventIds || List(), incomingEventIds, feedEvents)
  })
}

function matchWithOptimistic(optimisticEvent, event) {
  return event.get('body') === optimisticEvent.get('body')
}

function unique(value, index, _self) {
  return _self.indexOf(value) === index
}

function mergeEvents(existingEventIds, incomingEventIds, feedEvents) {
  const incomingEvents = incomingEventIds.map(id => feedEvents.get(id))
  return existingEventIds.filter(id => {
              const event = feedEvents.get(id)
              return !(
                event.get('isOptimistic') &&
                incomingEvents.find(incoming => matchWithOptimistic(event, incoming))
              )
            })
            .concat(fromJS(incomingEventIds))
            .filter(unique)
            .sort()
}

function deleteEvents(state = INITIAL_STATE, deleteOperations) {
  if (deleteOperations.length == 0) {
    return state
  }

  return deleteOperations.reduce((acc, {feedId, feedEventId}) => {
    return acc.updateIn(['byId', feedId, 'events'], events => {
      if (!events) {
        //happens in case project feed wasn't loaded even once
        return List()
      }
      return events.filter(id => id !== feedEventId)
    })
  }, state)
}

export function clear(state = INITIAL_STATE) {
  return INITIAL_STATE
}
