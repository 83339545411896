import store from '~/store'
import * as actions from '~/actions'
import { generateDirectFeedId } from '~/utils/feeds'

export const handleCardClick = (projectId: string | null | undefined) => (
  cardId: string
) => store.dispatch(actions.revealCard(cardId, projectId))

export const handleMentionClick = (currentUserId: string) => (
  userId: string
) => {
  if (userId === currentUserId) {
    return
  }
  store.dispatch(
    actions.toggleDirectChat(generateDirectFeedId(userId, currentUserId))
  )
}

export const handleChatClose = (chatId: string) => () =>
  store.dispatch(actions.toggleDirectChat(chatId))
