export function merge(...args) {
  return Object.assign({}, ...args)
}

export function set(obj, k, v) {
  if (obj[k] == v) return obj // if no mutation is necessary return obj as is
  return merge(obj, { [k]: v })
}

export function setIn(obj, path, value) {
  const k = path[0]
  if (path.length > 1) {
    const newChild = setIn(obj[k], path.slice(1), value)
    return set(obj, k, newChild)
  } else {
    return set(obj, k, value)
  }
}
