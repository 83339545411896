import React from 'react'
import cn from 'classnames'

import CardFeedHeader from '../card-feed-header'
import { ChatContainerRedux } from '../chat/redux-container'

export default class FullscreenFeed extends React.Component {
  render() {
    const containerClasses = cn(
      'fullscreen-feed',
      this.props.isFullscreenFeedOpen && '-open'
    )
    const closeClasses = cn(
      'close',
      !this.props.isFullscreenFeedOpen && '-close'
    )
    return (
      <div className={containerClasses}>
        <div
          className={closeClasses}
          onClick={this.props.closeFullscreenFeed}
        ></div>
        <div className="centered">
          <ChatContainerRedux
            chatId={this.props.selectedFeedId}
            header={<CardFeedHeader isFullscreen={true} />}
          />
        </div>
      </div>
    )
  }
}
