import { take, call } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

// import { submitAvailabilityGQL } from '~/api/submit-availability'
const submitAvailabilityGQL = () => {}

export default function* $availabilitySaga() {
  while (true) {
    const { start, end, availability } = yield take(
      ActionTypes.SUBMIT_AVAILABILITY
    )
    try {
      const response = yield call(
        submitAvailabilityGQL,
        start,
        end,
        availability
      )

      yield* $dispatch(Actions.availabilitySubmitted())
    } catch (e) {
      console.error('{ERROR} failed file upload', e)
      yield* $dispatch(Actions.availabilitySubmissionFailed(e))
    }
  }
}
