import React from 'react'

type RoundLoaderType = {
  size?: number
}

export const RoundLoader = ({ size = 30 }: RoundLoaderType) => {
  return (
    <div
      className="round-loader"
      style={{ width: size, height: size, borderWidth: size / 10 }}
    />
  )
}
