import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import {
  GetChatData_feed_feedEvents,
  GetChatData_feed_feedEvents_bodyTokens,
  GetChatData_feed_members,
} from '~/types/api'
import { UserMessage } from './user-message'
import { DateSeparator, ServiceMessage } from './service-message'
import dayjs from '~/utils/dayjs'
import Scrollable from '../scrollable'
import { Waypoint } from 'react-waypoint'
import { DELTA_TIME_SECONDS } from './util/constants'

type ChatMessagesProps = {
  currentUserId: string
  chatElRef: React.RefObject<HTMLDivElement>
  messages: (GetChatData_feed_feedEvents | null)[]
  onLoadMore: () => void
  onCardClick: (cardId: string) => void
  onDateTimeClick: (dateTime: string) => void
  onUserClick: (
    userId: string | null | undefined,
    sourceRef: React.RefObject<HTMLDivElement>
  ) => void
  onEditMessage: (
    feedEventId: string,
    bodyTokens: (GetChatData_feed_feedEvents_bodyTokens | null)[] | null
  ) => void
  onDeleteMessage: (feedEventId: string, timeEntryId?: string | null) => void
  onAddReaction: (feedEventId: string, emoji: string) => void
  onRemoveReaction: (feedEventId: string, emoji: string) => void
  withServiceMessages?: boolean
  chatMembers: (GetChatData_feed_members | null)[] | null
  theme?: string
}

export const ChatMessages = ({
  messages,
  chatElRef,
  theme,
  ...props
}: ChatMessagesProps) => {
  const [scrollHeight, setScrollHeight] = useState(
    chatElRef?.current?.scrollHeight
  )
  const prevLastIdRef = useRef<string | null | undefined>()
  useEffect(() => {
    prevLastIdRef.current = messages[messages.length - 1]?.id
  })
  const prevLastMessageId = prevLastIdRef.current

  const scrollToBottom = useCallback(() => {
    if (chatElRef.current) {
      chatElRef.current.scrollTop = chatElRef.current.scrollHeight
    }
  }, [chatElRef.current])

  useEffect(() => {
    scrollToBottom()
  }, [])

  useEffect(() => {
    const lastMessageId = messages[messages.length - 1]?.id
    if (lastMessageId !== prevLastMessageId) {
      scrollToBottom()
    } else if (scrollHeight && chatElRef.current) {
      chatElRef.current.scrollTop =
        chatElRef.current.scrollHeight - scrollHeight
    }
    setScrollHeight(chatElRef.current?.scrollHeight)
  }, [messages.length])

  const parseRawMessage = useCallback(
    (
      message: GetChatData_feed_feedEvents,
      prevMessage?: GetChatData_feed_feedEvents | null
    ) => {
      if (message.sender) {
        const isChained =
          dayjs(message.createdAt).diff(
            dayjs(prevMessage?.createdAt),
            'second'
          ) < DELTA_TIME_SECONDS &&
          prevMessage?.sender?.id === message.sender.id

        return (
          <UserMessage
            key={message.id!}
            message={message}
            isChained={isChained}
            theme={theme}
            {...props}
          />
        )
      } else if (props.withServiceMessages) {
        return (
          <ServiceMessage
            key={message.id!}
            message={message}
            theme={theme}
            {...props}
          />
        )
      } else {
        return <div key={message.id!} />
      }
    },
    [theme]
  )

  const renderMessages = useCallback(() => {
    if (!messages) {
      return []
    }
    if (messages.length === 0) {
      return <div className="no-messages">No messages yet.</div>
    }
    const result: JSX.Element[] = []
    messages.forEach((message, i) => {
      if (!message) {
        return null
      }
      const prev = messages[i - 1] ?? null
      if (
        !prev ||
        !dayjs(message.createdAt).isSame(dayjs(prev.createdAt), 'day')
      ) {
        result.push(
          <DateSeparator
            key={message.createdAt!}
            date={message.createdAt!}
            theme={theme}
          />
        )
      }
      result.push(parseRawMessage(message, prev))
    })
    return result
  }, [messages, theme])

  return (
    <Scrollable
      className={cn('messages', { 'theme-dark': theme === 'dark' })}
      divRef={chatElRef}
    >
      {!!messages.length && <Waypoint onEnter={props.onLoadMore} />}
      {renderMessages()}
    </Scrollable>
  )
}
