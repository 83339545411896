import dayjs from '~/utils/dayjs'
import React from 'react'

import DayPicker, { WeekdayPropTypes } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

function Weekday({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale)
  return (
    <div className={className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </div>
  )
}

Weekday.propTypes = WeekdayPropTypes

export default class TurtleDayPicker extends React.Component {
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.state = {
      selectedDay: props.currentDate
        ? dayjs(props.currentDate, 'YYYY-MM-DD').toDate()
        : null,
    }
  }

  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
    })
    const formattedDay = dayjs(day).format('YYYY-MM-DD')
    this.props.setDate(formattedDay)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedDay: dayjs(nextProps.currentDate, 'YYYY-MM-DD').toDate(),
    })
  }

  render() {
    const { selectedDay } = this.state
    return (
      <div className="turtle-day-picker">
        <DayPicker
          selectedDays={this.state.selectedDay}
          month={selectedDay}
          showOutsideDays
          onDayClick={this.handleDayClick}
          weekdayElement={<Weekday />}
          disabledDays={
            this.props.disabledDays ? this.props.disabledDays : null
          }
        />
      </div>
    )
  }
}
