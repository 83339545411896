import React from 'react'
import connect from '../utils/connect'
import store from '~/store'
import * as actions from '~/actions'
import SignIn from './sign-in'
import { buildPathFromLocation } from '~/utils/url'
import { useSelector } from 'react-redux'
import mapState from './map-state'
import { TurtleCredentials } from '~/utils/google-auth-flow'

const SignInContainer = () => {
  const isLoggingIn = useSelector<any, boolean>(mapState)

  const handleAuthSuccess = (credentials: TurtleCredentials) => {
    const { location } = window
    const returnPath = buildPathFromLocation(location)
    store.dispatch(actions.loginSuccess(credentials, returnPath))
  }

  const handleAuthError = () => {
    store.dispatch(actions.loginError('Error'))
  }

  return (
    <SignIn
      showSpinner={isLoggingIn}
      onAuthSuccess={handleAuthSuccess}
      onAuthError={handleAuthError}
    />
  )
}

export default connect(SignInContainer)
