import React from 'react'
import cn from 'classnames'

import CardListItemLayout from './card-list-item-layout'

import {CARD_LIST_PADDING_RIGHT} from './card-list-constants'
import {CARD_CLASS, BEFORE_TEXT_ACTIONS_CLASS, getLevelIndent} from './card-list-constants'
import {CARD_TEXT_SECTION_CLASS, CHECKBOX_CLASS} from './card-list-constants'

import {propTypes, defaultProps} from './card-list-item-prop-types'
import CardListItemInput from './card-list-item-input'


export default class EditingCardListItem extends React.Component {

  static propTypes = propTypes
  static defaultProps = defaultProps

  render() {
    const {props} = this
    return (
      <CardListItemLayout
        filteringInfo={props.filteringInfo}
        isExpanded={props.isExpanded}
        renderCard={this.renderCard}
        renderChildren={this.props.renderChildren}
        expandCollapseAnimationManager={props.expandCollapseAnimationManager}>
      </CardListItemLayout>
    )
  }

  renderCard = () => {
    const {props} = this
    const cardClasses = cn(CARD_CLASS, 'editing',
      props.isCompleted ? 'completed' : 'pending',
      props.isSelected && 'selected'
    )
    const cardStyle = {
      paddingRight: CARD_LIST_PADDING_RIGHT
    }
    const beforeTextActionsStyle = {
      marginLeft: getLevelIndent(props.cardLevel)
    }
    const bulletClass = props.isEphemeral ? 'new-task-icon' : `${CHECKBOX_CLASS} -editing`
    const {editingState} = props
    return (
      <div className={cardClasses} style={cardStyle} data-card-id={props.id}>
        <div className={BEFORE_TEXT_ACTIONS_CLASS} style={beforeTextActionsStyle}>
          <span className={bulletClass} />
        </div>
        <div className={CARD_TEXT_SECTION_CLASS}>
          <CardListItemInput
            value={props.name}
            selectionStart={editingState && editingState.get('selectionStart')}
            selectionEnd={editingState && editingState.get('selectionEnd')}
            submit={this.onSubmit}
            cancel={props.actions.cancelEditing}
            delete={this.delete}
            changeNestingLevel={props.actions.changeNestingLevel}>
          </CardListItemInput>
        </div>
      </div>
    )
  }

  onSubmit = (newName, enterPressed) => {
    if (newName) {
      this.props.actions.commitEditing(newName, enterPressed)
    } else {
      this.delete()
    }
  }

  delete = () => {
    this.props.actions.cancelEditing()
    if (!this.props.isEphemeral) {
      this.props.actions.delete()
    }
  }
}
