/* eslint-disable no-undef */
import { matchQueryParam, removeQueryParam } from '~/utils/url'
import { buildRuntimeEnv, detectAppEnv } from '~/utils/config'

// In web app, CONFIG here will be replaced by Webpack with actual object.
const { compileTimeEnv, env, environments } = CONFIG

const appEnv = detectAppEnv(
  { location: window.location + '', appEnv: CONFIG.APP_ENV },
  'development'
)
const runtimeEnv = buildRuntimeEnv(['INITIAL_LOCATION', 'HOST', 'PROTOCOL'])
const config = Object.assign(
  {},
  compileTimeEnv,
  env,
  environments[appEnv],
  runtimeEnv,
  {
    IS_DEV:
      CONFIG.APP_ENV === 'development' ||
      CONFIG.APP_ENV === 'development-local',
  }
)

export function makeAuthURL(provider, state = 'empty') {
  const authRedirectURI = config.DEV
    ? config.DEV_SERVER_URL
    : config.PROTOCOL + '//' + config.HOST
  const redirectURI = encodeURIComponent(authRedirectURI)
  const url = `${config.AUTH_BASE_URL}/${provider}?state=${state}&redirect_uri=${redirectURI}`
  return activatedWithLoginCode
    ? url + `&access_token=` + encodeURIComponent(activatedWithLoginCode)
    : url
}

let activatedWithLoginCode

if (window.location.search) {
  const url = '' + window.location
  const loginCode = matchQueryParam('login_code', url)
  if (loginCode) {
    activatedWithLoginCode = loginCode
    history.replaceState(
      {},
      document.title,
      removeQueryParam('login_code', url)
    )
  }
}

export default config
