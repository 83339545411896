import { Map, List } from 'immutable'

import * as ActionTypes from '~/actions/types'
import CardRelation from '~/utils/card-relation'
import { convertDurationFromStringToSeconds } from '~/utils/duration'
import sel from '~/selectors'

const OPTIMISTIC_COMPLETED = ['optimistic', 'isCompleted']
const OPTIMISTIC_COMPLETED_AT = ['optimistic', 'completedAt']
const OPTIMISTIC_DELETED = ['optimistic', 'isDeleted']
const OPTIMISTIC_NAME = ['optimistic', 'name']
const OPTIMISTIC_CURRENT_PARENT_ID = ['optimistic', 'currentParentId']
const OPTIMISTIC_ASSIGNEE = ['optimistic', 'assignee']
const OPTIMISTIC_DUE_DATE = ['optimistic', 'dueDate']
const OPTIMISTIC_IS_EDITING = ['optimistic', 'isEditing']

export default function cardsReducer(state, action, selectMovingOpId) {
  switch (action.type) {
    case ActionTypes.ASSIGN_CARD: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_ASSIGNEE, action.assigneeId)
      )
    }
    case ActionTypes.UNASSIGN_CARD: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_ASSIGNEE, null)
      )
    }
    case ActionTypes.MARK_CARD_WITH_ID_COMPLETED: {
      return state.update(action.cardId, card => {
        card = card.setIn(OPTIMISTIC_COMPLETED, action.isCompleted)
        card = action.isCompleted
          ? card.setIn(OPTIMISTIC_COMPLETED_AT, new Date().toISOString())
          : card.setIn(OPTIMISTIC_COMPLETED_AT, null)
        return card
      })
    }
    case ActionTypes.START_EDITING_CARD: {
      return state.update(action.cardId, card => {
        return card
          .setIn(OPTIMISTIC_IS_EDITING, true)
          .setIn(OPTIMISTIC_NAME, card.get('name'))
      })
    }
    case ActionTypes.CHANGE_CARD_NESTING_LEVEL: {
      return state.updateIn([action.cardId, 'optimistic'], opt =>
        opt.merge({
          name: action.currentName,
          editingState: Map({
            selectionStart: action.selectionStart,
            selectionEnd: action.selectionEnd,
          }),
        })
      )
    }
    case ActionTypes.COMMIT_EDITING_CARD: {
      return state.update(action.cardId, card => {
        card = card.setIn(OPTIMISTIC_IS_EDITING, false)
        return card.setIn(OPTIMISTIC_NAME, action.newName)
      })
    }
    case ActionTypes.DELETE_CARD: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_DELETED, true)
      )
    }
    case ActionTypes.UNDELETE_CARD: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_DELETED, false)
      )
    }
    case ActionTypes.SET_CARD_DUE_DATE: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_DUE_DATE, action.dueDate)
      )
    }
    case ActionTypes.UNSET_CARD_DUE_DATE: {
      return state.update(action.cardId, card =>
        card.setIn(OPTIMISTIC_DUE_DATE, null)
      )
    }
    case ActionTypes.CREATE_EPHEMERAL_CARD: {
      let { parentId, ephemeralId, top } = action
      return state.withMutations(state => {
        const parent = state.get(parentId)
        if (top) {
          state.set(
            parentId,
            parent.update('children', children => children.unshift(ephemeralId))
          )
        } else {
          state.set(
            parentId,
            parent.update('children', children => children.push(ephemeralId))
          )
        }
        state.set(
          ephemeralId,
          Map({
            id: ephemeralId,
            name: '',
            isEphemeral: true,
            parentId: parentId,
            path: parent.get('path').push(Map({ id: ephemeralId })),
            optimistic: Map({
              name: '',
              assignee: null,
              isCompleted: false,
              isEditing: true,
            }),
            children: List(),
            top: top,
          })
        )
      })
    }
    case ActionTypes.REPLACE_EPHEMERAL_CARD: {
      let { ephemeralId, finalId, parentId } = action
      return state.withMutations(state => {
        let parent = state.get(parentId)
        let children = parent.get('children')
        let index = children.indexOf(ephemeralId)
        children = children.set(index, finalId)
        parent = parent.set('children', children)
        state.set(parentId, parent)

        // keep the path property in sync with the state
        let newPath = parent.get('path').push(finalId)
        let newCard = state.get(finalId).set('path', newPath)
        state.set(finalId, newCard)

        state.delete(ephemeralId)
      })
    }
    case ActionTypes.DELETE_EPHEMERAL_CARD: {
      const { ephemeralId } = action
      return state.withMutations(state => {
        const ephemeralCard = state.get(ephemeralId)
        const parentId = sel.card.parentId(ephemeralCard)
        const parent = state.get(parentId).update('children', children => {
          const index = children.indexOf(ephemeralId)
          return children.splice(index, 1)
        })
        state.set(parentId, parent)
        state.delete(ephemeralId)
      })
    }
    case ActionTypes.MOVE_CARD: {
      return selectMovingOpId() ? state : moveCard(state, action)
    }
    case ActionTypes.CLEAR_CARD_OPTIMISTIC_STATE: {
      return state.update(action.cardId, card => card.delete('optimistic'))
    }
    case ActionTypes.UPDATE_ESTIMATE: {
      return state.update(action.cardId, card =>
        card.set(
          'estimate',
          Map({
            min: action.estimate.min,
            max: action.estimate.max,
          })
        )
      )
    }
    case ActionTypes.INCREASE_BUDGET: {
      return state.update(action.cardId, card =>
        card.set(
          'budget',
          Map({
            duration: convertDurationFromStringToSeconds(action.budget),
          })
        )
      )
    }
    case ActionTypes.DECREASE_BUDGET: {
      return state.update(action.cardId, card =>
        card.set(
          'budget',
          Map({
            duration: convertDurationFromStringToSeconds(action.budget),
          })
        )
      )
    }
  }
  return state
}

function moveCard(state, action) {
  const { targetId, relation, cardId } = action
  const card = state.get(cardId)

  const prevParentId = sel.card.parentId(card)
  const prevParent = state.get(prevParentId)

  let newParentId

  if (relation == CardRelation.CHILD) {
    newParentId = targetId
  } else {
    const newSibling = state.get(targetId)
    newParentId = sel.card.parentId(newSibling)
    if (newParentId == null) {
      // already under root level card
      return state
    }
  }

  const newParent = state.get(newParentId)

  if (newParent.get('isEphemeral')) {
    // disallow moving cards under ephemeral parents as we don't have their real id yet
    return state
  }

  if (prevParent == newParent) {
    // TODO: assert that relation is not CHILD
    const siblings = prevParent.get('children')
    const prevIndex = siblings.indexOf(cardId)
    const siblingsWithoutCard = siblings.delete(prevIndex)
    const newIndex = getNewIndex(siblingsWithoutCard, targetId, relation)
    return state.set(
      prevParentId,
      prevParent.set('children', siblingsWithoutCard.insert(newIndex, cardId))
    )
  }

  const prevSiblings = prevParent.get('children')
  const prevIndex = prevSiblings.indexOf(cardId)
  const newSiblings = newParent.get('children')

  const newIndex =
    relation == CardRelation.CHILD
      ? newSiblings.size
      : getNewIndex(newSiblings, targetId, relation)

  return state.withMutations(state => {
    state.set(
      prevParentId,
      prevParent.set('children', prevSiblings.delete(prevIndex))
    )
    state.set(
      newParentId,
      newParent.set('children', newSiblings.insert(newIndex, cardId))
    )
    state.set(
      cardId,
      card.withMutations(card => {
        rebuildPath(card, newParent)
        card.setIn(OPTIMISTIC_CURRENT_PARENT_ID, prevParentId)
      })
    )
  })
}

function rebuildPath(card, parent) {
  return card.withMutations(card => {
    const cardId = card.get('id')
    card.set('path', parent.get('path').push(cardId))
  })
}

function getNewIndex(siblings, siblingId, relation) {
  return siblings.indexOf(siblingId) + (relation == CardRelation.AFTER ? 1 : 0)
}
