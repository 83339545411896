import PropTypes from 'prop-types'
import React from 'react'
import connect from '../utils/connect'
import cn from 'classnames'

import AssigneeCard from '../assignee-card'
import DueDateDropdown from '../due-date-dropdown'
import BudgetDropdown from '../budget-dropdown'
import MoreActions from '../more-actions'
import WrapWithText from '../wrap-with-text'
import CardListItemInput from '../card-list/card-list-item-input'

import CardPath from './card-path'
import CardListHeaderFilters from './card-list-header-filters'

import sel from '~/selectors'

import { Filters, FilterValues } from '~/utils/filter-constants'
import { setClipboardText } from '~/utils/clipboard'
import { stopPropagation } from '~/utils/dom'
import { formatDuration } from '~/utils/time'
import { formatInterval, isValidDurationString } from '~/utils/duration'
import { iterablesShallowEqual } from '~/utils/shallow-compare'
import { buildPath, ROUTE_PLAN, ROUTE_MY_TASKS } from '~/utils/routing'
import TaskListsLinks from '~/components/task-lists-links'

const noop = () => {}

class CardListHeaderContainer extends React.Component {
  static propTypes = {
    topLevelCardId: PropTypes.string.isRequired,
    selectCard: PropTypes.func.isRequired,
    makeCardHref: PropTypes.func.isRequired,
  }

  static mapStateToProps() {
    let lastPath = null
    let lastPathSegments = null

    return function mapStateToProps(state, props) {
      const { topLevelCardId } = props
      const cardEntities = sel.cards(state)
      const card = cardEntities.get(topLevelCardId)

      let path = sel.card.optimisticPath(card).map(id => cardEntities.get(id))
      let pathSegments

      if (iterablesShallowEqual(path, lastPath)) {
        path = lastPath
        pathSegments = lastPathSegments
      } else {
        lastPath = path
        lastPathSegments = pathSegments = path
          .map(card => {
            return {
              id: card.get('id'),
              name: sel.card.optimisticName(card),
            }
          })
          .toJS()
        lastPathSegments.pop()
      }

      const assigneeId = sel.card.optimisticAssigneeId(card)
      const assignee = assigneeId && sel.users(state).get(assigneeId)

      return {
        cardTitle: sel.card.optimisticName(card),
        cardPath: pathSegments,
        projectMembers: sel.selectedProjectMembers(state),
        assignee,
        projectId: sel.card.projectId(card),
        cardId: card.get('id'),
        estimateRollup: sel.getEstimateRollup(state, card),
        timetrackRollup: sel.getTimetrackRollup(state, card),
        budget: sel.getBudget(state, card),
        dueDate: sel.card.optimisticDueDate(card),
        parentCardId: sel.card.parentId(card),
        isCompleted: sel.card.isOptimisticallyCompleted(card),
        routeType: sel.routeType(state),
        timeFilterValue: sel.filters(state).get(Filters.TIME),
        me: sel.me(state),
        selectedCardId: sel.selectedCardId(state),
      }
    }
  }

  constructor() {
    super()
    this.state = {
      isEditing: false,
      budgetDropdownOpened: false,
      rollupsVisible: false,
    }
    this.selectTopLevelCard = this.selectTopLevelCard.bind(this)
    this.clearCardSelection = this.clearCardSelection.bind(this)
    this.assignTopLevelCard = this.assignTopLevelCard.bind(this)
    this.unassignTopLevelCard = this.unassignTopLevelCard.bind(this)
    this.deleteTopLevelCard = this.deleteTopLevelCard.bind(this)
    this.getMoreActions = this.getMoreActions.bind(this)
    this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this)
    this.toggleBudgetDropdown = this.toggleBudgetDropdown.bind(this)
    this.showRollupsSection = this.showRollupsSection.bind(this)
    this.hideRollupsSection = this.hideRollupsSection.bind(this)
    this.toggleCompleted = this.toggleCompleted.bind(this)
    this.openCalendar = this.openCalendar.bind(this)
    this.setCardDueDate = this.setCardDueDate.bind(this)
    this.unsetCardDueDate = this.unsetCardDueDate.bind(this)
    this.handleDueDateDropdownRef = ref => {
      this.dueDateDropdown = ref
    }
    this.startEditing = this.startEditing.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.changeName = this.changeName.bind(this)
  }

  render() {
    const { props, state } = this
    let cardPathComponent

    const isSelected = props.cardId === props.selectedCardId
    const isPlan = props.routeType === ROUTE_PLAN

    if (props.cardPath.length > 0) {
      cardPathComponent = (
        <CardPath
          path={props.cardPath}
          showCard={props.actions.showCard}
          makeHref={props.makeCardHref}
        ></CardPath>
      )
    } else {
      cardPathComponent = (
        <div className="viewing-all">{this.getListDescription()}</div>
      )
    }

    return (
      <div
        className={cn(
          'card-list-header',
          props.isCompleted && '-completed',
          isSelected && '-selected',
          isPlan && '-at-plan-view',
          state.isEditing && '-editing',
          this.isEditable() && '-editable'
        )}
        onClick={
          isPlan ? () => props.actions.selectCard(props.cardId) : undefined
        }
      >
        <div className="first-row">
          {cardPathComponent}
          <TaskListsLinks projectId={props.projectId} />
        </div>
        <div className="second-row">
          {state.isEditing ? this.renderEditingCard() : this.renderCard()}
        </div>
        <div className="third-row">
          <CardListHeaderFilters />
        </div>
        {this.renderBudgetDropdown()}
        {this.state.rollupsVisible ? this.renderBudgetSection() : ''}
      </div>
    )
  }

  getListDescription() {
    const { props } = this
    if (this.props.routeType === ROUTE_PLAN) {
      return 'Viewing all tasks in the project'
    }
    switch (props.timeFilterValue) {
      case FilterValues.Time.NEXT_WEEK:
        return 'Viewing all your tasks scheduled for the next 7 days'
      case FilterValues.Time.PREV_WEEK:
        return 'Viewing all your tasks scheduled for the previous 7 days'
      case null:
        return 'Viewing all tasks assigned to you'
    }
  }

  getListTitle() {
    const { props } = this
    if (props.routeType !== ROUTE_MY_TASKS) {
      return props.cardTitle
    }
    switch (props.timeFilterValue) {
      case FilterValues.Time.NEXT_WEEK:
        return 'Next 7 days'
      case FilterValues.Time.PREV_WEEK:
        return 'Past 7 days'
      case null:
        return 'My Tasks'
    }
  }

  getEstimate() {
    const { props } = this
    const { estimateRollup } = props

    if (!props.parentCardId) {
      return ''
    } else {
      return formatInterval(
        estimateRollup.get('min'),
        estimateRollup.get('max')
      )
    }
  }

  getBudget() {
    const { props } = this
    const { budget } = props

    if (!props.parentCardId || !budget) {
      return ''
    } else {
      return formatDuration(budget.get('duration'))
    }
  }

  getTimetracked() {
    const { props } = this
    const { timetrackRollup } = props

    if (!props.parentCardId) {
      return ''
    } else {
      return formatDuration(timetrackRollup)
    }
  }

  getBudgetRemaining() {
    const { props } = this
    const { timetrackRollup, budget } = props
    if (!budget) {
      return '0h'
    }
    return formatDuration(budget.get('duration') - timetrackRollup)
  }

  getProjectedSpend() {
    const { props } = this
    const { timetrackRollup, estimateRollup, isCompleted } = props
    if (isCompleted) {
      return formatDuration(timetrackRollup)
    } else {
      return formatDuration(
        Math.max(estimateRollup.get('max'), timetrackRollup)
      )
    }
  }

  getUnallocatedBudget() {
    //Difference between budget and projected spend
    const { props } = this
    const { timetrackRollup, estimateRollup, budget, isCompleted } = props
    if (isCompleted) {
      return formatDuration(budget.get('duration') - timetrackRollup)
    } else {
      return formatDuration(
        budget.get('duration') -
          Math.max(estimateRollup.get('max'), timetrackRollup)
      )
    }
  }

  selectTopLevelCard() {
    this.props.selectCard(this.props.topLevelCardId)
  }

  clearCardSelection() {
    this.props.selectCard()
  }

  assignTopLevelCard(assigneeId) {
    this.props.actions.assignCard(this.props.topLevelCardId, assigneeId)
  }

  unassignTopLevelCard() {
    this.props.actions.unassignCard(this.props.topLevelCardId)
  }

  deleteTopLevelCard() {
    const { parentCardId } = this.props
    if (!parentCardId) {
      // foolproofing: refuse to delete a project card
      return
    }
    this.props.actions.showCard(parentCardId)
    this.props.actions.deleteCard(this.props.topLevelCardId)
  }

  copyLinkToClipboard() {
    const { routeType } = this.props
    const link =
      'https://oldapp.turtleos.com' +
      buildPath({
        type: routeType,
        cardId: this.props.cardId,
        projectId: this.props.projectId,
      })
    setClipboardText(link)
  }

  renderBudgetDropdown() {
    const budgetDropdownOpened = this.state.budgetDropdownOpened
    return (
      budgetDropdownOpened && (
        <BudgetDropdown
          budget={this.getBudget()}
          budgetRemaining={this.getBudgetRemaining()}
          closeSetBudgetForm={() => this.toggleBudgetDropdown()}
          confirmSetBudget={(budget, decrease) => {
            this.confirmSetBudget(budget, decrease)
          }}
          showRollupsSection={() => this.showRollupsSection()}
        />
      )
    )
  }

  toggleBudgetDropdown() {
    const budgetDropdownState = this.state.budgetDropdownOpened
    this.setState({ budgetDropdownOpened: !budgetDropdownState })
    this.props.actions.toggleBudgetForm()
  }

  showRollupsSection() {
    this.setState({ rollupsVisible: true })
    this.props.actions.showRollups()
  }

  hideRollupsSection() {
    this.setState({ rollupsVisible: false })
    this.props.actions.hideRollups()
  }

  confirmSetBudget(budget, decrease) {
    if (!isValidDurationString(budget)) {
      return
    }
    if (decrease) {
      const absBudget = budget.replace(/-( )*/g, '')
      this.props.actions.decreaseBudget(this.props.cardId, absBudget)
    } else {
      this.props.actions.increaseBudget(this.props.cardId, budget)
    }
    this.toggleBudgetDropdown()
  }

  toggleCompleted() {
    const { isCompleted } = this.props
    this.props.actions.markCardWithIdCompleted(this.props.cardId, !isCompleted)
  }

  startEditing() {
    this.props.actions.startEditingCard(this.props.cardId)
    this.setState({ isEditing: true })
  }

  cancelEditing() {
    this.props.actions.cancelEditingCard(this.props.cardId)
    this.setState({ isEditing: false })
  }

  changeName(newName) {
    if (newName) {
      this.props.actions.commitEditingCard(this.props.cardId, newName)
    }
    this.setState({ isEditing: false })
  }

  openCalendar() {
    this.dueDateDropdown.showDueDatePopup()
  }

  setCardDueDate(dueDate) {
    this.props.actions.setCardDueDate(this.props.cardId, dueDate)
  }

  unsetCardDueDate() {
    this.props.actions.unsetCardDueDate(this.props.cardId)
  }

  getMoreActions() {
    return [
      this.getDetailsAction(),
      this.getCopyLinkAction(),
      { name: '-' },
      this.getCompleteAction(),
      this.getEditAction(),
      this.getDueDateAction(),
      { name: '-' },
      this.getSetBudgetAction(),
      this.getShowRollupsAction(),
      { name: '-' },
      this.getDeleteAction(),
    ]
  }

  getCopyLinkAction() {
    return { name: 'Copy link', icon: 'link', action: this.copyLinkToClipboard }
  }

  getSetBudgetAction() {
    return {
      name: 'Set budget',
      icon: 'budget',
      action: this.toggleBudgetDropdown,
    }
  }

  getShowRollupsAction() {
    return {
      name: 'Show rollups',
      icon: 'info',
      action: this.showRollupsSection,
    }
  }

  getCompleteAction() {
    const { parentCardId, isCompleted } = this.props
    if (parentCardId) {
      return isCompleted
        ? { name: 'Incomplete', icon: 'uncheck', action: this.toggleCompleted }
        : { name: 'Complete', icon: 'check', action: this.toggleCompleted }
    }
  }

  getDetailsAction() {
    const { props } = this
    if (props.routeType === ROUTE_PLAN) {
      return props.selectedCardId !== props.topLevelCardId
        ? {
            name: 'Show details',
            icon: 'chat',
            action: this.selectTopLevelCard,
          }
        : {
            name: 'Hide details',
            icon: 'chat-outline',
            action: this.clearCardSelection,
          }
    }
  }

  getDueDateAction() {
    const { parentCardId } = this.props
    if (parentCardId) {
      return { name: 'Due date', icon: 'due-date', action: this.openCalendar }
    }
  }

  getDeleteAction() {
    const { parentCardId } = this.props
    if (parentCardId) {
      return {
        name: 'Delete',
        icon: 'delete',
        action: this.deleteTopLevelCard,
        isRed: true,
      }
    }
  }

  getEditAction() {
    if (this.isEditable()) {
      return { name: 'Edit', icon: 'edit', action: this.startEditing }
    }
  }

  renderEditingCard() {
    const { props } = this
    return (
      <CardListItemInput
        value={props.cardTitle}
        submit={this.changeName}
        cancel={this.cancelEditing}
        flavor={CardListItemInput.Flavor.IN_HEADER}
      ></CardListItemInput>
    )
  }

  renderBudgetSection() {
    return (
      <div className="fourth-row">
        <div>
          Budget remaining
          <span
            className={cn('count', 'budget')}
            onClick={() => {
              this.toggleBudgetDropdown()
            }}
          >
            {this.getBudgetRemaining()}
          </span>
        </div>
        <div>
          Tracked
          <span className="count">{this.getTimetracked()}</span>
        </div>
        <div>
          Estimates
          <span className="count">{this.getEstimate()}</span>
        </div>
        <div
          className="hide"
          onClick={() => {
            this.hideRollupsSection()
          }}
        >
          Hide
        </div>
      </div>
    )
  }

  renderCard() {
    const { props } = this
    const isSelected = props.cardId === props.selectedCardId
    const cardTitle = this.getListTitle()
    const isEditableCardTitle = this.isEditable() && isSelected
    return (
      <div
        className="card-title"
        onClick={() => isEditableCardTitle && this.startEditing()}
      >
        <WrapWithText text={cardTitle}>
          <div className="inline-actions" onClick={stopPropagation}>
            {this.renderDueDateDropdown()}
            {this.renderAssignee()}
            {this.renderMore()}
          </div>
        </WrapWithText>
      </div>
    )
  }

  renderDueDateDropdown() {
    return (
      <DueDateDropdown
        key="due-date-dropdown"
        ref={this.handleDueDateDropdownRef}
        badgeClassName={'-in-header'}
        dueDate={this.props.dueDate}
        isTaskCompleted={this.props.isCompleted}
        onPopupShown={noop}
        onPopupHidden={noop}
        setCardDueDate={this.setCardDueDate}
        unsetCardDueDate={this.unsetCardDueDate}
        viewportNodeSelector=".main"
        flavor={DueDateDropdown.Flavor.IN_HEADER}
      ></DueDateDropdown>
    )
  }

  renderAssignee() {
    const {
      me,
      projectMembers,
      isCompleted,
      parentCardId,
      assignee,
    } = this.props
    return (
      parentCardId && (
        <AssigneeCard
          assignee={assignee}
          assign={this.assignTopLevelCard}
          me={me}
          flavor={AssigneeCard.Flavor.IN_HEADER}
          unassign={this.unassignTopLevelCard}
          projectMembers={projectMembers}
          isCompleted={isCompleted}
          viewportNodeSelector=".main"
        ></AssigneeCard>
      )
    )
  }

  renderMore() {
    const { routeType } = this.props
    const isPlan = routeType === ROUTE_PLAN
    return isPlan ? (
      <MoreActions
        key="more-actions"
        btnClassName={'-in-header'}
        getActions={this.getMoreActions}
        viewportNodeSelector=".card-list-scrollable"
        flavor={MoreActions.Flavor.IN_HEADER}
      />
    ) : null
  }

  isEditable() {
    return !!this.props.parentCardId
  }
}

export default connect(CardListHeaderContainer)
