import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cn from 'classnames'
import {Link} from 'react-router-dom'
import {buildPath, ROUTE_PROFILE} from '~/utils/routing'

import {firstInitial} from '~/utils/name'
import sel from '~/selectors'
import connect from '../utils/connect'


class UserAvatar extends React.Component {

    static propTypes = {
        user: ImmutablePropTypes.map,
        userId: PropTypes.string.isRequired,
        projectId: PropTypes.string,
        presenceRecord: ImmutablePropTypes.map,
        noStatusBadge: PropTypes.bool,
        className: PropTypes.string,
        profilePageLink: PropTypes.bool,
        selectUser: PropTypes.func
    }

    static mapStateToProps(state, props) {
        const {userId} = props
        return {
            user: sel.users(state).get(userId),
            presenceRecord: sel.presence(state).find(rec => rec.get('userId') === userId)
        }
    }

    static getOnlineStatusClass(presenceRecord) {
      const status = presenceRecord && presenceRecord.get('status')
      return status && `-${status}`
    }

    render() {
        const {
            user,
            userId,
            projectId,
            presenceRecord,
            noStatusBadge,
            className,
            profilePageLink,
            selectUser
        } = this.props

        if (!user) {
          return  <div className={cn('user-avatar', className)}>NA</div>
        }

        const userPhoto = user.get('photo')
        const userName = user.get('name')
        const profilePageLinkClass = profilePageLink ? '-link' : ''
        const userAvatarClasess = cn('user-avatar', className, profilePageLinkClass,
          !noStatusBadge ? UserAvatar.getOnlineStatusClass(presenceRecord) : null
        )

        if (!profilePageLink) {
            return <div 
                className={userAvatarClasess}
                style={{backgroundImage: `url(${userPhoto})`}}
                >
                    {!userPhoto ? firstInitial(userName) : null}
                    {!noStatusBadge ? <div className='badge'></div> : null}
                </div>
        } else {
            const targetPath = buildPath({type: ROUTE_PROFILE, userId: userId, projectId: projectId})
            return <Link key={targetPath} to={targetPath}>
                    <div className={userAvatarClasess}
                        style={{backgroundImage: `url(${userPhoto})`}}
                        onClick={() => {selectUser(userId)}}
                    >
                        {!userPhoto ? firstInitial(userName) : null}
                        {!noStatusBadge ? <div className='badge'></div> : null}
                    </div>
                </Link>
        }  
    }
}

export default connect(UserAvatar)
