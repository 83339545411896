import { take, call } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as actions from '~/actions'
import * as persistentStore from '~/utils/persistent-store'

import { isAccessError, makeAccessError } from '~/utils/access-error'
// import { fetchMe } from '~/api/fetch-me'

export default function* $user() {
  // const userResponse = yield call(fetchMe)
  // const entities = userResponse.entities
  let userId = yield call($takeNewUserId)

  while (true) {
    try {
      // yield* $dispatch(actions.selectUserSuccess(userId, entities))
    } catch (e) {
      yield* $dispatch(
        actions.fatalError(
          isAccessError(e)
            ? makeAccessError(`Access to user ${userId} denied`, e)
            : new Error(`Failed to fetch user ${userId}: ${e.message}`)
        )
      )
      return
    }
    userId = yield call($takeNewUserId)
  }
}

function* $takeNewUserId() {
  let { userId } = yield take(ActionTypes.SELECT_USER)
  yield call(persistentStore.setUserId, userId)
  return userId
}
