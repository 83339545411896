import React from 'react'
import * as Types from '~/types/api'
import cn from 'classnames'
import { TurtleAvatar } from '~/components/turtle-avatar'

type Props = {
  user: Types.GetChatData_feed_members
  onClick: (p: Types.GetChatData_feed_members) => void
  className?: string
}

export const MentionCard = ({ user, onClick, className }: Props) => {
  return (
    <div
      className={cn('mention', className)}
      onClick={e => {
        e.preventDefault()
        onClick(user)
      }}
    >
      <TurtleAvatar source={user.photo} size={24} />
      <span className="mention-name">{user.name}</span>
    </div>
  )
}
