import React from 'react'

import {CARD_LIST_PADDING_RIGHT} from '../card-list/card-list-constants'
import {CARD_CLASS, BEFORE_TEXT_ACTIONS_CLASS, getLevelIndent} from '../card-list/card-list-constants'
import {CARD_TEXT_SECTION_CLASS, CARD_NODE_CONTAINER_CLASS} from '../card-list/card-list-constants'


export default class NewCardInput extends React.Component {

  render() {
    const cardStyle = {
      paddingRight: CARD_LIST_PADDING_RIGHT
    }
    const beforeTextActionsStyle = {
      marginLeft: getLevelIndent(0)
    }
    return (
      <div className={CARD_NODE_CONTAINER_CLASS}>
        <div className={`${CARD_CLASS} new-placeholder`} style={cardStyle}>
          <div className={BEFORE_TEXT_ACTIONS_CLASS} style={beforeTextActionsStyle}>
            <span className='new-task-icon' onClick={this.props.createChildCard} />
          </div>
          <div className={CARD_TEXT_SECTION_CLASS}>
            <input className='task-input'
                 type='text'
                 size='40'
                 defaultValue=''
                 placeholder='What’s next?'
                 onFocus={this.props.createChildCard}>
            </input>
          </div>
        </div>
      </div>
    )
  }
}
