import React, { useCallback } from 'react'
import Textarea from 'react-textarea-autosize'
import cn from 'classnames'
import * as Types from '~/types/api'
import { MentionContainer } from './mention-container'
import { useChatInput } from './use-chat-input'
import { Attachments } from './attachments'
import { MessageEditModal } from './edit-modal'
import { EditMessage } from '../chat'
import { TimeTrackingForm } from './time-tracking-form'
import { EmojiPopup } from '../emoji-popup'

export type ChatInputProps = {
  projectId: string | null
  currentUserId: string
  messageToEdit: EditMessage | null
  timeEntryToEdit:
    | Types.ChatEventFragment_bodyTokens_TimeEntry
    | null
    | undefined
  inputRef: React.RefObject<HTMLTextAreaElement>
  members: (Types.GetChatData_feed_members | null)[] | null
  allowAttachments?: boolean
  allowTimeTracking?: boolean
  theme?: string
  onResize: (newHeight: number) => void
  onEditMessage: (feedEventId: string, messageText: string) => void
  onEditModalClose: () => void
  onPostMessage: (value: string) => void
  onTimeEntryCreate: (date: string, duration: string, memo: string) => void
  onTimeEntryEdit: (
    timeEntry: Types.ChatEventFragment_bodyTokens_TimeEntry
  ) => void
  onTimeEntryFormClose: () => void
  uploadFile: (f: File) => void
}

export const ChatInput = (props: ChatInputProps) => {
  const {
    attachmentsVisible,
    emojiVisible,
    focused,
    membersToSuggest,
    timeEntryFormVisible,
    activeMemberIndex,
    onUserClick,
    onKeyDown,
    onKeyUp,
    onSend,
    onFocus,
    onBlur,
    insertSymbol,
    hideMentions,
    setAttachmentsVisible,
    clearInput,
    setTimeEntryFormVisible,
    setEmojiVisible,
    onPaste,
  } = useChatInput(props)

  const handleTimeEntryFormClose = useCallback(() => {
    setTimeEntryFormVisible(false)
    props.onTimeEntryFormClose()
  }, [])

  return (
    <div
      className={cn('chat-input', {
        focused,
        'theme-dark': props.theme === 'dark',
      })}
    >
      {!!membersToSuggest.length && (
        <MentionContainer
          members={membersToSuggest}
          activeMemberIndex={activeMemberIndex}
          onClick={onUserClick}
          onClose={hideMentions}
        />
      )}

      {attachmentsVisible && (
        <Attachments
          projectId={props.projectId}
          allowTimeTracking={props.allowTimeTracking}
          onHide={() => setAttachmentsVisible(false)}
          onAttachFile={props.uploadFile}
          onTimeEntryClick={() => setTimeEntryFormVisible(true)}
        />
      )}

      {timeEntryFormVisible || props.timeEntryToEdit ? (
        <TimeTrackingForm
          timeEntryToEdit={props.timeEntryToEdit}
          onTimeEntryEdit={props.onTimeEntryEdit}
          onTimeEntryCreate={props.onTimeEntryCreate}
          onClose={handleTimeEntryFormClose}
        />
      ) : null}

      {props.allowAttachments ? (
        <div
          className={cn('chat-input__options-button', {
            active: attachmentsVisible,
          })}
          onClick={() => setAttachmentsVisible(true)}
        >
          <span>+</span>
        </div>
      ) : null}

      {props.messageToEdit ? (
        <MessageEditModal
          inputRef={props.inputRef}
          messageText={props.messageToEdit.text}
          onClose={() => {
            clearInput()
            props.onEditModalClose()
          }}
          theme={props.theme}
        />
      ) : null}

      <Textarea
        placeholder="Write something..."
        className={cn('chat-input__textarea', {
          'no-attachments': !props.allowAttachments,
        })}
        style={{ resize: 'none' }}
        inputRef={props.inputRef}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onHeightChange={props.onResize}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        className="chat-input__emoji-button"
        onClick={() => setEmojiVisible(!emojiVisible)}
      />
      {emojiVisible && (
        <EmojiPopup
          onClose={() => setEmojiVisible(false)}
          onEmojiInsert={(s: string) => {
            insertSymbol(s)
            onFocus()
            setEmojiVisible(false)
          }}
          theme={props.theme}
        />
      )}
      <button className="chat-input__send-button" onClick={onSend} />
    </div>
  )
}
