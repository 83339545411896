import { gql } from '@apollo/client'

export const RequestToWorkWithQuery = gql`
  mutation RequestToWorkWithQuery($input: RequestToWorkWithInput!) {
    requestToWorkWith(input: $input) {
      id
      topic
      feedId
    }
  }
`

export const SetUserTimezone = gql`
  mutation SetUserTimezone($input: SetUserTimezoneInput!) {
    setUserTimezone(input: $input)
  }
`


export const TrackUtmCodes = gql`
mutation TrackUtmCodes($input: TrackUtmCodesInput!) {
  trackUtmCodes(input: $input)
}
`
