/* eslint-disable */
import React from 'react'
import connect from '../utils/connect'

import sel from '~/selectors'

import * as ActionTypes from '~/actions/types'


class UndoPopupContainer extends React.Component {

  static mapStateToProps(state) {
    return {
      newActionToUndo: sel.newActionToUndo(state),
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
      description: null
    }
    this.onUndoClick = this.onUndoClick.bind(this)
    this.onHideClick = this.onHideClick.bind(this)
    this.hide = this.hide.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {newActionToUndo} = nextProps
    if (newActionToUndo != this.props.newActionToUndo) {
      const description = newActionToUndo && describeAction(newActionToUndo.toJS())
      const isVisible = !!description
      this.setState({
        isVisible: isVisible,
        description: description || this.state.description
      })
    }
  }

  hide() {
    this.setState({isVisible: false})
  }

  onUndoClick() {
    this.props.actions.undo()
  }

  onHideClick() {
    this.hide()
  }

  render() {
    const {description, isVisible} = this.state
    const visibilityClass = isVisible ? '-visible' : '-hidden'
    return (
      <div className={`undo-popup ${visibilityClass}`}>
        <span className='description'>{description || ''}</span>
        <span className='undo-btn' onClick={this.onUndoClick}>UNDO</span>
        <span className='hide-btn' onClick={this.onHideClick}></span>
      </div>
    )
  }
}


function describeAction(action) {
  switch (action.type) {
    case ActionTypes.DELETE_CARD: return 'Deleted'
    default: return null
  }
}


export default connect(UndoPopupContainer)
