import React, { useRef, useState } from 'react'
import * as Types from '~/types/api'
import { MentionCard } from '~/components/chat/input/mention-card'
import { usePopup } from '~/hooks/use-popup'

type Props = {
  members: (Types.GetChatData_feed_members | null)[] | null
  activeMemberIndex: number
  onClick: (p: Types.GetChatData_feed_members) => void
  onClose: () => void
}

export const MentionContainer = ({
  members,
  activeMemberIndex,
  onClick,
  onClose,
}: Props) => {
  const mentionsRef = useRef(null)
  usePopup({ ref: mentionsRef, initialVisibleValue: true, onClose })
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      ref={mentionsRef}
      className="mention-container"
    >
      {members?.map((member, index) => (
        <MentionCard
          className={
            (index === activeMemberIndex || members.length === 1) && !isFocused
              ? 'highlighted'
              : ''
          }
          key={index}
          user={member as Types.GetChatData_feed_members}
          onClick={onClick}
        />
      ))}
    </div>
  )
}
