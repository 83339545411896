import React from 'react'
import Loader from '../loader'
import reactStringReplace from 'react-string-replace'

import { formatDuration } from '~/utils/time'
import Layout from '~/components/layout/layout-with-single-frame'

import { TimePeriodHeader } from '../time-period-header'
import dayjs from 'dayjs'

function selectTotalDuration(entries) {
  return (entries || []).reduce(
    (totalDuration, entry) => totalDuration + entry.duration,
    0
  )
}

export default class ContractorDashboard extends React.Component {
  render() {
    return (
      <Layout narrow={true}>
        <div className="contractor-dashboard">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </Layout>
    )
  }

  renderHeader() {
    return (
      <TimePeriodHeader
        offset={this.props.trackedHours.get('offset')}
        periodType={this.props.trackedHours.get('periodType')}
        onPeriodShift={this.props.shiftPeriod}
        onCustomPeriodSet={this.props.getCustomPeriod}
        onPeriodTypeChange={this.props.changePeriodType}
      />
    )
  }

  renderBody() {
    const entries = this.props.trackedHours.get('entries')
    const totalDuration = selectTotalDuration(entries)

    if (!entries) {
      return (
        <div className="loader-container">
          <Loader colorScheme={'dark'} />
        </div>
      )
    }

    if (entries.err) {
      return <div className="content">Error while loading period report</div>
    }

    if (totalDuration > 0) {
      return (
        <div className="content">
          <div className="time-entries">
            {entries.sort(sortTimeEntries).map(this.renderTaskEntry)}
          </div>
          <div className="time-entry-line total-duration">
            <div className="time-entry-title"></div>
            <div className="time-entry-comment"></div>
            <div className="time-entry-date">Total time</div>
            <div className="time-entry-time">
              {formatDuration(totalDuration)}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="no-tracked-hours">
          {"You haven't tracked time on this period"}
        </div>
      )
    }
  }

  renderTaskEntry = (entry, index) => {
    const revealCard = e => {
      e.preventDefault()
      this.props.revealCard(entry.card.id)
    }
    return (
      <a
        href={`/plan/${entry.card.id}`}
        className="time-entry-line"
        onClick={revealCard}
        key={`${index}`}
      >
        <div className="time-entry-title">{entry.card.name}</div>
        <div className="time-entry-comment">{entry.memo}</div>
        <div className="time-entry-date">
          <DateWithSup date={entry.date} />
        </div>
        <div className="time-entry-time">{formatDuration(entry.duration)}</div>
      </a>
    )
  }
}

// Time entries sorts by date first and by added_at second.
// added_at - time when time entry was created.
// date - day when user spents X hours on task Y.
// It's necessary because user wants to see last reported items
// for the same day in the bottom.
function sortTimeEntries(a, b) {
  return (
    dayjs(a.date).diff(dayjs(b.date), 'day') ||
    dayjs(a.addedAt).diff(dayjs(b.addedAt))
  )
}

function DateWithSup({ date }) {
  const formattedString = dayjs(date).format('MMM D, YYYY')
  const formattedElement = reactStringReplace(
    formattedString,
    /(st|nd|rd|th)/,
    (match, i) => <sup key={i}>{match}</sup>
  )
  return <div>{formattedElement}</div>
}
