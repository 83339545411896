export function matchQueryParam(paramName, url) {
  const re = new RegExp(`[&?]${encodeURIComponent(paramName)}=([^&#]*)(?=&|#|$)`)
  const match = re.exec('' + url)
  return match && decodeURIComponent(match[1])
}


export function removeQueryParam(paramName, url) {
  return ('' + url).replace(/[?]([^#]*)(?=#|$)/, (_, search) => {
    const newSearch = search
      .replace(/^[?]/, '')
      .split('&')
      .filter(part => {
        const eqIndex = part.indexOf('=')
        const key = decodeURIComponent(eqIndex == -1 ? part : part.substr(0, eqIndex))
        return key != paramName
      })
      .join('&')
    return newSearch ? ('?' + newSearch) : ''
  })
}


export function getQuery() {
  return parseLocationSearch(window.location && window.location.search)
}


export function parseLocationSearch(search) {
  let query = {}
  if (!search) {
    return query
  }
  search.replace(/^[?]/, '').split('&').forEach(part => {
    let key, value
    const eqIndex = part.indexOf('=')
    if (eqIndex == -1) {
      key = decodeURIComponent(part)
      value = null
    } else {
      key = decodeURIComponent(part.substr(0, eqIndex))
      value = decodeURIComponent(part.substr(eqIndex + 1))
    }
    query[key] = value
  })
  return query
}


export function buildPathFromLocation(location) {
  return location.pathname + location.search + location.hash
}


const RE_ABSOLUTE_URL = /^https?:[/][/]/

export function isAbsoluteURL(url) {
  return RE_ABSOLUTE_URL.test(url)
}
