import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TurtleInput } from '~/components/turtle-input'
import Tooltip from '~/components/tooltip'
import { isValidDurationString } from '~/utils/duration'
import dayjs from 'dayjs'
import { ChatEventFragment_bodyTokens_TimeEntry } from '~/types/api'
import { formatDuration } from '~/utils/time'
import PopupWrapper from '~/components/popup-wrapper'

const DATE_FORMAT = 'YYYY-MM-DD'

type Props = {
  timeEntryToEdit: ChatEventFragment_bodyTokens_TimeEntry | null | undefined
  onTimeEntryEdit: (timeEntry: ChatEventFragment_bodyTokens_TimeEntry) => void
  onTimeEntryCreate: (date: string, duration: string, memo: string) => void
  onClose: () => void
}

export const TimeTrackingForm = ({ timeEntryToEdit, ...props }: Props) => {
  const initValues = useMemo(() => {
    return {
      date: timeEntryToEdit?.date ?? dayjs().format(DATE_FORMAT),
      duration: timeEntryToEdit?.duration
        ? formatDuration(timeEntryToEdit.duration)
        : '',
      memo: timeEntryToEdit?.memo ?? '',
    }
  }, [timeEntryToEdit])

  const [date, setDate] = useState(initValues.date)
  const [duration, setDuration] = useState(initValues.duration)
  const [memo, setMemo] = useState(initValues.memo)
  const dateRef = useRef<HTMLInputElement>(null)
  const durationRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    durationRef.current?.focus()
  }, [])

  const validationObject = useMemo(() => {
    return {
      date: dayjs(date, DATE_FORMAT).format(DATE_FORMAT) === date,
      duration: isValidDurationString(duration),
    }
  }, [date, duration])

  const isFormInvalid = useMemo(() => {
    return !validationObject.date || !validationObject.duration
  }, [validationObject])

  const tooltipLabel = useMemo(() => {
    return isFormInvalid
      ? 'Please correct fields highlighted in red before sending'
      : 'Add time entry (Enter)'
  }, [isFormInvalid])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (isFormInvalid) {
        if (!validationObject.date) {
          dateRef.current?.focus()
        } else if (!validationObject.duration) {
          durationRef.current?.focus()
        }
      } else {
        submitForm()
      }
    }
  }

  const submitForm = useCallback(() => {
    if (timeEntryToEdit) {
      props.onTimeEntryEdit({
        id: timeEntryToEdit.id,
        date,
        duration,
        memo,
        __typename: 'TimeEntry',
      })
    } else {
      props.onTimeEntryCreate(date, duration, memo)
    }
    props.onClose()
  }, [date, duration, memo, timeEntryToEdit])

  return (
    <PopupWrapper onDismiss={props.onClose}>
      <div
        className="chat-popup time-tracking-form"
        onKeyPress={handleKeyPress}
      >
        <div className="title">
          {timeEntryToEdit ? 'Edit time entry' : 'Add time'}
        </div>
        <div className="divider"></div>
        <TurtleInput
          label="Date"
          placeholder={DATE_FORMAT.toLowerCase()}
          value={date}
          onChange={setDate}
          invalid={!validationObject.date}
          inputRef={dateRef}
          fontSize={14}
          withSideLabel
        />
        <TurtleInput
          label="Time"
          placeholder="e.g. 1.3h, 1:45, 1h 45m, 87m"
          value={duration}
          onChange={setDuration}
          invalid={!validationObject.duration}
          inputRef={durationRef}
          fontSize={14}
          withSideLabel
        />
        <TurtleInput
          label="Notes"
          placeholder="Type some notes here"
          value={memo}
          onChange={setMemo}
          isTextarea={true}
          maxRows={3}
          fontSize={14}
          withSideLabel
        />
        <span className="spacer" />
        <div className="actions">
          <Tooltip label="Cancel (Escape)">
            <button onClick={props.onClose}>Cancel</button>
          </Tooltip>
          <Tooltip label={tooltipLabel}>
            <button
              className="send"
              onClick={submitForm}
              disabled={isFormInvalid}
            >
              Send
            </button>
          </Tooltip>
        </div>
      </div>
    </PopupWrapper>
  )
}
