import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import sel from '~/utils/selectors-ts'

interface AuthData {
  isLoggingIn: boolean
  isLoggedIn: boolean
  userId: string | undefined
}

const getAuth = createStructuredSelector({
  isLoggingIn: sel.isLoggingIn,
  isLoggedIn: sel.isLoggedIn,
  userId: sel.userId,
})

export const useAuth = () => {
  return useSelector<any, AuthData>(getAuth)
}
