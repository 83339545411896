import { matchQueryParam } from '~/utils/url'

export function buildRuntimeEnv(keys) {
  let env = {}
  for (var i = 0; i < keys.length; i++) {
    let key = keys[i]
    let handler = RUNTIME_ENV_HANDLERS[key]
    env[key] = handler ? handler() : null
  }
  return env
}

export function detectAppEnv({location, appEnv}, defaultEnv) {
  let env;
  env = matchQueryParam('env', location)
  if (env) {
    console.log(`DETECTED ENV=${env} from query param env=${env}`)
    return env
  }
  env = appEnv
  if (env) {
    console.log(`DETECTED ENV=${env} from environment variable APP_ENV=${env}`)
    return env
  }
  console.log('NO ENV found, defaulting to ' + defaultEnv)
  return defaultEnv
}

const RUNTIME_ENV_HANDLERS = {
  INITIAL_LOCATION() {
    return window.location + ''
  },
  HOST() {
    return window.location.host
  },
  PROTOCOL() {
    return window.location.protocol
  }
}