import React from 'react'
import { ChatContainer, ChatContainerProps } from '.'
import {
  handleCardClick,
  handleChatClose,
  handleMentionClick,
} from './util/actions'
import connect from '../utils/connect'

export const ChatContainerRedux = connect((props: ChatContainerProps) =>
  React.createElement(ChatContainer, {
    ...props,
    onMentionClick: handleMentionClick,
    onCardClick: handleCardClick,
    onChatClose: handleChatClose,
    allowAttachments: true,
    withServiceMessages: true,
  })
)
