/* eslint-disable */
import React from 'react'
import sel from '~/selectors'
import connect from '../utils/connect'
import cn from 'classnames'

class OnlineIndicator extends React.Component {
  static mapStateToProps(state, props) {
    const {userId} = props
    return { presenceRecord: sel.presence(state).find(rec => rec.get('userId') == userId) }
  }

  render() {
    const {presenceRecord} = this.props
    const status = presenceRecord && presenceRecord.get('status')
    return <div className={cn('online-indicator', status && `-${status}`)}></div>
  }
}

export default connect(OnlineIndicator)
