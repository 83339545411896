
/**
 * Compares two Array-like structures.
 */
export function arraysShallowEqual(a1, a2) {
  if (a1 === a2) {
    return true
  }
  if (!a1 || !a2) {
    return false
  }
  let size = a1.length
  if (size !== a2.length) {
    return false
  }
  for (let i = 0; i < size; ++i) {
    if (a1[i] !== a2[i]) {
      return false
    }
  }
  return true
}

/**
 * Compares two Immutable.js Iterables.
 */
export function iterablesShallowEqual(l1, l2) {
  if (l1 === l2) {
    return true
  }
  if (!l1 || !l2) {
    return false
  }
  let size = l1.size
  if (size !== l2.size) {
    return false
  }
  for (let i = 0; i < size; ++i) {
    if (l1.get(i) !== l2.get(i)) {
      return false
    }
  }
  return true
}
