import React from 'react'
import connect from '../utils/connect'

import selectors from '~/selectors'

import Loader from '../loader'
import SigninContainer from '../sign-in'
import TooltipContainer from '../tooltip/tooltip-container'
import PageFatalError from '../pages/page-fatal-error'
import { ChatContainerRedux } from '../chat/redux-container'

class MobileContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      fatalError: selectors.fatalError(state),
      isLoggedIn: selectors.isLoggedIn(state),
      selectedProjectId: selectors.selectedProjectId(state),
      selectedFeedId: selectors.selectedFeedId(state),
      selectedCardId: selectors.selectedCardId(state),
    }
  }

  render() {
    const {
      fatalError,
      isLoggedIn,
      selectedProjectId,
      selectedFeedId,
    } = this.props
    if (fatalError) {
      return <PageFatalError error={fatalError} />
    }
    if (isLoggedIn === undefined) {
      return this.renderLoader()
    }
    if (!isLoggedIn) {
      return <SigninContainer />
    }
    if (!selectedProjectId) {
      return this.renderLoader()
    }
    return (
      <div className="mobile-container">
        <ChatContainerRedux chatId={selectedFeedId} />
        <TooltipContainer />
      </div>
    )
  }

  renderLoader() {
    return <Loader colorScheme="dark" expandOnFullHeight={true} />
  }
}

export default connect(MobileContainer)
