import { all, call, select, takeEvery, fork } from 'redux-saga/effects'

import { $dispatch } from './utils/effects'
import sel from '~/selectors'
import * as ActionTypes from '~/actions/types'
import * as actions from '~/actions'

export default function* $reports(action) {
  yield all([
    fork(takeEvery, ActionTypes.GET_REPORT, $offsetReport),
    fork(takeEvery, ActionTypes.GET_CUSTOM_REPORT, $customReport),
  ])
}

function* $offsetReport(action) {
  const { offset, periodType } = action
  const projectId = yield select(sel.selectedProjectId)
  let report
  try {
    // report = yield call(getDashboardReport, projectId, periodType, offset)
  } catch (err) {
    report = { err }
  }
  yield* $dispatch(actions.reportReady(report))
}

function* $customReport(action) {
  const { start, end } = action
  const projectId = yield select(sel.selectedProjectId)
  let report
  try {
    // report = yield call(getCustomDashboardReport, projectId, start, end)
  } catch (err) {
    report = { err }
  }
  yield* $dispatch(actions.reportReady(report))
}
