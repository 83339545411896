import ClipboardJS from 'clipboard'

export function setClipboardText(text) {
  let el = document.createElement("div")
  let clipboard = new ClipboardJS(el, {
    text: () => text
  })
  el.click()
  clipboard.destroy()
}
