import client from '~/api/apollo-client'
import { CreatePlaidLInkTokenMutation as MutationType } from '~/types/api'
import { CreatePlaidLInkTokenMutation } from './queries'

export const createPlaidLink = async () => {
  const { data } = await client.mutate<MutationType>({
    mutation: CreatePlaidLInkTokenMutation,
  })

  return data?.createPlaidLinkToken
}
