import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'
import {WEEK_TIME_PERIOD} from '~/utils/time'


const INITIAL_STATE = trackedHoursReducer.INITIAL_STATE = Map({
  entries: null,
  offset: 0,
  totalDuration: 0,
  periodType: WEEK_TIME_PERIOD,
  start: null,
  end: null
})

function getHoursRecordsForCurrentUser(entries, currentUserId) {
  return entries.filter(entry => entry.user.id == currentUserId)
}

export default function trackedHoursReducer(state = INITIAL_STATE, action, myUserIdSel) {
  switch (action.type) {
    case ActionTypes.GET_TRACKED_HOURS: {
      return state.withMutations(state => {
        return state.set('offset', action.offset)
                    .set('periodType', action.periodType)
                    .set('entries', null)
                    .set('start', null)
                    .set('end', null)
      })
    }
    case ActionTypes.GET_CUSTOM_TRACKED_HOURS: {
      return state.withMutations(state => {
        return state.set('start', action.start)
                    .set('end', action.end)
                    .set('entries', null)
      })
    }
    case ActionTypes.TRACKED_HOURS_READY: {
      const myEntries = getHoursRecordsForCurrentUser(action.entries, myUserIdSel())
      return state.set('entries', myEntries)
    }
  }
  return state
}
