import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { AuthenticateUser } from '~/api/queries'
import { RoundLoader } from './loader'
import {
  AuthenticateUser as MutationType,
  AuthenticateUserVariables as MutationVariablesType,
  AuthProvider,
} from '~/types/api'
import store from '~/store'
import * as actions from '~/actions'
import { useSearchParams } from '~/hooks/use-search-params'

enum AuthState {
  Loading = 'Loading',
  Success = 'Success',
  Fail = 'Fail',
}

export const LoginLinkHandler = () => {
  const [idToken, setIdToken] = useState<string | undefined>()
  const [authState, setAuthState] = useState(AuthState.Loading)
  const [logMessages, setLogMessages] = useState(['Getting code...'])
  const { code } = useSearchParams()
  const [authUserWithIdToken] = useMutation<
    MutationType,
    MutationVariablesType
  >(AuthenticateUser)

  const addLogMessage = (message: string) =>
    setLogMessages(messages => [...messages, message])

  useEffect(() => {
    if (code?.length) {
      setIdToken(code)
      addLogMessage('Loading auth token...')
    } else if (code === undefined || !code.length) {
      setLogMessages(messages => [
        ...messages,
        'Login code not found. Please contact the support',
      ])
      setAuthState(AuthState.Fail)
    }
  }, [])

  useEffect(() => {
    if (idToken?.length && authState === AuthState.Loading) {
      authUserWithIdToken({
        variables: { idToken, provider: AuthProvider.GOOGLE },
      })
        .then(({ data, errors }) => {
          if (data?.authenticate?.accessToken && !errors) {
            setAuthState(AuthState.Success)
            addLogMessage('Success! Redirecting to app...')
            const credentials = {
              user_id: data.authenticate.user?.id,
              token: data.authenticate.accessToken,
            }
            store.dispatch(actions.loginSuccess(credentials))
          } else {
            setAuthState(AuthState.Fail)
            addLogMessage('Auth failed. Please contact the support')
          }
        })
        .catch(e => {
          setAuthState(AuthState.Fail)
          switch (e.message) {
            case 'invalid_token':
              addLogMessage(
                'Provided code is invalid. Please contact the support'
              )
              break
            default:
              addLogMessage('Auth failed. Please contact the support')
              break
          }
        })
    }
  }, [idToken, authState])

  return (
    <div className="login-link--handler">
      {authState === AuthState.Loading ? <RoundLoader size={50} /> : null}
      {logMessages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
    </div>
  )
}
