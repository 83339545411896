/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import TooltipContainer from './tooltip-container'

const ERROR_UNSUPPORTED_CHILDREN =
  'Tooltip only supports single non-Component child. ' +
  'If you need to pass multiple children or a custom component, wrap them into ' +
  'some DOM element, like div or span.'

export default class Tooltip extends React.Component {
  static TOP = TooltipContainer.TOP
  static RIGHT = TooltipContainer.RIGHT
  static BOTTOM = TooltipContainer.BOTTOM
  static LEFT = TooltipContainer.LEFT

  static LIGHT = 'light'

  static propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([false, null, undefined])
    ]),
    position: PropTypes.oneOf([
      TooltipContainer.TOP,
      TooltipContainer.RIGHT,
      TooltipContainer.BOTTOM,
      TooltipContainer.LEFT
    ]),
    dx: PropTypes.number,
    dy: PropTypes.number,
    showDelayMs: PropTypes.number,
    extraClasses: PropTypes.string
  }

  static _lastId = -1

  constructor() {
    super()
    this.id = ++Tooltip._lastId
    this.node = null
    this.isVisible = false
    this.innerOnMouseEnter = undefined
    this.innerOnMouseLeave = undefined
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  get tooltipData() {
    const { props } = this
    return {
      text: props.label,
      node: ReactDOM.findDOMNode(this),
      position: props.position || TooltipContainer.TOP,
      dx: props.dx || 0,
      dy: props.dy || 0,
      extraClasses: props.extraClasses,
      showDelayMs: props.showDelayMs
    }
  }

  render() {
    const { children } = this.props

    if (React.Children.count(children) != 1) {
      throw new Error(ERROR_UNSUPPORTED_CHILDREN)
    }

    if ('string' != typeof children.type) {
      throw new Error(ERROR_UNSUPPORTED_CHILDREN)
    }

    this.innerOnMouseEnter = children.props.onMouseEnter
    this.innerOnMouseLeave = children.props.onMouseLeave

    const clonedEl = React.cloneElement(this.props.children, {
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave
    })

    return clonedEl
  }

  componentDidUpdate() {
    if (this.isVisible) {
      this.show()
    }
  }

  componentWillUnmount() {
    if (this.isVisible) {
      this.hide()
    }

    this.node = null
  }

  onMouseEnter(e) {
    this.innerOnMouseEnter && this.innerOnMouseEnter(e)
    this.show()
  }

  onMouseLeave(e) {
    this.hide()
    this.innerOnMouseLeave && this.innerOnMouseLeave(e)
  }

  show() {
    if (!this.props.label) {
      if (this.isVisible) {
        this.hide()
      }
      return
    }

    this.isVisible = true
    TooltipContainer.instance.showTooltip(this)
  }

  hide() {
    TooltipContainer.instance.hideTooltip(this)
  }

  onHidden() {
    this.isVisible = false
  }
}
