import React from 'react'
import { EmbeddableChat } from '~/components/embeddable-chat'

export const ChatPage = () => {
  return (
    <div className="chat-page">
      <EmbeddableChat />
    </div>
  )
}
