import cn from 'classnames/dedupe'


export default function addClassWithTimeout(timeoutMs, cls, node) {
  node.className = cn(node.className, cls)

  const removeCls = () => {
    node.className = cn(node.className, { [cls]: false })
    node = null
  }

  setTimeout(removeCls, timeoutMs)
}
