import { push } from 'connected-react-router'

import * as ActionTypes from './types'
import CardRelation from '~/utils/card-relation'

//
// navigation actions
//

export function routeChanged(route, location, uri) {
  return {
    type: ActionTypes.ROUTE_CHANGED,
    route,
    location,
    uri,
  }
}

export function showProviderAuthPage(provider, state) {
  return {
    type: ActionTypes.SHOW_PROVIDER_AUTH_PAGE,
    provider,
    state,
  }
}

export function navigateTo(pathname) {
  return push(pathname)
}

//
// auth actions
//

export function needsLogin() {
  return {
    type: ActionTypes.NEEDS_LOGIN,
  }
}

export function loginWithProvider(provider, returnPath) {
  return {
    type: ActionTypes.LOGIN,
    provider,
    returnPath,
  }
}

export function loginSuccess(credentials, returnPath) {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    credentials,
    returnPath,
  }
}

export function loginError(error) {
  return {
    type: ActionTypes.LOGIN_ERROR,
    error,
  }
}

export function logout() {
  return {
    type: ActionTypes.LOGOUT,
  }
}

export function setPermissions(permissions) {
  return {
    type: ActionTypes.SET_PERMISSIONS,
    permissions,
  }
}

//
// project-related actions
//

export function setProjectsList(projectIds, entities) {
  return {
    type: ActionTypes.SET_PROJECTS_LIST,
    projectIds,
    entities,
  }
}

export function selectProject(projectId, cardId = null, isInitial = false) {
  return {
    type: ActionTypes.SELECT_PROJECT,
    projectId,
    cardId,
    isInitial,
  }
}

export function selectProjectSuccess(projectId, entities) {
  return {
    type: ActionTypes.SELECT_PROJECT_SUCCESS,
    projectId,
    entities,
  }
}

export function projectRemotelyUpdated(actorId) {
  return {
    type: ActionTypes.PROJECT_REMOTELY_UPDATED,
    actorId,
  }
}

export function updateProject(entities) {
  return {
    type: ActionTypes.UPDATE_PROJECT,
    entities,
  }
}

export function selectUser(userId) {
  return {
    type: ActionTypes.SELECT_USER,
    userId,
  }
}

export function selectUserSuccess(userId, entities) {
  return {
    type: ActionTypes.SELECT_USER_SUCCESS,
    userId,
    entities,
  }
}

export function selectRecordSuccess(recordId, entities) {
  return {
    type: ActionTypes.SELECT_RECORD_SUCCESS,
    recordId,
    entities,
  }
}

export function selectRecord(recordId) {
  return {
    type: ActionTypes.SELECT_RECORD,
    recordId,
  }
}

//
// members actions
//

export function toggleInvitation() {
  return {
    type: ActionTypes.TOGGLE_INVITATION,
  }
}

export function inviteMember(memberMail) {
  return {
    type: ActionTypes.INVITE_MEMBER,
    memberMail,
  }
}

export function removeMember(memberId) {
  return {
    type: ActionTypes.REMOVE_MEMBER,
    memberId,
  }
}

export function memberInvited(memberMail, error, entities) {
  return {
    type: ActionTypes.MEMBER_INVITED,
    memberMail,
    error,
    entities,
  }
}

export function memberRemoved(memberId, error, entities) {
  return {
    type: ActionTypes.MEMBER_REMOVED,
    memberId,
    error,
    entities,
  }
}

export function resetInvitation() {
  return {
    type: ActionTypes.RESET_INVITATION,
  }
}

//
// card actions
//

export function startModifyingCard(cardId) {
  return {
    type: ActionTypes.START_MODIFYING_CARD,
    cardId,
  }
}

export function cancelModifyingCard(cardId) {
  return {
    type: ActionTypes.CANCEL_MODIFYING_CARD,
    cardId,
  }
}

export function startEditingCard(cardId) {
  return {
    type: ActionTypes.START_EDITING_CARD,
    cardId,
  }
}

export function cancelEditingCard(cardId) {
  return {
    type: ActionTypes.CANCEL_EDITING_CARD,
    cardId,
  }
}

export function changeCardNestingLevel(
  cardId,
  doIncrease,
  currentName,
  selectionStart,
  selectionEnd
) {
  return {
    type: ActionTypes.CHANGE_CARD_NESTING_LEVEL,
    cardId,
    doIncrease,
    currentName,
    selectionStart,
    selectionEnd,
  }
}

export function commitEditingCard(cardId, newName, shouldAddNewChild) {
  return {
    type: ActionTypes.COMMIT_EDITING_CARD,
    cardId,
    newName,
    // Whether new empty child should be added after the one that we just finished editing.
    // We set this to true when user pressed enter after adding new card (which is implemented
    // as adding child with empty name and then editing it) so it resembles cursor moving to
    // a new line in an editor.
    shouldAddNewChild,
  }
}

export function cardRenamed(newName) {
  return {
    type: ActionTypes.CARD_RENAMED,
    newName,
  }
}

export function cardMoved(movedUsingKeyboard) {
  return {
    type: ActionTypes.CARD_MOVED,
    movedUsingKeyboard,
  }
}

export function createEphemeralCard(parentId, top = null) {
  let ephemeralId = 'eph_' + uid()
  return {
    type: ActionTypes.CREATE_EPHEMERAL_CARD,
    parentId,
    ephemeralId,
    top,
  }
}

export function deleteEphemeralCard(ephemeralId) {
  return {
    type: ActionTypes.DELETE_EPHEMERAL_CARD,
    ephemeralId,
  }
}

export function quickAddCard(name) {
  return {
    type: ActionTypes.QUICK_ADD_CARD,
    name,
  }
}

export function cardCreated(name) {
  return {
    type: ActionTypes.CARD_CREATED,
    name,
  }
}

export function deleteCard(cardId) {
  return {
    type: ActionTypes.DELETE_CARD,
    cardId,
  }
}

export function undeleteCard(cardId) {
  return {
    type: ActionTypes.UNDELETE_CARD,
    cardId,
  }
}

export function setCardDueDate(cardId, dueDate) {
  return {
    type: ActionTypes.SET_CARD_DUE_DATE,
    cardId,
    dueDate,
  }
}

export function unsetCardDueDate(cardId) {
  return {
    type: ActionTypes.UNSET_CARD_DUE_DATE,
    cardId,
  }
}

export function unassignCard(cardId) {
  return {
    type: ActionTypes.UNASSIGN_CARD,
    cardId,
  }
}

export function assignCard(cardId, assigneeId) {
  return {
    type: ActionTypes.ASSIGN_CARD,
    cardId,
    assigneeId,
  }
}

export function moveCard(cardId, targetId, relation) {
  if (relation == CardRelation.NOP) {
    throw new Error('Invalid relation: NOP')
  }
  return {
    type: ActionTypes.MOVE_CARD,
    opId: uid(),
    cardId,
    targetId,
    relation,
  }
}

export function markCardWithIdCompleted(cardId, isCompleted) {
  return {
    type: ActionTypes.MARK_CARD_WITH_ID_COMPLETED,
    cardId,
    isCompleted,
  }
}

export function updateCard(cardId, entities) {
  return {
    type: ActionTypes.UPDATE_CARD,
    cardId,
    entities,
  }
}

export function replaceEphemeralCard(ephemeralId, finalId, parentId, entities) {
  return {
    type: ActionTypes.REPLACE_EPHEMERAL_CARD,
    ephemeralId,
    finalId,
    parentId,
    entities,
  }
}

export function clearCardOptimisticState(cardId, originalCardId) {
  return {
    type: ActionTypes.CLEAR_CARD_OPTIMISTIC_STATE,
    cardId,
    originalCardId,
  }
}

//
// Navigation actions
//

export function showCard(cardId) {
  return {
    type: ActionTypes.SHOW_CARD,
    cardId,
  }
}

export function selectCard(cardId) {
  return {
    type: ActionTypes.SELECT_CARD,
    cardId,
  }
}

export function clearCardSelection() {
  return {
    type: ActionTypes.CLEAR_CARD_SELECTION,
  }
}

export function revealCard(cardId, projectId) {
  return {
    type: ActionTypes.REVEAL_CARD,
    cardId,
    projectId,
  }
}

export function revealCardFinished(cardId, success) {
  return {
    type: ActionTypes.REVEAL_CARD_FINISHED,
    cardId,
    success,
  }
}

export function setCardExpanded(cardId, isExpanded) {
  return {
    type: ActionTypes.SET_CARD_EXPANDED,
    cardId,
    isExpanded,
  }
}

export function setCardsExpanded(cardIds, isExpanded) {
  return {
    type: ActionTypes.SET_CARDS_EXPANDED,
    cardIds,
    isExpanded,
  }
}

export function restoreCardsExpandState(cardsExpandState) {
  return {
    type: ActionTypes.RESTORE_CARDS_EXPAND_STATE,
    cardsExpandState,
  }
}

export function setFilter(filter, value) {
  return {
    type: ActionTypes.SET_FILTER,
    filter,
    value,
  }
}

export function resetAllFilters() {
  return {
    type: ActionTypes.RESET_ALL_FILTERS,
  }
}

export function updateEstimate(cardId, estimate) {
  return {
    type: ActionTypes.UPDATE_ESTIMATE,
    cardId,
    estimate,
  }
}

export function increaseBudget(cardId, budget) {
  return {
    type: ActionTypes.INCREASE_BUDGET,
    cardId,
    budget,
  }
}

export function decreaseBudget(cardId, budget) {
  return {
    type: ActionTypes.DECREASE_BUDGET,
    cardId,
    budget,
    decrease: true,
  }
}

//
// search actions
//

export function doSearch(query) {
  return {
    type: ActionTypes.DO_SEARCH,
    query,
  }
}

export function searchResults(results) {
  return {
    type: ActionTypes.SEARCH_RESULTS,
    results,
  }
}

//
// Feed events
//

export function feedOpen(feedId) {
  return {
    type: ActionTypes.FEED_OPEN,
    feedId,
  }
}

export function feedClose(feedId) {
  return {
    type: ActionTypes.FEED_CLOSE,
    feedId,
  }
}

export function unreadCountsChanged(counts) {
  return {
    type: ActionTypes.UNREAD_COUNTS_CHANGED,
    counts,
  }
}

export function feedToggleReaction(feedId, feedEventId, reaction) {
  return {
    type: ActionTypes.FEED_TOGGLE_REACTION,
    feedId,
    feedEventId,
    reaction,
  }
}

export function entitiesUpdated(entities) {
  return {
    type: ActionTypes.ENTITIES_UPDATED,
    entities,
  }
}

export function entitiesDeleted(refs) {
  return {
    type: ActionTypes.ENTITIES_DELETED,
    refs,
  }
}

export function feedNewEvents(feedId, events, entities) {
  return {
    type: ActionTypes.FEED_NEW_EVENTS,
    feedId,
    events,
    entities,
  }
}

export function feedUpdatedEvents(feedId, events, entities) {
  return {
    type: ActionTypes.FEED_UPDATED_EVENTS,
    feedId,
    events,
    entities,
  }
}

export function feedDeletedEvents(events) {
  return {
    type: ActionTypes.FEED_DELETED_EVENTS,
    events,
  }
}

export function timeEntryCreate(cardId, entryDate, entryDuration, entryMemo) {
  return {
    type: ActionTypes.TIME_ENTRY_CREATE,
    cardId,
    entryDate,
    entryDuration,
    entryMemo,
  }
}

export function timeEntryCreateOptimistically(
  cardId,
  entryDate,
  entryDuration,
  entryMemo
) {
  return {
    type: ActionTypes.TIME_ENTRY_CREATE_OPTIMISTICALLY,
    cardId,
    entryDate,
    entryDuration,
    entryMemo,
  }
}

export function timeEntryEdit(
  entryId,
  feedId,
  feedEventId,
  date,
  duration,
  memo
) {
  return {
    type: ActionTypes.TIME_ENTRY_EDIT,
    feedId,
    feedEventId,
    entryId,
    date,
    duration,
    memo,
  }
}

export function timeEntryDelete(feedEventId, entryId, feedId) {
  return {
    type: ActionTypes.TIME_ENTRY_DELETE,
    feedEventId,
    entryId,
    feedId,
  }
}

export function updateDraft(feedId, draft) {
  return {
    type: ActionTypes.UPDATE_DRAFT,
    feedId,
    draft,
  }
}

export function beginUpload(feedId, file) {
  return {
    type: ActionTypes.BEGIN_UPLOAD,
    feedId,
    file,
  }
}

export function setUploadProgress(feedId, progress) {
  return {
    type: ActionTypes.SET_UPLOAD_PROGRESS,
    progress,
    feedId,
  }
}

export function toggleDirectChat(feedId) {
  return {
    type: ActionTypes.TOGGLE_DIRECT_CHAT,
    feedId,
  }
}

export function openDirectChat(feedId) {
  return {
    type: ActionTypes.OPEN_DIRECT_CHAT,
    feedId,
  }
}

export function toggleFullscreenChat() {
  return {
    type: ActionTypes.TOGGLE_FULLSCREEN_CHAT,
  }
}

//
// misc actions
//

export function fatalError(error) {
  return {
    type: ActionTypes.FATAL_ERROR,
    error,
  }
}

export function noProjects() {
  return {
    type: ActionTypes.NO_PROJECTS,
  }
}

export function openInappURL(url) {
  return {
    type: ActionTypes.OPEN_INAPP_URL,
    url,
  }
}

export function getReport(offset, periodType) {
  return {
    type: ActionTypes.GET_REPORT,
    offset,
    periodType,
  }
}

export function getCustomReport(start, end) {
  return {
    type: ActionTypes.GET_CUSTOM_REPORT,
    start,
    end,
  }
}

export function reportReady(report) {
  return {
    type: ActionTypes.REPORT_READY,
    report,
  }
}

export function getTrackedHours(offset, periodType) {
  return {
    type: ActionTypes.GET_TRACKED_HOURS,
    offset,
    periodType,
  }
}

export function getCustomTrackedHours(start, end) {
  return {
    type: ActionTypes.GET_CUSTOM_TRACKED_HOURS,
    start,
    end,
  }
}

export function trackedHoursReady(entries) {
  return {
    type: ActionTypes.TRACKED_HOURS_READY,
    entries,
  }
}

export function newUndoItem(actionToUndo) {
  return {
    type: ActionTypes.NEW_UNDO_ITEM,
    actionToUndo,
  }
}

export function undo() {
  return {
    type: ActionTypes.UNDO,
  }
}

export function togglePaymentForm() {
  return {
    type: ActionTypes.TOGGLE_PAYMENT_FORM,
  }
}

export function toggleBudgetForm() {
  return {
    type: ActionTypes.TOGGLE_BUDGET_FORM,
  }
}

export function showRollups() {
  return {
    type: ActionTypes.SHOW_ROLLUPS,
  }
}

export function hideRollups() {
  return {
    type: ActionTypes.HIDE_ROLLUPS,
  }
}

export function sendPaymentV2(data) {
  return {
    type: ActionTypes.SEND_PAYMENT_V2,
    data,
  }
}

export function paymentSuccess(amount, transactionId = null) {
  return {
    type: ActionTypes.PAYMENT_SUCCESS,
    amount,
    transactionId,
  }
}

export function paymentFail(error) {
  return {
    type: ActionTypes.PAYMENT_FAIL,
    error,
  }
}

export function setHaveBankCard(hasCard) {
  return {
    type: ActionTypes.SET_HAVE_BANK_CARD,
    hasCard,
  }
}

export function submitAvailability(start, end, availability) {
  return {
    type: ActionTypes.SUBMIT_AVAILABILITY,
    start,
    end,
    availability,
  }
}

export function availabilitySubmitted() {
  return {
    type: ActionTypes.AVAILABILITY_SUBMITTED,
  }
}

export function availabilitySubmissionFailed(error) {
  return {
    type: ActionTypes.AVAILABILITY_SUBMISSION_FAILED,
    error,
  }
}

export function resetAvailabilityForm() {
  return {
    type: ActionTypes.RESET_AVAILABILITY_FORM,
  }
}

//
// video actions
//

export function toggleMyAudio() {
  return {
    type: ActionTypes.TOGGLE_MY_AUDIO,
  }
}

export function toggleMyVideo() {
  return {
    type: ActionTypes.TOGGLE_MY_VIDEO,
  }
}

export function addLocalAudioTrack(tracks) {
  return {
    type: ActionTypes.ADD_LOCAL_AUDIO_TRACK,
    tracks,
  }
}

export function removeLocalAudioTrack() {
  return {
    type: ActionTypes.REMOVE_LOCAL_AUDIO_TRACK,
  }
}

export function addLocalVideoTrack(tracks) {
  return {
    type: ActionTypes.ADD_LOCAL_VIDEO_TRACK,
    tracks,
  }
}

export function removeLocalVideoTrack() {
  return {
    type: ActionTypes.REMOVE_LOCAL_VIDEO_TRACK,
  }
}

export function shareScreen(tracks) {
  return {
    type: ActionTypes.SHARE_SCREEN,
    tracks,
  }
}

export function stopShareScreen() {
  return {
    type: ActionTypes.STOP_SHARE_SCREEN,
  }
}

export function setActiveMeetingRoom(activeRoom) {
  return {
    type: ActionTypes.SET_ACTIVE_ROOM,
    activeRoom: activeRoom,
  }
}

export function removeActiveMeetingRoom() {
  return {
    type: ActionTypes.REMOVE_ACTIVE_ROOM,
  }
}

export function joiningMeetingRoom() {
  return {
    type: ActionTypes.JOINING_MEETING_ROOM,
  }
}

export function meetingRoomJoined(token) {
  return {
    type: ActionTypes.MEETING_ROOM_JOINED,
    token,
  }
}

export function videoConnected() {
  return {
    type: ActionTypes.VIDEO_CONNECTED,
  }
}

export function meetingRoomLeft() {
  return {
    type: ActionTypes.MEETING_ROOM_LEFT,
  }
}

export function addRemoteTrack(track, participant) {
  return {
    type: ActionTypes.ADD_REMOTE_TRACK,
    track,
    participant,
  }
}

export function removeRemoteTrack(track, participant) {
  return {
    type: ActionTypes.REMOVE_REMOTE_TRACK,
    track,
    participant,
  }
}

export function addRemoteScreenTrack(track, participant) {
  return {
    type: ActionTypes.ADD_REMOTE_SCREEN_TRACK,
    track,
    participant,
  }
}

export function removeRemoteScreenTrack() {
  return {
    type: ActionTypes.REMOVE_REMOTE_SCREEN_TRACK,
  }
}

export function addRemoteParticipant(participant) {
  return {
    type: ActionTypes.ADD_REMOTE_PARTICIPANT,
    participant,
  }
}

export function removeRemoteParticipant(participant) {
  return {
    type: ActionTypes.REMOVE_REMOTE_PARTICIPANT,
    participant,
  }
}

export function removeAllRemoteParticipants() {
  return {
    type: ActionTypes.REMOVE_ALL_REMOTE_PARTICIPANTS,
  }
}

export function updateLocalNetworkQualityInfo(
  localNetworkQualityLevel,
  localNetworkQualityStats
) {
  return {
    type: ActionTypes.UPDATE_LOCAL_NETWORK_QUALITY_INFO,
    localNetworkQualityLevel,
    localNetworkQualityStats,
  }
}

export function removeLocalNetworkQualityInfo() {
  return {
    type: ActionTypes.REMOVE_LOCAL_NETWORK_QUALITY_INFO,
  }
}

export function updateDominantSpeaker(participant) {
  return {
    type: ActionTypes.UPDATE_DOMINANT_SPEAKER,
    participant,
  }
}

export function removeDominantSpeaker() {
  return {
    type: ActionTypes.REMOVE_DOMINANT_SPEAKER,
  }
}

export function toggleFullScreen() {
  return {
    type: ActionTypes.TOGGLE_FULL_SCREEN,
  }
}

export function tryingToReconnectToCall() {
  return {
    type: ActionTypes.TRYING_TO_RECONNECT_TO_CALL,
  }
}

export function reconnectedToCall() {
  return {
    type: ActionTypes.RECONNECTED_TO_CALL,
  }
}

//
// utils
//

function uid() {
  return Date.now() + '_' + Math.floor(1000 * Math.random())
}
