import React from 'react'
import connect from '../utils/connect'
import selectors from '~/selectors'

class CardFeedHeader extends React.Component {
  static mapStateToProps(state) {
    const selectedCard = selectors.selectedCard(state)

    return {
      selectedCardName: (selectedCard && selectedCard.get('name')) || 'Chat',
    }
  }

  renderRegularIcons() {
    return [
      <div
        key="fullscreen-icon"
        className="fullscreen-icon"
        onClick={this.props.actions.toggleFullscreenChat}
      ></div>,
      <div
        key="close-icon"
        className="close-icon"
        onClick={this.props.actions.clearCardSelection}
      ></div>,
    ]
  }

  renderFullscreenIcons() {
    return [
      <div
        key="close-fullscreen-icon"
        className="close-fullscreen-icon"
        onClick={this.props.actions.toggleFullscreenChat}
      ></div>,
    ]
  }

  render() {
    return (
      <div className="card-feed-header">
        <div className="icon"></div>
        <div className="title">
          <div className="card-name">{this.props.selectedCardName}</div>
        </div>
        {this.props.isFullscreen
          ? this.renderFullscreenIcons()
          : this.renderRegularIcons()}
      </div>
    )
  }
}

export default connect(CardFeedHeader)
