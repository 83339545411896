import React, { useState } from 'react'
import * as T from '~/types/api'
import { useQuery } from '@apollo/client'
import { GetCurrentUserData } from './gql'
import Loader from '../loader'
import { MenuContainer } from '../menu'
import BurgerIcon from '~assets/svg/burger-icon.svg'

export const NoProjects = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { data, loading, error } = useQuery<T.GetCurrentUserData>(
    GetCurrentUserData
  )

  if (loading) {
    return <Loader expandOnFullHeight colorScheme="dark" />
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const userFirstName = data?.currentUser?.name?.split(' ')[0]

  return (
    <div className="no-projects-with-agency">
      <div className="header">
        <MenuContainer
          isMenuVisible={isMenuOpen}
          onMenuClose={() => setIsMenuOpen(false)}
        />
        <p className="title">Turtle</p>
      </div>
      <div className="content">
        <div className="placeholder">
          <p>Welcome to Turtle, {userFirstName}!</p>
          <p>
            You currently don’t have any projects. You can ask someone to invite
            you to a project.
          </p>
          {/* <p>
            Your user ID is <strong>{data?.currentUser?.id}</strong>
          </p> */}
          <p className="burger-par">
            <span>But you still can chat with someone on Turtle. Click</span>
            <span className="burger-icon" onClick={() => setIsMenuOpen(true)}>
              <BurgerIcon />
            </span>
            <span>to access available chats</span>
          </p>
        </div>
      </div>
    </div>
  )
}
