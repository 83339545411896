/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'

import PopupWrapper from '../popup-wrapper'
import Tooltip from '../tooltip'

import MoreActionItem from './more-action-item'

const MORE_ACTIONS_BTN_CLASS = 'more-actions-btn'
const MORE_ACTIONS_BTN_LABEL = 'More actions'


export const Flavor = {
  IN_LIST: 0,
  IN_HEADER: 1
}


export default class MoreActions extends React.Component {

  static Flavor = Flavor

  static propTypes = {
    // This function should return an array of items, where each item should be null (in which
    // case it is ignored), an object denoting separator ({name: '-'}), or a menu item object
    // of the following form: {name, icon, action, isRed}.
    getActions: PropTypes.func.isRequired,
    onPopupShown: PropTypes.func,
    onPopupHidden: PropTypes.func,
    viewportNodeSelector: PropTypes.string,
    btnClassName: PropTypes.string,
    flavor: PropTypes.number,
  }

  static defaultProps = {
    flavor: Flavor.IN_LIST
  }

  constructor() {
    super()
    this.state = {showMoreActions: false}
    this.togglePopup = this.togglePopup.bind(this)
  }

  togglePopup(e) {
    if (this.state.showMoreActions) {
      this.setState({showMoreActions: false})
      this.props.onPopupHidden && this.props.onPopupHidden()
      return
    }

    const {left, top, bottom} = this.rootNode.getBoundingClientRect()
    const {viewportNodeSelector} = this.props

    const viewport = viewportNodeSelector
      && document.querySelectorAll(viewportNodeSelector)[0]
      || document.children[0]

    const viewportBounds = viewport.getBoundingClientRect()

    const positionClass = cn(
      bottom + 255 < viewportBounds.bottom ? 'bottom' : 'top',
      left + 175 < viewportBounds.right ? 'right' : 'left'
    )

    this.setState({
      showMoreActions: true,
      popupLeft: left,
      popupTop: top,
      positionClass,
    })

    this.props.onPopupShown && this.props.onPopupShown()
  }

  renderMoreActions() {
    const showMoreActions = this.state.showMoreActions
    if (!showMoreActions) {
      return null
    }

    const actions = fixActions(this.props.getActions()).map((action, index) => {
      if (action.name == '-') {
        return <div key={`separator_${index}`}className='separator'></div>
      } else {
        return <MoreActionItem key={action.icon} {...action} />
      }
    })

    return <PopupWrapper onDismiss={this.togglePopup}>
      <div className={`more-actions-popup ${this.state.positionClass}`}>
        {actions}
      </div>
    </PopupWrapper>
  }

  render() {
    const tooltipDy = this.props.flavor == Flavor.IN_HEADER ? 8 : -3
    return (
      <Tooltip key={MORE_ACTIONS_BTN_CLASS}
               label={this.state.showMoreActions ? null : MORE_ACTIONS_BTN_LABEL}
               dy={tooltipDy}>
        <div className={`action-btn ${MORE_ACTIONS_BTN_CLASS} ${this.props.btnClassName}`}
            onClick={this.togglePopup}
            ref={rootNode => this.rootNode = rootNode}>
            {this.renderMoreActions()}
        </div>
      </Tooltip>
    )
  }
}


/**
 * Removes all null items and collapses all consequtive separators.
 */
function fixActions(actions) {
  const fixedActions = []
  let isLastSeparator = true
  for (let i = 0, t = actions.length; i < t; ++i) {
    const action = actions[i]
    if (action) {
      if (action.name == '-') {
        if (!isLastSeparator) {
          fixedActions.push(action)
        }
        isLastSeparator = true
      } else {
        fixedActions.push(action)
        isLastSeparator = false
      }
    }
  }
  if (fixedActions.length && fixedActions[fixedActions.length - 1].name == '-') {
    fixedActions.pop()
  }
  return fixedActions
}
