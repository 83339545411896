import { Map, List } from 'immutable'
import { createSelector } from 'reselect'

import mapValues from '~/utils/map-values'
import { iterablesShallowEqual } from '~/utils/shallow-compare'
import { FilteringState } from '~/utils/filter-constants'
import { ROUTE_PLAN, ROUTE_MY_TASKS } from '~/utils/routing'
import { isUserFeed } from '~/utils/feeds'
import { patchWithShortNames } from '~/utils/name'
import { isBot, isNotBot } from '~/utils/checks'

let sel = makeSelectors({
  isLoggedIn: ['auth', 'isLoggedIn'],
  isLoggingIn: ['auth', 'isLoggingIn'],
  userId: ['auth', 'credentials', 'user_id'],
  permissions: 'permissions',
  routingState: ['router'],
  route: ['router', 'route'],
  routeType: ['router', 'route', 'type'],
  routeQuery: ['router', 'location', 'search'],
  entities: 'entities',
  cards: ['entities', 'card'],
  users: ['entities', 'user'],
  feedEvents: ['entities', 'feed_event'],
  feedEntities: ['entities', 'feed'],
  notificationGroups: ['entities', 'notification_group'],
  timeEntryEntities: ['entities', 'time_entry'],
  fileEntities: ['entities', 'file'],
  presence: ['presence', 'list'],
  projects: 'projects',
  hasProjects: ['projects', 'hasProjects'],
  projectIds: ['projects', 'allIds'],
  selectedProjectId: ['projects', 'selectedId'],
  selectingProjectId: ['projects', 'selectingId'],
  selectedUserId: ['users', 'selectedId'],
  selectingUserId: ['users', 'selectingId'],
  selectedRecordId: ['records', 'selectedId'],
  selectingRecordId: ['records', 'selectingId'],
  planTopLevelCardId: ['planTopLevelCardId', 'current'],
  previousPlanTopLevelCardId: ['planTopLevelCardId', 'previous'],
  cardsViewState: 'cardsViewState',
  cardMovingOpId: 'cardMovingOpId',
  card: {
    optimisticCurrentParentId: ['optimistic', 'currentParentId'],
  },
  feeds: 'feeds',
  feedsById: ['feeds', 'byId'],
  openFeedIds: ['feeds', 'openIds'],
  unreadCounts: 'unreadCounts',
  search: 'search',
  filters: ['filters', 'current'],
  usingDefaultFilters: ['filters', 'usingDefaultFilters'],
  selectedCardId: 'selectedCardId',
  editingCardId: 'editingCardId',
  drafts: 'drafts',
  reports: 'reports',
  trackedHours: 'trackedHours',
  isRevealingCard: 'isRevealingCard',
  newActionToUndo: 'newActionToUndo',
  fatalError: 'fatalError',
  directChats: 'directChats',
  fullscreenChat: 'fullscreenChat',
  hasBankCard: 'hasBankCard',
  paymentStatus: 'paymentStatus',
  availabilityForm: 'availabilityForm',
  video: 'video',
})

sel.card.optimisticName = card => {
  let optimistic = card.get('optimistic')
  let optimisticName = optimistic && optimistic.get('name')
  return optimisticName || card.get('name')
}

sel.card.optimisticPath = card => {
  const optimistic = card.get('optimistic')
  return (optimistic ? optimistic.get('path') : null) || card.get('path')
}

sel.card.optimisticAssigneeId = card => {
  let optimistic = card.get('optimistic')
  return optimistic && optimistic.has('assignee')
    ? optimistic.get('assignee')
    : card.get('assignee')
}

sel.card.isOptimisticallyCompleted = card => {
  const optimistic = card.get('optimistic')
  if (optimistic) {
    const isCompleted = optimistic.get('isCompleted')
    if (isCompleted != undefined) {
      return isCompleted
    }
  }
  return card.get('completed')
}

sel.card.isOptimisticallyDeleted = card => {
  const optimistic = card.get('optimistic')
  if (optimistic) {
    const isDeleted = optimistic.get('isDeleted')
    if (isDeleted != undefined) {
      return isDeleted
    }
  }
  return card.get('deleted')
}

sel.card.optimisticDueDate = card => {
  let optimistic = card.get('optimistic')
  return optimistic && optimistic.has('dueDate')
    ? optimistic.get('dueDate')
    : card.get('dueDate')
}

sel.card.parentId = card => {
  const path = card.get('path')
  // hack for now until we fix the optimistic updates mess
  if (!path) {
    return card.get('parentId') || card.get('parent_id')
  }
  const index = path.size - 2
  return index >= 0 ? path.get(index) : null
}

sel.card.projectId = card => {
  // hack for now until we standardize to camelCase later
  return card.get('projectId') || card.get('project_id')
}

sel.cardViewState = {}

sel.cardViewState.isExpandedKey = (routeType, topLevelCardId) => {
  // WARN: changing value returned from this function for a given route type
  // will invalidate expand records stored on the clients' computers and cause
  // storage memory leak. So, at least clear all persisted expand state records
  // once on each client machine after introducing that kind of change. See
  // sagas/cards-view-state-persistence.js for the logic of persisting expand
  // state records to local storage.
  let routeKey
  switch (routeType) {
    case ROUTE_PLAN:
      routeKey = 'plan'
      break
    case ROUTE_MY_TASKS:
      routeKey = 'myTasks'
      topLevelCardId = null
      break
    // eslint-disable-next-line no-console
    default:
      console.error(`unsupported route type "${routeType}"`)
  }
  return topLevelCardId ? routeKey + '-' + topLevelCardId : routeKey
}

sel.cardViewState.isExpanded = (
  cardsViewState,
  cardId,
  routeType,
  topLevelCardId
) => {
  const viewState = cardsViewState.get(cardId)
  if (!viewState) return false
  const isDP =
    viewState.get('filtering').get('state') ==
    FilteringState.DESCENDANTS_PASSING
  const isExpandedMap = isDP
    ? viewState.get('isExpandedDP')
    : viewState.get('isExpanded')
  const key = sel.cardViewState.isExpandedKey(routeType, topLevelCardId)
  const isExpanded = isExpandedMap && isExpandedMap.get(key)
  return isExpanded == null ? isDP : isExpanded
}

sel.cardViewState.FILTERING_INFO_PASSING = Map.of(
  'state',
  FilteringState.PASSING,
  'hasPassingDescendants',
  true,
  'numFailingActiveChildren',
  0
)

sel.cardViewState.FILTERING_INFO_UNKNOWN = Map.of(
  'state',
  FilteringState.UNKNOWN,
  'hasPassingDescendants',
  false,
  'numFailingActiveChildren',
  0
)

sel.cardViewState.filteringInfo = (cardsViewState, cardId) => {
  const viewState = cardsViewState.get(cardId)
  const filteringInfo = viewState && viewState.get('filtering')
  return filteringInfo == null
    ? sel.cardViewState.FILTERING_INFO_UNKNOWN
    : filteringInfo
}

sel.projectEntities = createSelector(
  sel.projectIds,
  sel.cards,
  (projectIds, cards) => projectIds.map(id => cards.get(id))
)

let prevProjects = List()

sel.projectsList = createSelector(
  sel.projectIds,
  sel.cards,
  (projectIds, cards) => {
    const projects = projectIds.map(id => cards.get(id))
    if (iterablesShallowEqual(projects, prevProjects)) {
      return prevProjects
    } else {
      prevProjects = projects
      return projects
    }
  }
)

sel.me = createSelector(
  sel.userId,
  sel.users,
  (myUserId, users) => users && users.get(myUserId)
)

sel.selectingOrSelectedProjectId = createSelector(
  sel.selectingProjectId,
  sel.selectedProjectId,
  (selectingProjectId, selectedProjectId) =>
    selectingProjectId || selectedProjectId
)

sel.selectedProject = createSelector(
  sel.selectedProjectId,
  sel.cards,
  (selectedProjectId, cards) => cards && cards.get(selectedProjectId)
)

sel.selectedUser = createSelector(
  sel.selectedUserId,
  sel.users,
  (selectedUserId, users) => users && users.get(selectedUserId)
)

sel.selectingOrSelectedUserId = createSelector(
  sel.selectingUserId,
  sel.selectedUserId,
  (selectingUserId, selectedUserId) => selectingUserId || selectedUserId
)

sel.selectingOrSelectedRecordId = createSelector(
  sel.selectingRecordId,
  sel.selectedRecordId,
  (selectingRecordId, selectedRecordId) => selectingRecordId || selectedRecordId
)

sel.selectedProjectMembers = createSelector(
  sel.selectedProject,
  sel.users,
  (project, users) => {
    if (!project) {
      return List()
    }
    return project.get('memberships').map(m => users.get(m.get('user_id')))
  }
)

sel.selectedProjectMembersWithShortNames = createSelector(
  sel.selectedProjectMembers,
  patchWithShortNames
)

sel.selectedProjectMembersWithoutMe = createSelector(
  sel.selectedProjectMembers,
  sel.userId,
  (selectedProjectMembers, myUserId) => {
    return (
      selectedProjectMembers &&
      selectedProjectMembers.filter(user => user.get('id') !== myUserId)
    )
  }
)

sel.teamMembersWithShortNames = createSelector(
  sel.selectedProjectMembersWithoutMe,
  members => {
    return patchWithShortNames(members.filter(isNotBot))
  }
)

sel.botUserId = createSelector(sel.selectedProjectMembersWithoutMe, members => {
  const botUser = members.find(isBot)
  return botUser && botUser.get('id')
})

sel.cardWithId = (state, id) => sel.cards(state).get(id)

sel.feedWithId = (state, feedId) => {
  const feeds = sel.feedsById(state)
  return feeds.get(feedId)
}

sel.isFeedOpen = (state, feedId) => {
  const openFeedIds = sel.openFeedIds(state)
  return openFeedIds.has(feedId)
}

sel.selectedCard = state => {
  const selectedCardId = sel.selectedCardId(state)
  return selectedCardId && sel.cardWithId(state, selectedCardId)
}

sel.selectedFeedId = state => {
  const selectedCard = sel.selectedCard(state)
  const selectedFeedId = selectedCard && selectedCard.get('feedId')
  return selectedFeedId
}

sel.uploadProgress = (state, feedId) => {
  return state.getIn(['uploadProgress', feedId])
}

sel.selectedProjectFeedId = state => {
  const projectCard = sel.cardWithId(state, sel.selectedProjectId(state))
  if (!projectCard) {
    return undefined
  }
  return projectCard.get('feedId')
}

sel.selectedProjectActivityFeedId = state => {
  return `project-${sel.selectedProjectId(state)}`
}

sel.unreadCountForCardWithId = (state, cardId) => {
  const card = sel.cardWithId(state, cardId)
  if (!card) {
    return 0
  }
  const feedId = card.get('feedId')
  return sel.unreadCounts(state).get(feedId)
}

sel.unreadCountsForUserFeeds = state => {
  return sel.unreadCounts(state).filter((value, key) => isUserFeed(key))
}

sel.getEstimateRollup = (state, card) => {
  let sumMinEstimate = 0
  let sumMaxEstimate = 0
  const allCards = state.getIn(['entities', 'card'])

  //TODO: Do not use global variables inside a function
  const calcEstimates = (card, allCards) => {
    const cardEstimate = card.getIn(['estimate'])
    if (cardEstimate) {
      sumMinEstimate = sumMinEstimate + cardEstimate.get('min')
      sumMaxEstimate = sumMaxEstimate + cardEstimate.get('max')
    }
    const children = card.getIn(['children'])
    if (children) {
      children.forEach(child => {
        const childCard = allCards.getIn([child])
        return calcEstimates(childCard, allCards)
      })
    }
    return [sumMinEstimate, sumMaxEstimate]
  }

  ;[sumMinEstimate, sumMaxEstimate] = calcEstimates(card, allCards)

  return Map({
    min: sumMinEstimate,
    max: sumMaxEstimate,
  })
}

sel.getTimetrackRollup = (state, card) => {
  let timeTracked = 0
  const allCards = state.getIn(['entities', 'card'])

  //TODO: Do not use global variables inside a function
  const calcTimeTracked = (card, allCards) => {
    const cardTimeTracked = card.getIn(['timeTracked'])
    if (cardTimeTracked) {
      timeTracked = timeTracked + cardTimeTracked
    }
    const children = card.getIn(['children'])
    if (children) {
      children.forEach(child => {
        const childCard = allCards.getIn([child])
        return calcTimeTracked(childCard, allCards)
      })
    }
    return timeTracked
  }

  return calcTimeTracked(card, allCards)
}

sel.getBudget = (state, card) => {
  return card.get('budget')
}

function makeSelectors(sel) {
  return mapValues(sel, path => {
    switch (typeof path) {
      case 'string':
        return memoizeKey(path)
      case 'function':
        return path
      default:
        return Array.isArray(path) ? memoizePath(path) : makeSelectors(path)
    }
  })
}

function memoizePath(path) {
  let prevState = null
  let prevResult = null
  return state => {
    // TODO: use isImmutable instead of checking ad-hoc __mutable flag when Immutable.js 4.0 is out,
    // see github.com/facebook/immutable-js/pull/1113.
    if (state === prevState && !state.__mutable) {
      return prevResult
    }
    prevState = state
    return (prevResult = state.getIn(path))
  }
}

function memoizeKey(key) {
  let prevState = null
  let prevResult = null
  return state => {
    // TODO: use isImmutable instead of checking ad-hoc __mutable flag when Immutable.js 4.0 is out,
    // see github.com/facebook/immutable-js/pull/1113.
    if (state === prevState && !state.__mutable) {
      return prevResult
    }
    prevState = state
    return (prevResult = state.get(key))
  }
}

export default sel
