import React from 'react'
import cn from 'classnames'

type Props = {
  onCancelClick: () => void
  onDeleteClick: () => void
  theme?: string
}

export const MessageDeleteModal = (p: Props) => {
  return (
    <div className={cn('delete-modal', { 'theme-dark': p.theme === 'dark' })}>
      <div className="delete-modal-inner">
        <div>
          Are you sure you want to delete this message? This cannot be undone
        </div>
        <div className="buttons">
          <input
            type="button"
            className="cancel"
            onClick={p.onCancelClick}
            value="Cancel"
            autoFocus
          ></input>
          <input
            type="button"
            className="delete"
            onClick={p.onDeleteClick}
            value="Delete"
          ></input>
        </div>
      </div>
    </div>
  )
}
