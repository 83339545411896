import { useState, useCallback, useEffect } from 'react'
import { ChatInputProps } from './chat-input'
import * as Types from '~/types/api'

const TEXTAREA_HEIGHT = '40px'

export const useChatInput = ({ inputRef, ...props }: ChatInputProps) => {
  const [membersToSuggest, setMembersToSuggest] = useState<
    Types.GetChatData_feed_members[]
  >([])
  const [activeMemberIndex, setActiveMemberIndex] = useState(0)
  const [attachmentsVisible, setAttachmentsVisible] = useState(false)
  const [emojiVisible, setEmojiVisible] = useState(false)
  const [timeEntryFormVisible, setTimeEntryFormVisible] = useState(false)
  const [focused, setFocused] = useState(false)

  const hideMentions = () => {
    setActiveMemberIndex(0)
    setMembersToSuggest([])
  }

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    setFocus()
    if (inputRef.current) {
      inputRef.current.style.height = TEXTAREA_HEIGHT
    }
  }, [])

  useEffect(() => {
    if (props.messageToEdit) {
      clearInput()
      setFocus()
      insertSymbol(props.messageToEdit.text)
    }
  }, [props.messageToEdit])

  const onSend = useCallback(() => {
    if (inputRef.current) {
      const currentValue = inputRef.current.value.trim()
      clearInput()

      if (currentValue.length) {
        const members = props.members as Types.GetChatData_feed_members[]
        if (props.messageToEdit) {
          props.onEditMessage(
            props.messageToEdit.id,
            normalizeMessage(currentValue, members)
          )
          props.onEditModalClose()
        } else {
          props.onPostMessage(normalizeMessage(currentValue, members))
        }
      }

      inputRef.current.blur()
      setTimeout(() => {
        setFocus()
      }, 1)
    }
  }, [props.messageToEdit])

  const onUserClick = (user: Types.GetChatData_feed_members) => {
    if (inputRef.current) {
      const { value, selectionStart } = inputRef.current
      const userName = ('@' + user.name) as string

      const { newValue } = replaceCurrentMention(
        value,
        selectionStart,
        userName
      )
      const newCaretPosition =
        selectionStart + newValue.length - value.length + 1
      inputRef.current.value = newValue
      inputRef.current.setSelectionRange(newCaretPosition, newCaretPosition)
      setFocus()
    }
    hideMentions()
  }

  const onFocus = () => {
    inputRef.current?.focus()
    setFocused(true)
  }

  const onBlur = () => setFocused(false)

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    switch (e.key) {
      case 'Esc':
      case 'Escape':
        if (props.messageToEdit) {
          clearInput()
          props.onEditModalClose()
        }
        break
      case 'Enter':
        e.preventDefault()
        if (!e.shiftKey && !membersToSuggest.length) {
          onSend()
          break
        }
        if (membersToSuggest.length && membersToSuggest[activeMemberIndex]) {
          onUserClick(membersToSuggest[activeMemberIndex])
          break
        } else {
          insertSymbol('\n', e.currentTarget.selectionStart)
          break
        }
      case 'ArrowUp':
        if (membersToSuggest.length) {
          e.preventDefault()
          setActiveMemberIndex(
            activeMemberIndex === 0
              ? membersToSuggest.length - 1
              : activeMemberIndex - 1
          )
        }
        break
      case 'ArrowDown':
        if (membersToSuggest.length) {
          e.preventDefault()
          setActiveMemberIndex(
            activeMemberIndex === membersToSuggest.length - 1
              ? 0
              : activeMemberIndex + 1
          )
        }
        break
      case 'Tab':
        e.preventDefault()
        if (membersToSuggest.length) {
          onUserClick(membersToSuggest[activeMemberIndex])
        }
        break
      default:
        break
    }
  }

  const onKeyUp = () => {
    if (inputRef.current) {
      const { value, selectionStart } = inputRef.current
      const preString = value.substring(0, selectionStart)
      const atIndex = preString.lastIndexOf('@')

      if (atIndex === -1) {
        hideMentions()
        return
      }

      const lastAfterSignString = preString.substring(atIndex + 1)
      const afterStringWords = value
        .substring(selectionStart)
        .split('\n')[0]
        .split(' ')
      const initialPrefix = lastAfterSignString + afterStringWords[0]
      const members = props.members as Types.GetChatData_feed_members[]

      setMembersToSuggest(
        members.filter(member => {
          const memberName = member.name as string
          return (
            memberName.toLowerCase().indexOf(initialPrefix.toLowerCase()) !==
              -1 && member.id !== props.currentUserId
          )
        })
      )
    }
  }

  const onPaste = useCallback(e => {
    const fileName = e.clipboardData.getData('text')
    const shouldPasteFile =
      e.clipboardData.items.length &&
      (fileName === '' || /[.]\w+$/.test(fileName))
    if (shouldPasteFile) {
      const images = [...e.clipboardData.items].filter(i => i.kind === 'file')
      if (images.length) {
        const file = images[0].getAsFile()
        if (file) {
          props.uploadFile(file)
        }
        e.preventDefault()
      }
    }
  }, [])

  const insertSymbol = (s: string, caretLocation?: number) => {
    if (inputRef.current && typeof caretLocation === 'number') {
      const inputValueArray = inputRef.current.value.split('')
      const newCaretLocation = caretLocation + 1

      inputValueArray.splice(caretLocation, 0, '\n')
      inputRef.current.value = inputValueArray.join('')
      inputRef.current.setSelectionRange(newCaretLocation, newCaretLocation)
    } else if (inputRef.current) {
      inputRef.current.value += s
    }
  }

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return {
    attachmentsVisible,
    focused,
    emojiVisible,
    timeEntryFormVisible,
    membersToSuggest,
    activeMemberIndex,
    onSend,
    onKeyDown,
    onKeyUp,
    onUserClick,
    onFocus,
    onBlur,
    onPaste,
    insertSymbol,
    hideMentions,
    clearInput,
    setTimeEntryFormVisible,
    setEmojiVisible,
    setAttachmentsVisible,
  }
}

const makeNameRegExp = (name: string) => {
  const escapedName = name.replace(/\(/g, '[(]').replace(/\)/g, '[)]')
  return new RegExp(`@(${escapedName})`, 'gi')
}

//we could memoize the damn thing
const gatherMemberNameRegexps = (members: Types.GetChatData_feed_members[]) => {
  const fullNames = members.reduce(
    (res, member: Types.GetChatData_feed_members) => {
      const id = member.id
      const name = member.name as string

      // @ts-ignore
      res.push({ id, regExp: makeNameRegExp(name) })
      return res
    },
    []
  )
  return fullNames
}

const normalizeMessage = (
  message: string,
  members: Types.GetChatData_feed_members[]
) => {
  const trimmedMessage = message.trim()
  const memberNameRegexps = gatherMemberNameRegexps(members)

  return memberNameRegexps.reduce((resultText, { id, regExp }) => {
    return resultText.replace(regExp, `<user:${id}>`)
  }, trimmedMessage)
}

function replaceCurrentMention(
  str: string,
  cursor: number,
  mentionText: string
) {
  const mentionBeginIndex = str.substring(0, cursor).lastIndexOf('@')

  const mentionAndAfterLowerString = str
    .substring(mentionBeginIndex)
    .toLowerCase()

  const lowerMentionReplace = mentionText.toLowerCase()
  let index = 0
  let run = true
  while (run) {
    if (mentionAndAfterLowerString[index] === lowerMentionReplace[index]) {
      index += 1
    } else {
      run = false
    }
    if (
      index >= mentionAndAfterLowerString.length ||
      index >= lowerMentionReplace.length
    ) {
      run = false
    }
  }

  const mentionEndIndex =
    index + mentionBeginIndex + (str[index + mentionBeginIndex] === ' ' ? 1 : 0)

  return {
    newValue: `${str.substring(
      0,
      mentionBeginIndex
    )}${mentionText} ${str.substring(mentionEndIndex)}`,
    insertedMentionEndIndex: mentionBeginIndex + mentionText.length,
  }
}
