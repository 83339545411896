import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'
import {WEEK_TIME_PERIOD} from '~/utils/time'
import {dollarsToCents, formatDollars} from '~/utils/money'
import { setIn } from '~/utils/mutate'

const INITIAL_STATE = reportReducer.INITIAL_STATE = Map({
  report: null,
  offset: 0,
  periodType: WEEK_TIME_PERIOD,
  start: null,
  end: null,
})

export default function reportReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.GET_REPORT: {
      return state.withMutations(state => {
        return state.set('offset', action.offset)
                    .set('periodType', action.periodType)
                    .set('report', null)
                    .set('start', null)
                    .set('end', null)
      })
    }
    case ActionTypes.GET_CUSTOM_REPORT: {
      return state.withMutations(state => {
        return state.set('start', action.start)
                    .set('end', action.end)
                    .set('report', null)
                    .set('periodType', null)
                    .set('offset', null)
      })
    }
    case ActionTypes.REPORT_READY: {
      return state.withMutations(state => {
        const report = action.report
        return state.set('report', report)
      })
    }
    case ActionTypes.PAYMENT_SUCCESS: {
      return state.update('report', report => {
        const newBalance = report.balance.amount + dollarsToCents(action.amount)
        return setIn(report, ['balance', 'amount'], newBalance)
      })
    }
  }
  return state
}
