import React from 'react'
import PageError from './page-error'


export default function Page404() {
  return (
    <PageError title='Not found'>
      There is nothing here. <a href='/'>Go to home page</a>.
    </PageError>
  )
}
