import React, { useRef } from 'react'
import cn from 'classnames'

type Props = {
  messageText: string
  onClose: () => void
  inputRef: React.RefObject<HTMLTextAreaElement>
  theme?: string
}

export const MessageEditModal = React.memo((p: Props) => {
  const innerRef = useRef(null)
  return (
    <div
      className={cn('edit-modal', { 'theme-dark': p.theme === 'dark' })}
      ref={innerRef}
    >
      <div className="edit-icon" />
      <div className="text">{p.messageText}</div>
      <div className="close-icon" onClick={p.onClose}></div>
    </div>
  )
})
