
export function hideGlobalLoader() {
  let loader = document.getElementById('loader')
  if (!isGlobalLoaderHidden(loader)) {
    loader.className += ' hidden'
  }
}

export function showGlobalLoader() {
  let loader = document.getElementById('loader')
  if (isGlobalLoaderHidden(loader)) {
    loader.className += loader.className.split(/ +/).filter(s => s != 'hidden').join(' ')
  }
}

function isGlobalLoaderHidden(loader) {
  return /(?:^| )hidden(?: |$)/.test(loader.className)
}
