import React, { useMemo, useState, useCallback, useRef } from 'react'
import {
  GetChatData_feed_feedEvents,
  GetChatData_feed_feedEvents_bodyTokens,
  GetChatData_feed_members,
} from '~/types/api'
import cn from 'classnames'
import { HoverMenu } from './hover-menu'
import Tooltip from '../tooltip'
import { isMessageOptimistic } from './util/helpers'
import reactStringReplace from 'react-string-replace'
import dayjs from '~/utils/dayjs'
import { formatInterval } from '~/utils/duration'
import { EMOJI_POPUP_HEIGHT, LIKE_REACTION } from './util/constants'
import { EmojiPopup } from './emoji-popup'
import { TurtleAvatar } from '../turtle-avatar'

type Props = {
  currentUserId: string
  isChained: boolean
  message: GetChatData_feed_feedEvents | null
  onCardClick: (cardId: string) => void
  onDateTimeClick: (dateTime: string) => void
  onAddReaction: (feedEventId: string, emoji: string) => void
  onUserClick: (
    userId: string | null | undefined,
    sourceRef: React.RefObject<HTMLDivElement>
  ) => void
  onRemoveReaction: (feedEventId: string, emoji: string) => void
  onEditMessage: (
    feedEventId: string,
    bodyTokens: (GetChatData_feed_feedEvents_bodyTokens | null)[] | null
  ) => void
  onDeleteMessage: (feedEventId: string) => void
  chatMembers: (GetChatData_feed_members | null)[] | null
  theme?: string
}

type Reaction = {
  users: Pick<GetChatData_feed_members, 'id' | 'name'>[]
  emoji: string
  hasOwnReaction: boolean
}

export const UserMessage = ({ message, isChained, theme, ...props }: Props) => {
  if (!message) {
    return null
  }
  const [emojiVisible, setEmojiVisible] = useState(false)
  const [isWithFile, setIsWithFile] = useState(false)
  const [isEmojiBottomPadded, setIsEmojiBottomPadded] = useState(false)
  const messageRef = useRef<HTMLDivElement>(null)

  const messageSenderName = useMemo(() => {
    if (message.sender?.name?.length) {
      return message.sender.name.split(' ')[0]
    } else {
      return 'User'
    }
  }, [message.sender])

  const messageTime = useMemo(() => dayjs(message.createdAt).format('h:mm a'), [
    message.createdAt,
  ])

  const parsedMessage = useMemo(() => {
    const result = message.bodyTokens?.map((token, index) => {
      if (!token) {
        return null
      }
      switch (token.__typename) {
        case 'StringObject':
          if (!token.string) {
            return null
          }
          const coded = reactStringReplace(
            token.string,
            /`([\s\S]*?)`/,
            match => (
              <span key={`code_${index}`} className="code">
                {match}
              </span>
            )
          )
          return <span key={index}>{coded}</span>
        case 'DateObject':
          return <span key={index}>{token.dateObj}</span>
        case 'DurationRange':
          return (
            <span key={index}>
              {formatInterval(token.min, token.max, false)}
            </span>
          )
        case 'Link':
          return (
            <a key={index} href={token.uri!} target="blank">
              {token.uri}
            </a>
          )
        case 'User':
          return (
            <span
              key={index}
              onClick={() => props.onUserClick(token.id, messageRef)}
              className={cn('mention', {
                current: props.currentUserId === token.id,
              })}
            >
              @{token.name}
            </span>
          )
        case 'Card':
          return (
            <a
              href="#"
              key={index}
              className={cn({ completed: token.completed })}
              onClick={() => props.onCardClick(token.id!)}
            >
              {token.name}
            </a>
          )
        case 'File':
          setIsWithFile(true)
          if (token.mimetype?.includes('image') && token.uri) {
            return (
              <a
                key={index}
                className={cn('image', {
                  optimistic: isMessageOptimistic(token.id),
                })}
                target="blank"
                href={token.uri}
              >
                {isMessageOptimistic(token.id) ? (
                  <div className="loader" />
                ) : null}
                <img src={token.uri} />
              </a>
            )
          } else if (token.uri) {
            const fileType = token.mimetype?.substring(
              token.mimetype.indexOf('/') + 1,
              token.mimetype.length
            )
            return (
              <a
                key={index}
                className={cn('file', {
                  optimistic: isMessageOptimistic(token.id),
                })}
                target="blank"
                href={token.uri}
              >
                {isMessageOptimistic(token.id) ? (
                  <div className="loader" />
                ) : null}
                <div className="icon">{fileType}</div>
                <div className="content">
                  <div className="name">{token.name}</div>
                  <div className="size">
                    {token.size && (token.size / 1024).toFixed(2)} kB
                  </div>
                </div>
              </a>
            )
          }
          return <b key={index}>{' ' + token.name}</b>
        case 'TimeEntry':
        case 'DurationObject':
          return null
        // return <span key={index}>{formatDuration(token.duration)}</span>
        case 'DatetimeObject':
          const formattedDateTime = dayjs(token.datetime).format('L LT')
          return (
            <span
              onClick={() => props.onDateTimeClick(formattedDateTime)}
              className="date-time"
              key={index}
            >
              {formattedDateTime}
            </span>
          )
        default:
          return null
      }
    })
    return result
  }, [message.bodyTokens])

  const messageReactions = useMemo(() => {
    return (
      message.reactions?.reduce((res: Reaction[], reaction) => {
        const userId = reaction?.user?.id
        const exisitingReaction = res.find(e => e.emoji === reaction?.emoji)
        const reactionUserData = props?.chatMembers?.find(
          member => member?.id === userId
        )
        const ownUserReaction = userId === props.currentUserId

        if (!exisitingReaction) {
          const reactionUser = {
            name: ownUserReaction ? 'You' : reactionUserData?.name ?? 'A User',
            id: userId ?? '',
          }

          res.push({
            emoji: reaction?.emoji!,
            users: [reactionUser],
            hasOwnReaction: ownUserReaction,
          })
        } else {
          const existingReactionUser = {
            name: ownUserReaction ? 'You' : reactionUserData?.name ?? 'A User',
            id: userId ?? '',
          }

          exisitingReaction.users = [
            ...exisitingReaction.users,
            existingReactionUser,
          ]
          exisitingReaction.hasOwnReaction = !exisitingReaction.hasOwnReaction
            ? ownUserReaction
              ? true
              : false
            : true
        }
        return res
      }, []) ?? []
    )
  }, [message.reactions])

  const handleOpenReactions = useCallback(() => {
    if (messageRef.current) {
      const parent = messageRef.current.parentElement
      if (
        parent &&
        messageRef.current.getBoundingClientRect().top -
          parent.getBoundingClientRect().top <
          EMOJI_POPUP_HEIGHT
      ) {
        setIsEmojiBottomPadded(true)
      } else {
        setIsEmojiBottomPadded(false)
      }
    }
    setEmojiVisible(!emojiVisible)
  }, [message, emojiVisible])

  const isReactionAdded = useCallback(
    (emoji: string) =>
      messageReactions.find(r => r.emoji === emoji)?.hasOwnReaction ?? false,
    [messageReactions]
  )

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      isReactionAdded(emoji)
        ? props.onRemoveReaction(message.id!, emoji)
        : props.onAddReaction(message.id!, emoji)
    },
    [message.reactions]
  )

  const isDark = theme === 'dark'

  return (
    <div
      className={cn('user-message', {
        chained: isChained,
        optimistic: isMessageOptimistic(message.id),
        'theme-dark': isDark,
      })}
      ref={messageRef}
    >
      {!isChained ? (
        <TurtleAvatar
          source={message.sender?.photo}
          size={isDark ? 32 : 34}
          onClick={() => props.onUserClick(message.sender?.id, messageRef)}
        />
      ) : null}
      <div className="content" style={!isChained ? {} : { paddingLeft: 50 }}>
        {!isChained ? (
          <div className="header">
            <span className="sender">{messageSenderName}</span>
            <span className="time">{messageTime}</span>
          </div>
        ) : null}
        <div className="body">
          {parsedMessage}
          {message.edited ? <span className="edited">(edited)</span> : null}
        </div>
        <div className="reaction">
          {messageReactions.map((reaction, index) => (
            <Tooltip
              label={`${reaction.users.map(user => user.name).join(', ')}`}
              position={Tooltip.BOTTOM}
              key={index}
            >
              <div
                onClick={() =>
                  reaction.hasOwnReaction
                    ? props.onRemoveReaction(message.id!, reaction.emoji)
                    : props.onAddReaction(message.id!, reaction.emoji)
                }
                className={`${reaction.hasOwnReaction && 'self-reaction'}`}
              >
                <span className="emoji">{reaction.emoji}</span>
                <span className="count">{reaction.users.length}</span>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
      {!isMessageOptimistic(message.id) ? (
        <HoverMenu
          onThumbClick={() => handleEmojiClick(LIKE_REACTION)}
          onDeleteClick={() => props.onDeleteMessage(message.id!)}
          onEditClick={() =>
            props.onEditMessage(message.id!, message.bodyTokens)
          }
          onReactionClick={handleOpenReactions}
          isSendersMessage={message.sender?.id === props.currentUserId}
          isMessageLiked={isReactionAdded(LIKE_REACTION)}
          isEditable={!isWithFile}
          theme={theme}
        />
      ) : null}
      {emojiVisible && (
        <EmojiPopup
          onClose={() => setEmojiVisible(false)}
          onEmojiInsert={(emoji: string) => {
            handleEmojiClick(emoji)
            setEmojiVisible(false)
          }}
          isBottomPadded={isEmojiBottomPadded}
          theme={theme}
        />
      )}
    </div>
  )
}
