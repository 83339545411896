import React from 'react'
import connect from '../utils/connect'

import sel from '~/selectors'
import { buildPath, routeAfterSwitchingProject } from '~/utils/routing'

import Header from './header'

class HeaderContainer extends React.Component {
  static mapStateToProps(state) {
    const reports = sel.reports(state)
    return {
      me: sel.me(state),
      selectedProjectId: sel.selectedProjectId(state),
      selectingProjectId: sel.selectingProjectId(state),
      projects: sel.projectsList(state),
      routeType: sel.routeType(state),
      permissions: sel.permissions(state),
      report: reports.get('report'),
    }
  }

  constructor() {
    super()
    this.selectProject = this.selectProject.bind(this)
  }

  selectProject(projectId) {
    const { routeType } = this.props
    const newRoute = routeAfterSwitchingProject(routeType, projectId)
    const newPathname = buildPath(newRoute)
    this.props.actions.navigateTo(newPathname)
  }

  render() {
    const { props } = this
    return (
      <Header
        me={props.me}
        selectedProjectId={props.selectedProjectId}
        selectingProjectId={props.selectingProjectId}
        projects={props.projects}
        selectProject={this.selectProject}
        logout={props.actions.logout}
        selectUser={props.actions.selectUser}
        permissions={props.permissions}
        report={props.report}
      />
    )
  }
}

export default connect(HeaderContainer)
