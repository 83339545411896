import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import {Map} from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'

import {shortName} from '~/utils/name'
import {isNotBot} from '~/utils/checks'
import escapeRegExp from '~/utils/escape-regexp'

import UserAvatar from '../user-avatar'
import FilterInput from '../filter-input'
import PopupWrapper from '../popup-wrapper'
import {SelectItem} from '../select-items'
import SelectItems from '../select-items'
import Tooltip from '../tooltip'

const ASSIGNEE_CARD = 'assignee-card'
const EMPTY = 'empty'

export default class AssigneeCard extends React.Component {

  static propTypes = {
    projectMembers: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    assignee: ImmutablePropTypes.map,
    assign: PropTypes.func,
    unassign: PropTypes.func,
    onPopupShown: PropTypes.func,
    onPopupHidden: PropTypes.func,
    isCompleted: PropTypes.bool,
    viewportNodeSelector: PropTypes.string,
  }

  static Flavor = {
    DEFAULT: null,
    IN_HEADER: 'IN_HEADER'
  }

  state = {showAssigneeList: false, filterValue: ''}

  togglePopup = () => {
    if (this.props.isCompleted) {
      return
    }

    if (this.state.showAssigneeList) {
      this.setState({showAssigneeList: false})
      this.props.onPopupHidden && this.props.onPopupHidden()
      return
    }

    const {left, top, bottom} = this.rootNode.getBoundingClientRect()
    const {viewportNodeSelector} = this.props

    const viewport = viewportNodeSelector
      && document.querySelectorAll(viewportNodeSelector)[0]
      || document.children[0]

    const viewportBounds = viewport.getBoundingClientRect()

    const positionClass = cn(
      bottom + 140 < viewportBounds.bottom ? 'bottom' : 'top',
      left + 200 < viewportBounds.right ? 'right' : 'left'
    )

    this.setState({
      showAssigneeList: true,
      filterValue: '',
      showingIndexes: null,
      popupLeft: left,
      popupTop: top,
      positionClass,
    })

    this.props.onPopupShown && this.props.onPopupShown()
  }

  renderAssigneeListItem = (member, index) => {
    return <SelectItem  key={`assignee_${index}`}
                  className={`assignee-suggestion ${this.props.assignee ? '' : 'no-selected'}`}
                  onSelected={() => this.assign(member.get('id'))}>
                <div className='team-member' key={member.get('id')}>
                  <UserAvatar userId={member.get('id')}/>
                  <span className='username'>{member.get('name')}</span>
                </div>
            </SelectItem>
  }

  isNotAssigneeOrMeOrBot = (member) => {
    return this.isNotAssignee(member) && this.isNotMe(member) && isNotBot(member)
  }

  isNotAssignee(member) {
    return !this.props.assignee || this.props.assignee.get('id') !== member.get('id')
  }

  isNotMe(member) {
    return !this.props.me || this.props.me.get('id') !== member.get('id')
  }

  renderCurrentAssignee() {
    const {assignee} = this.props
    if (!assignee) {
      return false
    }
    return <SelectItem  key={`current_assignee`}
                  className='assignee-suggestion current'
                  onSelected={this.unassign}>
                <div className='team-member' key={assignee.get('id')}>
                  <UserAvatar userId={assignee.get('id')}/>
                  <span className='username'>{assignee.get('name')}</span>
                  <span className='unassign-sign'></span>
                </div>
            </SelectItem>
  }

  renderAssigneeList() {
    const assigneeList = [
      this.renderCurrentAssignee(),
      this.isNotAssignee(this.props.me) && this.renderAssigneeListItem(this.props.me, -1),
      this.props.projectMembers.filter(this.isNotAssigneeOrMeOrBot).map(this.renderAssigneeListItem).toArray()
    ].filter(assignee => !!assignee)

    return this.state.showAssigneeList &&
          <PopupWrapper onDismiss={this.togglePopup}>
            <div className={`assignee-popup ${this.state.positionClass}`}>
              {this.renderFilter()}
              <SelectItems
                onFilteringStarted={this.onFilteringStarted}
                onItemSelected={this.togglePopup}
                showingIndexes={this.state.showingIndexes}
                containerClassName="assignee-list">
                {assigneeList}
              </SelectItems>
            </div>
          </PopupWrapper>
  }

  onFilteringStarted = (e) => {
    this.updateFilter(e.key)
  }

  onFilterChanged = (e) => {
    const filterValue = e.target.value
    this.updateFilter(filterValue)
  }

  updateFilter = (filterValue) => {
    if (filterValue === '') {
      this.clearFilter()
      return
    }

    const filterRegexp = new RegExp(`(?:^|\\s)${escapeRegExp(filterValue)}`, 'i')

    // construct list with same indexes as for SelectItems children
    let assigneeList = this.props.projectMembers.filter(this.isNotAssigneeOrMeOrBot)

    if (this.props.assignee && this.isNotAssignee(this.props.me)){
      assigneeList = assigneeList.unshift(this.props.me)
      assigneeList = assigneeList.unshift(this.props.assignee)
    } else {
      assigneeList = assigneeList.unshift(this.props.me)
    }

    const showingIndexes = assigneeList
      .reduce((indexes, member, index) => {
        if (filterRegexp.test(member.get('name'))) {
          indexes.push(index)
        }
        return indexes
      }, [])


    this.setState({filterValue, showingIndexes})
  }

  clearFilter = () => this.setState({filterValue: '', showingIndexes: null})

  renderFilter() {
    return this.state.filterValue !== '' && <FilterInput className="-in-assignee-list"
                                                 onClear={this.clearFilter}
                                                 value={this.state.filterValue || ''}
                                                 onChange={this.onFilterChanged}/>
  }

  assign = (userId) => {
    this.props.assign && this.props.assign(userId)
  }

  unassign = () => {
    this.props.unassign && this.props.unassign()
  }

  render() {
    const {isCompleted, flavor} = this.props
    const assignee = this.props.assignee || Map({name: null, photo: null, id: null})
    const assigneeName = assignee.get('name')
    const inHeader = flavor === AssigneeCard.Flavor.IN_HEADER


    const assigneeListHidden = !this.state.showAssigneeList

    const tooltipDy = inHeader
      ? assigneeName ? 2 : 8
      : assigneeName ? -3 : -3

    return assigneeName ? (
      <Tooltip label={assigneeListHidden && shortName(assigneeName)} dy={tooltipDy}>
        <div className={cn(ASSIGNEE_CARD, inHeader && '-in-header')}
             onClick={this.togglePopup}
             ref={rootNode => this.rootNode = rootNode}>
          <UserAvatar userId={assignee.get('id')} className={cn(inHeader && '-big')}/>
          {this.renderAssigneeList()}
        </div>
      </Tooltip>
    ) : (
      <Tooltip label={assigneeListHidden && (isCompleted ? 'No assignee' : 'Assign')} dy={tooltipDy}>
        <div className={cn(ASSIGNEE_CARD, EMPTY, inHeader && '-in-header')}
            onClick={this.togglePopup}
            ref={rootNode => this.rootNode = rootNode}>
          {this.renderAssigneeList()}
        </div>
      </Tooltip>
    )
  }
}
