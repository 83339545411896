import React from 'react'

import Loader from '../loader'
import UserAvatar from '../user-avatar'
import { formatDuration } from '~/utils/time'
import Layout from '~/components/layout/layout-with-single-frame'
import ScrollableTable from '~/components/scrollable-table'
import { TimePeriodHeader } from '../time-period-header'
import Budget from '~/components/budget'
import dayjs from '~/utils/dayjs'

function totalTime(report) {
  return report.timeEntries.reduce((res, el) => res + el.duration, 0)
}

const TASK_TAB = 'TASK'
const INDIVIDUAL_TAB = 'INDIVIDUAL'
const TRANSACTIONS_TAB = 'TRANSACTIONS'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: TASK_TAB,
    }
  }

  render() {
    return (
      <Layout>
        <div className="dashboard">
          <div className="hours-panel">
            {this.renderHeader()}
            {this.renderBody()}
          </div>
          <div className="budget-panel-container">{this.renderBudget()}</div>
        </div>
      </Layout>
    )
  }

  renderBudget() {
    const report = this.props.reports.get('report')

    if (!report) {
      return false
    }

    return (
      <Budget
        report={report}
        user={this.props.user}
        togglePaymentForm={this.props.togglePaymentForm}
        hasBankCard={this.props.hasBankCard}
        sendPaymentV2={this.props.sendPaymentV2}
        paymentStatus={this.props.paymentStatus}
      />
    )
  }

  renderHeader = () => {
    return (
      <div className="hours-header">
        <TimePeriodHeader
          offset={this.props.reports.get('offset')}
          periodType={this.props.reports.get('periodType')}
          onPeriodShift={this.props.shiftPeriod}
          onCustomPeriodSet={this.props.getCustomPeriod}
          onPeriodTypeChange={this.props.changePeriodType}
        />
        <div className="tabs">
          <div
            className={`tab ${this.state.tab === TASK_TAB ? 'active' : ''}`}
            onClick={this.setTabTask}
          >
            Hours by task
          </div>
          <div
            className={`tab ${
              this.state.tab === INDIVIDUAL_TAB ? 'active' : ''
            }`}
            onClick={this.setTabIndividual}
          >
            Hours by person
          </div>
          <div
            className={`tab ${
              this.state.tab === TRANSACTIONS_TAB ? 'active' : ''
            }`}
            onClick={this.setTabTransactions}
          >
            Transactions
          </div>
        </div>
      </div>
    )
  }

  setTabTask = () => this.setState({ tab: TASK_TAB })

  setTabIndividual = () => this.setState({ tab: INDIVIDUAL_TAB })

  setTabTransactions = () => this.setState({ tab: TRANSACTIONS_TAB })

  renderBody = () => {
    const report = this.props.reports.get('report')
    if (!report) {
      return (
        <div className="loader-container">
          <Loader colorScheme={'dark'} />
        </div>
      )
    }
    if (report.err) {
      return <div className="content">Error while loading period report</div>
    }
    if (this.isScreenIsEmpty(report) && this.state.tab !== TRANSACTIONS_TAB) {
      return this.renderNoEntries(
        'Nothing tracked for this time period. Any questions?'
      )
    }
    if (this.state.tab === INDIVIDUAL_TAB) {
      return (
        <ScrollableTable
          header={this.renderIndividualHeader()}
          content={this.getTimeTrackedByUser(report)}
          footer={this.renderFooter()}
        />
      )
    }
    if (this.state.tab === TRANSACTIONS_TAB) {
      return this.renderTransactions()
    }
    return (
      <ScrollableTable
        header={this.renderTaskHeader()}
        content={this.getTimeTrackedByTask(report)}
        footer={this.renderFooter()}
      />
    )
  }

  renderIndividualHeader = () => {
    return (
      <div className="details-individual-header">
        <div className="main-label">Freelancer</div>
        <div className="secondary-label">Time</div>
      </div>
    )
  }

  renderTaskHeader = () => {
    return (
      <div className="details-task-header">
        <div className="main-label">Task</div>
        <div className="secondary-label">Freelancer</div>
        <div className="secondary-label">Time</div>
      </div>
    )
  }

  // renderTransactionsHeader = () => {
  //   return (
  //     <div className="details-task-header">
  //       <div className="first-fixed-label">Date</div>
  //       <div className="main-label">Description</div>
  //       <div className="secondary-label">Amount</div>
  //       <div className="secondary-label">Balance</div>
  //     </div>
  //   )
  // }

  renderTransactions = () => {
    return null
    // <AccountTransactionTable
    //   accountId={`project:${projectId}`}
    //   start={start}
    //   end={end}
    //   withoutSourceProjectName
    //   withoutLoadMoreOnScroll
    // />

    // return (
    //   <ApolloProvider client={client}>
    //     <Query
    //       query={getTxQuery}
    //       variables={{
    //         id: `project:${projectId}`,
    //         startDate: start,
    //         endDate: end,
    //       }}
    //     >
    //       {({ loading, error, data }) => {
    //         if (loading)
    //           return (
    //             <div className="loader-container">
    //               <Loader colorScheme={'dark'} />
    //             </div>
    //           )
    //         if (error)
    //           return this.renderNoEntries(
    //             'Something went wrong while loading transactions. Please try again later or'
    //           )
    //         if (data.account.entries.length === 0) {
    //           return this.renderNoEntries(
    //             'There is no transactions for this time period.  Any questions?'
    //           )
    //         }

    //         const content = (
    //           <div>
    //             {data.account.entries.map(tx => {
    //               const description = tx.sourceEvent
    //                 ? getDescriptionForTransaction(tx)
    //                 : [
    //                     {
    //                       type: 'text',
    //                       text: tx.memo ? tx.memo : 'no description',
    //                     },
    //                   ]

    //               // @TODO: In the future we need a better way to know for sure the actor id.
    //               const mostLikelyActorId = ['user', 'user-shortname'].includes(
    //                 description[0].type
    //               )
    //                 ? description[0].user.id
    //                 : ''

    //               const id = String(Math.ceil(Math.random() * Math.pow(10, 10)))
    //               const createdAt = tx.createdAt
    //               const delta = tx.delta.amount
    //               const balance = tx.balanceSnapshot.amount

    //               return (
    //                 <TransactionEntry
    //                   key={id}
    //                   createdAt={createdAt}
    //                   userId={mostLikelyActorId}
    //                   description={description}
    //                   delta={delta}
    //                   balance={balance}
    //                 />
    //               )
    //             })}
    //           </div>
    //         )

    //         return (
    //           <ScrollableTable
    //             header={this.renderTransactionsHeader()}
    //             content={content}
    //             footer={<div></div>}
    //           />
    //         )
    //       }}
    //     </Query>
    //   </ApolloProvider>
    // )
  }

  renderFooter = () => {
    const report = this.props.reports.get('report')
    const totalTimeSpent = totalTime(report)
    const totalTimeSpentLabel = formatDuration(totalTimeSpent)
    return (
      <div className="total-footer">
        <div className="total-label">Total time</div>
        <div className="total-time">{totalTimeSpentLabel}</div>
      </div>
    )
  }

  renderTaskEntry = ({ task, user, duration, details }) => {
    return (
      <TaskEntry
        key={`${user.id}-${task.id}`}
        revealCard={this.props.revealCard}
        task={task}
        user={user}
        duration={duration}
        details={details}
      />
    )
  }

  getTimeTrackedByTask = ({ timeEntries }) => {
    const taskUserEntries = timeEntries.reduce((res, timeEntry) => {
      const key = `${timeEntry.card.id}_${timeEntry.user.id}`
      if (!res[key]) {
        res[key] = {
          task: {
            id: timeEntry.card.id,
            name: timeEntry.card.name,
          },
          user: {
            id: timeEntry.user.id,
          },
          details: [],
          duration: 0,
        }
      }
      res[key].duration += timeEntry.duration
      res[key].details.push({
        memo: timeEntry.memo,
        duration: timeEntry.duration,
        date: dayjs(timeEntry.date).format('MMM, D'),
      })
      return res
    }, {})

    return Object.keys(taskUserEntries)
      .map(key => taskUserEntries[key])
      .map(this.renderTaskEntry)
  }

  getTimeTrackedByUser = report => {
    const timeEntries = report.timeEntries
    const userEntries = timeEntries.reduce((res, timeEntry) => {
      if (!res[timeEntry.user.id]) {
        res[timeEntry.user.id] = {
          user: {
            id: timeEntry.user.id,
            name: timeEntry.user.name,
          },
          details: [],
          duration: 0,
        }
      }
      res[timeEntry.user.id].duration += timeEntry.duration
      res[timeEntry.user.id].details.push({
        name: timeEntry.card.name,
        memo: timeEntry.memo,
        duration: timeEntry.duration,
        cardId: timeEntry.card.id,
        date: dayjs(timeEntry.date).format('MMM, D'),
      })
      return res
    }, {})
    return Object.keys(userEntries)
      .map(key => userEntries[key])
      .map(this.renderUserEntry)
  }

  renderUserEntry = ({ user, duration, details }, index) => {
    return (
      <UserEntry
        key={index}
        user={user}
        duration={duration}
        details={details}
        revealCard={this.props.revealCard}
      />
    )
  }

  isScreenIsEmpty = report => totalTime(report) === 0

  renderNoEntries = message => {
    return (
      <div className="help">
        <span>{`${message} `}</span>
        <a
          className="ask-link"
          href="mailto:help@turtleos.com?Subject=Question about my team"
        >
          Contact us.
        </a>
      </div>
    )
  }
}

class TaskEntry extends React.Component {
  state = { expanded: false }

  revealCard = e => {
    e.stopPropagation()
    if (e.metaKey || e.ctrlKey) {
      return
    }
    e.preventDefault()
    this.props.revealCard(this.props.task.id)
  }

  toggleDetails = () => this.setState({ expanded: !this.state.expanded })

  render() {
    const { user, task, duration, details } = this.props
    return (
      <div className="task-entry" onClick={this.toggleDetails}>
        <div className="main-info">
          <div className="entry-author">
            <a href={`/plan/${task.id}`} onClick={this.revealCard}>
              {task.name}
            </a>
          </div>
          <div className="entry-avatar">
            <UserAvatar userId={user.id} noStatusBadge={true} />
          </div>
          <div className="entry-time">{formatDuration(duration)}</div>
        </div>
        {this.state.expanded && (
          <div className="details-list">
            {details.map((detail, index) => (
              <div key={index} className="detail-row">
                <div className={`memo ${detail.memo ? '' : 'holder'}`}>
                  {detail.memo || 'No details'}
                </div>
                <div className="date">{detail.date}</div>
                <div className="duration">
                  {formatDuration(detail.duration)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

class UserEntry extends React.Component {
  state = { expanded: false }
  toggleDetails = () => this.setState({ expanded: !this.state.expanded })
  revealCard = (e, cardId) => {
    e.stopPropagation()
    if (e.metaKey || e.ctrlKey) {
      return
    }
    e.preventDefault()
    this.props.revealCard(cardId)
  }
  render() {
    const { user, duration, details } = this.props
    return (
      <div className="task-entry" onClick={this.toggleDetails}>
        <div className="main-info">
          <div className="individual-line">
            <UserAvatar userId={user.id} noStatusBadge={true} />
            <div className="individual-name">{user.name}</div>
          </div>
          <div className="entry-time">{formatDuration(duration)}</div>
        </div>
        {this.state.expanded && (
          <div className="details-list user-details">
            {details.map((detail, index) => (
              <div key={index} className="detail-row">
                <div className="memo">
                  <a
                    className="task-name"
                    href={`/plan/${detail.cardId}`}
                    onClick={e => this.revealCard(e, detail.cardId)}
                  >
                    {detail.name}
                  </a>
                  <span className="holder"> {detail.memo}</span>
                </div>
                <div className="date">{detail.date}</div>
                <div className="duration">
                  {formatDuration(detail.duration)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
