import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router/immutable'
import createSagaMiddleware from 'redux-saga'

import history from '~/utils/history'
import rootReducer from '~/reducers'
import sagas from '~/sagas'


const sagaMiddleware = createSagaMiddleware()

const middlewareEnchancer = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware
)

const createEnchancedStore = compose(middlewareEnchancer)(createStore)


export default function createProdStore(initialState) {
  let store = createEnchancedStore(rootReducer, initialState)
  sagas.forEach($saga => sagaMiddleware.run($saga))
  return store
}
