
export default function scrollListElementToView(element, list) {
  const elementRect = element.getBoundingClientRect()
  const listRect = list.getBoundingClientRect()

  if (listRect.bottom < elementRect.bottom) {
    const newScroll = elementRect.bottom - listRect.bottom
    list.scrollTop +=  newScroll
  }

  if (listRect.top > elementRect.top) {
    list.scrollTop -= listRect.top - elementRect.top
  }
}
