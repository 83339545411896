import React from 'react'
import cn from 'classnames'

export type EmbeddableChatHeaderProps = {
  chatTitle?: string
  headerColor?: string
  headerTextColor?: string
  theme?: string
}

export const EmbeddableChatHeader = (props: EmbeddableChatHeaderProps) => {
  const {
    chatTitle = 'Chat',
    headerColor = '#A173FF',
    headerTextColor = '#fff',
    theme = 'default',
  } = props
  return (
    <div
      className={cn('embeddable-chat--header', {
        'theme-dark': theme === 'dark',
      })}
      style={{ backgroundColor: headerColor, color: headerTextColor }}
    >
      {chatTitle}
    </div>
  )
}
