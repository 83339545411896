/* eslint-disable */
import PropTypes from 'prop-types'
import React from 'react'

import { isAccessError } from '~/utils/access-error'
import PageError from './page-error'

PageFatalOrAccessError.propTypes = {
  error: PropTypes.instanceOf(Error)
}

export default function PageFatalOrAccessError({ error }) {
  return error && isAccessError(error) ? (
    <PageAccessDenied />
  ) : (
    <PageFatalError error={error} />
  )
}

function PageFatalError({ error }) {
  const doDeload = () => window.location.reload()
  return (
    <PageError title="We're sorry" errorDetails={error && error.message}>
      Something went wrong. Please{' '}
      <a onClick={doDeload} href="javascript:void(0)">
        reload this page
      </a>{' '}
      in a few minutes or <a href="mailto:help@turtleos.com">contact support</a>
      .
    </PageError>
  )
}

function PageAccessDenied() {
  return (
    <PageError title="Oops!">
      Seems you don't have access to this page. Please ask the person who gave
      you this link to add you to the project or{' '}
      <a href="mailto:help@turtleos.com">contact support</a>.
    </PageError>
  )
}
