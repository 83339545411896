import * as Sentry from '@sentry/browser'

export function initSentry() {
  Sentry.init({
    dsn: 'https://7517fb792d954270a19e042526986b69@sentry.io/1299242'
  })
}

export function captureException(err) {
  Sentry.captureException(err)
}

export function captureMessage(msg) {
  Sentry.captureMessage(msg)
}
