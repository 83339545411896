import React from 'react'

import PopupWrapper from '../popup-wrapper'
import TurtleDayPicker from '../turtle-day-picker'

const SHADOW_OFFSET_PX = 4

function calculateDiff(left, right, intervalLeft, intervalRight) {
  const rightOffset = Math.max(intervalRight - right, 0)
  const leftOffset = Math.min(intervalLeft - left, 0)

  return Math.abs(rightOffset) > Math.abs(leftOffset)
          ? rightOffset
          : leftOffset
}

export default class DueDatePopup extends React.Component {
  componentDidMount() {
    const viewport = this.props.viewportNodeSelector
      && document.querySelectorAll(this.props.viewportNodeSelector)[0]
      || document.children[0]

    const {top, bottom, right} = viewport.getBoundingClientRect()

    const popupRect = this.popupNode.getBoundingClientRect()

    const pTop = popupRect.top
    const pBottom = popupRect.bottom
    const pRight = popupRect.right

    const diffX = Math.max(pRight - right, 0)
    const diffY = calculateDiff(top, bottom, pTop, pBottom)

    let transform = ''

    if (diffX !== 0) {
      transform = `translateX(${ -(diffX + Math.sign(diffX) * SHADOW_OFFSET_PX) }px)`
    }

    if (diffY !== 0) {
      transform += ` translateY(${ -(diffY + Math.sign(diffY) * SHADOW_OFFSET_PX) }px)`
    }

    this.popupNode.style.transform = transform
  }

  render() {
    const {dueDate, onPopupHidden, setCardDueDate, clearDueDate} = this.props

    return <PopupWrapper onDismiss={onPopupHidden}>
        <div className='due-date-popup'
             ref={(node) => { this.popupNode = node }}>
          <div className='header'>
            <div className='icon' />
            <div className='title'>Due date</div>
            <div className='clear' onClick={clearDueDate}>Clear</div>
          </div>
          <div className='separator' />
          <TurtleDayPicker
            currentDate={dueDate}
            setDate={setCardDueDate}
            disabledDays={[
              {
                before: new Date(),
              },
            ]}
          />
        </div>
    </PopupWrapper>
  }
}
