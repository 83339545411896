function firstCharacterOfLastname(fullName) {
  if (!fullName) return ''

  const lastName = fullName.split(' ')[1] || ''
  const firstCharacter = lastName[0] || ''

  return firstCharacter !== '' ? firstCharacter.toUpperCase() : ''
}

export function firstInitial(fullName) {
  if (!fullName) return null

  const firstCharacter = fullName[0] || ''
  return firstCharacter.toUpperCase()
}

export function shortName(fullName) {
  return fullName.replace(/\s.*$/, '')
}

export function namePlusFirstLetterOfLastname(fullName) {
  const firstLetterOfLastName = firstCharacterOfLastname(fullName)

  let result = shortName(fullName)

  if (firstLetterOfLastName !== '') {
    result += ' ' + firstLetterOfLastName + '.'
  }

  return result
}

function calculateNamesCount(members) {
  if (!members) {
    return {}
  }
  return members.reduce((counts, member) => {
    const shortMemberName = shortName(member.get('name'))
    counts[shortMemberName] = (counts[shortMemberName] || 0) + 1
    return counts
  }, {})
}

function patchMemberName(member, namesCount) {
  // if in the team list there are two Alexes
  // it adds first letter of last name to first name
  // e.g instead of Alex and Alex -> Alex P. and Alex T.

  let memberName = member.get('name')

  if (namesCount[shortName(memberName)] > 1) {
    memberName = namePlusFirstLetterOfLastname(memberName)
  } else {
    memberName = shortName(memberName)
  }

  return member.set('name', memberName)
}

export function patchWithShortNames(members) {
  const namesCount = calculateNamesCount(members)
  return members.map(member => patchMemberName(member, namesCount))
}

export function getLetterForAvatar(name) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const lowerName = name.toLowerCase()
  for (let i = 0; i < lowerName.length; ++i) {
    const currentLetter = lowerName[i]
    if (alphabet.indexOf(currentLetter) != -1) {
      return currentLetter.toUpperCase()
    }
  }

  return 'T' // as a fallback
}
