import { all, take, call, fork } from 'redux-saga/effects'
import { $dispatch, nextTick } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'
import * as persistentStore from '~/utils/persistent-store'
import { encodeState, decodeState } from '~/utils/encode-state'

import { getQuery } from '~/utils/url'
import { setUserContext } from '~/analytics'

export default function* $authWrapperSaga() {
  yield all([fork($authPersistenceSaga), fork($authSaga)])
}

//
// Auth persistence saga
//

export function* $authPersistenceSaga() {
  yield call(nextTick) // waiting for other sagas to start
  let query = getQuery()
  if (query.access_token) {
    let credentials = { token: query.access_token, user_id: query.user_id }
    persistentStore.setCredentials(credentials)
    let returnPath = '/'
    if (query.state) {
      let state = decodeState(query.state)
      if (state.returnPath) {
        returnPath = state.returnPath
      }
    }
    yield* $dispatch(Actions.loginSuccess(credentials, returnPath))
  } else if (persistentStore.hasCredentials()) {
    let credentials = persistentStore.getCredentials()
    yield* $dispatch(Actions.loginSuccess(credentials, undefined))
  } else {
    yield* $dispatch(Actions.needsLogin())
  }
  while (true) {
    let action = yield take([
      ActionTypes.LOGIN_SUCCESS,
      ActionTypes.LOGIN_ERROR,
      ActionTypes.LOGOUT,
    ])
    if (action.type == ActionTypes.LOGIN_SUCCESS) {
      persistentStore.setCredentials(action.credentials)
    } else {
      persistentStore.clearCredentials()
    }
  }
}

//
// Auth flow saga
//

let isLoggedIn = false

function setLoggedIn(userId) {
  setUserContext({ id: userId })
  isLoggedIn = true
}

function setLoggedOut() {
  if (DEBUG) console.log(`[saga auth] logout`)
  isLoggedIn = false
}

export function* $authSaga() {
  let action = yield take([ActionTypes.LOGIN_SUCCESS, ActionTypes.NEEDS_LOGIN])
  if (action.type == ActionTypes.LOGIN_SUCCESS) {
    if (DEBUG) console.log(`[saga auth] was logged in initially`)
    setLoggedIn(action.credentials.user_id)
  }
  while (true) {
    if (!isLoggedIn) {
      const userId = yield* $performAuth()
      if (userId) {
        setLoggedIn(userId)
      } else {
        continue
      }
    }
    yield take(ActionTypes.LOGOUT)
    if (DEBUG) console.log(`[saga auth] logout`)
    setLoggedOut()
  }
}

function* $performAuth() {
  let { provider, returnPath } = yield take(ActionTypes.LOGIN)
  let state = encodeState({ returnPath })
  yield call(sleepMs, 100) // give time for spinner to show
  yield* $dispatch(Actions.showProviderAuthPage(provider, state))
  // In web, all following code will never execute, as the browser will navigate
  // out of the app page. So the following code is only for Electron.
  let action = yield take([ActionTypes.LOGIN_SUCCESS, ActionTypes.LOGIN_ERROR])
  if (action.type == ActionTypes.LOGIN_ERROR) {
    console.error(`[saga auth] auth error: ${action.error}`)
    return
  }
  return action.credentials.user_id
}

function sleepMs(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
