import sel from '~/selectors'

import { canRouteShowCards } from '~/utils/routing'
import { FilteringState } from '~/utils/filter-constants'
import dayjs from '~/utils/dayjs'

export function doesCardHaveIdInPath(id, card) {
  return sel.card.optimisticPath(card).indexOf(id) >= 0
}

/**
 * showCompletedSince and topLevelCardId are optional.
 *
 * showCompletedSince specifies whether completed cards are assumed to be visible. The possible
 * values are:
 *
 * - false: completed cards are hidden;
 * - true: completed cards are visible;
 *
 * topLevelCardId allows to specify card that is known to be currently visible. For example,
 * suppose that you have these cards:
 *
 * - A [completed]
 *   - B
 *     - C
 *
 * If you pass C to this function, and pass false to showCompletedSince (which means that
 * completed cards are hidden), then it will return false, since card C is a indirect child
 * of A which is completed.
 *
 * But suppose that the top-level card is currently B, e.g. because you selected it from
 * search results. In this case, C will be visible on screen. To make the function consider
 * this, topLevelCardId is used. If you pass B to this parameter, it will return true, as
 * it will assume it is known to be visible.
 */
export function canCardBeShown(
  cardId,
  cards,
  showCompletedSince = false,
  topLevelCardId = null
) {
  if (cardId == topLevelCardId) {
    return true
  }
  const card = cards.get(cardId)
  if (!card || !canCardBeShownIgnoringParents(card, showCompletedSince)) {
    return false
  }
  const path = card.get('path')
  if (!path) {
    return false
  }
  for (let i = path.size - 2; i >= 0; --i) {
    const cardId = path.get(i)
    if (cardId == topLevelCardId) {
      return true
    }
    const card = cards.get(cardId)
    if (!card || !canCardBeShownIgnoringParents(card, showCompletedSince)) {
      return false
    }
  }
  return topLevelCardId == null
}

export function isCardDeleted(cardId, cards) {
  return !canCardBeShown(cardId, cards, true, null)
}

// An utility function used in canCardBeShown.
//
function canCardBeShownIgnoringParents(card, showCompletedSince = false) {
  const optimistic = card.get('optimistic')
  if (optimistic && optimistic.get('isDeleted')) {
    return false
  }
  if (card.get('deleted')) {
    return false
  }
  if (showCompletedSince === true) {
    return true
  }
  const isOptimisticallyCompleted = optimistic && optimistic.get('isCompleted')
  if (isOptimisticallyCompleted === false) {
    return true
  }
  if (isOptimisticallyCompleted === true) {
    return showCompletedSince !== false
  }
  if (!card.get('completed')) {
    return true
  }
  if (!showCompletedSince) {
    return false
  }
  const completedAt = dayjs(card.get('completedAt'))
  return completedAt.isAfter(showCompletedSince)
}

/**
 * Returns array of card ids that, considering the passed cards view/filtering state,
 * need to be expanded in order to make some card visible. Returns null if the card
 * cannot be made visible by expanding ancestors (e.g. if it's hidden by filters).
 */
export function getCardIdsToExpandToRevealCard(
  cardId,
  cardsViewState,
  cards,
  routeType,
  topLevelCardId,
  projectId
) {
  if (!canRouteShowCards(routeType)) {
    return null
  }
  const rootCardId = topLevelCardId || projectId
  if (cardId == rootCardId) {
    return []
  }
  const viewState = cardsViewState.get(cardId)
  const filteringState = viewState && viewState.get('filtering').get('state')
  if (!viewState || !FilteringState.isVisible(filteringState)) {
    return null
  }
  // We don't need to check ancestors' visibility, as a visible descendant makes all its
  // ancestors automatically visible by transferring them to DESCANDANTS_PASSING filtering
  // state. Also, since DELETED filtering state get propagated to all descendants, we don't
  // need to check if any of the ancestors are deleted, as otherwise the card's filtering
  // state would be DELETED.
  const { isExpanded } = sel.cardViewState
  const { parentId } = sel.card
  let cardIdsToExpand = []
  while (true) {
    const card = cards.get(cardId)
    if (card == null) {
      // card itself or some of its ancestors is deleted
      return null
    }
    cardId = parentId(card)
    if (cardId == null) {
      // card is not a descendant of the top-level card
      return null
    } else if (cardId == rootCardId) {
      // card is a descendant of the top-level card
      return cardIdsToExpand
    } else if (!isExpanded(cardsViewState, cardId, routeType, topLevelCardId)) {
      // a card's ancestor is collapsed
      cardIdsToExpand.push(cardId)
    }
  }
}
