import $auth from './auth'
import $navigation from './navigation'
import $project from './project'
import $user from './user'
import $globalLoader from './global-loader'
import $cards from './cards'
import $realtimeTreeUpdates from './realtime-tree-updates'
import $invitation from './invitation'
import $analytics from './analytics'
import $search from './search'
import $cardsViewStatePersistence from './cards-view-state-persistence'
import $revealCard from './reveal-card'
import $reports from './reports'
import $trackedHours from './tracked-hours'
import $resetSelectedCardIfHidden from './reset-selected-card-if-hidden'
import $keyboardNavigation from './keyboard-navigation'
import $undo from './undo'
import $paymentsV2 from './payments-v2'
import $cardQuickAddSaga from './card-quick-add'
import $availabilitySaga from './availability'
// import $videoSaga from './video'

const sagas = [
  // beware: order matters here
  $navigation,
  $auth,
  $project,
  $user,
  $globalLoader,
  $realtimeTreeUpdates,
  $cards,
  $cardQuickAddSaga,
  $resetSelectedCardIfHidden,
  $keyboardNavigation,
  $cardsViewStatePersistence,
  $revealCard,
  $invitation,
  $analytics,
  $search,
  $reports,
  $trackedHours,
  $undo,
  $paymentsV2,
  $availabilitySaga,
  // $videoSaga,
]

export default sagas
