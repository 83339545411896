import React from 'react'
import { EMOJI_LIST, FREQUENTLY_USED_EMOJI } from '~/utils/emoji'
import Tooltip from '../tooltip'

export default class EmojiPicker extends React.Component {
  constructor(props) {
    super(props)
    const { frequentlyUsed, other } = this.populateEmojisLists()
    this.frequentlyUsed = frequentlyUsed
    this.other = other
  }

  populateEmojisLists() {
    let other = []
    const frequentlyUsed = FREQUENTLY_USED_EMOJI.map(emojiId =>
      this.renderEmoji(emojiId, EMOJI_LIST[emojiId])
    )
    for (let emojiId in EMOJI_LIST) {
      if (
        EMOJI_LIST.hasOwnProperty(emojiId) &&
        !FREQUENTLY_USED_EMOJI.includes(emojiId)
      ) {
        other.push(this.renderEmoji(emojiId, EMOJI_LIST[emojiId]))
      }
    }

    return { frequentlyUsed, other }
  }

  renderEmoji(emojiId, emojiCode) {
    return (
      <Tooltip key={emojiId} label={emojiId} dy={4}>
        <span onClick={() => this.props.insert(emojiCode)} className="emoji">
          {emojiCode}
        </span>
      </Tooltip>
    )
  }

  render() {
    return (
      <div className="emoji-picker">
        <div className="frequently-used-title">Frequently Used</div>
        <div className="frequently-used">{this.frequentlyUsed}</div>
        <div className="other">{this.other}</div>
      </div>
    )
  }
}
