interface SessionStorage {
  clear(): void
  getItem(key: string): string | null
  removeItem(key: string): void
  setItem(key: string, value: string): void
  getKeys(): string[]
}

class LocalStorage implements SessionStorage {
  clear(): void {
    localStorage.clear()
  }
  getItem(key: string): string | null {
    return localStorage.getItem(key)
  }
  removeItem(key: string): void {
    localStorage.removeItem(key)
  }
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }
  getKeys(): string[] {
    return Object.keys(localStorage)
  }
}

class InMemoryStorage implements SessionStorage {
  private map: { [name: string]: string } = {}

  clear(): void {
    this.map = {}
  }
  getItem(key: string): string | null {
    return this.map[key]
  }
  removeItem(key: string): void {
    delete this.map[key]
  }
  setItem(key: string, value: string): void {
    this.map[key] = String(value)
  }
  getKeys(): string[] {
    return Object.keys(this.map)
  }
}

function checkIsLocalStorageSupported(): boolean {
  try {
    window.localStorage.length
    return true
  } catch (e) {
    return false
  }
}

const isLocalStorageSupported = checkIsLocalStorageSupported()
const sessionStorageInstance: SessionStorage = isLocalStorageSupported ? new LocalStorage() : new InMemoryStorage()

if (!isLocalStorageSupported) {
  console.warn('LocalStorage unsupported. Falling back to in memory implementation.')
}

export default sessionStorageInstance