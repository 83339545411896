import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

export const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  cardLevel: PropTypes.number.isRequired,
  me: ImmutablePropTypes.map.isRequired,
  projectMembers: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,

  filteringInfo: PropTypes.shape({
    hasPassingDescendants: PropTypes.bool.isRequired,
    numFailingActiveChildren: PropTypes.number.isRequired,
  }).isRequired,

  renderChildren: PropTypes.func.isRequired,
  onNode: PropTypes.func.isRequired,

  actions: PropTypes.shape({
    toggleExpanded: PropTypes.func.isRequired,
    toggleCompleted: PropTypes.func.isRequired,
    startEditing: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
    commitEditing: PropTypes.func.isRequired,
    createChildCard: PropTypes.func.isRequired,
    setTopLevel: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    setCardDueDate: PropTypes.func.isRequired,
    unsetCardDueDate: PropTypes.func.isRequired,
    unassign: PropTypes.func.isRequired,
    assign: PropTypes.func.isRequired,
    selectCard: PropTypes.func.isRequired,
    clearCardSelection: PropTypes.func.isRequired,
    changeNestingLevel: PropTypes.func.isRequired,
  }).isRequired,

  expandCollapseAnimationManager: PropTypes.object.isRequired,

  schedulingInterval: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }),
  assignee: ImmutablePropTypes.map,
  dueDate: PropTypes.string,
  unreadCount: PropTypes.number,

  isEphemeral: PropTypes.bool,
  isDraggingPreview: PropTypes.bool,

  editingState: ImmutablePropTypes.mapContains({
    selectionStart: PropTypes.number.isRequired,
    selectionEnd: PropTypes.number.isRequired,
  }),

  onDragStart: PropTypes.func,
}

export const defaultProps = {
  isEphemeral: false,
  isDraggingPreview: false,
}
