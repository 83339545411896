import * as ActionTypes from '~/actions/types'

const INITIAL_STATE = paymentStatusReducer.INITIAL_STATE = {
  showForm: false,
  sending: false,
  success: false,
  error: false,
}

function formatError(error) {
  if (!error.responseText) {
    return error.message
  }

  try {
    const json = JSON.parse(error.responseText)

    console.error('error', json)

    return typeof json.error == 'string'
      ? json.error
      : json.error.message
  } catch (e) {
    return 'An unknown error occurred'
  }
}

export default function paymentStatusReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ActionTypes.TOGGLE_PAYMENT_FORM: {
      return {
        showForm: !state.showForm,
        sending: false,
        success: false,
        error: false,
      }
    }
    case ActionTypes.SEND_PAYMENT: return {
      showForm: true,
      sending: true,
      success: false,
      error: false,
    }
    case ActionTypes.SEND_PAYMENT_V2: return {
      showForm: true,
      sending: true,
      success: false,
      error: false,
    }
    case ActionTypes.PAYMENT_SUCCESS: return {
      showForm: true,
      sending: false,
      success: true,
      error: false,
    }
    case ActionTypes.PAYMENT_FAIL:
      return {
        showForm: true,
        sending: false,
        success: false,
        error: formatError(action.error)
      }
    default: return state
  }
}
