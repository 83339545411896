import { all, take, select } from 'redux-saga/effects'

import sel from '~/selectors'
import * as ActionTypes from '~/actions/types'

import { hideGlobalLoader } from '~/utils/global-loader'

export default function* $globalLoader() {
  while (true) {
    yield take([
      ActionTypes.NEEDS_LOGIN,
      ActionTypes.LOGIN_SUCCESS,
      ActionTypes.SET_PROJECTS_LIST,
      ActionTypes.NO_PROJECTS,
      ActionTypes.FATAL_ERROR,
    ])
    const [isLoggedIn, hasProjects, projectIds, fatalError] = yield all([
      select(sel.isLoggedIn),
      select(sel.hasProjects),
      select(sel.projectIds),
      select(sel.fatalError),
    ])
    const needsHidingLoader =
      fatalError ||
      isLoggedIn == false ||
      (isLoggedIn && (!hasProjects || projectIds.size > 0))
    if (needsHidingLoader) {
      hideGlobalLoader()
      return
    }
  }
}
