import { RefObject, useEffect, useMemo, useRef } from 'react'

type Args = {
  ref?: RefObject<any>
  refs?: RefObject<any>[]
  onClickOutside: (event: MouseEvent) => void
}

export const useClickOutside = ({ ref, refs = [], onClickOutside }: Args) => {
  if (!ref && !refs.length) {
    return
  }

  const savedCallback = useRef(onClickOutside)
  useEffect(() => {
    savedCallback.current = onClickOutside
  }, [onClickOutside])

  const refList = useMemo(() => {
    if (ref) {
      return [ref, ...refs]
    }
    return refs
  }, [ref, refs])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const touchedElementsCount = refList.filter(ref =>
        ref.current?.contains(event.target)
      ).length

      if (touchedElementsCount === 0) {
        savedCallback.current(event)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [refList, savedCallback])
}
