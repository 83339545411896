import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { noop } from '~/utils/noop'
import { useClickOutside } from '../use-click-outside'

type Args = {
  ref?: RefObject<HTMLElement | null>
  refs?: RefObject<HTMLElement | null>[]
  initialVisibleValue?: boolean
  onClose?: () => void
}

export const usePopup = ({
  ref,
  refs,
  initialVisibleValue = false,
  onClose = noop,
}: Args): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isVisible, setIsVisible] = useState(initialVisibleValue)

  const handlePopupClose = useCallback(() => {
    setIsVisible(false)
    onClose()
  }, [onClose])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlePopupClose()
      }
    },
    [onClose, handlePopupClose]
  )

  useClickOutside({ ref, refs, onClickOutside: handlePopupClose })

  const handlePopupShow = useCallback(() => {
    document.body.classList.add('no-scroll')
    window.addEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  const handlePopupHide = useCallback(() => {
    document.body.classList.remove('no-scroll')
    window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  useEffect(() => {
    if (isVisible) {
      handlePopupShow()
    } else {
      handlePopupHide()
    }
    return () => {
      handlePopupHide()
    }
  }, [isVisible])

  return [isVisible, setIsVisible]
}
