import { parseLocationSearch } from './url'

export const ROUTE_MY_TASKS = 'my-tasks'
export const ROUTE_PLAN = 'plan'
export const ROUTE_TEAM_WEEK = 'team-week'
export const ROUTE_MOBILE_CHAT = 'mobile'
export const ROUTE_DASHBOARD = 'dashboard'
export const ROUTE_CONTRACTOR_DASHBOARD = 'contractor-dashboard'
export const ROUTE_AVAILABILITY = 'availability'
export const ROUTE_PROFILE = 'profile'
export const ROUTE_VIDEO = 'video'
export const ROUTE_MEETINGS = 'meetings'
export const ROUTE_RECORD = 'record'
export const ROUTE_ACTIVITY = 'activity'
export const ROUTE_CHAT = 'chat'
export const ROUTE_CONTRACTS = 'contracts'
export const ROUTE_GENERATE_CONTRACT = 'generate-contract'
export const ROUTE_TEST = 'test'
export const ROUTE_LOGIN_LINK = 'login-link'

export const ROUTE_AGENCY_SIGN_UP = 'agency-sign-up'

export const ROUTE_SETTINGS = 'settings'
export const ROUTE_BO = 'back-office'
export const ROUTE_BO_PROJECTS = `/${ROUTE_BO}/projects`
export const ROUTE_BO_PROJECT = `/${ROUTE_BO}/project`
export const ROUTE_BO_BALANCE = `/${ROUTE_BO}/balance`
export const ROUTE_BO_CONTRACTS = `/${ROUTE_BO}/contracts`
export const ROUTE_BO_NEW_CONTRACT = `/${ROUTE_BO}/new-contract`
export const ROUTE_BO_PEOPLE = `/${ROUTE_BO}/people`
export const ROUTE_TIME_TRACKED = `/${ROUTE_BO}/time-tracked`
export const ROUTE_BO_DASHBOARD = `/${ROUTE_BO}/dashboard`

export function routeAfterSwitchingProject(routeType, newProjectId) {
  return doesRouteNeedPermissions(routeType)
    ? { type: ROUTE_PLAN, cardId: newProjectId }
    : isRouteToCard(routeType)
    ? { type: routeType, cardId: newProjectId }
    : { type: routeType, projectId: newProjectId }
}

export function doesRouteNeedPermissions(routeType) {
  return (
    routeType === ROUTE_DASHBOARD ||
    routeType === ROUTE_CONTRACTOR_DASHBOARD ||
    routeType === ROUTE_PROFILE ||
    routeType === ROUTE_RECORD ||
    routeType === ROUTE_GENERATE_CONTRACT
  )
}

/**
 * Whether this route points to some top-level card, which is displayed in the list header.
 */
export function isRouteToCard(routeType) {
  return routeType === ROUTE_PLAN || routeType === ROUTE_MOBILE_CHAT
}

/**
 * Whether this route displays list of cards.
 */
export function canRouteShowCards(routeType) {
  // TODO: add TEAM_WEEK to here when we support showing details inside this route
  return (
    routeType === ROUTE_PLAN ||
    routeType === ROUTE_MY_TASKS ||
    routeType === ROUTE_MOBILE_CHAT
  )
}

export function doesRouteAllowCardSelection(routeType) {
  return routeType === ROUTE_PLAN || routeType === ROUTE_MY_TASKS
}

export function buildPath(route) {
  const { type } = route
  if (!type) {
    throw new Error(`invalid route, must contain "type" field:`, route)
  }

  let path = '' + '/' + type + '/'
  let addToPath
  switch (type) {
    case ROUTE_PLAN:
    case ROUTE_MOBILE_CHAT:
      addToPath = route.cardId || ''
      break
    case ROUTE_PROFILE:
      addToPath = route.projectId + '/' + route.userId || ''
      break
    case ROUTE_RECORD:
      addToPath = route.projectId + '/' + route.recordId || ''
      break
    case ROUTE_CONTRACTS:
      addToPath = route.contractId || ''
      break
    case ROUTE_BO:
      if (route.projectId) {
        addToPath = `project/${route.projectId}`
      }
      break
    default:
      addToPath = route.projectId || ''
      break
  }
  path = path + addToPath

  if (route.selectedCardId) {
    if (route.selectedCardId === route.cardId) {
      path += '?sel'
    } else {
      path += '?sel=' + encodeURIComponent(route.selectedCardId)
    }
  } else {
    path += route.search || ''
  }
  return path
}

export function routeFromLocation({ pathname, search }) {
  const slashIndex = pathname.indexOf('/', 1)
  const type = pathname.substring(
    1,
    slashIndex === -1 ? pathname.length : slashIndex
  )
  let route = {
    type: type,
  }
  const slashIndexSecond = pathname.indexOf('/', slashIndex + 1)

  if (slashIndex >= 0 && slashIndexSecond >= 0 && type === ROUTE_PROFILE) {
    const routeKey = 'projectId'
    route[routeKey] = pathname.substring(slashIndex + 1, slashIndexSecond)
    route['userId'] = pathname.substring(slashIndexSecond + 1)
  } else if (
    slashIndex >= 0 &&
    slashIndexSecond >= 0 &&
    type === ROUTE_RECORD
  ) {
    const routeKey = 'projectId'
    route[routeKey] = pathname.substring(slashIndex + 1, slashIndexSecond)
    route['recordId'] = pathname.substring(slashIndexSecond + 1)
  } else if (slashIndex >= 0) {
    const routeKey = isRouteToCard(type) ? 'cardId' : 'projectId'
    route[routeKey] = pathname.substring(slashIndex + 1)
  }
  if (search && doesRouteAllowCardSelection(type)) {
    const query = parseLocationSearch(search)
    if (query.sel !== undefined) {
      route.selectedCardId = query.sel || route.cardId
    }
  }
  return route
}

const projectAgnosticRoutes = [
  ROUTE_AVAILABILITY,
  ROUTE_CHAT,
  ROUTE_CONTRACTS,
  ROUTE_GENERATE_CONTRACT,
  ROUTE_TEST,
  ROUTE_LOGIN_LINK,
]

export function isProjectAgnosticRoute({ route }) {
  return projectAgnosticRoutes.includes(route.type)
}
