import React from 'react'
import connect from '../utils/connect'
import sel from '~/selectors'
import Dashboard from './dashboard'
import { WEEK_TIME_PERIOD } from '~/utils/time'

class DashboardContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      reports: sel.reports(state),
      projectId: sel.selectedProjectId(state),
      user: sel.me(state),
      hasBankCard: sel.hasBankCard(state),
      paymentStatus: sel.paymentStatus(state),
    }
  }

  componentDidMount() {
    if (this.props.reports.get('periodType') === null) {
      this.props.actions.getReport(0, WEEK_TIME_PERIOD)
    } else {
      this.props.actions.getReport(0, this.props.reports.get('periodType'))
    }
  }

  shiftPeriod = delta => {
    const reports = this.props.reports
    const periodType =
      reports.get('periodType') !== null
        ? reports.get('periodType')
        : WEEK_TIME_PERIOD
    this.props.actions.getReport(reports.get('offset') + delta, periodType)
  }

  getCustomPeriodReport = (start, end) => {
    this.props.actions.getCustomReport(start, end)
  }

  changePeriodType = periodType => {
    this.props.actions.getReport(0, periodType)
  }

  revealCard = cardId => {
    this.props.actions.revealCard(cardId, this.props.projectId)
  }

  render() {
    return (
      <Dashboard
        projectId={this.props.projectId}
        reports={this.props.reports}
        shiftPeriod={this.shiftPeriod}
        getCustomPeriod={this.getCustomPeriodReport}
        changePeriodType={this.changePeriodType}
        revealCard={this.revealCard}
        user={this.props.user}
        hasBankCard={this.props.hasBankCard}
        togglePaymentForm={this.props.actions.togglePaymentForm}
        sendPaymentV2={this.props.actions.sendPaymentV2}
        paymentStatus={this.props.paymentStatus}
      />
    )
  }
}

export default connect(DashboardContainer)
