import {Map, fromJS} from 'immutable'

import * as ActionTypes from '~/actions/types'
import CardRelation from '~/utils/card-relation'

import sel from '~/selectors'
const {isExpandedKey} = sel.cardViewState

import {FilteringState} from '~/utils/filter-constants'
import filteringViewStateReducer from './cards-filtering-view-state'


// The state is a map from card id to the following structure:
//
// {
//   filtering: {
//     state: int, (see ~/utils/filter-constants.js)
//     hasPassingDescendants: bool,
//     numFailingActiveChildren: int
//   },
//   isExpanded: {
//     [`${viewType}-${topLevelCardId}`]: true
//   },
//   isExpandedDP: {
//     [`${viewType}-${topLevelCardId}`]: false
//   }
// }
//
// isExpanded sets expanded state under specific views and top-level card
// ids when target card's filtering state is PASSING. Only contains positive
// records, and the default state (when no record is present) is "collapsed".
//
// isExpandedDP does the same for DESCENDANTS_PASSING state of the target card.
// Only contains negative records, and the default state (when no record is
// present) is "expanded".
//
export const INITIAL_STATE = cardsViewStateReducer.INITIAL_STATE = Map()


const FILTERING_INFO_UNKNOWN = Map.of(
  'state', FilteringState.UNKNOWN,
  'hasPassingDescendants', false,
  'numFailingActiveChildren', 0
)


export default function cardsViewStateReducer(state = INITIAL_STATE, action, boundSel) {
  state = expandViewStateReducer(state, action, boundSel)
  state = filteringViewStateReducer(state, action, boundSel)
  return state
}


function expandViewStateReducer(state = INITIAL_STATE, action, boundSel) {
  switch (action.type) {
    case ActionTypes.SET_CARD_EXPANDED: {
      return setCardExpanded(state,
        action.cardId,
        boundSel.selectRouteType(),
        boundSel.selectTopLevelCardId(),
        action.isExpanded
      )
    }
    case ActionTypes.SET_CARDS_EXPANDED: {
      const routeType = boundSel.selectRouteType()
      const topLevelCardId = boundSel.selectTopLevelCardId()
      const {cardIds, isExpanded} = action
      for (let i = 0; i < cardIds.length; ++i) {
        state = setCardExpanded(
          state, cardIds[i], routeType, topLevelCardId, isExpanded
        )
      }
      return state
    }
    case ActionTypes.MOVE_CARD: {
      if (action.relation === CardRelation.CHILD) {
        return setCardExpanded(state,
          action.targetId,
          boundSel.selectRouteType(),
          boundSel.selectTopLevelCardId(),
          true
        )
      }
      return state
    }
    case ActionTypes.RESTORE_CARDS_EXPAND_STATE: {
      return state.withMutations(state => {
        restoreCardsExpandState(state, action.cardsExpandState)
      })
    }
  }
  return state
}


function setCardExpanded(state, cardId, routeType, topLevelCardId, isExpanded) {
  const key = isExpandedKey(routeType, topLevelCardId)

  let viewState = state.get(cardId)
  if (viewState) {
    viewState = viewState.update('isExpanded',
      isExpandedMap => addIsExpandedRecord(isExpandedMap, key, isExpanded, false))
    const filteringState = viewState.get('filtering').get('state')
    if (filteringState === FilteringState.DESCENDANTS_PASSING) {
      viewState = viewState.update('isExpandedDP',
        isExpandedMap => addIsExpandedRecord(isExpandedMap, key, isExpanded, true))
    }
  } else {
    viewState = Map.of(
      'isExpanded', isExpanded ? Map.of(key, true) : Map(),
      'filtering', FILTERING_INFO_UNKNOWN
    )
  }

  return state.set(cardId, viewState)
}


function addIsExpandedRecord(isExpandedMap, key, value, defaultValue) {
  return (value === defaultValue
    ? isExpandedMap ? isExpandedMap.delete(key) : Map()
    : isExpandedMap ? isExpandedMap.set(key, value) : Map.of(key, value)
  )
}


function restoreCardsExpandState(mutableState, cardsExpandState) {
  for (let i = 0; i < cardsExpandState.length; ++i) {
    const {cardId, expandState} = cardsExpandState[i]
    const viewState = mutableState.get(cardId)
    mutableState.set(cardId, viewState
      ? viewState.set('isExpanded', fromJS(expandState))
      : Map.of('isExpanded', fromJS(expandState), 'filtering', FILTERING_INFO_UNKNOWN)
    )
  }
}
