import {shortName} from './name'

const TURTLE_BOT_NAME = 'Turtle AI'

export function isBot(member) {
  return member && (member.get('name') === TURTLE_BOT_NAME
    || member.get('name') === shortName(TURTLE_BOT_NAME))
}

export function isNotBot(member) {
  return !isBot(member)
}
