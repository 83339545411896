import React, { useEffect } from 'react'
import { buildPath, ROUTE_PLAN } from '~/utils/routing'
import { useHistory } from 'react-router'
import storage from '~/utils/session-storage'

import { useAuth } from '~/utils/use-auth'
import { LoginLinkHandler } from './login-link-handler'

export const LoginLinkContainer = () => {
  const { isLoggedIn } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(
        buildPath({
          type: ROUTE_PLAN,
          cardId: storage.getItem('projectId'),
        })
      )
    }
  }, [isLoggedIn])

  return (
    <div className="login-link--wrapper">
      {!isLoggedIn && <LoginLinkHandler />}
    </div>
  )
}
