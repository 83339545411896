/* eslint-disable */
import React from 'react'
import connect from '../utils/connect'

import sel from '~/selectors'
import {createSelector} from 'reselect'

import {isNotBot} from '~/utils/checks'
import {Filters, FilteringState} from '~/utils/filter-constants'
import splitArray from '~/utils/split-array'

import TeamWeek from './team-week'


const NOT_ASSIGNED_EXPAND_RECORD_ID = 'NOT_ASSIGNED'

const {isOptimisticallyCompleted} = sel.card
const isItemOptimisticallyCompleted = item => isOptimisticallyCompleted(item.card)

// TODO: use logic already present in cards-filtering-view-state reducer to build this list?
//
const makeSelectFilteredItems = () => {
  let filteredItems = []
  let totalFilteredItems = 0

  let newFilteredItems = []
  let totalNewFilteredItems = 0

  let filteredItemsChanged = false

  function filterLevel(parentCard, cards, cardsViewState) {
    const {PASSING, isVisible} = FilteringState
    const cardIds = parentCard.get('children')
    for (let i = 0, s = cardIds.size; i < s; ++i) {
      const cardId = cardIds.get(i)
      const filteringState = cardsViewState.get(cardId).get('filtering').get('state')
      if (isVisible(filteringState)) {
        const card = cards.get(cardId)
        const path = sel.card.optimisticPath(card)
          .shift().pop()
          .map(id => cards.get(id).get('name'))
          .join(' > ') || '(root of the project)'
        if (filteringState == PASSING) {
          if (totalNewFilteredItems < totalFilteredItems) {
            const prevItem = filteredItems[totalNewFilteredItems]
            if (prevItem.card != card || prevItem.path != path) {
              filteredItemsChanged = true
            }
          }
          newFilteredItems.push({card, path})
          ++totalNewFilteredItems
        }
        filterLevel(card, cards, cardsViewState)
      }
    }
  }

  return createSelector(sel.cards, sel.cardsViewState, sel.selectedProjectId,
    (cards, cardsViewState, selectedProjectId) => {
      newFilteredItems.length = 0
      totalNewFilteredItems = 0
      filteredItemsChanged = false

      filterLevel(cards.get(selectedProjectId), cards, cardsViewState)

      if (filteredItemsChanged || totalNewFilteredItems != totalFilteredItems) {
        filteredItems = newFilteredItems
        totalFilteredItems = totalNewFilteredItems
        newFilteredItems = []
      }

      return filteredItems
    }
  )
}

const selectItemsByPerson = createSelector(makeSelectFilteredItems(), sel.selectedProjectMembers,
  (filteredItems, selectedProjectMembers) => {
    const nonAssignedItems = []
    const personIdToItems = {}

    const sections = []

    for (let i = 0, s = selectedProjectMembers.size; i < s; ++i) {
      const person = selectedProjectMembers.get(i)
      if (isNotBot(person)) {
        const items = []
        personIdToItems[ person.get('id') ] = items
        sections.push({ person, items, id: person.get('id') })
      }
    }

    sections.push({ // Not Assigned section
      person: null,
      items: nonAssignedItems,
      id: NOT_ASSIGNED_EXPAND_RECORD_ID,
    })

    const getCardAssigneeId = sel.card.optimisticAssigneeId

    for (let i = 0, l = filteredItems.length; i < l; ++i) {
      const item = filteredItems[i]
      const assigneeId = getCardAssigneeId(item.card)
      if (assigneeId == null) {
        nonAssignedItems.push(item)
      } else {
        const personsItems = personIdToItems[assigneeId]
        if (personsItems) {
          personsItems.push(item)
        }
      }
    }

    for (let i = 0; i < sections.length; ++i) {
      const section = sections[i]
      const [completed, nonCompleted] = splitArray(section.items, isItemOptimisticallyCompleted)
      if (completed.length) {
        section.totalCompleted = completed.length
        nonCompleted.push.apply(nonCompleted, completed)
        section.items = nonCompleted
      } else {
        section.totalCompleted = 0
      }
    }

    return sections.filter(section => section.items.length > 0)
  })


export class TeamWeekContainer extends React.Component {

  render() {
    return (
      <TeamWeek
        sections={this.props.sections}
        expandStatus={this.state.expandStatus}
        timeFilterValue={this.props.timeFilterValue}
        projectId={this.props.projectId}
        actions={this.actions}>
      </TeamWeek>
    )
  }

  constructor(props){
    super(props)
    this.state = {
      expandStatus: {}
    }
    this.actions = {
      revealCardWithId: this.revealCardWithId.bind(this),
      toggleExpandCollapse: this.toggleExpandCollapse.bind(this),
      showNextWeek: this.showNextWeek.bind(this),
      showPrevWeek: this.showPrevWeek.bind(this),
      showAll: this.showAll.bind(this),
    }
  }

  static mapStateToProps(state) {
    const sections = selectItemsByPerson(state)
    const timeFilterValue = sel.filters(state).get(Filters.TIME)
    return {
      sections,
      timeFilterValue,
      projectId: sel.selectedProjectId(state)
    }
  }

  UNSAFE_componentWillMount() {
    this.initExpandStatusForNewSections(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.initExpandStatusForNewSections(newProps)
  }

  initExpandStatusForNewSections(props) {
    const {sections} = props
    const {expandStatus} = this.state
    let sectionsListChanged = false

    for (let i = 0; i < sections.length; ++i) {
      const {id} = sections[i]
      if (expandStatus[id] == null) {
        expandStatus[id] = true
        sectionsListChanged = true
      }
    }

    if (sectionsListChanged) {
      this.setState({expandStatus})
    }
  }

  revealCardWithId(cardId) {
    this.props.actions.revealCard(cardId, this.props.projectId)
  }

  toggleExpandCollapse(id) {
    const {expandStatus} = this.state
    expandStatus[id] = !expandStatus[id]
    this.setState({expandStatus})
  }

  showNextWeek() {
    this.props.actions.setFilter(Filters.TIME, this.props.timeFilterValue + 1)
  }

  showPrevWeek() {
    this.props.actions.setFilter(Filters.TIME, this.props.timeFilterValue - 1)
  }

  showAll() {
    this.props.actions.setFilter(Filters.TIME, 0)
  }
}


export default connect(TeamWeekContainer)
