/* eslint-disable */
import React from 'react'
import cn from 'classnames'
import { bind0, bind1 } from '~/utils/functional'

// Default delay between hovering mouse over some control and showing tooltip.
// Delay can be customized for individual tooltips using the showDelay property
// of the corresponding Tooltip instance.
//
const DEFAULT_TOOLTIP_SHOW_DELAY_MS = 500

// If user waits for tooltip to show, and then hovers some other control within
// this period of time, the new tooltip will show immediately.
//
const SHOW_WITHOUT_DELAY_WITHIN_MS = 500

export default class TooltipContainer extends React.Component {
  static TOP = 'top'
  static BOTTOM = 'bottom'
  static RIGHT = 'right'
  static LEFT = 'left'

  static _instance = null

  static get instance() {
    const instance = TooltipContainer._instance
    if (instance == null) {
      throw new Error(
        'You need to place TooltipContainer component somewhere ' +
          'in the render tree in order to use Tooltip components'
      )
    }
    return instance
  }

  constructor() {
    super()

    this.state = {
      isVisible: false,
      text: '',
      position: TooltipContainer.TOP,
      x: 0,
      y: 0
    }

    this.visibleTooltip = null
    this.timeoutId = null
    this.showTime = 0
    this.hideTime = 0
    this.tooltipData = {}

    this.hideCurrentTooltip = bind0(this.hideCurrentTooltip, this)
    this.doShowTooltip = bind1(this.doShowTooltip, this)

    TooltipContainer._instance = this
  }

  render() {
    // This is needed to reset _instance after hot update, which replaces
    // TooltipContainer class with all its properties
    TooltipContainer._instance = this

    const { state } = this
    const style = { left: state.x + 'px', top: state.y + 'px' }

    const className = cn(
      'tooltip',
      state.position,
      state.isVisible && 'visible',
      state.showImmediately && 'immediately',
      state.extraClasses
    )

    return (
      <div className="tooltip-container">
        <div className={className} style={style}>
          <div className="text">{state.text}</div>
        </div>
      </div>
    )
  }

  showTooltip(tooltip) {
    const prevTooltip = this.visibleTooltip

    if (prevTooltip && tooltip != prevTooltip) {
      prevTooltip.onHidden()
    }

    this.visibleTooltip = tooltip

    document.addEventListener('scroll', this.hideCurrentTooltip, true)
    document.addEventListener('mouseup', this.hideCurrentTooltip, true)

    const now = Date.now()
    this.tooltipData = tooltip.tooltipData

    const showDelayMs =
      this.tooltipData.showDelayMs == null
        ? DEFAULT_TOOLTIP_SHOW_DELAY_MS
        : this.tooltipData.showDelayMs

    if (
      this.state.isVisible ||
      now - this.hideTime <= SHOW_WITHOUT_DELAY_WITHIN_MS
    ) {
      return this.doShowTooltip(true)
    }

    let delay

    if (this.timeoutId == null) {
      delay = showDelayMs
    } else {
      clearTimeout(this.timeoutId)
      delay = Math.max(showDelayMs - (now - this.showTime), 0)
    }

    this.timeoutId = setTimeout(this.doShowTooltip, delay)
    this.showTime = now
  }

  doShowTooltip(immediately) {
    this.timeoutId = null

    const { tooltipData } = this
    const bounds = tooltipData.node.getBoundingClientRect()

    let x = tooltipData.dx
    let y = tooltipData.dy

    switch (tooltipData.position) {
      case TooltipContainer.TOP: {
        x += (bounds.left + bounds.right) / 2
        y += bounds.top
        break
      }
      case TooltipContainer.BOTTOM: {
        x += (bounds.left + bounds.right) / 2
        y += bounds.bottom
        break
      }
      case TooltipContainer.LEFT: {
        x += bounds.left
        y += (bounds.top + bounds.bottom) / 2
        break
      }
      case TooltipContainer.RIGHT: {
        x += bounds.right
        y += (bounds.top + bounds.bottom) / 2
        break
      }
    }

    this.setState({
      isVisible: true,
      text: tooltipData.text,
      position: tooltipData.position,
      extraClasses: tooltipData.extraClasses,
      showImmediately: !!immediately,
      x,
      y
    })
  }

  hideTooltip(tooltip) {
    if (tooltip != this.visibleTooltip) {
      tooltip.onHidden()
      return
    }

    this.visibleTooltip = null
    this.tooltipData = null

    if (this.state.isVisible) {
      this.hideTime = Date.now()
    }

    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }

    document.removeEventListener('scroll', this.hideCurrentTooltip, true)
    document.removeEventListener('mouseup', this.hideCurrentTooltip, true)

    this.setState({ isVisible: false })
    tooltip.onHidden()
  }

  hideCurrentTooltip(e) {
    if (this.visibleTooltip != null) {
      this.hideTooltip(this.visibleTooltip)
    }
  }
}
