import PropTypes from 'prop-types'
import React from 'react'
import { throttle } from 'lodash'
import { stopPropagation } from '~/utils/dom'

// The width of a path segment containing the only one ellipsis
// symbol, which is achieved by applying CLASS_SEGMENT_ELLIPSIS
// class.
//
const ELLIPSIS_SEGMENT_WIDTH_PX = 10

// The minimal width of a shortened path segment. Shortening is
// achieved by applying CLASS_SEGMENT_SHORTENED class and
// setting explicit width.
//
const MIN_SHORTENED_SEGMENT_WIDTH_PX = 50

// Class names for different states of path segments. These different
// states are used to implement card path collapsing/shortening when
// there is no horizontal space to fit the whole path.
//
const CLASS_SEGMENT_NORMAL = 'path-segment'
const CLASS_SEGMENT_HIDDEN = 'path-segment hidden'
const CLASS_SEGMENT_ELLIPSIS = 'path-segment ellipsis'
const CLASS_SEGMENT_SHORTENED = 'path-segment shortened'

class CardPath extends React.PureComponent {
  static propTypes = {
    path: PropTypes.array.isRequired,
    showCard: PropTypes.func.isRequired,
    makeHref: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.onPathContainerNode = node => (this.pathContainerNode = node)
    this.onWindowResize = throttle(() => this.updateSegmentsVisibility(), 300, {
      leading: false,
    })
    this.segments = []
    this.separatorWidth = 0
    this.totalPathWidth = 0
  }

  render() {
    const { path, makeHref } = this.props

    let pathSegments = path.map(segment => {
      const cardId = segment.id
      const href = makeHref(cardId)
      return (
        <span
          className={CLASS_SEGMENT_NORMAL}
          key={cardId}
          onClick={stopPropagation}
        >
          <a href={href} onClick={e => this.showCard(cardId, e)}>
            {segment.name}
          </a>
        </span>
      )
    })

    pathSegments = intersperse(pathSegments, key => (
      <span key={key} className="path-separator" />
    ))

    return (
      <div className="top-level-card-path" ref={this.onPathContainerNode}>
        {pathSegments}
        <div className="clipper" />
      </div>
    )
  }

  componentDidUpdate() {
    this.resetSegmentsState()
    this.updateSegmentsData()
    this.updateSegmentsVisibility()
  }

  componentDidMount() {
    this.resetSegmentsState()
    this.updateSegmentsData()
    this.updateSegmentsVisibility()
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  resetSegmentsState() {
    const { segments } = this
    for (let i = 0; i < segments.length; ++i) {
      const { node } = segments[i]
      node.className = CLASS_SEGMENT_NORMAL
      node.style.width = ''
    }
  }

  updateSegmentsData() {
    const separatorNode = this.pathContainerNode.querySelector(
      '.path-separator'
    )

    const separatorWidth = separatorNode
      ? separatorNode.getBoundingClientRect().width
      : 0

    this.separatorWidth = separatorWidth

    const segmentNodes = this.pathContainerNode.querySelectorAll(
      '.' + CLASS_SEGMENT_NORMAL
    )
    const totalSegments = segmentNodes.length
    const { segments } = this

    let totalPathWidth = totalSegments * separatorWidth
    segments.length = totalSegments

    for (let i = 0; i < totalSegments; ++i) {
      const node = segmentNodes[i]
      const width = node.getBoundingClientRect().width
      segments[i] = { node, width }
      totalPathWidth += width
    }

    this.totalPathWidth = totalPathWidth
  }

  updateSegmentsVisibility() {
    const { segments } = this
    const totalSegments = segments.length

    const availableWidth = this.pathContainerNode.getBoundingClientRect().width
    const { separatorWidth } = this

    let pathWidth = this.totalPathWidth
    let prevSegment = null
    let numSegmentsToHide = 0

    for (
      let i = 1, t = totalSegments - 2;
      i < t && pathWidth > availableWidth;
      ++i
    ) {
      if (prevSegment) {
        pathWidth -= ELLIPSIS_SEGMENT_WIDTH_PX + separatorWidth
      }
      const segment = segments[i]
      pathWidth -= segment.width - ELLIPSIS_SEGMENT_WIDTH_PX
      prevSegment = segment
      ++numSegmentsToHide
    }

    segments[0].node.className = CLASS_SEGMENT_NORMAL

    for (let i = 1; i < numSegmentsToHide; ++i) {
      segments[i].node.className = CLASS_SEGMENT_HIDDEN
    }

    if (numSegmentsToHide > 0) {
      segments[numSegmentsToHide].node.className = CLASS_SEGMENT_ELLIPSIS
    }

    const iLastButOne = totalSegments - 2
    if (iLastButOne >= 0) {
      // <=> totalSegments > 1
      for (let i = numSegmentsToHide + 1; i < iLastButOne; ++i) {
        segments[i].node.className = CLASS_SEGMENT_NORMAL
      }
      shortenSegmentIfNeeded(segments[iLastButOne])
    }

    shortenSegmentIfNeeded(segments[totalSegments - 1])

    function shortenSegmentIfNeeded(segment) {
      const widthDeficit = pathWidth - availableWidth
      if (widthDeficit > 0 && segment.width > MIN_SHORTENED_SEGMENT_WIDTH_PX) {
        const segWidth = Math.max(
          MIN_SHORTENED_SEGMENT_WIDTH_PX,
          segment.width - widthDeficit
        )
        segment.node.className = CLASS_SEGMENT_SHORTENED
        segment.node.style.width = segWidth + 'px'
        pathWidth -= segment.width - segWidth
      } else {
        segment.node.className = CLASS_SEGMENT_NORMAL
      }
    }
  }

  showCard(id, e) {
    e.preventDefault()
    this.props.showCard(id)
  }
}

function intersperse(arr, sepGen) {
  return flatMap(arr, (el, i) => [el, sepGen(i)])
}

function flatMap(arr, fn) {
  return arr.reduce((xs, x, i) => xs.concat(fn(x, i)), [])
}

export default CardPath
