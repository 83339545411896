export const Filters = {
  SHOW_COMPLETED: 'showCompleted',
  TIME: 'time',
  ASSIGNEE: 'assignee',
}

export const FilterValues = {
  ShowCompleted: {
    SHOW_ALL_COMPLETED: 0,
    SHOW_RECENTLY_COMPLETED: 1,
    SHOW_INCOMPLETE: 2,
  },
  // Apart from these special values, Time filter may have the following values:
  //
  // 1. Any integer number. In this case, filtering period is a week displaced
  //    by the given number of weeks from the current one.
  //
  // 2. An object containing "start" and "end" fields, which contain either
  //    Dates, day.js instances, or numbers.
  //
  Time: {
    ALL: 0,
    PREV_WEEK: -1,
    NEXT_WEEK: 1,
  },
  // Apart from this special value, Assignee filter may contain a list of user ids.
  Assignee: {
    ME: 0,
  },
}

const PASSING = 0
const ANCESTORS_PASSING = 1
const DESCENDANTS_PASSING = 2
const FAILING = 3
const FAILING_BUT_ACTIVE = 4
const DELETED = 5
const UNKNOWN = 6

export const FilteringState = {
  PASSING,
  ANCESTORS_PASSING,
  DESCENDANTS_PASSING,
  FAILING,
  FAILING_BUT_ACTIVE,
  DELETED,
  UNKNOWN,
  isVisible: state => state < FAILING,
}

export default { Filters, FilterValues, FilteringState }
