import React from 'react'
import cn from 'classnames'

export default function MoreActionItem({ action, name, icon, isRed }) {
  const componentClassNames = cn('more-action-item', isRed ? '-red' : '-normal')
  const iconClassNames = cn('icon', `-${icon}`)

  return <div className={componentClassNames} onClick={action} >
      <span className={iconClassNames}/>
      {name}
  </div>
}
