import * as ActionTypes from '~/actions/types'


selectedCardIdReducer.INITIAL_STATE = null


export default function selectedCardIdReducer(selectedCardId, action, selectUnreadCount) {
  switch (action.type) {
    case ActionTypes.SELECT_CARD: {
      return action.cardId
    }
    case ActionTypes.CLEAR_CARD_SELECTION: {
      return null
    }
    case ActionTypes.SHOW_CARD: {
      // If no card is selected, and new top-level card has unread messages,
      // select it so chat gets shown.
      return (selectedCardId == null && selectUnreadCount(action.cardId) > 0
        ? action.cardId
        // Otherwise, leave selected card as is. If it cannot be selected under the new
        // top-level card, reset-selected-card-id-if-hidden saga will reset the selection.
        : selectedCardId
      )
    }
    case ActionTypes.SELECT_PROJECT: {
      return (action.isInitial
        // if it's an initial project select, change selected card to the top-level
        // card if no card was selected prior to this (selection might happen at this stage
        // if URL contains selected card id, see sagas/navigation/handle-location.js).
        ? selectedCardId || action.cardId
        // if it's a project change, preserve selection state, i.e. select new card
        // only if there is currently a selected card.
        : selectedCardId && (action.cardId || action.projectId)
      )
    }
  }

  return selectedCardId
}
