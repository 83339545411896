import React from 'react'
import connect from '../utils/connect'

import {List} from 'immutable'

import selectors from '~/selectors'

import InvitationPopup from './invitation-popup'

class InvitationPopupContainer extends React.Component {

  static mapStateToProps(state) {
    const members = selectors.selectedProjectMembers(state) || List()
    const memberMails = members.map(user => user.get('email'))
    const invitation = state.get('invitation')
    const project = selectors.selectedProject(state)

    return {
      memberMails,
      invitation,
      project,
    }
  }

  render() {
    const {actions, memberMails, invitation, project} = this.props
    return <InvitationPopup
      memberMails={memberMails}
      invitation={invitation}
      project={project}
      inviteMember={actions.inviteMember}
      toggleInvitation={actions.toggleInvitation}
      resetInvitation={actions.resetInvitation}
    />
  }

}

export default connect(InvitationPopupContainer)
