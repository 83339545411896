import { fromJS} from 'immutable'
import * as ActionTypes from '~/actions/types'

export const INITIAL_STATE = fromJS({
  projectId: null,
  list: []
})

let matchesCurrentProjectTopic = (action, state) => {
  const projectId = state.get('projectId')
  return action.topic == `cards:${projectId}`
}

export default function presenceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SELECT_PROJECT_SUCCESS: {
      return state.set('projectId', action.projectId)
    }
    case "PRESENCE_STATE": {
      if (matchesCurrentProjectTopic(action, state)) {
        return state.set('list', fromJS(action.presences))
      }
    }
    case "PRESENCE_DIFF": {
      if (matchesCurrentProjectTopic(action, state)) {
        return state.set('list', fromJS(action.presences))
      }
    }
  }
  return state
}
