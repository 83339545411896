import { all, take, select, call } from 'redux-saga/effects'
import { $dispatch, nextTick } from './utils/effects'

import sel from '~/selectors'
import { Filters, FilterValues } from '~/utils/filter-constants'
import { ROUTE_PLAN, ROUTE_MY_TASKS } from '~/utils/routing'
import { isCardDeleted, getCardIdsToExpandToRevealCard } from '~/utils/cards'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

export default function* $revealCardSaga() {
  while (true) {
    // TODO: cancel the current call if new action received
    const { cardId, projectId } = yield take(ActionTypes.REVEAL_CARD)
    yield* $revealCard(cardId, projectId)
  }
}

function* $revealCard(cardId, projectId) {
  if (!projectId) {
    throw new Error(`projectId should be set`)
  }

  const selectedProjectId = yield select(sel.selectedProjectId)

  if (projectId != selectedProjectId) {
    yield* $dispatch(Actions.selectProject(projectId))
    yield take(ActionTypes.SELECT_PROJECT_SUCCESS) // FIXME: handle errors
    const newProjectId = yield select(sel.selectedProjectId)
    if (newProjectId != projectId) {
      console.warn(
        `Cannot reveal card ${cardId}: failed to select target project ${projectId}`
      )
      yield* $dispatch(Actions.revealCardFinished(cardId, false))
      return
    }
    yield call($waitUntilFiltersRun)
  }

  if (cardId == projectId) {
    yield* $dispatch(Actions.showCard(projectId))
    yield* $dispatch(Actions.selectCard(projectId))
    yield* $dispatch(Actions.revealCardFinished(cardId, true))
    return
  }

  let [cardsViewState, cards, routeType] = yield all([
    select(sel.cardsViewState),
    select(sel.cards),
    select(sel.routeType),
  ])

  if (isCardDeleted(cardId, cards, true, projectId)) {
    window.alert(`The card you're trying to view is deleted`)
    yield* $dispatch(Actions.revealCardFinished(cardId, false))
    return
  }

  let topLevelCardId

  if (routeType == ROUTE_PLAN || routeType == ROUTE_MY_TASKS) {
    topLevelCardId =
      routeType == ROUTE_PLAN ? yield select(sel.planTopLevelCardId) : null
    if (
      yield* $tryMakeCardVisible(
        cardId,
        cardsViewState,
        cards,
        routeType,
        topLevelCardId,
        projectId
      )
    ) {
      yield* $dispatch(Actions.selectCard(cardId))
      yield* $dispatch(Actions.revealCardFinished(cardId, true))
      return
    }
  }

  const parentId = sel.card.parentId(cards.get(cardId))

  if (!(routeType == ROUTE_PLAN && topLevelCardId == parentId)) {
    routeType = ROUTE_PLAN
    topLevelCardId = parentId
    yield* $dispatch(Actions.showCard(topLevelCardId))
    if (yield* $waitUntilFiltersRunAndDoSelect()) return
  }

  yield* $dispatch(Actions.resetAllFilters())
  if (yield* $waitUntilFiltersRunAndDoSelect()) return

  yield* $dispatch(
    Actions.setFilter(
      Filters.SHOW_COMPLETED,
      FilterValues.ShowCompleted.SHOW_ALL_COMPLETED
    )
  )
  if (yield* $waitUntilFiltersRunAndDoSelect()) return

  console.warn(
    `Cannot reveal card ${cardId}: failed to make it visible ` +
      `even under broadest filters; trying to make the card a top-level one`
  )

  yield* $dispatch(Actions.showCard(cardId))
  yield* $dispatch(Actions.revealCardFinished(cardId, false))

  function* $waitUntilFiltersRunAndDoSelect() {
    yield call($waitUntilFiltersRun)
    cardsViewState = yield select(sel.cardsViewState)
    if (
      yield* $tryMakeCardVisible(
        cardId,
        cardsViewState,
        cards,
        routeType,
        topLevelCardId,
        projectId
      )
    ) {
      yield* $dispatch(Actions.selectCard(cardId))
      yield* $dispatch(Actions.revealCardFinished(cardId, true))
      return true
    }
    return false
  }
}

function* $waitUntilFiltersRun() {
  yield call(nextTick)
}

function* $tryMakeCardVisible(
  cardId,
  cardsViewState,
  cards,
  routeType,
  topLevelCardId,
  projectId
) {
  const cardIdsToExpand = getCardIdsToExpandToRevealCard(
    cardId,
    cardsViewState,
    cards,
    routeType,
    topLevelCardId,
    projectId
  )
  if (cardIdsToExpand == null) {
    return false
  }
  if (cardIdsToExpand.length > 0) {
    yield* $dispatch(Actions.setCardsExpanded(cardIdsToExpand, true))
  }
  return true
}
