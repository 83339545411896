import React from 'react'
import { TurtleCredentials } from '~/utils/google-auth-flow'
import Loader from '../loader'
import { GoogleSignInButton } from './google-sign-in-button'

type Props = {
  showSpinner: boolean
  onAuthSuccess: (credentils: TurtleCredentials | null) => void
  onAuthError: () => void
}

const SignIn = ({ showSpinner, onAuthSuccess, onAuthError }: Props) => {
  const content = showSpinner ? (
    <Loader expandOnFullHeight colorScheme="light" />
  ) : (
    <div>
      <div className="login-welcome-text">
        Welcome to <span className="login-welcome-turtle">Turtle</span>
      </div>
      <GoogleSignInButton
        onAuthSuccess={onAuthSuccess}
        onAuthError={onAuthError}
        className="login-google-button"
      />
      <p>
        <a href="https://turtleos.com/privacy">Privacy policy</a> and{' '}
        <a href="https://turtleos.com/terms">Terms of Service</a>
      </p>
    </div>
  )
  return (
    <div className="signin-container">
      <div className="turtle">{content}</div>
    </div>
  )
}

export default SignIn
