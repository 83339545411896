import React from 'react'
import cn from 'classnames'
import {
  formatShortDate,
  formatPeriodByOffset,
  WEEK_TIME_PERIOD,
  MONTH_TIME_PERIOD,
} from '~/utils/time'
import TurtleDayPicker from '~/components/turtle-day-picker'
import Tooltip from '~/components/tooltip'
import PopupWrapper, {
  ESCAPE_PRESSED,
  CLICKED_OUTSIDE,
} from '~/components/popup-wrapper'
import dayjs, { Dayjs, OpUnitType } from '~/utils/dayjs'

export type TimePeriodHeaderProps = {
  offset: number
  periodType: OpUnitType
  onPeriodShift: (offset: number) => void
  onCustomPeriodSet: (startPeriod: Dayjs, endPeriod: Dayjs) => void
  onPeriodTypeChange: (period: OpUnitType) => void
}

type TimePeriodHeaderState = {
  customPeriod: boolean
  startDateDropDownOpened: boolean
  endDateDropDownOpened: boolean
  startDate: Dayjs
  endDate: Dayjs
}

export class TimePeriodHeader extends React.Component<
  TimePeriodHeaderProps,
  TimePeriodHeaderState
> {
  constructor(props: TimePeriodHeaderProps) {
    super(props)
    this.state = {
      customPeriod: false,
      startDateDropDownOpened: false,
      endDateDropDownOpened: false,
      startDate: dayjs(),
      endDate: dayjs(),
    }
  }

  decPeriod = () => {
    this.props.onPeriodShift(-1)
  }

  toCustomPeriodView = () => {
    this.setState({
      customPeriod: true,
    })
    this.updateCustomPeriod()
  }

  toOffsetPeriodView = (periodType: OpUnitType) => {
    this.setState({
      customPeriod: false,
    })
    if (periodType === WEEK_TIME_PERIOD) {
      this.props.onPeriodTypeChange(WEEK_TIME_PERIOD)
    } else {
      this.props.onPeriodTypeChange(MONTH_TIME_PERIOD)
    }
  }

  incPeriod = () => {
    if (this.props.offset === 0) {
      return
    }

    this.props.onPeriodShift(+1)
  }

  jumpToNow = () => {
    this.props.onPeriodShift(-this.props.offset)
  }

  toggleStartDatePicker = () => {
    const prevStat = this.state.startDateDropDownOpened
    this.setState({ startDateDropDownOpened: !prevStat })
  }

  toggleEndDatePicker = () => {
    const prevStat = this.state.endDateDropDownOpened
    this.setState({ endDateDropDownOpened: !prevStat })
  }

  closeDatePicker = (type: string) => {
    if (type === 'start') {
      this.setState({ startDateDropDownOpened: false })
    } else {
      this.setState({ endDateDropDownOpened: false })
    }
  }

  setDate = (selectedDate: Dayjs, type: string) => {
    if (type === 'start') {
      if (selectedDate > this.state.endDate) {
        alert('Incorrect start date. Select correct date')
        return
      }
      this.setState(
        {
          startDate: selectedDate,
          startDateDropDownOpened: false,
        },
        this.updateCustomPeriod
      )
    } else {
      if (this.state.startDate > selectedDate) {
        alert('Incorrect end date. Select correct date')
        return
      }
      this.setState(
        {
          endDate: selectedDate,
          endDateDropDownOpened: false,
        },
        this.updateCustomPeriod
      )
    }
  }

  updateCustomPeriod = () => {
    const { startDate, endDate } = this.state
    if (startDate && endDate) {
      this.props.onCustomPeriodSet(startDate, endDate)
    }
  }

  handleDueDatePopupHidden = (dismissReason: string) => {
    if (dismissReason === ESCAPE_PRESSED || dismissReason === CLICKED_OUTSIDE) {
      this.setState({
        startDateDropDownOpened: false,
        endDateDropDownOpened: false,
      })
    }
  }

  render() {
    const { offset, periodType } = this.props

    return (
      <div className="time-period-header">
        {this.renderPeriodSelector()}
        <div className="navigation">
          {this.state.customPeriod ? (
            this.renderCustomPeriodPicker()
          ) : (
            <div className="nav-offset-container">
              <Tooltip label="Custom period">
                <div className="nav-subtitle" onClick={this.toCustomPeriodView}>
                  {offset === null && periodType === null
                    ? formatPeriodByOffset(0, WEEK_TIME_PERIOD)
                    : formatPeriodByOffset(offset, periodType)}
                </div>
              </Tooltip>
              <div className="nav-arrows-container">
                <div className="nav-prev" onClick={this.decPeriod} />
                <div
                  className={`nav-next ${!offset && 'disabled'}`}
                  onClick={this.incPeriod}
                />
              </div>
            </div>
          )}
        </div>

        <div className="jump-to-now" onClick={this.jumpToNow}>
          {offset !== 0 && !this.state.customPeriod
            ? `Jump to this ${periodType}`
            : ''}
        </div>
      </div>
    )
  }

  renderPeriodSelector() {
    const { periodType } = this.props

    const weekPeriodClassnames = cn('week-period', {
      active: periodType === WEEK_TIME_PERIOD,
    })
    const monthPeriodClassnames = cn('month-period', {
      active: periodType === MONTH_TIME_PERIOD,
    })

    return (
      <div className="period-selector">
        <span
          className={weekPeriodClassnames}
          onClick={() => this.toOffsetPeriodView(WEEK_TIME_PERIOD)}
        >
          Week
        </span>
        <span
          className={monthPeriodClassnames}
          onClick={() => this.toOffsetPeriodView(MONTH_TIME_PERIOD)}
        >
          Month
        </span>
      </div>
    )
  }

  renderCustomPeriodPicker() {
    return (
      <div className="custom-period-badges">
        <div className="custom-period-date-wrap">
          <div
            className="custom-period-badge"
            onClick={this.toggleStartDatePicker}
          >
            {this.state.startDate
              ? formatShortDate(this.state.startDate)
              : 'Start date'}
          </div>
          {this.state.startDateDropDownOpened
            ? this.renderDayPickerPopup('start')
            : ''}
        </div>
        <div className="custom-period-date-wrap">
          <div
            className="custom-period-badge"
            onClick={this.toggleEndDatePicker}
          >
            {this.state.endDate
              ? formatShortDate(this.state.endDate)
              : 'End date'}
          </div>
          {this.state.endDateDropDownOpened
            ? this.renderDayPickerPopup('end')
            : ''}
        </div>
        <div
          className="close-icon"
          onClick={() => this.toOffsetPeriodView(WEEK_TIME_PERIOD)}
        ></div>
      </div>
    )
  }

  renderDayPickerPopup(type: string) {
    let nowDate
    if (type === 'start') {
      nowDate = this.state.startDate ? this.state.startDate : dayjs()
    } else {
      nowDate = this.state.endDate ? this.state.endDate : dayjs()
    }
    return (
      <PopupWrapper onDismiss={this.handleDueDatePopupHidden}>
        <div className="custom-date-picker">
          <div className="header">
            <div className="title">
              {type === 'start' ? 'Start date' : 'End date'}
            </div>
            <div className="close" onClick={() => this.closeDatePicker(type)}>
              Close
            </div>
          </div>
          <div className="separator" />
          <TurtleDayPicker
            currentDate={nowDate}
            setDate={(e: Dayjs) => this.setDate(e, type)}
          />
        </div>
      </PopupWrapper>
    )
  }
}
