import { useMemo } from 'react'
import { useLocation } from 'react-router'

type ParsedSearchType = {
  [key: string]: string | undefined
}

export const useSearchParams = (): ParsedSearchType => {
  const { search } = useLocation()

  const parsedSearchParams = useMemo(
    () =>
      search
        .substr(1)
        .split('&')
        .reduce((result: ParsedSearchType, pair) => {
          const splittedPair = pair.split('=')
          if (splittedPair[0].length) {
            return { ...result, [splittedPair[0]]: splittedPair[1] }
          }
        }, {}) ?? {},
    [search]
  )

  return parsedSearchParams
}
