const EMPTY = {}

export default function animateScrollTo(scrollTop, container, opts = EMPTY) {
  const {
    easingFunc = easeInOutSine,
    timeoutMs = 200,
  } = opts

  const tStart = performance.now()
  const scrollTopStart = container.scrollTop
  const dScrollTop = scrollTop - scrollTopStart

  let animFrameId

  function step(now) {
    const dt = now - tStart
    let p
    if (dt >= timeoutMs) {
      p = easingFunc(1)
      animFrameId = null
    } else {
      p = easingFunc(dt / timeoutMs)
      animFrameId = requestAnimationFrame(step)
    }
    container.scrollTop = scrollTopStart + dScrollTop * p
  }

  animFrameId = requestAnimationFrame(step)

  return function stop() {
    if (animFrameId != null) {
      cancelAnimationFrame(animFrameId)
      animFrameId = null
    }
  }
}


function easeOutSine(p) {
  return Math.sin(p * Math.PI / 2)
}


function easeInOutSine(p) {
  return (-0.5 * (Math.cos(Math.PI * p) - 1))
}


function easeInOutQuint(p) {
  if ((p /= 0.5) < 1) {
      return 0.5 * Math.pow(p, 5)
  }
  return 0.5 * (Math.pow((p - 2), 5) + 2)
}


animateScrollTo.easeOutSine = easeOutSine
animateScrollTo.easeInOutSine = easeInOutSine
animateScrollTo.easeInOutQuint = easeInOutQuint
