import { fromJS } from 'immutable'
import * as ActionTypes from '~/actions/types'

const INITIAL_STATE = (directChatsReducer.INITIAL_STATE = fromJS([]))

export default function directChatsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.TOGGLE_DIRECT_CHAT: {
      const existingIndex = state.findIndex((el) => action.feedId === el)
      return existingIndex === -1
        ? state.push(action.feedId)
        : state.delete(existingIndex)
    }
    case ActionTypes.OPEN_DIRECT_CHAT: {
      const existingIndex = state.findIndex((el) => action.feedId === el)
      if (existingIndex === -1) {
        return state.push(action.feedId)
      }
      return state
    }
  }
  return state
}
