import PropTypes from 'prop-types'
import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import UnreadCountBadge from '../unread-count-badge'
import OnlineIndicator from './member-card-online-indicator'

class MemberCard extends React.PureComponent {
  static propTypes = {
    member: PropTypes.object,
    unreadCounts: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.string),
    directFeedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showRemoveMember: PropTypes.bool,
    removeMember: PropTypes.func,
  }

  toggleRemoveMember = teamMember => {
    const ok = confirm(
      'Do you really want to remove ' + teamMember + ' from a team?'
    )
    if (!ok) return

    const memberId = this.props.member.get('id')
    this.props.removeMember(memberId)
  }

  render() {
    const { member, unreadCounts, directFeedId, showRemoveMember } = this.props
    const teamMemberName = member.get('name')
    const memberId = member.get('id')
    const count = unreadCounts.get(directFeedId)
    return (
      <div className="team-member" key={memberId}>
        <OnlineIndicator userId={memberId} />
        <div className="username" onClick={this.props.onNameClick}>
          {teamMemberName}
        </div>
        {!!count && <UnreadCountBadge count={count} />}
        {showRemoveMember && (
          <div
            className="remove-member"
            onClick={() => {
              this.toggleRemoveMember(teamMemberName)
            }}
          >
            ×
          </div>
        )}
      </div>
    )
  }
}

export default MemberCard
