import {fromJS, List, Map} from 'immutable'
import * as ActionTypes from '~/actions/types'

export const INITIAL_STATE = unreadCountsReducer.INITIAL_STATE = fromJS({})

function toZeroMap(values) {
  const tuples = List(values).map(v => [v, 0])
  return Map(tuples)
}

export default function unreadCountsReducer(state = INITIAL_STATE, action, selectOpenFeedIds) {
  switch (action.type) {
    case ActionTypes.UNREAD_COUNTS_CHANGED: {
      const counts = Map(action.counts)
      const zeroCounts = toZeroMap(selectOpenFeedIds())
      return counts.merge(zeroCounts)
    }
  }
  return state
}
