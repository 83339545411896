import React from 'react'
import Tooltip from '../tooltip'
import {pluralForm} from '~/utils/pluralize'

export default function UnreadCountBadge({ count }) {

  const tooltipLabel = `${pluralForm(count, 'notification')} inside card`

  return (
    <Tooltip label={tooltipLabel} dy={-4}>
      <div className='unread-count-badge'>{count}</div>
    </Tooltip>
  )
}
