import React from 'react'

const DropOverlay: React.FC = () => {
  return (
    <div className="drop-overlay-container">
      <div className="drop-overlay-box">
        <div className="drop-overlay-upload-icon" />
        <div className="drop-overlay-text">Drop to upload file to Turtle</div>
      </div>
    </div>
  )
}

export default DropOverlay
