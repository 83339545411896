import client from '~/api/apollo-client'
import * as Types from '~/types/api'
import * as Queries from './queries'

export const uploadFile = async (file: File, feedId: string) => {
  const { data } = await client.mutate<
    Types.AuthorizeFileUpload,
    Types.AuthorizeFileUploadVariables
  >({
    mutation: Queries.UploadFile,
    variables: { input: { filename: file.name } },
  })
  if (data?.authorizeFileUpload) {
    const { fileId, presignedUrl } = data.authorizeFileUpload
    await sendFileToFeed(file, feedId, fileId, presignedUrl as string)
  }
}

export const postFileToFeed = async (fileId: string) => {
  const { data } = await client.mutate<
    Types.ProcessUploadedFile,
    Types.ProcessUploadedFileVariables
  >({
    mutation: Queries.ProcessUploadedFile,
    variables: {
      input: { fileId },
    },
  })
  if (!data?.processUploadedFile) {
    return null
  }

  return data?.processUploadedFile
}

const sendFileToFeed = (
  file: Blob,
  feedId: string,
  fileId: string,
  presignedUrl: string
) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    const handlers = {
      load: () => resolve(true),
      error: () => reject(false),
    }

    request.addEventListener('load', handlers.load)
    request.addEventListener('error', handlers.error)

    request.open('PUT', presignedUrl, true)
    if (file.type) {
      request.setRequestHeader('Content-Type', file.type)
    }
    request.send(file)
  })

export const feedAddReaction = async (
  feedEventId: string,
  reaction: string
) => {
  await client.mutate<
    Types.FeedAddReactionMutation,
    Types.FeedAddReactionMutationVariables
  >({
    mutation: Queries.FeedAddReactionMutation,
    variables: { input: { feedEventId, emoji: reaction } },
  })
}

export const feedRemoveReaction = async (
  feedEventId: string,
  reaction: string
) => {
  await client.mutate<
    Types.FeedRemoveReactionMutation,
    Types.FeedRemoveReactionMutationVariables
  >({
    mutation: Queries.FeedRemoveReactionMutation,
    variables: { input: { feedEventId, emoji: reaction } },
  })
}

export const feedPostMessage = async (
  feedId: string,
  messageBody: string,
  feedEventId?: string
) => {
  await client.mutate<
    Types.FeedPostMessageMutation,
    Types.FeedPostMessageMutationVariables
  >({
    mutation: Queries.FeedPostMessageMutation,
    variables: { input: { feedId, messageBody, feedEventId, tags: [] } },
  })
}

export const feedDeleteMessage = async (feedEventId: string) => {
  await client.mutate<
    Types.FeedDeleteMessageMutation,
    Types.FeedDeleteMessageMutationVariables
  >({
    mutation: Queries.FeedDeleteMessageMutation,
    variables: { input: { feedEventId } },
  })
}

export const feedEditMessage = async (
  feedEventId: string,
  messageBody: string
) => {
  await client.mutate<
    Types.FeedEditMessageMutation,
    Types.FeedEditMessageMutationVariables
  >({
    mutation: Queries.FeedEditMessageMutation,
    variables: { input: { feedEventId, messageBody } },
  })
}

const feedMarkAsReadMaxCallWindowMs = 200
let feedMarkAsReadLastExecuted = 0

export const feedMarkAsRead = async (feedId: string) => {
  const now = +new Date()
  if (now - feedMarkAsReadLastExecuted < feedMarkAsReadMaxCallWindowMs) {
    console.warn(`Double exec feedMarkAsRead(${feedId})`)
    return Promise.resolve()
  }
  feedMarkAsReadLastExecuted = now
  await client.mutate<
    Types.FeedMarkAsReadMutation,
    Types.FeedMarkAsReadMutationVariables
  >({
    mutation: Queries.FeedMarkAsReadMutation,
    variables: { input: { feedId } },
  })
}
