import React from 'react'
import { ChatContainerRedux } from '~/components/chat/redux-container'

export default class DirectChats extends React.Component {
  render() {
    return (
      <div className="direct-chats-container">
        {this.props.feedIds.map(feedId => (
          <div className="direct-chat" key={feedId}>
            <ChatContainerRedux chatId={feedId} />
          </div>
        ))}
      </div>
    )
  }
}
