import React from "react";
import { NavLink } from "react-router-dom";
import {
  buildPath,
  ROUTE_PLAN,
  ROUTE_ACTIVITY,
  ROUTE_MY_TASKS,
} from "~/utils/routing";

const TaskListsLinks = ({ projectId }: { projectId: string }) => {
  return (
    <div className="task-lists-links" onClick={(e) => e.stopPropagation()}>
      <NavLink
        key="0"
        to={buildPath({
          type: ROUTE_PLAN,
          cardId: projectId,
          projectId: projectId,
        })}
        activeClassName="active"
      >
        All tasks
      </NavLink>
      <NavLink
        key="1"
        to={buildPath({ type: ROUTE_MY_TASKS, projectId: projectId })}
        activeClassName="active"
      >
        My tasks
      </NavLink>
      <NavLink
        key="2"
        to={buildPath({ type: ROUTE_ACTIVITY, projectId: projectId })}
        activeClassName="active"
      >
        Activity
      </NavLink>
    </div>
  );
};

export default TaskListsLinks;
