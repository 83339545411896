import React from 'react'
import connect from '../utils/connect'
import cn from 'classnames'

import config from '~/config'
import selectors from '~/selectors'

import SigninContainer from '../sign-in'
import LayoutContainer from '../layout'
import ErrorBoundary from '../error-boundary'

import { NoProjects } from '../no-projects'
import TooltipContainer from '../tooltip/tooltip-container'

import PageFatalError from '../pages/page-fatal-error'
import TurtleAvatar from '~assets/images/turtle-user-avatar.png'

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'

export const optimizelyInstance = createInstance({
  sdkKey: config.OPTIMIZELY_SDK_KEY,
})

class App extends React.Component {
  static mapStateToProps(state) {
    return {
      isLoggedIn: selectors.isLoggedIn(state),
      userId: selectors.userId(state),
      hasProjects: selectors.hasProjects(state),
      projectsLoaded: selectors.projectIds(state).size > 0,
      fatalError: selectors.fatalError(state),
      projectId: selectors.selectedProjectId(state),
    }
  }

  render() {
    const {
      children,
      isLoggedIn,
      userId,
      projectId,
      hasProjects,
      projectsLoaded,
      fatalError,
      className,
    } = this.props

    const iOSLink =
      'https://itunes.apple.com/us/app/turtle-mobile/id1299195526?mt=8'
    const androidLink =
      'https://play.google.com/store/apps/details?id=com.turtle.mobile'
    const linkToApp =
      getMobileOperatingSystem() === 'iOS' ? iOSLink : androidLink
    const intViewportWidth = window.innerWidth
    const mobileSplashScreen = (
      <div className="mobile-splash-screen">
        Looks like you&apos;re on a mobile device. Please use our web app or
        install our
        <a href={linkToApp} className="link">
          {' '}
          mobile app
        </a>
        .
        <img className="avatar" src={TurtleAvatar} />
      </div>
    )

    if (fatalError) {
      return <PageFatalError error={fatalError} />
    }

    const app =
      isLoggedIn === undefined ? undefined : isLoggedIn ? ( // app is not initialized yet
        intViewportWidth < 500 ? (
          mobileSplashScreen
        ) : hasProjects ? (
          projectsLoaded ? (
            <ErrorBoundary>
              <OptimizelyProvider
                optimizely={optimizelyInstance}
                timeout={500}
                user={{
                  id: userId,
                  attributes: { id: userId, selectedProjectId: projectId },
                }}
              >
                <LayoutContainer>{children}</LayoutContainer>
              </OptimizelyProvider>
            </ErrorBoundary>
          ) : null
        ) : (
          <NoProjects />
        )
      ) : (
        <SigninContainer />
      )
    return (
      <div className={cn('app', className)}>
        {app}
        <TooltipContainer />
      </div>
    )
  }
}

function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export default connect(App)
