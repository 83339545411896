import { gql } from '@apollo/client'

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    name
    email
    photo
    status
  }
`

export const CardFragment = gql`
  fragment CardFragment on Card {
    completed
    completedAt
    createdAt
    deleted
    dueDate
    estimate {
      min
      max
    }
    budget {
      duration
    }
    feedId
    id
    name

    assigneeId
    parentId
    timeTracked
    position
  }
`
