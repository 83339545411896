import { Map } from 'immutable'
import * as ActionTypes from '~/actions/types'

export const INITIAL_STATE = (usersReducer.INITIAL_STATE = Map({
  selectedId: null,
  selectingId: null,
}))

export default function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SELECT_USER: {
      return state.merge({
        selectedId: null,
        selectingId: action.userId,
      })
    }
    case ActionTypes.SELECT_USER_SUCCESS: {
      return state.merge({
        selectedId: action.userId,
        selectingId: null,
      })
    }
  }
  return state
}
