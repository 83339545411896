import React from 'react'
import connect from '../utils/connect'

import sel from '~/selectors'

import Sidebar from './sidebar'

class SidebarContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      myUserId: sel.userId(state),
      permissions: sel.permissions(state),
      routeType: sel.routeType(state),
      projectId: sel.selectingOrSelectedProjectId(state),
      planTopLevelCardId: sel.planTopLevelCardId(state),
      teamMembers: sel.teamMembersWithShortNames(state),
      turtleUserId: sel.botUserId(state),
      unreadCounts: sel.unreadCountsForUserFeeds(state),
    }
  }

  render() {
    const { props } = this
    return (
      <Sidebar
        myUserId={props.myUserId}
        permissions={props.permissions}
        routeType={props.routeType}
        projectId={props.projectId}
        planTopLevelCardId={props.planTopLevelCardId}
        teamMembers={props.teamMembers}
        turtleUserId={props.turtleUserId}
        unreadCounts={props.unreadCounts}
        toggleInvitation={props.actions.toggleInvitation}
        removeMember={props.actions.removeMember}
        toggleDirectChat={props.actions.toggleDirectChat}
      ></Sidebar>
    )
  }
}

export default connect(SidebarContainer)
