import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'


const INITIAL_STATE = inviteReducer.INITIAL_STATE = Map({
  inviting: false,
  pending: false,
  successfull: false,
})


export default function inviteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.TOGGLE_INVITATION: {
      return state.update('inviting', flag => !flag)
    }
    case ActionTypes.INVITE_MEMBER: {
      return state.withMutations(state => {
        state.set('pending', true)
        state.set('invitationError', null)
      })
    }
    case ActionTypes.MEMBER_INVITED: {
      return state.withMutations(state => {
        state.set('pending', false)
        state.set('invitationError', action.error)
        state.set('successfull', !action.error)
      })
    }
    case ActionTypes.RESET_INVITATION: {
      return state.withMutations(state => {
        state.set('pending', false)
        state.set('invitationError', null)
        state.set('successfull', false)
      })
    }
    case ActionTypes.REMOVE_MEMBER: {
      return state.withMutations(state => {
        state.set('inviting', false)
      })
    }
  }
  return state
}
