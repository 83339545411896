/* eslint-disable */
import React from 'react'
import PopupWrapper from '../popup-wrapper'
import cn from 'classnames'
import {isValidDurationString, convertDurationFromStringToSeconds, formatInterval} from '~/utils/duration'

export default class EstimateDropDown extends React.Component {

  state = {popupVisible: false, estimate: '', valid: true}

  render() {
    return <div className={`estimate-dropdown ${this.props.estimateMin ? '' : '-empty'}`}>
      <div className='estimate-badge' onClick={this.showPopup}>
        {formatEstimate(this.props.estimateMin, this.props.estimateMax, true)}
      </div>
      {this.state.popupVisible && <PopupWrapper onDismiss={this.onPopupHidden}>
        <div className='estimate-popup' ref={this.setTransform}>
          <div className='header'>
            <div className='title'>Estimate</div>
            <div className='clear' onClick={this.clearEstimate}>Clear</div>
          </div>
          <div className='body'>
            <div className='first-row'>
              <div className='label'>Time</div>
              <input className={`estimate-input ${this.state.valid ? '' : '-invalid'}`}
                      autoFocus={true}
                      placeholder='1.5h-2h'
                      value={this.state.estimate}
                      onChange={this.updateEstimate}
                      onKeyPress={this.handlePress}
                      />
            </div>
            <div className='second-row'>
              <button className='estimate-button'
                      onClick={this.saveEstimate}
                      disabled={!this.state.valid}>
                Update
              </button>
            </div>
          </div>
        </div>
      </PopupWrapper>}
    </div>
  }

  showPopup = () => {
    this.setState({popupVisible: true, estimate: formatEstimate(this.props.estimateMin, this.props.estimateMax)})
    setTimeout(this.props.onPopupShown, 0)
  }

  onPopupHidden = (reason) => {
    this.setState({popupVisible: false})
    this.props.onPopupHidden(reason)
  }

  clearEstimate = () => {
    this.setState({popupVisible: false, estimate: ''})
    this.props.updateEstimate(parseEstimate(''))
    this.props.onPopupHidden()
  }

  handlePress = ({key}) => {
    if (key === 'Enter') {
      this.saveEstimate()
    }
  }

  updateEstimate = (e) => {
    const estimate = e.target.value
    this.setState({estimate, valid: isValidEstimate(estimate)})
  }

  saveEstimate = (e) => {
    e && e.preventDefault()
    if (this.state.valid) {
      this.setState({popupVisible: false})
      this.props.updateEstimate(parseEstimate(this.state.estimate))
      this.props.onPopupHidden()
    }
  }

  setTransform = (node) => {
    if (!node) {
      return
    }

    const viewport = this.props.viewportNodeSelector
      && document.querySelectorAll(this.props.viewportNodeSelector)[0]
      || document.children[0]

    node.style.transform = calculateTransform(node, viewport)
  }

}

function calculateTransform(node, viewport) {
    const SHADOW_OFFSET_PX = 4

    const {top, bottom, right, left} = viewport.getBoundingClientRect()

    const popupRect = node.getBoundingClientRect()

    const pTop = popupRect.top
    const pBottom = popupRect.bottom
    const pRight = popupRect.right

    const diffX = Math.max(pRight - right, 0)
    const diffY = calculateDiff(top, bottom, pTop, pBottom)

    let transform = ''

    if (diffX != 0) {
      transform = `translateX(${ -(diffX + Math.sign(diffX) * SHADOW_OFFSET_PX) }px)`
    }

    if (diffY != 0) {
      transform += ` translateY(${ -(diffY + Math.sign(diffY) * SHADOW_OFFSET_PX) }px)`
    }

    return transform
}

function calculateDiff(left, right, intervalLeft, intervalRight) {
  const rightOffset = Math.max(intervalRight - right, 0)
  const leftOffset = Math.min(intervalLeft - left, 0)

  return Math.abs(rightOffset) > Math.abs(leftOffset)
          ? rightOffset
          : leftOffset
}

function formatEstimate(estimateMin, estimateMax, parens = false) {
  if (!estimateMin) {
    return ''
  }
  const str = formatInterval(estimateMin, estimateMax)
  return parens ? `[${str}]` : str
}

function parseEstimate(estimateString) {
  const [_minStr, maxStr] = estimateString.split('-').map(s => s.trim())
  const minStr = isValidDurationString(_minStr)
    ? _minStr
    : `${_minStr}${maxStr ? maxStr[maxStr.length - 1] : ''}`
  if (!isValidDurationString(minStr) &&
    (maxStr && !isValidDurationString(minStr+maxStr[maxStr.length - 1]))) {
    return {}
  }
  const minSeconds = convertDurationFromStringToSeconds(minStr)
  if (!maxStr || !isValidDurationString(maxStr)) {
    return {min: minSeconds, max: minSeconds}
  }
  const maxSeconds = convertDurationFromStringToSeconds(maxStr)
  return {min: minSeconds, max: maxSeconds}
}

function isValidEstimate(estimateString) {
  if (!estimateString) {
    return true
  }
  const {min, max} = parseEstimate(estimateString)
  return min && min <= max
}
