import { feedMarkAsRead } from '../api/mutations'
import * as Types from '~/types/api'
import { convertDurationFromStringToSeconds } from '~/utils/duration'
import { getBodyTokensFromText } from './helpers'

export const updateChatOnLoadMore = (
  prev: Types.GetChatData,
  { fetchMoreResult }: { fetchMoreResult: Types.GetChatData | undefined }
) => {
  if (!fetchMoreResult?.feed?.feedEvents?.length || !prev.feed?.feedEvents)
    return prev
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: [
        ...fetchMoreResult.feed.feedEvents.reverse(),
        ...prev.feed.feedEvents,
      ],
    },
  })
}

export const updateChatOnSubcriptionUpdate = (chatId: string) => (
  prev: Types.GetChatData,
  {
    subscriptionData,
  }: {
    subscriptionData: {
      data: Types.ChatMessageSubscription
    }
  }
) => {
  const { messageUpdated } = subscriptionData.data
  if (!messageUpdated || messageUpdated.feedId !== chatId) {
    return prev
  }
  const newFeed = [...(prev.feed?.feedEvents ?? [])]
  const messageIndex = newFeed.findIndex(e => e?.id === messageUpdated?.id)
  if (messageIndex !== -1 && messageUpdated.deleted) {
    newFeed.splice(messageIndex, 1)
  } else if (messageIndex === -1 && !messageUpdated.deleted) {
    const tempMessageIndex = newFeed.findIndex(
      e => messageUpdated.tempId && e?.tempId === messageUpdated.tempId
    )
    if (tempMessageIndex !== -1) {
      newFeed[tempMessageIndex] = messageUpdated
    } else {
      newFeed.push(messageUpdated)
    }
    feedMarkAsRead(chatId)
  } else if (messageIndex !== -1 && messageUpdated.edited) {
    newFeed[messageIndex] = messageUpdated
  }
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: newFeed,
    },
  })
}

export const postMessageOptimistically = (
  chatId: string,
  messageBody: string,
  optimisticId?: string
) => (prev: Types.GetChatData) => {
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: [
        ...prev.feed?.feedEvents!,
        {
          id: optimisticId,
          tempId: optimisticId,
          deleted: false,
          edited: false,
          feedId: chatId,
          reactions: [],
          sender: prev.currentUser,
          event: {
            id: optimisticId,
            __typename: 'FeedMessagePosted',
          },
          createdAt: new Date().toISOString(),
          __typename: 'FeedEvent',
          bodyTokens: getBodyTokensFromText(messageBody, prev.feed?.members),
        },
      ],
    },
  })
}

export const addReactionOptimistically = (
  feedEventId: string,
  emoji: string,
  userId: string
) => (prev: Types.GetChatData) => {
  const messageIndex = prev.feed?.feedEvents?.findIndex(
    e => e?.id === feedEventId
  )
  if (!messageIndex || messageIndex === -1) {
    return prev
  }
  const newFeed = [...(prev.feed?.feedEvents ?? [])]
  newFeed[messageIndex] = Object.assign({}, newFeed[messageIndex], {
    ...newFeed[messageIndex],
    reactions: [
      ...(newFeed[messageIndex]?.reactions ?? []),
      { userId, emoji, __typename: 'Reaction' },
    ],
  })
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: newFeed,
    },
  })
}

export const removeReactionOptimistically = (
  feedEventId: string,
  emoji: string,
  userId: string
) => (prev: Types.GetChatData) => {
  const messageIndex = prev.feed?.feedEvents?.findIndex(
    e => e?.id === feedEventId
  )
  if (!messageIndex || messageIndex === -1) {
    return prev
  }
  const newFeed = [...(prev.feed?.feedEvents ?? [])]
  const newReactions = [...(newFeed[messageIndex]?.reactions ?? [])]
  const reactionIndex = newReactions.findIndex(
    reaction => reaction?.emoji === emoji && reaction.user?.id === userId
  )
  if (reactionIndex === -1) {
    return prev
  }
  newReactions.splice(reactionIndex, 1)
  newFeed[messageIndex] = Object.assign({}, newFeed[messageIndex], {
    ...newFeed[messageIndex],
    reactions: newReactions,
  })
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: newFeed,
    },
  })
}

export const editMessageOptimistically = (
  feedEventId: string,
  messageBody: string
) => (prev: Types.GetChatData) => {
  const editableMessageIndex = prev.feed?.feedEvents?.findIndex(
    e => e?.id === feedEventId
  )
  if (!editableMessageIndex || editableMessageIndex === -1) {
    return prev
  }
  const newFeed = [...(prev.feed?.feedEvents ?? [])]
  newFeed[editableMessageIndex] = Object.assign(
    {},
    newFeed[editableMessageIndex],
    {
      ...newFeed[editableMessageIndex],
      edited: true,
      bodyTokens: getBodyTokensFromText(messageBody, prev.feed?.members),
    }
  )
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: newFeed,
    },
  })
}

export const uploadFileOptimistically = (
  chatId: string,
  file: File,
  optimisticId?: string
) => (prev: Types.GetChatData) => {
  const localFilePath = window.URL.createObjectURL(file)
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: [
        ...prev.feed?.feedEvents!,
        {
          id: optimisticId,
          tempId: optimisticId,
          deleted: false,
          edited: false,
          feedId: chatId,
          reactions: [],
          sender: prev.currentUser,
          event: {
            id: optimisticId,
            __typename: 'FeedMessagePosted',
          },
          createdAt: new Date().toISOString(),
          __typename: 'FeedEvent',
          bodyTokens: [
            {
              id: optimisticId,
              uri: localFilePath,
              __typename: 'File',
              name: file.name,
              mimetype: file.type,
              size: file.size,
            },
          ],
        },
      ],
    },
  })
}

export const createTimeEntryOptimistically = (
  chatId: string,
  date: string,
  duration: string,
  memo: string,
  optimisticId?: string
) => (prev: Types.GetChatData) => {
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: [
        ...prev.feed?.feedEvents!,
        {
          id: optimisticId,
          tempId: optimisticId,
          deleted: false,
          edited: false,
          feedId: chatId,
          reactions: [],
          sender: null,
          event: {
            id: optimisticId,
            __typename: 'TimeEntryCreated',
          },
          createdAt: new Date().toISOString(),
          __typename: 'FeedEvent',
          bodyTokens: [
            {
              id: prev.currentUser?.id,
              name: prev.currentUser?.name,
              __typename: 'User',
            },
            {
              string: ' tracked ',
              __typename: 'StringObject',
            },
            {
              date,
              duration: convertDurationFromStringToSeconds(duration),
              id: optimisticId,
              memo,
              __typename: 'TimeEntry',
            },
          ],
        },
      ],
    },
  })
}

export const editTimeEntryOptimistically = (
  feedEventId: string,
  token: Types.ChatEventFragment_bodyTokens_TimeEntry
) => (prev: Types.GetChatData) => {
  const editableMessageIndex = prev.feed?.feedEvents?.findIndex(
    e => e?.id === feedEventId
  )
  if (!editableMessageIndex || editableMessageIndex === -1) {
    return prev
  }
  const newFeed = [...(prev.feed?.feedEvents ?? [])]
  const newTokens = newFeed[editableMessageIndex]?.bodyTokens?.map(
    bodyToken => {
      if (bodyToken?.__typename === 'TimeEntry') {
        return {
          ...token,
          duration: convertDurationFromStringToSeconds(token.duration),
        }
      }
      return bodyToken
    }
  )
  newFeed[editableMessageIndex] = Object.assign(
    {},
    newFeed[editableMessageIndex],
    {
      ...newFeed[editableMessageIndex],
      edited: true,
      bodyTokens: newTokens,
    }
  )
  return Object.assign({}, prev, {
    feed: {
      ...prev.feed,
      feedEvents: newFeed,
    },
  })
}
