import {all, take, fork, select, actionChannel} from 'redux-saga/effects'
import {buffers, eventChannel} from 'redux-saga'
import {$dispatch} from '../utils/effects'
import Worker from 'worker-loader!./worker.js'

import sel from '~/selectors'
import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import * as MessageTypes from './message-types'


// TODO: decide what to do with cards completed long ago, which are not displayed in the list.
//       Currently we show them in search output.

// TODO: filter out results with words containing the first match in the middle of the word,
//       like "ha[bah]ab[a]".


let worker
let workerMessageChan


export default function* $search() {
  initWorker()
  yield all([
    fork($searchSaga),
    fork($updateCardsSaga)
  ])
}


function* $searchSaga() {
  const actionChan = yield actionChannel(ActionTypes.DO_SEARCH, buffers.sliding(1))
  while (true) {
    const {query} = yield take(actionChan)
    if (query.length < 3) {
      yield* $dispatch(Actions.searchResults([]))
      continue
    }
    worker.postMessage({type: MessageTypes.DO_SEARCH, data: query})
    const msg = yield take(workerMessageChan)
    if (msg.type == MessageTypes.SEARCH_RESULTS) {
      yield* $dispatch(Actions.searchResults(msg.data))
    } else {
      const err = msg.data
      console.error('Search failed:', err && err.stack || err)
    }
  }
}


let prevEntities = null

function* $updateCardsSaga() {
  while (true) {
    const action = yield take()

    if (action.type == ActionTypes.SELECT_PROJECT_SUCCESS) {
      prevEntities = null
    }

    const [entities, projectId] = yield all([
      select(sel.entities),
      select(sel.selectingOrSelectedProjectId)
    ])

    if (entities == prevEntities) {
      continue
    }

    const cardEntities = entities.get('card')
    const userEntities = entities.get('user')

    if (!cardEntities || !userEntities) {
      continue
    }

    const cards = logTime('[saga search] Cards list build',
      () => buildCards(cardEntities, userEntities, projectId)
    )

    logTime('[saga search] postMessage', () => worker.postMessage({
      type: MessageTypes.UPDATE_CARDS_LIST,
      data: {cards}
    }))

    prevEntities = entities
  }
}


function buildCards(cardEntities, userEntities, projectId) {
  const cards = {}

  cardEntities.forEach(card => {
    if (sel.card.projectId(card) != projectId) {
      return
    }

    const id = card.get('id')
    const optimistic = card.get('optimistic')
    let assigneeId, completed, deleted

    if (optimistic) {
      assigneeId = optimistic.get('assignee')
      completed = optimistic.get('isCompleted')
      deleted = optimistic.get('isDeleted')
      if (assigneeId == undefined) {
        assigneeId = card.get('assignee')
      }
      if (completed == undefined) {
        completed = card.get('completed')
      }
      if (deleted == undefined) {
        deleted = card.get('deleted')
      }
    } else {
      assigneeId = card.get('assignee')
      completed = card.get('completed')
      deleted = card.get('deleted')
    }

    const assigneeEntity = assigneeId && userEntities.get(assigneeId)

    cards[id] = {id,
      title: card.get('name'),
      assignee: assigneeEntity && assigneeEntity.get('name') || '',
      deleted: deleted,
      completed: completed,
      parentId: sel.card.parentId(card)
    }
  })

  return cards
}


function initWorker() {
  // Because of same-origin policy, worker script cannot be server from a domain
  // different from the one page is loaded. So we serve the script from the
  // app domain, proxying all requests starting from /search-worker- to Cloudfront.
  // Here we strip the host part to make the request relative to the app domain.
  worker = new Worker()
  workerMessageChan = eventChannel(emit => {
    worker.onmessage = evt => emit(evt.data)
    worker.onerror = evt => emit({type: MessageTypes.SEARCH_ERROR, data: new Error(evt.message)})
    return () => {
      worker.onmessage = undefined
      worker.onerror = undefined
    }
  })
}


function logTime(desc, fn) {
  if (DEBUG) {
    const t0 = performance.now()
    const result = fn()
    console.info(`${desc} took ${performance.now() - t0} ms`)
    return result
  } else {
    return fn()
  }
}
