import {
  AssignCardMutation_cardAssign as Card,
  FetchProjectWithIdQuery_card_members,
  FetchProjectWithIdQuery_card,
  FetchProjectWithIdQuery_card_descendants,
} from '~/types/api'
import { groupBy } from 'lodash'

interface User
  extends Omit<FetchProjectWithIdQuery_card_members, '__typename'> {
  type: string
}

interface ProjectCard
  extends Omit<
    FetchProjectWithIdQuery_card,
    '__typename' | 'descendants' | 'assignee' | 'members'
  > {
  type: string
  assignee: string | null
  memberships?:
    | {
        card_id: string | null | undefined
        user_id: string | null | undefined
      }[]
    | undefined
  children: (string | null | undefined)[]
  path: (string | null)[]
}

export interface ProjectCardsObject {
  [id: string]: ProjectCard
}

export interface UsersObject {
  [id: string]: User
}

export const normalizeCardData = (card: Card | null | undefined) => {
  if (!card) {
    return null
  }
  return {
    entities: {
      card: {
        [card.id!]: {
          ...card,
          assignee: card.assigneeId,
          type: 'card',
        },
      },
    },
    result: card.id,
  }
}

const calculateCardPath = (
  card: ProjectCard,
  cardsById: ProjectCardsObject
) => {
  const path = []
  for (let cur = card; cur != null; cur = cardsById[cur.parentId!]) {
    path.push(cur.id)
  }
  path.reverse()
  return path
}

export const injectCardPaths = (cardsById: ProjectCardsObject) => {
  const cards = Object.values(cardsById)
  for (let i = 0; i < cards.length; i++) {
    cards[i].path.push(...calculateCardPath(cards[i], cardsById))
  }
}

export const getCardsChildren = (
  cards: (FetchProjectWithIdQuery_card_descendants | null)[] | null
) => {
  const sortedCards = cards?.sort(
    (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
  )
  return groupBy(sortedCards, 'parentId')
}
