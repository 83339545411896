import React from 'react'
import cn from 'classnames'

import DueDateBadge, { DueDateBadgeState, Flavor } from '../due-date-badge'
import DueDatePopup from '../due-date-popup'
import { ESCAPE_PRESSED, CLICKED_OUTSIDE } from '../popup-wrapper'
import dayjs from 'dayjs'

export default class DueDateDropdown extends React.Component {
  static Flavor = Flavor

  constructor(props) {
    super(props)

    this.state = {
      dueDateViewState: this.props.dueDate,
      dueDatePopupVisible: false,
      wasEdited: false,
    }

    this.showDueDatePopup = this.showDueDatePopup.bind(this)
    this.handleDueDatePopupHidden = this.handleDueDatePopupHidden.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dueDate !== this.props.dueDate) {
      this.setState({ dueDateViewState: nextProps.dueDate })
    }
  }

  showDueDatePopup() {
    if (this.props.dueDate) {
      this.setState({ dueDatePopupVisible: true })
    } else {
      this.setState({
        dueDatePopupVisible: true,
        dueDateViewState: dayjs().format('YYYY-MM-DD'),
      })
    }

    // Hack to fix case of sequential closing of one popup and opening another
    setTimeout(() => {
      this.props.onPopupShown()
    }, 0)
  }

  handleDueDatePopupHidden(dismissReason) {
    const wasPickedDate = this.state.wasEdited || this.props.dueDate
    if (dismissReason === ESCAPE_PRESSED) {
      if (!this.props.dueDate)
        this.setState({ dueDatePopupVisible: false, dueDateViewState: null })
      else this.setState({ dueDatePopupVisible: false })
    } else if (dismissReason === CLICKED_OUTSIDE && !wasPickedDate) {
      this.setState({ dueDatePopupVisible: false, dueDateViewState: null })
    } else {
      if (this.props.dueDate !== this.state.dueDateViewState)
        this.props.setCardDueDate(this.state.dueDateViewState)

      this.setState({ dueDatePopupVisible: false })
    }
    this.props.onPopupHidden()
  }

  setNewDueDate = dueDate => {
    if (dueDate !== this.props.dueDateViewState) {
      this.props.setCardDueDate(dueDate)
    }
    this.setState({ dueDatePopupVisible: false })
    this.props.onPopupHidden()
  }

  render() {
    const { isTaskCompleted, viewportNodeSelector } = this.props

    const isMissed = !dayjs(this.state.dueDateViewState)
      .endOf('day')
      .isAfter(dayjs())

    let dueDateBadgeState = DueDateBadgeState.DUE_DATE_BADGE_REGULAR
    if (isTaskCompleted) {
      dueDateBadgeState = DueDateBadgeState.DUE_DATE_BADGE_FOR_COMPLETED_TASK
    } else if (isMissed) {
      dueDateBadgeState = DueDateBadgeState.DUE_DATE_BADGE_MISSED
    }

    const isActive = this.state.dueDatePopupVisible

    const clearDueDate = () => {
      this.setState({
        dueDateViewState: null,
        dueDatePopupVisible: false,
        wasEdited: false,
      })
      this.props.unsetCardDueDate()
      this.props.onPopupHidden()
    }

    const classNames = cn(
      'due-date-badge-wrapper',
      !this.state.dueDateViewState ? '-empty' : null
    )

    return (
      <div className={classNames}>
        <DueDateBadge
          dueDate={this.state.dueDateViewState}
          isActive={isActive}
          className={this.props.badgeClassName}
          dueDateBadgeState={dueDateBadgeState}
          openCalendar={this.showDueDatePopup}
          flavor={this.props.flavor}
        />
        {this.state.dueDatePopupVisible && (
          <DueDatePopup
            key="due-date-popup"
            dueDate={this.state.dueDateViewState}
            viewportNodeSelector={viewportNodeSelector}
            onPopupHidden={this.handleDueDatePopupHidden}
            setCardDueDate={this.setNewDueDate}
            clearDueDate={clearDueDate}
          />
        )}
      </div>
    )
  }
}
