
export default function splitArray(arr, pred) {
  const pass = []
  const fail = []
  for (let i = 0; i < arr.length; ++i) {
    const el = arr[i]
    if (pred(el)) {
      pass.push(el)
    } else {
      fail.push(el)
    }
  }
  return [pass, fail]
}
