import {take} from 'redux-saga/effects'
import {push as navigateTo} from 'connected-react-router'
import {$dispatch} from '../utils/effects'

import * as ActionTypes from '~/actions/types'

import {makeAuthURL} from '~/config'
import {isAbsoluteURL} from '~/utils/url'


const GET_URI_BY_ACTION = {
  [ActionTypes.SHOW_PROVIDER_AUTH_PAGE]: ({provider, state}) => {
    return makeAuthURL(provider, state)
  },
  [ActionTypes.LOGIN_SUCCESS]: ({returnPath}) => returnPath,
  [ActionTypes.OPEN_INAPP_URL]: ({url}) => url
}


export default function* $handleActions() {
  const types = Object.keys(GET_URI_BY_ACTION)
  while (true) {
    const action = yield take(types)
    const getURI = GET_URI_BY_ACTION[ action.type ]
    const uri = getURI(action)
    if (!uri) {
      continue
    }
    if (isAbsoluteURL(uri)) {
      replaceAppWithURL(uri)
      return
    }
    yield* $dispatch(navigateTo(uri))
  }
}


function replaceAppWithURL(uri) {
  window.location.href = uri
}
