import PropTypes from 'prop-types'
import React from 'react'

import PopupWrapper, {ESCAPE_PRESSED, CLICKED_OUTSIDE} from '../popup-wrapper'
import {isValidDurationString, convertDurationFromStringToSeconds} from '~/utils/duration'
import {formatDuration} from '~/utils/time'

export default class BudgetDropdown extends React.Component {
  static propTypes = {
    budget: PropTypes.string.isRequired,
    budgetRemaining: PropTypes.string.isRequired,
    closeSetBudgetForm: PropTypes.func.isRequired,
    confirmSetBudget: PropTypes.func.isRequired,
    showRollupsSection: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      oldValue: this.props.budget,
      displayedBudget: this.props.budgetRemaining,
      value: '0h',
      blocked: false
    }
  }

  toggleClose = () => {
    this.props.closeSetBudgetForm()
  }

  valueChange = e => {
    const {target} = e
    const {value} = target
    if (isValidDurationString(value) && value !== '') {
      const budgetRemaining = convertDurationFromStringToSeconds(this.props.budgetRemaining)
      const addition = convertDurationFromStringToSeconds(value)
      const displayedBudget = formatDuration(budgetRemaining + addition)
      this.setState({
        value: value,
        blocked: false,
        displayedBudget: displayedBudget
      })
    } else {
      this.setState({blocked: true})
    }
  }

  handleDown = (e) => {
    if (e.key === 'Escape') {
      this.toggleClose()
    }
    if (e.key !== 'Enter') {
      return
    }
    e.preventDefault()
    this.updateBudget()
  }

  handleBudgetDropdownHidden = (dismissReason) => {
    if (dismissReason === ESCAPE_PRESSED || dismissReason === CLICKED_OUTSIDE) {
      this.toggleClose()
    }
  }

  updateBudget = () => {
    if (this.state.blocked) {
      return
    }
    const decrease = this.state.value.indexOf('-') > -1 ? true : false
    this.props.confirmSetBudget(this.state.value, decrease)
    this.props.showRollupsSection()
  }

  updateInputVal = (decrease) => {
    const budgetInputStr = this.budgetInput.value
    let newInputVal
    if (decrease) {
      newInputVal = convertDurationFromStringToSeconds(budgetInputStr) - 3600
    } else {
      newInputVal = convertDurationFromStringToSeconds(budgetInputStr) + 3600
    }
    this.budgetInput.value = formatDuration(newInputVal)
    const budgetRemaining = convertDurationFromStringToSeconds(this.props.budgetRemaining)
    const displayedBudget = formatDuration(budgetRemaining + newInputVal)
    this.setState({
      value: formatDuration(newInputVal),
      displayedBudget: displayedBudget
    })
  }

  render() {
    const {value, blocked, displayedBudget} = this.state

    return (
      <PopupWrapper onDismiss={this.handleBudgetDropdownHidden}>
        <div className='budget-dropdown'>
          <div className='budget-form'>
            <div className='budget-header'>
              <div className='budget-label'>Set Project Budget</div>
              <div className='budget-close' onClick={this.toggleClose}>close</div>
            </div>
            <div className='budget-body'>
              <div className='budget-line'>
                <div className='budget-text'>
                  How much would<br/>you like to allocate?
                </div>
                <div className='budget-input-container'>
                  <div
                    className='budget-change-btn'
                    onClick={() => {this.updateInputVal(true)}}
                  >-</div>
                  <input 
                    className='budget-input'
                    defaultValue={value}
                    onChange={this.valueChange}
                    onKeyDown={this.handleDown}
                    ref={(budgetInput) => { this.budgetInput = budgetInput }}
                    autoFocus
                  />
                  <div
                    className='budget-change-btn'
                    onClick={() => {this.updateInputVal(false)}}
                  >+</div>
                </div>
              </div>
              <div className='budget-line'>
                <div className='budget-text'>Budget Remaining:</div>
                <div className='budget-amount'>
                  <span className='new-duration'>{displayedBudget}</span>
                </div>
              </div>
            </div>
            <div className='budget-footer'>
              <div
                onClick={this.updateBudget}
                className={`budget-button ${blocked ? 'blocked' : ''}`}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      </PopupWrapper>
    )
  }
}
