import {Map} from 'immutable'
import * as ActionTypes from '~/actions/types'

export const INITIAL_STATE = recordsReducer.INITIAL_STATE = Map({
  selectedId: null,
  selectingId: null
})

export default function recordsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SELECT_RECORD: {
      return state.merge({
        selectedId: null,
        selectingId: action.recordId
      })
    }
    case ActionTypes.SELECT_RECORD_SUCCESS: {
      return state.merge({
        selectedId: action.recordId,
        selectingId: null
      })
    }
  }
  return state
}
