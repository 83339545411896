import { take, call, fork, select } from 'redux-saga/effects'
import { $dispatch } from './utils/effects'

import * as ActionTypes from '~/actions/types'
import * as Actions from '~/actions'

import sel from '~/selectors'
import { FilteringState } from '~/utils/filter-constants'
import CardRelation from '~/utils/card-relation'
import { moveCard, renameCard, createCard } from '~/api/cards'

export default function* $cardsEditingSaga($addQueueItem, changeQueueId) {
  while (true) {
    const action = yield take([
      ActionTypes.START_EDITING_CARD,
      ActionTypes.CREATE_EPHEMERAL_CARD,
    ])
    const cardId =
      action.type === ActionTypes.START_EDITING_CARD
        ? action.cardId
        : action.ephemeralId
    yield fork($startEditingCard, cardId, $addQueueItem, changeQueueId)
  }
}

function* $startEditingCard(cardId, $addQueueItem, changeQueueId) {
  const modifyingCardIds = []

  function* $addModifyingCardId(cardId) {
    if (modifyingCardIds.indexOf(cardId) === -1) {
      modifyingCardIds.push(cardId)
      yield* $dispatch(Actions.startModifyingCard(cardId))
    }
  }

  function* $stopModifyingCards() {
    for (let i = 0; i < modifyingCardIds.length; ++i) {
      yield* $dispatch(Actions.cancelModifyingCard(modifyingCardIds[i]))
    }
  }

  const card = yield select(sel.cardWithId, cardId)

  const preEditingParentId = sel.card.parentId(card)
  const preEditingParent = yield select(sel.cardWithId, preEditingParentId)
  const preEditingChildIndex = preEditingParent.get('children').indexOf(cardId)
  const preEditingState = {
    parentId: preEditingParentId,
    childIndex: preEditingChildIndex,
  }

  yield* $addModifyingCardId(cardId)
  yield* $addModifyingCardId(preEditingParentId)

  const nestingLevelTask = yield fork(
    $watchChangeNestingLevel,
    cardId,
    $addModifyingCardId
  )

  const termAction = yield take([
    ActionTypes.COMMIT_EDITING_CARD,
    ActionTypes.CANCEL_EDITING_CARD,
  ])
  nestingLevelTask.cancel()

  if (termAction.type === ActionTypes.COMMIT_EDITING_CARD) {
    yield* $addQueueItem(
      cardId,
      termAction,
      function* $commitEditingCardQueueItem() {
        yield* $commitEditingCard(termAction, preEditingState, changeQueueId)
        yield* $stopModifyingCards()
      }
    )
  } else {
    yield* $cancelEditingCard(termAction, preEditingState)
    yield* $stopModifyingCards()
  }
}

function* $watchChangeNestingLevel(cardId, $addModifyingCardId) {
  while (true) {
    const action = yield take(ActionTypes.CHANGE_CARD_NESTING_LEVEL)
    if (action.cardId === cardId) {
      yield* $changeCardNestingLevel(action, $addModifyingCardId)
    }
  }
}

function* $changeCardNestingLevel(action, $addModifyingCardId) {
  const { cardId, doIncrease } = action
  const cards = yield select(sel.cards)
  const cardsViewState = yield select(sel.cardsViewState)
  const card = cards.get(cardId)
  const parentId = sel.card.parentId(card)
  const parentCard = cards.get(parentId)

  const visibleChildren = parentCard.get('children').filter(childId => {
    const filteringInfo = sel.cardViewState.filteringInfo(
      cardsViewState,
      childId
    )
    return FilteringState.isVisible(filteringInfo.get('state'))
  })

  const cardIndex = visibleChildren.indexOf(cardId)

  if (doIncrease) {
    if (cardIndex == 0) {
      return
    }
    const newParentId = visibleChildren.get(cardIndex - 1)
    const newParent = cards.get(newParentId)
    if (newParent.get('isEphemeral')) {
      // disallow moving cards under ephemeral parents as we don't have their real id yet
      return
    }
    yield* $addModifyingCardId(newParentId)
    yield* $dispatch(Actions.moveCard(cardId, newParentId, CardRelation.CHILD))
  } else {
    if (cardIndex != visibleChildren.size - 1) {
      return
    }
    const newParentId = sel.card.parentId(card)
    yield* $addModifyingCardId(newParentId)
    yield* $dispatch(Actions.moveCard(cardId, parentId, CardRelation.AFTER))
  }
}

function* $commitEditingCard(action, preEditingState, changeQueueId) {
  const { cardId, newName, shouldAddNewChild } = action

  const cards = yield select(sel.cards)
  const card = cards.get(cardId)

  if (card.get('isEphemeral')) {
    yield call(
      $finishCreatingCard,
      card,
      shouldAddNewChild,
      changeQueueId,
      cards
    )
    return
  }

  let response

  if (newName != card.get('name')) {
    yield* $dispatch(Actions.cardRenamed(newName))
    response = yield call(renameCard, action.cardId, action.newName)
  }

  if (getWasCardMoved(card, cards, preEditingState)) {
    yield* $dispatch(Actions.cardMoved(true))
    const parent = cards.get(preEditingState.parentId)
    const childIndex = parent.get('children').indexOf(cardId)
    const { relatedCardId, relation } = getRelation(parent, childIndex)
    response = yield call(moveCard, cardId, relatedCardId, relation)
  }

  if (response) {
    yield* $dispatch(Actions.updateCard(response.result, response.entities))
  }
}

function* $cancelEditingCard(action, preEditingState) {
  const { cardId } = action
  const cards = yield select(sel.cards)
  const card = cards.get(cardId)

  if (card.get('isEphemeral')) {
    yield* $dispatch(Actions.deleteEphemeralCard(card.get('id')))
    return
  }

  if (getWasCardMoved(card, cards, preEditingState)) {
    const preEditingParent = cards.get(preEditingState.parentId)
    const { relatedCardId, relation } = getRelation(
      preEditingParent,
      preEditingState.childIndex
    )
    yield* $dispatch(Actions.moveCard(cardId, relatedCardId, relation))
  }

  yield* $dispatch(Actions.clearCardOptimisticState(cardId))
}

function* $finishCreatingCard(card, shouldAddNewChild, changeQueueId, cards) {
  const title = card.get('optimistic').get('name')

  yield* $dispatch(Actions.cardCreated(title))

  const parentId = sel.card.parentId(card)
  const optimistic = card.get('optimistic')
  const ephemeralId = card.get('id')
  const top = card.get('top')

  let beforeId = null
  if (top) {
    const parentCard = cards.get(parentId)
    const siblings = parentCard.get('children')
    if (siblings.size > 1) {
      const currInd = siblings.indexOf(ephemeralId)
      beforeId =
        currInd + 1 === siblings.size ? null : siblings.get(currInd + 1)
    }
  }

  if (shouldAddNewChild && !top) {
    yield* $dispatch(Actions.createEphemeralCard(parentId))
  }

  const response = yield call(
    createCard,
    title,
    parentId,
    beforeId,
    optimistic.get('assignee')
  )

  const finalId = response.result
  changeQueueId(ephemeralId, finalId)

  yield* $dispatch(
    Actions.replaceEphemeralCard(
      ephemeralId,
      finalId,
      parentId,
      response.entities
    )
  )
  if (top) {
    yield* $dispatch(Actions.selectCard(finalId))
  }
}

function getWasCardMoved(card, cards, preEditingState) {
  const currentParentId = sel.card.parentId(card)
  if (currentParentId != preEditingState.parentId) {
    return true
  }
  const currentParent = cards.get(currentParentId)
  const siblings = currentParent.get('children')
  const childIndex = siblings.indexOf(card.get('id'))
  return childIndex != preEditingState.childIndex
}

function getRelation(parent, childIndex) {
  const siblings = parent.get('children')
  if (siblings.size === 0) {
    return { relatedCardId: parent.get('id'), relation: CardRelation.CHILD }
  } else if (childIndex === 0) {
    return { relatedCardId: siblings.get(1), relation: CardRelation.BEFORE }
  } else {
    return {
      relatedCardId: siblings.get(childIndex - 1),
      relation: CardRelation.AFTER,
    }
  }
}
