import {
  all,
  take,
  call,
  fork,
  select,
  actionChannel,
} from 'redux-saga/effects'
import { buffers } from 'redux-saga'

import sel from '~/selectors'
import * as ActionTypes from '~/actions/types'

import {
  trackAction,
  trackPageOnceAtVisit,
  actionTypesToTrack,
} from '~/analytics'
import {
  trackMixpanelEvent,
  PAGE_VIEW,
  OLD_WEB_APP_CATEGORY,
} from '~/components/mixpanel'

export default function* $analyticsSaga() {
  yield all([fork($trackActions), fork($trackPageVisits)])
}

function* $trackActions() {
  const actionChan = yield actionChannel(
    actionTypesToTrack,
    buffers.sliding(1000)
  )
  while (true) {
    const action = yield take(actionChan)
    yield call(trackAction, action)
  }
}

function* $trackPageVisits() {
  while (true) {
    yield take(ActionTypes.ROUTE_CHANGED)
    const [routingState, projectIds] = yield all([
      select(sel.routingState),
      select(sel.projectIds),
    ])
    const route = routingState.get('route')
    const routeCardId = route.get('cardId')
    trackMixpanelEvent(OLD_WEB_APP_CATEGORY, PAGE_VIEW, {
      pageName: route.get('type'),
      pathname: routingState.get('location').get('pathname'),
    })
    trackPageOnceAtVisit({
      pageName: route.get('type'),
      pathname: routingState.get('location').get('pathname'),
      // Notice that isProject is false for non-card routes like "My Week" or "Team Week"
      // eslint-disable-next-line eqeqeq
      isProject: routeCardId != null && projectIds.indexOf(routeCardId) >= 0,
    })
  }
}
