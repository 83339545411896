import React from 'react'
import connect from '../utils/connect'

import selectors from '~/selectors'

import SidebarContainer from '../sidebar'
import DirectChats from '../direct-chats'

import Loader from '../loader'
import InvitationPopup from '../invitation-popup'
import Header from '../header'
import UndoPopupContainer from '../undo-popup'
import FullscreenFeed from '../fullscreen-feed'

import { ROUTE_VIDEO } from '~/utils/routing'

class LayoutContainer extends React.Component {
  static mapStateToProps(state) {
    return {
      selectedProjectId: selectors.selectedProjectId(state),
      routeType: selectors.routeType(state),
      selectedFeedId: selectors.selectedFeedId(state),
      directChatIds: selectors.directChats(state),
      fullscreenChatOpen: selectors.fullscreenChat(state),
    }
  }

  render() {
    const {
      selectedProjectId,
      children,
      selectedFeedId,
      routeType,
    } = this.props

    if (routeType !== ROUTE_VIDEO) {
      return (
        <div>
          <Header />
          {!selectedProjectId ? (
            <Loader colorScheme="dark" expandOnFullHeight={true} />
          ) : (
            <div className="layout">
              <SidebarContainer />
              {children}
            </div>
          )}
          <InvitationPopup />
          <UndoPopupContainer />
          <DirectChats
            feedIds={this.props.directChatIds}
            selectUser={this.props.actions.selectUser}
          />
          <FullscreenFeed
            isFullscreenFeedOpen={this.props.fullscreenChatOpen}
            selectedFeedId={selectedFeedId}
          />
        </div>
      )
    } else {
      return (
        <div>
          {!selectedProjectId ? (
            <Loader colorScheme="dark" expandOnFullHeight={true} />
          ) : (
            <div className="layout">
              <div className="layout-main-frame">
                <div className="layout-content">{children}</div>
              </div>
            </div>
          )}
        </div>
      )
    }
  }
}

export default connect(LayoutContainer)
