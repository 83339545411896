import React from 'react'
import cn from 'classnames'

import AssigneeCard from '../assignee-card'
import MoreActions from '../more-actions'
import WrapWithText from '../wrap-with-text'
import DueDateDropdown from '../due-date-dropdown'
import UnreadCountBadge from '../unread-count-badge'
import EstimateDropdown from '../estimate-dropdown'
import Tooltip from '../tooltip'

import { setClipboardText } from '~/utils/clipboard'
import { hasClass } from '~/utils/dom'

import CardListItemLayout from './card-list-item-layout'
import {
  CARD_CLASS,
  CARD_NODE_CONTAINER_CLASS,
  getLevelIndent,
  BEFORE_TEXT_ACTIONS_CLASS,
  INLINE_ACTIONS_CLASS,
  CARD_TEXT_SECTION_CLASS,
  CHECKBOX_CLASS,
  CARD_LIST_PADDING_RIGHT,
} from './card-list-constants'

import { propTypes, defaultProps } from './card-list-item-prop-types'

// The distance by which user needs to move mouse after pressing its left button
// to start dragging an item. If user releases left button before moving by that
// distance, a click is registered instead.
//
const DRAG_DR = 3
const DRAG_DR_SQ = DRAG_DR * DRAG_DR

export default class RegularCardListItem extends React.Component {
  static propTypes = propTypes
  static defaultProps = defaultProps

  constructor() {
    super()
    this.state = {
      popupVisible: false,
      addToTop: false,
    }

    this.boundActions = {
      handleItemRef: node => this.handleItemRef(node),
      getItemNodeBounds: () => this.getItemNodeBounds(),
      handleCardEditNodeRef: node => this.handleCardEditNodeRef(node),
      handleEditingCardKeyDown: e => this.handleEditingCardKeyDown(e),
      handleDocumentMouseDown: e => this.handleDocumentMouseDown(e),
      handleItemClick: e => this.handleItemClick(e),
      copyCardLink: () => this.copyCardLink(),
      handleMouseDown: e => this.handleMouseDown(e),
      handleMouseMove: e => this.handleMouseMove(e),
      openCalendar: () => this.openCalendar(),
      updateEstimate: estimate => this.updateEstimate(estimate),
      handlePopupShown: () => this.setState({ popupVisible: true }),
      handlePopupHidden: () => this.setState({ popupVisible: false }),
    }

    this.renderCard = this.renderCard.bind(this)
    this.getMoreActions = this.getMoreActions.bind(this)

    this.layoutComponent = null
    this.handleLayoutRef = ref => (this.layoutComponent = ref)

    this.dueDateDropdown = null
    this.handleDueDateDropdownRef = ref => (this.dueDateDropdown = ref)

    this.clickX = null
    this.clickY = null
    this.ignoreClick = false
    this.isDragging = false
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleDown)
    document.addEventListener('keyup', this.handleUp)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDown)
    document.removeEventListener('keyup', this.handleUp)
  }

  render() {
    const { props } = this
    return (
      <CardListItemLayout
        ref={this.handleLayoutRef}
        filteringInfo={props.filteringInfo}
        isExpanded={props.isExpanded}
        renderCard={this.renderCard}
        renderChildren={props.renderChildren}
        containerClasses={cn(this.isDragging && 'dragging')}
        expandCollapseAnimationManager={props.expandCollapseAnimationManager}
      ></CardListItemLayout>
    )
  }

  renderCard() {
    const { props, state } = this
    const cardClass = cn(
      CARD_CLASS,
      props.isCompleted ? 'completed' : 'pending',
      state.popupVisible && 'showing-popup',
      props.isSelected && 'selected',
      props.assignee && 'assigned'
    )
    const cardStyles = {
      paddingRight: CARD_LIST_PADDING_RIGHT,
    }
    return (
      <a
        href={props.href || '#'}
        data-card-id={props.id}
        className={cardClass}
        style={cardStyles}
        onClick={this.boundActions.handleItemClick}
        draggable={false}
        onMouseDown={this.boundActions.handleMouseDown}
        ref={this.boundActions.handleItemRef}
      >
        <div
          className={BEFORE_TEXT_ACTIONS_CLASS}
          style={{ marginLeft: this.getLevelIndent() }}
        >
          {this.renderExpandCollapse()}
          {this.renderCheckbox()}
        </div>
        <div className={CARD_TEXT_SECTION_CLASS}>
          <WrapWithText text={props.name}>
            <div className={INLINE_ACTIONS_CLASS}>
              {this.renderUnreadCount()}
              {this.renderAssignee()}
              {this.renderEstimates()}
              {this.renderDueDateDropdown()}
              {this.renderAddSubtask()}
              {this.renderMore()}
            </div>
          </WrapWithText>
        </div>
      </a>
    )
  }

  renderExpandCollapse() {
    const { props } = this
    if (mayExpand(props.filteringInfo)) {
      const className = cn('drop-down-btn', props.isExpanded ? '' : 'collapsed')
      return (
        <div
          key="collapse"
          className={className}
          onClick={props.actions.toggleExpanded}
        ></div>
      )
    }
  }

  renderCheckbox() {
    const tooltipLabel = this.props.isCompleted ? 'Uncomplete' : 'Complete'
    return (
      <Tooltip
        key="checkbox"
        label={tooltipLabel}
        showDelayMs={1000}
        dx={-1}
        dy={12}
      >
        <div className={`${CHECKBOX_CLASS} -regular`}></div>
      </Tooltip>
    )
  }

  renderDueDateDropdown() {
    return (
      <DueDateDropdown
        key="due-date-dropdown"
        ref={this.handleDueDateDropdownRef}
        dueDate={this.props.dueDate}
        isTaskCompleted={this.props.isCompleted}
        viewportNodeSelector=".card-list-scrollable"
        onPopupShown={this.boundActions.handlePopupShown}
        onPopupHidden={this.boundActions.handlePopupHidden}
        setCardDueDate={this.props.actions.setCardDueDate}
        unsetCardDueDate={this.props.actions.unsetCardDueDate}
      ></DueDateDropdown>
    )
  }

  renderEstimates() {
    return (
      <EstimateDropdown
        key="estimate-dropdown"
        estimateMin={this.props.estimateMin}
        estimateMax={this.props.estimateMax}
        onPopupShown={this.boundActions.handlePopupShown}
        onPopupHidden={this.boundActions.handlePopupHidden}
        updateEstimate={this.boundActions.updateEstimate}
        viewportNodeSelector=".card-list-scrollable"
      />
    )
  }

  renderAssignee() {
    const isCompleted = this.props.isCompleted
    const noAssignee = this.props.assignee === null
    return (
      !(isCompleted && noAssignee) && (
        <AssigneeCard
          key="assignee"
          assignee={this.props.assignee}
          me={this.props.me}
          assign={this.props.actions.assign}
          unassign={this.props.actions.unassign}
          projectMembers={this.props.projectMembers}
          onPopupShown={this.boundActions.handlePopupShown}
          onPopupHidden={this.boundActions.handlePopupHidden}
          isCompleted={this.props.isCompleted}
          viewportNodeSelector=".main"
        ></AssigneeCard>
      )
    )
  }

  renderUnreadCount() {
    const { unreadCount } = this.props
    return (
      (unreadCount || null) && (
        <UnreadCountBadge key="unread-indicator-badge" count={unreadCount} />
      )
    )
  }

  renderAddSubtask() {
    return (
      <Tooltip label="Add subtask" dy={-2}>
        <div
          className="add-subtask"
          onClick={() => this.createChildCard()}
        ></div>
      </Tooltip>
    )
  }

  renderMore() {
    return (
      <MoreActions
        key="more-actions"
        getActions={this.getMoreActions}
        onPopupShown={this.boundActions.handlePopupShown}
        onPopupHidden={this.boundActions.handlePopupHidden}
        viewportNodeSelector=".card-list-scrollable"
      ></MoreActions>
    )
  }

  getMoreActions() {
    return [
      {
        name: 'Zoom in',
        icon: 'zoom-in',
        action: this.props.actions.setTopLevel,
      },
      {
        name: 'Copy link',
        icon: 'link',
        action: this.boundActions.copyCardLink,
      },
      this.getDetailsAction(),
      { name: '-' },
      { name: 'Edit', icon: 'edit', action: this.props.actions.startEditing },
      { name: '-' },
      {
        name: 'Delete',
        icon: 'delete',
        action: this.props.actions.delete,
        isRed: true,
      },
    ].filter(item => !!item)
  }

  getDetailsAction() {
    const { isSelected, actions } = this.props
    return !isSelected
      ? { name: 'Show details', icon: 'chat', action: actions.selectCard }
      : {
          name: 'Hide details',
          icon: 'chat-outline',
          action: actions.clearCardSelection,
        }
  }

  getLevelIndent() {
    return getLevelIndent(this.props.cardLevel)
  }

  handleDown = e => {
    if (e.key === 'Shift') {
      this.setState({
        addToTop: true,
      })
    }
  }

  handleUp = e => {
    if (e.key === 'Shift') {
      this.setState({
        addToTop: false,
      })
    }
  }

  handleItemRef(node) {
    this.itemNode = node
    this.props.onNode &&
      this.props.onNode(node, this.boundActions.getItemNodeBounds)
  }

  handleCardEditNodeRef(node) {
    this.cardEditNode = node
    node && node.focus()
  }

  getItemNodeBounds() {
    return this.itemNode.getBoundingClientRect()
  }

  copyCardLink() {
    let link = 'https://oldapp.turtleos.com' + this.props.href
    setClipboardText(link)
  }

  openCalendar() {
    this.dueDateDropdown.showDueDatePopup()
  }

  updateEstimate(estimate) {
    this.props.actions.updateEstimate(estimate)
  }

  createChildCard() {
    const { addToTop } = this.state
    if (!this.props.isExpanded) {
      this.props.actions.toggleExpanded()
    }
    this.props.actions.createChildCard(addToTop)
  }

  get allowDragging() {
    return this.props.onDragStart !== null && !this.state.popupVisible
  }

  handleMouseDown(e) {
    if (e.nativeEvent.button !== 0) {
      return
    }

    e.preventDefault()
    this.ignoreClick = false

    if (!this.allowDragging) {
      return
    }

    this.clickX = e.clientX
    this.clickY = e.clientY

    window.addEventListener('mousemove', this.boundActions.handleMouseMove)
    window.addEventListener('mouseup', this.boundActions.handleMouseUp)
  }

  handleMouseMove(e) {
    const dx = e.clientX - this.clickX
    const dy = e.clientY - this.clickY
    if (dx * dx + dy * dy > DRAG_DR_SQ) {
      this.stopListeningMouseEvents()
      this.startDragging(e)
    }
  }

  startDragging(e) {
    if (this.props.isEphemeral) {
      return
    }

    this.isDragging = true
    this.ignoreClick = true

    const bounds = this.getItemNodeBounds()

    this.props.onDragStart({
      mouseX: e.clientX,
      mouseY: e.clientY,
      itemDx: this.clickX - bounds.left,
      itemDy: this.clickY - bounds.top,
      levelIndent: this.getLevelIndent(),
      width: this.layoutComponent.getWidth(),
      stopDragging: this.stopDragging.bind(this),
    })

    this.forceUpdate()
  }

  stopDragging() {
    this.isDragging = false
    this.forceUpdate() // TODO: this should be not needed after reducer is ready
  }

  handleMouseUp() {
    this.stopListeningMouseEvents()
  }

  stopListeningMouseEvents() {
    window.removeEventListener('mousemove', this.boundActions.handleMouseMove)
    window.removeEventListener('mouseup', this.boundActions.handleMouseUp)
  }

  handleItemClick(e) {
    e.preventDefault()
    this.stopListeningMouseEvents()

    if (this.ignoreClick) {
      return
    }

    const { isEphemeral, actions } = this.props
    if (isEphemeral) {
      return
    }

    let node = e.target
    if (hasClass(CHECKBOX_CLASS, node)) {
      actions.toggleCompleted()
      return
    }

    while (node && !hasClass(CARD_NODE_CONTAINER_CLASS, node)) {
      if (
        hasClass(BEFORE_TEXT_ACTIONS_CLASS, node) ||
        hasClass(INLINE_ACTIONS_CLASS, node)
      ) {
        return
      }
      node = node.parentNode
    }

    if (this.props.isSelected) {
      this.props.actions.startEditing()
    } else {
      this.props.actions.selectCard()
    }
  }
}

function mayExpand(filteringInfo) {
  return filteringInfo.hasPassingDescendants
}
